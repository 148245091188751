import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cannabinoid } from '../../../../../../../../models/enum/dto/cannabinoid';

@Component({
  selector: 'app-popper-cannabinoid-preview-list-item',
  templateUrl: './popper-cannabinoid-preview-list-item.component.html',
  styleUrls: [
    '../../menu-section-product-preview.component.scss',
    './popper-cannabinoid-preview-list-item.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopperCannabinoidPreviewListItemComponent {

  @Input() cannabinoidTitle: Cannabinoid;
  @Input() cannabinoidValue: string;

}
