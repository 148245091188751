<div class="datatable-container">
  <!-- Filter Options -->
  <div class="datatable-filters-container">
    <app-all-products-filter-form
      #filterFormComponent
      [includeBottomBorder]="false"
      (searchText)="hasSearchText.emit($event?.length > 0)"
      (hasFilters)="hasFilters.emit($event)"
      (hideOutOfStock)="hideOutOfStock.emit($event)"
      (resetPageNumber)="productsTableComponent?.resetPageNumber()">
    </app-all-products-filter-form>
  </div>

  <div class="datatable-content">
    <app-products-table
      #productsTableComponent
      [products]="viewModel.productsFilteredByProductTypeVariantTypeStrainType$ | push : 'userBlocking'"
      [nItemsToDisplay]="viewModel.numberOfProductsPerPage$ | push : 'userBlocking'"
      [tableColumnsChanged]="viewModel.tableColumnsChanged$ | push : 'immediate'">
    </app-products-table>
  </div>
</div>
