<lib-reactive-form-merge-groups
  #addNewLabelFormCapture
  [mergeKey]="'addNewLabelFormCapture'"
  (formsSubmitted$)="submitForms($event)">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div
    #modalHeader
    libStickyAutoPosition
    [stickyCollectionKey]="'create-new-label-modal'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container add-new-label-header"
    [style.position]="!(viewModel.allowedToCreateSmartLabel$ | async) ? 'relative' : null">
    <div class="modal-header no-bottom-border">
      <div class="modal-title" ngbAutofocus>Add Custom Label</div>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'create-new-label-modal'"
    [stickChildClassInstead]="'mat-tab-header'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    [style.overflow-y]="'unset'">
    <app-tab-bar
      #tabController
      [tabs]="tabs$ | async"
      [hideTabBarHeader]="(tabs$ | async)?.length === 1"
      [skipFragmentAppending]="true"
      [useDefaultHeight]="false"
      (selectedTab)="viewModel.connectToCurrentlySelectedTab($event)"
      class="flex-fill">
    </app-tab-bar>
  </div>

  <div
    #footerContainer
    class="sticky-footer-container modal-footer-flex-end add-new-label-footer"
    [style.position]="bottomButtonPosition$ | async"
    [style.padding-top.rem]="1"
    [style.z-index]="viewModel.stickyZIndex$ | async">
    <lib-reactive-form-group [bindTo]="viewModel.initialLabel" [mergeKey]="'addNewLabelFormCapture'">
      <lib-reactive-form-checkbox
        *ngIf="viewModel.allowApplyToAllLocations$ | async"
        [inputName]="'applyToAllCompanyLocations'"
        [bindingProperty]="'applyToAllCompanyLocations'">
        Apply to all company locations
      </lib-reactive-form-checkbox>
    </lib-reactive-form-group>
    <div class="button-holder">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary [disabled]="disableSubmitForm$ | async" (buttonClicked)="formSubmitted()">
        Create
      </lib-button-primary>
    </div>
  </div>
</div>
