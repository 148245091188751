import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductTotalTerpeneWithUnits'
})
export class GetProductTotalTerpeneWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): any {
    return product?.getTotalTerpenesWithUnits(true);
  }

}
