import { Pipe, PipeTransform } from '@angular/core';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'variantDisplayAttributesMethod'
})
export class VariantDisplayAttributesMethodPipe implements PipeTransform {

  transform(variant: Variant, method: string, ...args: any[]): any {
    return variant?.displayAttributes?.[method]?.(...args);
  }

}
