import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductMaxTACWithUnits'
})
export class GetProductMaxTacWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): string | null {
    return product?.getMaxTACWithUnits(true);
  }

}
