export class ArrayUtils {

  // laces two lists together - ie, interlace([0,2,4,6,8,10], [1,3,5,7,9]) = [0,1,2,3,4,5,6,7,8,9]
  static interlace = (zs, ys) => {
    if (zs.length === 0) { return ys; }          // base case, are there any Z's to lace? no? then return Y's
    const  [x, ...xs] = zs;                      // break Z's into first item and everything else
    return [x, ...ArrayUtils.interlace(ys, xs)]; // interlace arrays
  };

  static interlaceThreeLists = (xs, ys, zs) => {
    if (xs.length === 0) {
      return ArrayUtils.interlace(ys, zs); // base case, are there any X's to lace? no? then interlace Y's and Z's
    }
    const [x, ...xss] = xs; // break X's into first item and everything else
    return [x, ...ArrayUtils.interlaceThreeLists(ys, zs, xss)]; // interlace arrays
  };

  /**
   * Portions start at 1, not 0.
   *
   * Example
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10], 1, '⅓') = [1,2,3,4]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10], 2, '⅓') = [5,6,7]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10], 3, '⅓') = [8,9,10]
   *
   * Example
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], 1, '¼') = [1,2,3,4]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], 2, '¼') = [5,6,7,8]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], 3, '¼') = [9,10,11,12]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], 4, '¼') = [13,14,15]
   *
   * Example
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16], 1, '⅓') = [1,2,3,4,5,6]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16], 2, '⅓') = [7,8,9,10,11]
   *  ArrayUtils.getFractionalChunk([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16], 3, '⅓') = [12,13,14,15,16]
   */
  static getFractionalChunk = (
    list: any[],
    whichPortion: number,
    fractionAltCode: '½'|'⅓'|'¼'|'⅕'
  ): string[] => {
    if (!list?.length || whichPortion < 1) return [];
    const denominator = ArrayUtils.getDenominatorFromAltCode(fractionAltCode);
    const length = list?.length ?? 0;
    const chunkSize = Math.floor(length / denominator);
    const remainder = length % denominator;
    const start = (whichPortion - 1) * chunkSize + Math.min(whichPortion - 1, remainder);
    const end = start + chunkSize + (whichPortion <= remainder ? 1 : 0);
    return list?.slice(start, end) ?? [];
  };

  private static getDenominatorFromAltCode = (altCode: string): number => {
    switch (altCode) {
      case '½': return 2;
      case '⅓': return 3;
      case '¼': return 4;
      case '⅕': return 5;
      default:  return 1;
    }
  };

}
