<div class="modal-padding">
  <div class="cannabinoid-title">
    <span class="text"> Primary Cannabinoids </span>
  </div>

  <ng-container *rxFor="let cannabinoid of viewModel.primaryCannabinoids$ | push">
    <app-cannabinoid-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [cannabinoid]="cannabinoid"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-cannabinoid-min-max-form>
  </ng-container>
  <lib-reactive-form-pill-drop-down
    [inputName]="'presentCannabinoids'"
    [label]="'Present Cannabinoids'"
    [emptyPlaceholder]="'Enter present cannabinoids'"
    [placeholder]="'Enter present cannabinoids'"
    [dropdowns]="viewModel.cannabinoidDropdowns$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [delimitedPillBackgroundColors]="viewModel.cannabinoidPillBackgroundColors$ | push"
    [delimitedPillTooltips]="viewModel.cannabinoidPillTooltip$ | push"
    [tooltip]="'If any of the Present Cannabinoids exist on a product, it will be considered a match.'"
    [bindingProperty]="'presentCannabinoids'"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-pill-drop-down>

  <div *ngIf="viewModel.hasEnabledCannabinoids$ | async" class="cannabinoid-title" [style.margin-top.rem]="0.5">
    <span class="text"> Secondary Cannabinoids </span>
  </div>
  <ng-container *rxFor="let cannabinoid of viewModel.enabledSecondaryCannabinoids$ | push">
    <app-cannabinoid-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [cannabinoid]="cannabinoid"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-cannabinoid-min-max-form>
  </ng-container>
</div>
