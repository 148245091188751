<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Update Label Order</div>
      <hr />
      <div class="modal-subtitle-container mt-2">
        <div class="f14px bs-bold">
          Choose how you would like the labels to appear when multiple are applied to a single variant.
        </div>
        <div *ngIf="viewModel.showDragInstructions$ | async" class="f14px bs-regular mt-2">
          Drag and drop items into the desired order.
        </div>
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container parent-container" cdkScrollable>
    <div class="reorder-labels-container mb-3" (cdkDropListDropped)="viewModel.drop($event)" cdkDropList>
      <div *ngFor="let l of viewModel.lockedLabels$ | async">
        <app-label-action-card
          [label]="l"
          [locked]="true"
          [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
          [activeSaleLabelFormat]="viewModel?.activeSaleLabelFormat$ | async">
        </app-label-action-card>
      </div>
      <div *ngFor="let l of viewModel.draggableLabels$ | async" cdkDrag [cdkDragLockAxis]="'y'">
        <app-label-action-card
          [label]="l"
          [reordering]="true"
          [clearable]="viewModel.reorderCardClearable$ | async"
          [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
          [activeSaleLabelFormat]="viewModel?.activeSaleLabelFormat$ | async"
          (clearClicked)="viewModel.makeCardNonEditable($event)">
        </app-label-action-card>
        <div class="reorder-placeholder" *cdkDragPlaceholder></div>
      </div>
    </div>
    <hr />
    <ng-container *ngIf="(viewModel.editableLabels$ | async)?.length > 0">
      <div class="f14px bs-regular mb-3">Select items you would like to lock into the desired order.</div>
      <div class="reorder-labels-container mb-3">
        <div *ngFor="let l of viewModel.editableLabels$ | async">
          <app-label-action-card
            [label]="l"
            [activeLabelStyle]="viewModel?.activeLabelStyle$ | async"
            [activeSaleLabelFormat]="viewModel?.activeSaleLabelFormat$ | async"
            (cardClicked)="viewModel.makeCardEditable($event)">
          </app-label-action-card>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end mt-3" [style.padding-top]="0" [style.z-index]="0">
    <button (click)="viewModel.cancel()" class="bs-button neutral-button">Cancel</button>
    <button
      appThrottleClick
      (throttleClick)="viewModel.savePriorityChanges()"
      [disabled]="!(viewModel.changesMade$ | async)"
      [ngClass]="{ disabled: !(viewModel.changesMade$ | async) }"
      class="bs-button preferred-button">
      Confirm Order
    </button>
  </div>
</div>
