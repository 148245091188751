import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantMinTotalTerpeneWithUnits'
})
export class GetVariantMinTotalTerpeneWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null): string | null {
    return variant?.getMinTotalTerpeneWithUnits();
  }

}
