<app-loading *rxIf="container.isLoading$" [options]="container.loadingOpts$ | push"></app-loading>

<lib-reactive-form-merge-groups
  #newCollectionFormGroup
  [mergeKey]="container.mergeKey$ | push"
  (canSubmit$)="container.connectToCanSubmitForm($event)"
  (formsSubmitted$)="container.createNewTemplateCollection()">
</lib-reactive-form-merge-groups>

<lib-sticky-modal [height]="(container.currentStepIsSelectTemplates$ | push : 'immediate') ? '40rem' : null">
  <lib-sticky-header [stickyCollectionKey]="stickyCollectionKey" [stickyOrder]="0" [stickyZ]="9">
    <div class="modal-header">
      <div class="modal-title">{{ container.title$ | push }}</div>
    </div>
  </lib-sticky-header>
  <lib-sticky-body [position]="'relative'">
    <app-collection-details-form
      [hidden]="!(container.currentStepIsCollectionDetails$ | push)"
      [bindTo]="container.newCollection$ | push"
      [mergeKey]="container.mergeKey$ | push">
    </app-collection-details-form>
    <app-select-menu-or-collection
      *rxIf="container.currentStepIsSelectTemplates$; strategy: 'immediate'"
      [stickyCollectionKey]="stickyCollectionKey"
      [display]="container.newCollection$ | push"
      [emptyStateText]="'No templates found.'"
      [searchPlaceholder]="'Search by template name'"
      [itemIdsToAdd]="container.templateIdsToAdd$ | push"
      [itemsToSearch]="container.templates$ | push"
      (itemClicked)="container.handleItemClicked($event)">
    </app-select-menu-or-collection>
  </lib-sticky-body>
  <lib-sticky-footer>
    <app-go-back-modal-footer
      [includeStickyFooterContainerClass]="false"
      [primaryButtonText]="container.primaryButtonText$ | push"
      [showBackButton]="container.currentStepIsSelectTemplates$ | push"
      [showPrimaryButton]="true"
      [disablePrimaryButton]="container.disablePrimaryButton$ | push"
      (cancel)="cancel()"
      (primaryButtonClicked)="container.handlePrimaryButtonClicked(newCollectionFormGroup)"
      (goBack)="container.goBack()">
    </app-go-back-modal-footer>
  </lib-sticky-footer>
</lib-sticky-modal>
