import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { OverrideProductGroup } from '../../../../../../models/product/dto/override-product-group';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { map } from 'rxjs/operators';
import { Product } from '../../../../../../models/product/dto/product';

@Injectable()
export class ProductGroupingInfoListItemViewModel extends BaseViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
  ) {
    super();
  }

  public readonly loading$ = this.productDomainModel.currentLocationProducts$.pipe(map(products => !products));

  private readonly _overrideProductGroup = new BehaviorSubject<OverrideProductGroup>(null);
  public readonly overrideProductGroup$ = combineLatest([
    this._overrideProductGroup,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([group, variants]) => {
      if (!group || !variants?.length) return group;
      const variantIds = group?.products?.flatMap(p => p?.variants?.map(v => v?.id) ?? []) ?? [];
      const variantsInGroup = variants?.filter(v => variantIds?.includes(v?.id)) ?? [];
      const groupWithValidData = new OverrideProductGroup(group?.companyId, group?.name, group?.productIds);
      groupWithValidData.products = window?.injector?.Deserialize?.arrayOf(Product, group?.products);
      groupWithValidData.products?.forEach(product => {
        const thisProductsVariantIds = product?.variants?.map(v => v?.id) ?? [];
        product.variants = variantsInGroup?.filter(variant => thisProductsVariantIds?.includes(variant?.id));
      });
      return groupWithValidData;
    })
  );
  connectToOverrideProductGroup = (group: OverrideProductGroup) => this._overrideProductGroup.next(group);

  private readonly _product = new BehaviorSubject<Product>(null);
  public readonly product$: Observable<Product> = combineLatest([
    this.overrideProductGroup$,
    this._product
  ]).pipe(
    map(([group, product]) => {
      if (!group) return product;
      return group?.products?.find(p => p?.id === product?.id) ?? null;
    })
  );
  connectToProduct = (product: Product) => this._product.next(product);

  public readonly variants$ = this.product$.pipe(map(product => product?.variants ?? []));

}
