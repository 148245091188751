<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-sticky-modal [height]="(viewModel.contentStepIsSelectContent$ | push : 'immediate') ? '40rem' : null">
  <lib-sticky-header [stickyCollectionKey]="stickyCollectionKey" [stickyZ]="9" [stickyOrder]="0">
    <app-add-menu-or-collection-modal-header [title]="viewModel.title$ | push">
    </app-add-menu-or-collection-modal-header>
  </lib-sticky-header>
  <lib-sticky-body [position]="'relative'">
    <app-choose-display-content-option
      *rxIf="viewModel.contentStepIsChooseOption$"
      (contentOptionSelected)="viewModel.connectToDisplayContentOption($event)"
      (cancel)="cancel()">
    </app-choose-display-content-option>
    <app-select-menu-or-collection
      *rxIf="viewModel.contentStepIsSelectContent$"
      [stickyCollectionKey]="stickyCollectionKey"
      [collectionMode]="viewModel.collectionMode$ | push"
      [display]="viewModel.display$ | push"
      [displayContentOption]="viewModel.displayContentOption$ | push"
      [emptyStateText]="viewModel.emptyStateText$ | push"
      [itemIdsToAdd]="viewModel.itemsIdsToAdd$ | push"
      [itemsToSearch]="viewModel.itemsToSearch$ | push"
      [searchPlaceholder]="viewModel.searchPlaceholder$ | push"
      (cancel)="cancel()"
      (itemClicked)="viewModel.handleItemClick($event)">
    </app-select-menu-or-collection>
  </lib-sticky-body>
  <lib-sticky-footer>
    <app-go-back-modal-footer
      [includeStickyFooterContainerClass]="false"
      [includeWhiteBackground]="false"
      [primaryButtonText]="'Apply'"
      [showBackButton]="viewModel.showBackButton$ | push"
      [showPrimaryButton]="viewModel.contentStepIsSelectContent$ | push"
      [disablePrimaryButton]="viewModel.disablePrimaryButton$ | push"
      (cancel)="cancel()"
      (primaryButtonClicked)="viewModel.handleAddItems()"
      (goBack)="viewModel.goBack()">
    </app-go-back-modal-footer>
  </lib-sticky-footer>
</lib-sticky-modal>
