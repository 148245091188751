<div
  class="section-product-preview-detail"
  [popper]="popperContent"
  [popperDisabled]="!(viewModel.hasTerpenesGreaterThanZero$ | push)"
  [popperShowOnStart]="false"
  [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
  [popperModifiers]="popperModifier"
  [popperHideOnClickOutside]="true"
  [popperHideOnScroll]="false"
  [popperPreventOverflow]="true"
  [popperPositionFixed]="true"
  [popperApplyClass]="'product-info-popper'"
  [popperStyles]="popperStyles"
  [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
  <img [src]="'assets/icons/dark/outline/test-tube.svg'" alt="" />
  <ng-container *rxIf="!(viewModel.hasTerpenesGreaterThanZero$ | push); else hasTerpenes">
    <span>No Terpene Info</span>
  </ng-container>
  <ng-template #hasTerpenes>
    <span *rxLet="viewModel.largestPascalCasedTerpenes$; let largestPascalCasedTerpene">
      {{ viewModel | getTerpeneValuePreviewText : largestPascalCasedTerpene | push }}
      {{ largestPascalCasedTerpene | pascalCaseToSentenceCase }}
    </span>
    <span>
      +{{ viewModel.nMore$ | push }} more
      <img class="info-icon" src="assets/icons/dark/outline/information-circle.svg" />
    </span>
  </ng-template>
</div>

<popper-content #popperContent [style.z-index]="2">
  <app-popper-terpene-preview-list-item
    [terpeneTitle]="'Total Terp'"
    [terpeneValue]="viewModel | getTotalTerpeneValuePreviewText | push" />
  <app-popper-terpene-preview-list-item
    [terpeneTitle]="'Top Terp'"
    [terpeneValue]="viewModel | getTopTerpeneValuePreviewText | push" />
  <ng-container *rxIf="viewModel.hasTerpenesGreaterThanZero$">
    <hr class="no-margin ml-12px mr-12px" />
    <app-popper-terpene-preview-list-item
      *rxFor="let terpenePascalCased of viewModel.pascalCasedTerpsGreaterThanZeroNameAsc$"
      [terpeneTitle]="terpenePascalCased | pascalCaseToSentenceCase"
      [terpeneValue]="viewModel | getTerpeneValuePreviewText : terpenePascalCased | push" />
  </ng-container>
</popper-content>
