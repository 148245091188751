import { Pipe, PipeTransform } from '@angular/core';
import type { CannabinoidPreviewInfo } from '../cannabinoids-preview-view-model';
import type { PrimaryCannabinoid } from '../../../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../../../../../../../../../models/enum/dto/secondary-cannabinoid';

@Pipe({
  name: 'getCannabinoidPreviewInfoFor'
})
export class GetCannabinoidPreviewInfoForPipe implements PipeTransform {

  /**
   * @param previewInfoList list of all preview info
   * @param cannabinoid can be min${Cannabinoid} and max${Cannabinoid}
   */
  transform(
    previewInfoList: CannabinoidPreviewInfo[],
    cannabinoid: PrimaryCannabinoid | SecondaryCannabinoid | 'presentCannabinoids' | string
  ): CannabinoidPreviewInfo | null {
    return previewInfoList?.find(it => cannabinoid === it?.cannabinoid) ?? null;
  }

}
