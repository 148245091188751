<div class="modal-body sticky">
  <div
    #modalHeader
    libStickyAutoPosition
    [stickyCollectionKey]="'template-view-locations'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="modal-title" ngbAutofocus>
        {{ viewModel.province$ | async }}
      </div>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'template-view-locations'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    [style.overflow-y]="'unset'">
    <div class="desc-and-search pb-16px" fxLayout="column" [style.gap.rem]="1">
      <app-message-banner>
        All locations marked as required will automatically create this templated menu. If a location is not required
        and the templated menu already exists at that location, it will not be deleted.
      </app-message-banner>
      <div class="asterisk-description">
        Required locations are indicated with a red asterisk (<span class="asterisk">*</span>).
      </div>
      <hr class="no-margin" />
      <app-search-with-look-ahead
        [placeHolder]="'Search for a location'"
        [lookAheadItems]="viewModel.locations$ | async"
        [searchTheseProperties]="['name', 'address', 'city', 'state', 'country']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        [outputAllItemsWhenSearchIsEmpty]="true"
        (searchText)="viewModel.connectToSearchString($event)"
        (lookAheadHits)="viewModel.connectToSearchedLocations($event)">
      </app-search-with-look-ahead>
    </div>
  </div>
  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="modal-search-results-container">
      <div *ngIf="!(viewModel.searchedLocations$ | async)?.length" class="empty-search-state">No locations found.</div>
      <div
        *ngFor="let location of viewModel.searchedLocations$ | async; trackBy: viewModel.trackById"
        class="location-container">
        <app-template-location-info-text
          [requiredLocationIds]="viewModel.requiredLocationIds$ | async"
          [location]="location">
        </app-template-location-info-text>
      </div>
    </div>
  </div>
</div>

<div class="sticky-footer-container modal-footer-flex-end">
  <lib-button-primary (buttonClicked)="this.cancel()"> Done </lib-button-primary>
</div>
