<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | push">
  <div *ngIf="viewModel.container.showTerpenePOSSyncBanner$ | push" class="terpene-disabled-banner">
    <app-lot-info-banner
      [inventoryProvider]="viewModel.container.inventoryProvider$ | push"
      [displayAttribute]="viewModel.container.displayAttribute$ | push"
      [selectedTabId]="ClientTypes.EditVariantModalTabId.Terpenes"></app-lot-info-banner>
  </div>
  <div class="terpenes-form">
    <div class="input-and-then-preview">
      <lib-reactive-form-group
        [bindTo]="viewModel.container.variant$ | push"
        [mergeKey]="'editVariantFormCapture'"
        class="flex-fill">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-drop-down
            [inputName]="'terpeneUnitOfMeasure'"
            [bindingProperty]="'terpeneUnitOfMeasure'"
            [label]="'Terpene Unit Of Measure'"
            [required]="true"
            [placeholder]="'Select terpene unit of measure'"
            [tooltip]="viewModel.TUOMTooltip$ | push"
            [dropdowns]="viewModel.terpeneUnitOfMeasureOptions$ | push"
            [disabled]="viewModel.container.syncTerpenesFromPOS$ | push"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.tuomSelectionChanged($event)"></lib-reactive-form-drop-down>
          <lib-reactive-form-switch
            *ngIf="viewModel.container.showVariantUseRangeSwitch$ | push"
            [inputName]="'useTerpeneRange'"
            [bindingProperty]="'useTerpeneRange'"
            [label]="'Use Terpene Range'"
            [tooltip]="
              'Specific products can be enabled to use terpene range. ' +
              'This is useful for multi-pack products with varying terpenes.'
            "
            [disabled]="!(viewModel.container.isCompanyAdmin$ | push)"
            [disabledTooltip]="!(viewModel.container.isCompanyAdmin$ | push) | notAnAdminTooltip"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.container.setVariantUsesTerpeneRange($event)"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <app-terpene-preview-top></app-terpene-preview-top>
    </div>
    <div class="input-and-then-preview">
      <lib-reactive-form-column-layout nColumns="2" class="flex-fill">
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantCompanyDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.companyTopTerpeneUpdated($event)"
            [inputName]="'companyTopTerpene'"
            [label]="'Top Terpene (Company)'"
            [clearable]="true"
            [placeholder]="viewModel.companyTopTerpeneTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.companyTopTerpeneTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.companyTopTerpenePillPlaceholder$ | push"
            [bindingProperty]="'topTerpene'"
            [dropdowns]="viewModel.enabledTerpenes$ | push"
            [limitNumberOfSelectedItemsTo]="1"
            [submitAsDelimitedString]="true"
            [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
            [delimitedPillTextColors]="viewModel.terpenePillTextColors$ | push"
            [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
            [disabled]="!(viewModel.container.isCompanyAdmin$ | push) || (viewModel.disableTopTerpeneInput$ | push)"
            [disabledTooltip]="viewModel.disableTopTerpeneTooltip$ | push">
          </lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantLocationDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.locationTopTerpeneUpdated($event)"
            [inputName]="'locationTopTerpene'"
            [label]="'Top Terpene (Location)'"
            [clearable]="true"
            [placeholder]="viewModel.locationTopTerpeneTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.locationTopTerpeneTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.locationTopTerpenePillPlaceholder$ | push"
            [bindingProperty]="'topTerpene'"
            [dropdowns]="viewModel.enabledTerpenes$ | push"
            [limitNumberOfSelectedItemsTo]="1"
            [submitAsDelimitedString]="true"
            [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
            [delimitedPillTextColors]="viewModel.terpenePillTextColors$ | push"
            [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
            [disabled]="viewModel.disableTopTerpeneInput$ | push"
            [disabledTooltip]="viewModel.disableTopTerpeneTooltip$ | push">
          </lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
      </lib-reactive-form-column-layout>
      <app-terpene-preview-chunk [terpene]="'topTerpene'"></app-terpene-preview-chunk>
    </div>
    <div class="input-and-then-preview">
      <lib-reactive-form-column-layout nColumns="2" class="flex-fill">
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantCompanyDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.companyDAUpdated($event, 'presentTerpenes')"
            [inputName]="'companyPresentTerpenes'"
            [label]="'Present Terpenes (Company)'"
            [tooltip]="
              'Associate terpenes with this product. If not explicitly specified, then the list is generated from terpenes present on the product'
            "
            [placeholder]="viewModel.companyPresentTerpeneTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.companyPresentTerpeneTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.companyPresentTerpenePillPlaceholders$ | push"
            [bindingProperty]="'presentTerpenes'"
            [dropdowns]="viewModel.enabledTerpenes$ | push"
            [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
            [delimitedPillTextColors]="viewModel.terpenePillTextColors$ | push"
            [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
            [disabled]="viewModel.disableCompanyPresentTerpenesInput$ | push"
            [disabledTooltip]="viewModel.disableCompanyLevelPresentTerpenesInputTooltip$ | push">
          </lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantLocationDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.locationDAUpdated($event, 'presentTerpenes')"
            [inputName]="'locationPresentTerpenes'"
            [label]="'Present Terpenes (Location)'"
            [tooltip]="
              'Associate terpenes with this product. If not explicitly specified, then the list is generated from terpenes present on the product'
            "
            [placeholder]="viewModel.locationPresentTerpeneTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.locationPresentTerpeneTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.locationPresentTerpenePillPlaceholders$ | push"
            [bindingProperty]="'presentTerpenes'"
            [dropdowns]="viewModel.enabledTerpenes$ | push"
            [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
            [delimitedPillTextColors]="viewModel.terpenePillTextColors$ | push"
            [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
            [disabled]="viewModel.disableLocationPresentTerpenesInput$ | push"
            [disabledTooltip]="viewModel.disableLocationLevelPresentTerpenesInputTooltip$ | push">
          </lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
      </lib-reactive-form-column-layout>
      <app-terpene-preview-chunk [terpene]="'presentTerpenes'"></app-terpene-preview-chunk>
    </div>
    <!-- Terpene Values -->
    <div class="input-and-then-preview">
      <div class="form-section-title flex-fill">Terpene Values</div>
      <app-terpene-preview-chunk></app-terpene-preview-chunk>
    </div>
    <app-terpenes-form
      [terpene]="'Total Terpene'"
      [terpeneCamelCased]="'totalTerpene'"
      [terpenePascalCased]="'TotalTerpene'"
      [pluralizeLabel]="true"
      [mergeKey]="'editVariantFormCapture'">
    </app-terpenes-form>
    <!-- Terpenes in POS -->
    <ng-container *rxIf="viewModel.hasPosSupportedTerpenes$ | push">
      <div class="input-and-then-preview">
        <div class="flex-fill">
          <div class="form-section-sub-title">
            <span class="text">POS Managed Terpenes</span>
          </div>
          <app-message-banner
            *rxIf="(viewModel.terpenesWithPOSValueNotEnabledInBudsense$ | push)?.length > 0"
            class="mb-32px"
            >The following terpenes have values set, but are not enabled within BudSense settings. In order to view this
            value, enable the terpene in Settings > Products > Cannabinoids & Terpenes.
            <ul class="row">
              <li *rxFor="let terpene of viewModel.terpenesWithPOSValueNotEnabledInBudsense$" class="col-lg-6">
                {{ terpene }}
              </li>
            </ul>
          </app-message-banner>
        </div>
        <app-terpene-preview-chunk></app-terpene-preview-chunk>
      </div>
      <app-terpenes-form
        *rxFor="let terpeneNames of viewModel.posSupportedEnabledTerpeneNames$; trackBy: viewModel.trackByTerpeneName"
        [terpene]="terpeneNames?.terpeneName"
        [terpeneCamelCased]="terpeneNames?.camelCased"
        [terpenePascalCased]="terpeneNames?.pascalCased"
        [pluralizeLabel]="false"
        [mergeKey]="'editVariantFormCapture'">
      </app-terpenes-form>
    </ng-container>
    <!-- Other Enabled Terpenes -->
    <ng-container *rxIf="viewModel.hasOtherEnabledTerpenes$ | push">
      <div class="input-and-then-preview">
        <div class="form-section-sub-title flex-fill">
          <span class="text">BudSense Managed Terpenes</span>
        </div>
        <app-terpene-preview-chunk></app-terpene-preview-chunk>
      </div>
      <app-terpenes-form
        *rxFor="let terpeneNames of viewModel.otherEnabledTerpeneNames$; trackBy: viewModel.trackByTerpeneName"
        [terpene]="terpeneNames?.terpeneName"
        [terpeneCamelCased]="terpeneNames?.camelCased"
        [terpenePascalCased]="terpeneNames?.pascalCased"
        [pluralizeLabel]="false"
        [mergeKey]="'editVariantFormCapture'">
      </app-terpenes-form>
    </ng-container>
    <!-- Terpenes Not Enabled Message -->
    <div *rxIf="!(viewModel.hasEnabledTerpenes$ | push)" class="input-and-then-preview">
      <div class="secondary-container flex-fill">
        <div class="secondary-message">
          Individual Terpenes can be enabled within "Settings > Products > Cannabinoids & Terpenes". Once enabled they
          will appear here (ie: Myrcene, Limonene, Linalool).
        </div>
        <lib-button-neutral
          *rxIf="viewModel.container.isCompanyAdmin$ | push"
          [iconSrc]="'assets/icons/dark/outline/cog.svg'"
          (buttonClicked)="editProductSettings()">
          Product Settings
        </lib-button-neutral>
      </div>
      <app-terpene-preview-chunk></app-terpene-preview-chunk>
    </div>
    <div class="input-and-then-preview">
      <div class="flex-fill"></div>
      <app-terpene-preview-bottom></app-terpene-preview-bottom>
    </div>
  </div>
</div>
