import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnConfigKey } from '../../../models/enum/dto/section-column-config-key';
import { ColumnAccordionLabelHelper } from '../viewModels/column-options-view-model';

@Pipe({
  name: 'getCurrentlySelectedDefaultState'
})
export class GetCurrentlySelectedDefaultStatePipe implements PipeTransform {

  transform(
    key: SectionColumnConfigKey,
    defaultStateMap: Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>
  ): any {
    return defaultStateMap?.get(key)?.defaultState;
  }

}
