<app-loading *rxIf="viewModel.isLoading$ | push" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #columnOptionsFormGroup
  [mergeKey]="viewModel?.mergeKey"
  (canSubmit$)="viewModel?.connectToFormatIsValid($event)"
  (unsavedChanges$)="viewModel.connectToUnsavedChangesInTab($event)"
  (formsAutoSubmitted$)="viewModel.saveChangesWithinModal()"
  (formsSubmitted$)="viewModel.submitForms()">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky" [style.height.vh]="90">
  <div
    class="sticky-header-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickyZ]="20">
    <div class="modal-header no-bottom-border column-options-modal-header-container">
      <div class="modal-title">
        Column Options
        <img
          [ngbTooltip]="viewModel?.message$ | push"
          [container]="'body'"
          [src]="'assets/icons/dark/outline/information-circle.svg'"
          alt="" />
      </div>
      <div *rxIf="!(viewModel?.managingDefault$ | push)">
        <app-reset-column-options
          [themeDefault]="viewModel?.themeColumnConfig$ | push"
          (resetClicked)="viewModel.resetToDefaultColumnConfig($event)"></app-reset-column-options>
      </div>
    </div>
    <div *rxIf="viewModel?.managingDefault$ | push">
      <lib-reactive-form-group [bindTo]="viewModel?.defaultSectionConfig$ | push" [mergeKey]="'column-options-form'">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-text
            [inputName]="'defaultOptionsName'"
            [label]="'Default Options Name'"
            [placeholder]="'Enter a name for the default options'"
            [bindingProperty]="'name'"
            [required]="viewModel?.managingDefault$ | push"
            [tooltip]="'The name of the Default Column Options'">
          </lib-reactive-form-text>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
  </div>

  <div
    #contentContainer
    libStickyAutoPosition
    [stickyCollectionKey]="'column-options-modal'"
    [stickChildClassInstead]="'mat-tab-header'"
    [stickyZ]="10"
    class="sticky-body-container column-option-modal-body-container">
    <app-tab-bar
      #tabController
      [tabs]="tabs$ | push"
      [skipFragmentAppending]="true"
      [showLoadingSpinnerOnTabChange]="true"
      [loadingTimeMs]="500"
      [delayTabClick]="100"
      [canChangeTabs]="viewModel.canChangeTabs$ | push"
      [canChangeTabsErrorMsg]="canChangeTabsErrorMsg$ | push"
      (previousTab)="setPreviousTab($event); checkForChanges()"
      (selectedTab)="setCurrentTab($event)"
      class="flex-fill">
    </app-tab-bar>
  </div>

  <div
    class="sticky-footer-container column-option-modal-footer-container"
    [style.position]="viewModel.bottomButtonPosition$ | push">
    <div *rxIf="this.viewModel?.columnsWidthTotalError$ | push" class="error-banner-container">
      <app-error-banner [message]="viewModel?.columnsWidthTotalErrorMessage$ | push"></app-error-banner>
    </div>
    <div class="modal-footer-flex-end button-holder">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        (buttonClicked)="columnOptionsFormGroup.submitForms()"
        [disabled]="!(viewModel?.canSubmitForm$ | push)">
        Done
      </lib-button-primary>
    </div>
  </div>
</div>
