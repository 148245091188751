import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMenuOrCollectionModalViewModel } from './add-menu-or-collection-modal-view-model';
import { BaseDisplay } from '../../../../../models/display/dto/base-display';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-add-menu-or-collection-modal',
  templateUrl: './add-menu-or-collection-modal.component.html',
  styleUrls: ['./add-menu-or-collection-modal.component.scss'],
  providers: [AddMenuOrCollectionModalViewModel]
})
export class AddMenuOrCollectionModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public viewModel: AddMenuOrCollectionModalViewModel
  ) {
    super(activeModal);
  }

  @Input() stickyCollectionKey: string = 'add-menu-or-collection';
  @Input() @ViewModelConnector('connectToDisplay') display: BaseDisplay;
  @Input() @ViewModelConnector('connectToCollectionMode') collectionMode: boolean;

}
