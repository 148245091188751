import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-add-menu-or-collection-modal-header',
  templateUrl: './add-menu-or-collection-modal-header.component.html',
  styleUrls: ['./add-menu-or-collection-modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AddMenuOrCollectionModalHeaderComponent {

  @Input() title: string;

}
