import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnAccordionLabelHelper, ColumnChanges, ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SectionColumnConfigKey } from '../../../../../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/enum/dto/section-column-config-default-state';
import type { SectionColumnConfigKeyType } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import type { SecondaryCannabinoidsTypeDefinition } from '../../../../../../../models/utils/dto/secondary-cannabinoids-type-definition';
import type { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import type { ThemeSectionColumnConfig } from '../../../../../../../models/menu/dto/theme-section-column-config';

@Injectable()
export class CannabinoidsColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  public primaryCannabinoidColumnConfigsAndKeys$ = window.types.cannabinoidSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(keyType => keyType?.isPrimaryCannabinoidKey()));
    }),
    startWith([])
  );

  public secondaryCannabinoidColumnConfigsAndKeys$ = combineLatest([
    this.container.enabledSecondaryCannabinoids$,
    window.types.cannabinoidSectionColumnConfigKeys$
  ]).pipe(
    switchMap(([secondaryCannabinoids, keys]) => {
      const filteredKeys = keys
        ?.filter(keyType => keyType?.isSecondaryCannabinoidKey())
        ?.filter(keyType => {
          const secondaryCannabinoidValues = secondaryCannabinoids?.map(sc => sc?.getSelectionValue());
          const keyTypeValue = keyType?.getSelectionValue();
          return secondaryCannabinoidValues?.includes(keyTypeValue);
        });
      return this.container.makeColumnOptionFormFromKeys$(filteredKeys);
    }),
    startWith([])
  );

  public currentDefaultStateMap$ = combineLatest([
    this.container.columnConfig$,
    this.container.columnChanges$,
    this.container.themeColumnConfig$,
    this.container.enabledSecondaryCannabinoids$,
    window.types.cannabinoidSectionColumnConfigKeys$
  ]).pipe(
    map(([sectionColumnConfigs, columnChanges, themeColumnConfigs, secondaryCannabinoids, keys]) => {
      const primaryKeys = this.primaryCannabinoidStateKeys(keys);
      const secondaryKeys = this.secondaryCannabinoidStateKeys(keys, secondaryCannabinoids);
      const defaultStateMap = new Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>();
      primaryKeys?.forEach(key => {
        this.addToDefaultStateMap(key, columnChanges, sectionColumnConfigs, themeColumnConfigs, defaultStateMap);
      });
      secondaryKeys.forEach(key => {
        this.addToDefaultStateMap(key, columnChanges, sectionColumnConfigs, themeColumnConfigs, defaultStateMap, true);
      });
      return defaultStateMap;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private primaryCannabinoidStateKeys(keys: SectionColumnConfigKeyType[]): SectionColumnConfigKey[] {
    return keys
      ?.filter(keyType => keyType?.isPrimaryCannabinoidKey())
      ?.map(keyType => keyType?.getSelectionValue());
  }

  private secondaryCannabinoidStateKeys(
    keys: SectionColumnConfigKeyType[],
    secondaryCannabinoids: SecondaryCannabinoidsTypeDefinition[]
  ): SectionColumnConfigKey[] {
    return keys
      ?.filter(keyType => keyType?.isSecondaryCannabinoidKey())
      ?.filter(keyType => {
        const secondaryCannabinoidValues = secondaryCannabinoids?.map(sc => sc?.getSelectionValue());
        const keyTypeValue = keyType?.getSelectionValue();
        return secondaryCannabinoidValues?.includes(keyTypeValue);
      })
      ?.map(keyType => keyType?.getSelectionValue());
  }

  private addToDefaultStateMap(
    key: SectionColumnConfigKey,
    columnChanges: Map<string, ColumnChanges>,
    sectionColumnConfigs: Map<String, SectionColumnConfig>,
    themeColumnConfigs: Map<String, ThemeSectionColumnConfig>,
    currentDefaultStateMap: Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>,
    isSecondaryCannabinoidKey = false
  ): void {
    let defaultStateIsFromTheme = false;
    let currentDefaultState = columnChanges?.get(key)?.defaultState || sectionColumnConfigs?.get(key)?.defaultState;
    if (!currentDefaultState) {
      if (isSecondaryCannabinoidKey) {
        const themeConfig = themeColumnConfigs?.get(key);
        const secondaryCannabinoidsConfig = themeColumnConfigs?.get('SecondaryCannabinoids');
        currentDefaultState = themeConfig?.defaultState || secondaryCannabinoidsConfig?.defaultState;
      } else {
        currentDefaultState = themeColumnConfigs?.get(key)?.defaultState;
      }
      defaultStateIsFromTheme = true;
    }
    const currentDefaultOn = currentDefaultState === SectionColumnConfigDefaultState.On;
    const currentDefaultAuto = currentDefaultState === SectionColumnConfigDefaultState.Auto;
    if (currentDefaultOn || currentDefaultAuto) {
      const helper = {
        defaultState: currentDefaultState,
        labelStyle: ColumnOptionsViewModel.getStateLabelBackgroundColor(
          currentDefaultState,
          defaultStateIsFromTheme
        )
      };
      currentDefaultStateMap?.set(key, helper);
    }
  }

}
