<app-loading *ngIf="container.isLoading$ | async" [options]="container.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div
    class="sticky-header-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'create-template-modal'"
    [stickyOrder]="0"
    [style.overflow-y]="'unset'">
    <div class="modal-header">
      <div class="modal-title">
        {{ container.modalTitle$ | async }}
      </div>
      <div class="modal-subtitle">
        {{ container.modalSubtitle$ | async }}
      </div>
    </div>
  </div>

  <div class="sticky-body-container new-template-container" [style.overflow-y]="'unset'">
    <app-template-creation-method
      *ngIf="(container.getCreateStepFromNavMap$ | async) === container.createStep.HowToCreate"
      (selectedCreationType)="container.connectToCreationType($event); container.incrementCreationStep()"
      [paddingLeft]="'0'"
      [paddingRight]="'0'">
    </app-template-creation-method>

    <app-menu-type-picker
      *ngIf="(container.getCreateStepFromNavMap$ | async) === container.createStep.ChooseMenuType"
      [menuType]="container.menuType$ | async"
      [paddingLeft]="'0'"
      [paddingRight]="'0'"
      (selectedSubTypes)="container.connectToMenuSubTypes($event); container.incrementCreationStep()"
      (readableSubtype)="container.connectToReadableMenuType($event)">
    </app-menu-type-picker>

    <lib-reactive-form-group
      #newTemplateFormGroup
      [bindTo]="container.newTemplate$ | async"
      [dispersedKey]="container.dispersedKey$ | async"
      (canSubmit$)="container.connectToFormIsValid($event)"
      (hasErrors$)="container.connectToFormHasErrors($event)"
      (formSubmitted$)="container.createNewTemplate()">
      <app-theme-picker
        *ngIf="container.showThemePicker$ | async"
        [menuType]="container.menuType$ | async"
        [menuSubtypes]="container.menuSubtypes$ | async"
        [tags]="container.tags$ | async"
        [dispersedKey]="container.dispersedKey$ | async"
        [creatingFromExisting]="container.creatingFromExisting$ | async"
        [templateMode]="true"
        (themeSelected)="container.connectToThemeSelected($event)"
        (stackSizeSelected)="container.connectToSelectedStackSize($event)"
        (orientationSelected)="container.connectToOrientationSelected($event)">
      </app-theme-picker>
    </lib-reactive-form-group>
  </div>

  <app-searchable-single-select
    [hidden]="(container.getCreateStepFromNavMap$ | async) !== container.createStep.SelectExisting"
    [searchableItems]="container.selectableMenus$ | async"
    [noItemsBody]="'No Menus'"
    [noItemsTitle]="'Any menus that belong to your location will appear here'"
    [stickyCollectionKey]="'create-template-modal'"
    [stickyOrder]="1"
    (selectedItem)="container.existingMenuSelected($event)">
  </app-searchable-single-select>
</div>

<div class="sticky-footer-container flex-container" style="padding-top: 1rem">
  <div>
    <lib-button-text
      [hidden]="(container.creationStep$ | async) === 0"
      (buttonClicked)="container.decrementCreationStep()">
      Go Back
    </lib-button-text>
  </div>
  <div [style.margin-left]="'auto'">
    <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary
      *ngIf="container.showNextButton$ | async"
      [style.margin-left]="'0.5rem'"
      [disabled]="!(container.canGoNextStep$ | async)"
      (buttonClicked)="container.moveToNextStepForExistingSelection()">
      Next
    </lib-button-primary>
    <lib-button-primary
      *ngIf="container.showCreateButton$ | async"
      [style.margin-left]="'0.5rem'"
      [disabled]="!(container.canSubmitForm$ | async)"
      (buttonClicked)="newTemplateFormGroup.submitForm()">
      Create
    </lib-button-primary>
  </div>
</div>
