import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuSectionProductInfoPreviewComponent } from '../menu-section-product-info-preview/menu-section-product-info-preview.component';
import { MenuSectionProductCannabinoidPreviewViewModel } from './menu-section-product-cannabinoid-preview-view-model';

@Component({
  selector: 'app-menu-section-product-cannabinoid-preview',
  templateUrl: './menu-section-product-cannabinoid-preview.component.html',
  styleUrls: [
    '../menu-section-product-preview.component.scss',
    './menu-section-product-cannabinoid-preview.component.scss'
  ],
  providers: [MenuSectionProductCannabinoidPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionProductCannabinoidPreviewComponent extends MenuSectionProductInfoPreviewComponent {

  constructor(
    public override viewModel: MenuSectionProductCannabinoidPreviewViewModel
  ) {
    super(viewModel);
  }

}
