import { Injectable } from '@angular/core';
import type { SortFunctions } from '../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';

/**
 * This service is needed to keep track of singleton instances of sorting functions for the table. The table uses the
 * memory address of the sorting functions to know if a sort function is selected or not. If the memory address is the
 * same for both asc and desc, then the table can't distinguish which one has been selected.
 */
@Injectable({ providedIn: 'root' })
export class TableSortingService {

  /**
   * Singleton instances of sorting functions for the table.
   */
  private sortingFunctions = new Map<string, SortFunctions>();

  public hasSortingFunctions(key: string): boolean {
    return this.sortingFunctions.has(key);
  }

  public addSortingFunctions(
    key: string,
    sortFunctions: SortFunctions
  ): void {
    this.sortingFunctions.set(key, sortFunctions);
  }

  public getSortingFunctions(key: string): SortFunctions {
    return this.sortingFunctions.get(key);
  }

}
