import { Type } from '@angular/core';
import { Tabbable } from '../../protocols/tabbable';
import { Subject } from 'rxjs';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class TabBarItem implements UniquelyIdentifiable {

  public active: boolean = false;
  public title: string;
  public iconSrc: string;
  public componentType: Type<Tabbable>;
  public activated = new Subject<boolean>();
  public disabled: boolean = false;
  public disabledTooltip: string = '';
  public incompleteProperties: number = null;

  constructor(
    componentType: Type<Tabbable>,
    title,
    iconSrc: string,
    active = false,
    disabled?: boolean,
    incompleteProperties?: number,
    disabledTooltip?: string
  ) {
    this.title = title;
    this.iconSrc = iconSrc;
    this.componentType = componentType;
    this.active = active;
    this.disabled = disabled ?? false;
    this.incompleteProperties = incompleteProperties ?? null;
    this.disabledTooltip = disabledTooltip ?? '';
  }

  getUniqueIdentifier(): string {
    return `
      ${this.active}-
      ${this.title}-
      ${this.iconSrc}-
      ${this.componentType}-
      ${this.activated}-
      ${this.disabled}-
      ${this.incompleteProperties}-
      ${this.disabledTooltip}
    `;
  }

  static setSelectedTab(tabs: TabBarItem[], currentTabId: number) {
    if (!!tabs && !!currentTabId && tabs?.length > currentTabId) {
      tabs[currentTabId].active = true;
    }
  }

}
