import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { DefaultCannabisUnitOfMeasureReq } from '../shared/default-cannabis-unit-of-measure-req';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { PosProductCategoryGroup } from './pos-product-category-group';
import { PosProductCategory } from './pos-product-category';
import { DefaultSectionColumnConfig } from '../../menu/dto/default-section-column-config';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { SaleLabelFormat } from '../../utils/dto/sale-label-format-type';
import { VariantType, VariantTypeDefinition } from '../../utils/dto/variant-type-definition';
import { CannabinoidDisplayType } from '../../utils/dto/cannabinoid-display-type-definition';
import { CannabisUnitOfMeasure } from '../../utils/dto/cannabis-unit-of-measure-type';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TerpeneDisplayType } from '../../utils/dto/terpene-display-type-definition';
import { TerpeneUnitOfMeasure } from '../../utils/dto/terpene-unit-of-measure-type';
import { ProductTableColumnConfig } from '../../menu/dto/product-table-column-config';
import { SecondaryCannabinoid } from '../../enum/dto/secondary-cannabinoid';
import { Terpene } from '../../enum/dto/terpene';

export class CompanyConfiguration implements Deserializable, Cachable, UniquelyIdentifiable {

  public companyId: number;
  public clientToken: string;
  public lastProductSync: number;
  public lastInventorySync: number;
  public lastLocationSync: number;
  public lastPricingSync: number;
  public lastLotInfoSync: number;
  public lastDisplayNameSync: number;
  public lastLabelSync: number;
  public defaultCannabisUnitOfMeasure: Map<VariantType, CannabisUnitOfMeasure>;
  public defaultTerpeneUnitOfMeasure: TerpeneUnitOfMeasure;
  public cannabinoidDisplayType: CannabinoidDisplayType = CannabinoidDisplayType.Exact;
  public terpeneDisplayType: TerpeneDisplayType = TerpeneDisplayType.Exact;
  public integrationDisabled: boolean = false;
  public posSyncLockout: boolean;
  public colorPalette: string[];
  public syncPOSCannabinoid: boolean = false;
  public syncPOSTerpene: boolean = false;
  public syncPOSLabels: boolean = false;
  public syncDisplayNames: boolean = false;
  public posProductCategories: PosProductCategory[];
  public posProductCategoryGroups: PosProductCategoryGroup[];
  public budSenseInventoryProvider: boolean;
  public defaultColumnConfigs: DefaultSectionColumnConfig[];
  public saleLabelFormat: SaleLabelFormat;
  public labelStyle: LabelStyle;
  public discontinuedProductThreshold: number;
  public usesLocationInventoryProvider: boolean;
  public enabledCannabinoids: SecondaryCannabinoid[];
  public enabledTerpenes: Terpene[];
  public defaultProductTableColumConfigs: ProductTableColumnConfig[];
  public ignoreDelistedProducts: boolean;

    // If adding a new property that will affect the appearance of products (labels, badges, DAs, etc)
  // make you sure you add the property to the list in productPropertiesFromConfig$ in the CompanyDomainModel

  // Client only properties
  public formattedLastDisplayNameSync: string;

  // Cache
  cachedTime: number;

  static buildCacheKey(id: string): string {
    return `CompanyConfiguration-${id}`;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (!this.defaultCannabisUnitOfMeasure) {
      this.defaultCannabisUnitOfMeasure = new Map<VariantType, CannabisUnitOfMeasure>();
    } else if (!(this.defaultCannabisUnitOfMeasure instanceof Map)) {
      this.defaultCannabisUnitOfMeasure = Deserialize?.genericMap(this.defaultCannabisUnitOfMeasure);
    } else {
      this.defaultCannabisUnitOfMeasure = new Map(this.defaultCannabisUnitOfMeasure);
    }
    if (!this.defaultTerpeneUnitOfMeasure) {
      this.defaultTerpeneUnitOfMeasure = TerpeneUnitOfMeasure.Percent;
    }
    if (!this.cannabinoidDisplayType) {
      this.cannabinoidDisplayType = CannabinoidDisplayType.Exact;
    }
    if (!this.terpeneDisplayType) {
      this.terpeneDisplayType = TerpeneDisplayType.Exact;
    }
    this.colorPalette = Array.from(this.colorPalette || []);
    this.posProductCategories = Deserialize?.arrayOf(PosProductCategory, this.posProductCategories) || [];
    this.posProductCategoryGroups = Deserialize?.arrayOf(PosProductCategoryGroup, this.posProductCategoryGroups) || [];
    this.defaultColumnConfigs = Deserialize?.arrayOf(DefaultSectionColumnConfig, this.defaultColumnConfigs) || [];
    this.formattedLastDisplayNameSync = DateUtils.formatUnixToLastSyncTime(this.lastDisplayNameSync);
    this.enabledCannabinoids = Array.from(this.enabledCannabinoids || []);
    this.enabledTerpenes = Array.from(this.enabledTerpenes || []);
    this.defaultProductTableColumConfigs = Deserialize?.arrayOf(
      ProductTableColumnConfig,
      this.defaultProductTableColumConfigs
    ) || [];
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/CompanyConfigurationDTO.go
  public onSerialize() {
    const dto = Object.create(CompanyConfiguration.prototype);
    dto.companyId = this.companyId;
    dto.clientToken = this.clientToken;
    dto.lastProductSync = this.lastProductSync;
    dto.lastInventorySync = this.lastInventorySync;
    dto.lastLotInfoSync = this.lastLotInfoSync;
    dto.lastLocationSync = this.lastLocationSync;
    dto.lastPricingSync = this.lastPricingSync;
    dto.lastDisplayNameSync = this.lastDisplayNameSync;
    dto.lastLabelSync = this.lastLabelSync;
    dto.syncPOSCannabinoid = this.syncPOSCannabinoid;
    dto.syncPOSTerpene = this.syncPOSTerpene;
    dto.syncPOSLabels = this.syncPOSLabels;
    dto.syncDisplayNames = this.syncDisplayNames;
    dto.integrationDisabled = this.integrationDisabled;
    dto.posSyncLockout = this.posSyncLockout;
    dto.saleLabelFormat = this.saleLabelFormat;
    dto.labelStyle = this.labelStyle;
    dto.cannabinoidDisplayType = this.cannabinoidDisplayType;
    dto.terpeneDisplayType  = this.terpeneDisplayType;
    dto.defaultCannabisUnitOfMeasure = this.defaultCannabisUnitOfMeasure;
    dto.defaultTerpeneUnitOfMeasure = this.defaultTerpeneUnitOfMeasure;
    dto.discontinuedProductThreshold = this.discontinuedProductThreshold;
    dto.posProductCategories = this.posProductCategories;
    dto.posProductCategoryGroups = this.posProductCategoryGroups;
    dto.budSenseInventoryProvider = this.budSenseInventoryProvider;
    dto.colorPalette = this.colorPalette;
    dto.usesLocationInventoryProvider = this.usesLocationInventoryProvider;
    dto.defaultColumnConfigs = this.defaultColumnConfigs;
    dto.enabledCannabinoids = this.enabledCannabinoids;
    dto.enabledTerpenes = this.enabledTerpenes;
    dto.defaultProductTableColumConfigs = this. defaultProductTableColumConfigs;
    dto.ignoreDelistedProducts = this.ignoreDelistedProducts;
    return dto;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return CompanyConfiguration.buildCacheKey(this.companyId.toString());
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  setPropertiesFromDefaultCUOMReq(req: DefaultCannabisUnitOfMeasureReq): CompanyConfiguration {
    this.defaultCannabisUnitOfMeasure.clear();
    req.getAsMap().forEach((value, key) => {
      this.defaultCannabisUnitOfMeasure.set(key, value);
    });
    return this;
  }

  public changesRequireProductRefresh$(updated: CompanyConfiguration): Observable<boolean> {
    return combineLatest([
      this.cannabinoidDisplayTypeChanged$(updated),
      this.discontinuedProductThresholdChanged$(updated),
      this.cuomChanged$(updated),
      this.tuomChanged$(updated),
      this.syncTimeChanged$(updated),
    ]).pipe(
      take(1),
      map(changes => changes?.some(changed => changed)),
    );
  }

  private cannabinoidDisplayTypeChanged$(updated: CompanyConfiguration): Observable<boolean> {
    return of(this.cannabinoidDisplayTypeChanged(updated));
  }

  private cannabinoidDisplayTypeChanged(updated: CompanyConfiguration): boolean {
    return this.cannabinoidDisplayType !== updated?.cannabinoidDisplayType;
  }

  private discontinuedProductThresholdChanged$(updated: CompanyConfiguration): Observable<boolean> {
    return of(this.discontinuedProductThresholdChanged(updated));
  }

  private discontinuedProductThresholdChanged(updated: CompanyConfiguration): boolean {
    return this.discontinuedProductThreshold !== updated?.discontinuedProductThreshold;
  }

  private cuomChanged$(updated: CompanyConfiguration): Observable<boolean> {
    return window?.types?.variantTypes$.pipe(
      take(1),
      map(variantTypes => this.cuomChanged(updated, variantTypes))
    );
  }

  private cuomChanged(updated: CompanyConfiguration, variantTypes: VariantTypeDefinition[]): boolean {
    return variantTypes?.some(variantType => {
      const currentValue = this.defaultCannabisUnitOfMeasure?.get(variantType?.value);
      const updatedValue = updated?.defaultCannabisUnitOfMeasure?.get(variantType?.value);
      return currentValue !== updatedValue;
    });
  }

  private tuomChanged$(updated: CompanyConfiguration): Observable<boolean> {
    const currentValue = this.defaultTerpeneUnitOfMeasure;
    const updatedValue = updated?.defaultTerpeneUnitOfMeasure;
    return of(currentValue !== updatedValue);
  }

  private syncTimeChanged$(updated: CompanyConfiguration): Observable<boolean> {
    return of(this.syncTimeChanged(updated));
  }

  private syncTimeChanged(updated: CompanyConfiguration): boolean {
    if (!updated) return false;
    const productSyncDifferent = this.lastProductSync !== updated?.lastProductSync;
    const inventorySyncDifferent = this.lastInventorySync !== updated?.lastInventorySync;
    const pricingSyncDifferent = this.lastPricingSync !== updated?.lastPricingSync;
    const lotInfoSyncDifferent = this.lastLotInfoSync !== updated?.lastLotInfoSync;
    const displayNameSyncDifferent = this.lastDisplayNameSync !== updated?.lastDisplayNameSync;
    const labelSyncDifferent = this.lastLabelSync !== updated?.lastLabelSync;
    return productSyncDifferent
        || inventorySyncDifferent
        || pricingSyncDifferent
        || lotInfoSyncDifferent
        || displayNameSyncDifferent
        || labelSyncDifferent;
  }

  rangedCannabinoids(): boolean {
    return this.cannabinoidDisplayType === CannabinoidDisplayType.Range;
  }

  rangedTerpenes(): boolean {
    return this.terpeneDisplayType === TerpeneDisplayType.Range;
  }

  getUniqueIdentifier(): string {
    const defaultCannabisUnit: string[] = [];
    this.defaultCannabisUnitOfMeasure?.forEach((val, key) => defaultCannabisUnit.push(`${key}-${val}`));
    const defaultCannabisUnitId = defaultCannabisUnit.sort().join(',') ?? '';
    const colorPaletteId = this.colorPalette?.sort().join(',') ?? '';
    const posCategoriesId = this.posProductCategories?.map(c => c.getUniqueIdentifier()).sort().join(',');
    const posCategoryGroupsId = this.posProductCategoryGroups?.map(c => c.getUniqueIdentifier()).sort().join(',');
    const defaultColumnConfigsId = this.defaultColumnConfigs?.map(c => c.getUniqueIdentifier()).sort().join(',');
    const tableConfigs = this.defaultProductTableColumConfigs?.map(c => c.getUniqueIdentifier()).sort().join(',');
    const enabledCannabinoids = this.enabledCannabinoids?.shallowCopy().sort().join(',');
    const enabledTerpenes = this.enabledTerpenes?.shallowCopy().sort().join(',');
    return `
      -${this.companyId}
      -${this.lastProductSync}
      -${this.lastInventorySync}
      -${this.lastLocationSync}
      -${this.lastPricingSync}
      -${this.lastLotInfoSync}
      -${this.lastDisplayNameSync}
      -${this.cannabinoidDisplayType}
      -${this.terpeneDisplayType}
      -${this.integrationDisabled}
      -${this.syncPOSCannabinoid}
      -${this.syncPOSTerpene}
      -${this.syncPOSLabels}
      -${this.syncDisplayNames}
      -${this.saleLabelFormat}
      -${this.labelStyle}
      -${this.discontinuedProductThreshold}
      -${enabledCannabinoids}
      -${enabledTerpenes}
      -${defaultCannabisUnitId}
      -${posCategoriesId}
      -${posCategoryGroupsId}
      -${colorPaletteId}
      -${defaultColumnConfigsId}
      -${tableConfigs}
      -${this.ignoreDelistedProducts}
      -${this.defaultTerpeneUnitOfMeasure}
    `;
  }

}
