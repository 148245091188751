import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'toCamelCase'
})
export class ToCamelCasePipe implements PipeTransform {

  transform(value: string): string {
    return StringUtils.toCamelCase(value);
  }

}
