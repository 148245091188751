import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuSectionProductTerpenePreviewViewModel } from '../components/edit-menu/shared/menu-section-product-preview/menu-section-product-terpene-preview/menu-section-product-terpene-preview-view-model';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'getTerpeneValuePreviewText'
})
export class GetTerpeneValuePreviewTextPipe implements PipeTransform {

  transform(vm: MenuSectionProductTerpenePreviewViewModel, terpenePascalCased: string): Observable<string> {
    return vm.getTerpeneText$(StringUtils.toCamelCase(terpenePascalCased));
  }

}
