<div class="pt-24px pb-24px bs-bold f14px">Collection Info</div>

<lib-reactive-form-group #newCollectionFormGroup [bindTo]="bindTo" [mergeKey]="mergeKey">
  <lib-reactive-form-text
    [inputName]="'collectionName'"
    [label]="'Collection Name'"
    [placeholder]="'Enter the collection name'"
    [bindingProperty]="'name'"
    [required]="true"></lib-reactive-form-text>

  <lib-reactive-form-drop-down
    [inputName]="'previewSize'"
    [label]="'Preview Size'"
    [placeholder]="'Select the size of this display'"
    [bindingProperty]="'displaySize.name'"
    [required]="true"
    [dropdowns]="defaultSizes$ | push"></lib-reactive-form-drop-down>

  <lib-reactive-form-drop-down
    [inputName]="'orientation'"
    [label]="'Orientation'"
    [placeholder]="'Orientation'"
    [bindingProperty]="'displaySize.orientation'"
    [required]="true"
    [tooltip]="'Portrait is a 90º clockwise rotation. Reverse portrait is a 90º counterclockwise rotation.'"
    [dropdowns]="orientations$ | push"></lib-reactive-form-drop-down>
</lib-reactive-form-group>
