<div class="modal-body sticky">
  <div
    libStickyAutoPosition
    class="sticky-header-container"
    [stickyCollectionKey]="'edit-collection-save-confirmation'"
    [stickyZ]="9">
    <div class="modal-header no-bottom-border">
      <div class="modal-title mb-28px">Location Display Changes</div>
    </div>
  </div>

  <div
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'edit-collection-save-confirmation'"
    [stickChildClassInstead]="'info-text'"
    [stickyZ]="9"
    [style.overflow-y]="'unset'">
    <div class="info-text bs-medium bg-white f12px">
      <p>The following changes will be made to the required location displays.</p>
      <hr />
    </div>

    <ng-container *ngIf="viewModel.numberOfProvinces$ | async as numberOfProvinces">
      <app-single-province-location-display-list-items
        *ngIf="numberOfProvinces === 1"
        [collectionIsPublished]="true"
        [originalRequiredDisplayIds]="viewModel.originalRequiredDisplayIds$ | async"
        [updatedRequiredDisplayIds]="viewModel.updatedRequiredDisplayIds$ | async"
        [groupedRequiredDisplays]="viewModel.changedDisplaysGroupedByLocationAndProvince$ | async">
      </app-single-province-location-display-list-items>

      <app-grouped-province-location-display-list-items
        *ngIf="numberOfProvinces > 1"
        [collectionIsPublished]="true"
        [originalRequiredDisplayIds]="viewModel.originalRequiredDisplayIds$ | async"
        [updatedRequiredDisplayIds]="viewModel.updatedRequiredDisplayIds$ | async"
        [groupedRequiredDisplays]="viewModel.changedDisplaysGroupedByLocationAndProvince$ | async">
      </app-grouped-province-location-display-list-items>
    </ng-container>
  </div>

  <div class="sticky-footer-container flex-container" style="padding-top: 1rem">
    <div [style.margin-left]="'auto'">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary ngbAutofocus [style.margin-left]="'0.5rem'" (buttonClicked)="close(true)">
        Save Collection
      </lib-button-primary>
    </div>
  </div>
</div>
