import { Component, Input } from '@angular/core';
import { CreateTemplateCollectionContainer } from './create-template-collection-container';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-template-collection-modal',
  templateUrl: './create-template-collection-modal.component.html',
  providers: [CreateTemplateCollectionContainer]
})
export class CreateTemplateCollectionModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public container: CreateTemplateCollectionContainer
  ) {
    super(activeModal);
  }

  @Input() stickyCollectionKey: string = 'create-template-collection';

}
