import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from 'src/app/models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../../../models/utils/dto/section-layout-type';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { InformationItem } from '../../../../../../../models/shared/information-item';
import { SectionColumnConfigDataValueType } from '../../../../../../../models/utils/dto/section-column-config-data-value-type';
import { ThemeSectionColumnConfig } from '../../../../../../../models/menu/dto/theme-section-column-config';
import { exists } from '../../../../../../../functions/exists';
import { SectionColumnConfigCannabinoidKey, SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigIndividualTerpeneKey, SectionColumnConfigKey, SectionColumnConfigPrimaryCannabinoidKey, SectionColumnConfigProductInfoKey, SectionColumnConfigSecondaryCannabinoidKey, SectionColumnConfigTerpeneKey } from '../../../../../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigDefaultStateType } from '../../../../../../../models/utils/dto/section-column-config-default-state-type';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/enum/dto/section-column-config-default-state';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../../../domainModels/cannabinoids-and-terpenes-domain-model';

type DefaultMapStates = [SectionColumnConfigKey, Observable<SectionColumnConfigDefaultStateType[]>];

@Injectable()
export class ColumnGeneralOptionsFormViewModel extends BaseViewModel {

  constructor(
    private cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  private noAutoSectionDefaultStateOptions$ = window.types.noAutoSectionColumnConfigDefaultStates$;
  private autoSectionDefaultStateOptions$ = window.types.autoSectionColumnConfigDefaultStates$;
  private disableSectionDefaultStateOptions$ = window.types.disabledSectionColumnConfigDefaultStates$;

  private selectableDefaultStateMap = new Map([
    ...Object.values(SectionColumnConfigProductInfoKey).map(key => {
      switch (key) {
        case SectionColumnConfigProductInfoKey.Asset:
        case SectionColumnConfigProductInfoKey.StrainType:
        case SectionColumnConfigProductInfoKey.Price:
        case SectionColumnConfigProductInfoKey.SecondaryPrice:
          return [key, this.noAutoSectionDefaultStateOptions$];
        default:
          return [key, this.autoSectionDefaultStateOptions$];
      }
    }),
    ...Object.values(SectionColumnConfigPrimaryCannabinoidKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigSecondaryCannabinoidKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigCollectiveTerpeneKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
    ...Object.values(SectionColumnConfigIndividualTerpeneKey).map(k => [k, this.autoSectionDefaultStateOptions$]),
  ] as DefaultMapStates[]);

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => this._formColumnConfig.next(formColumnConfig);

  private _columnSectionKey = new BehaviorSubject<SectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey.pipe(distinctUntilChanged());
  connectToColumnSectionKey = (columnSectionKey: SectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  private _inputDisabled = new BehaviorSubject<boolean>(false);
  public inputDisabled$ = this._inputDisabled as Observable<boolean>;
  connectToInputDisabled = (inputDisabled: boolean) => this._inputDisabled.next(inputDisabled);

  private _themeDefaults = new BehaviorSubject<ThemeSectionColumnConfig>(null);
  public themeDefaults$ = this._themeDefaults as Observable<ThemeSectionColumnConfig>;
  connectToThemeDefaults = (themeDefaults: ThemeSectionColumnConfig) => this._themeDefaults.next(themeDefaults);

  private _managingDefault = new BehaviorSubject<boolean>(false);
  public managingDefault$ = this._managingDefault as Observable<boolean>;
  connectToManagingDefault = (managingDefault: boolean) => this._managingDefault.next(managingDefault);

  public columnConfigDefaultState$ = this.formColumnConfig$.pipe(
    map(columnConfig => columnConfig?.defaultState)
  );

  public defaultStatePlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault || !exists(themeDefaults?.defaultState)) {
        return 'Select Column State';
      }
      return themeDefaults?.defaultState?.toString() + ' (Theme default)';
    })
  );

  public sectionDefaultStateOptions$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$.notNull()
  ]).pipe(
    switchMap(([sectionLayoutType, columnSectionKey]) => {
      if (ColumnOptionsViewModel.disabledForGridLayout(columnSectionKey, sectionLayoutType)) {
        return this.disableSectionDefaultStateOptions$;
      }
      return this.selectableDefaultStateMap?.get(columnSectionKey);
    }),
  );

  public sectionDefaultStateOptionsWithUnknown$ = combineLatest([
    this.sectionDefaultStateOptions$,
    this.defaultStatePlaceholder$
  ]).pipe(
    map(([defaultStateOptions, defaultStatePlaceholder]) => {
      const unknownDefaultStateOpt = new SectionColumnConfigDefaultStateType(
        SectionColumnConfigDefaultState.Unknown,
        defaultStatePlaceholder
      );
      return [...defaultStateOptions, unknownDefaultStateOpt];
    })
  );

  public tooltip$ = this.columnSectionKey$.pipe(
    map((key: any) => {
      switch (true) {
        case Object.values(SectionColumnConfigProductInfoKey).includes(key):
          return this.getProductInfoToopltip(key as SectionColumnConfigProductInfoKey);
        case Object.values(SectionColumnConfigPrimaryCannabinoidKey).includes(key):
        case Object.values(SectionColumnConfigSecondaryCannabinoidKey).includes(key):
          return this.getCannabinoidTooltip(key as SectionColumnConfigCannabinoidKey);
        case Object.values(SectionColumnConfigCollectiveTerpeneKey).includes(key):
        case Object.values(SectionColumnConfigIndividualTerpeneKey).includes(key):
          return this.getTerpeneTooltip(key as SectionColumnConfigTerpeneKey);
        default:
          return '';
      }
    })
  );

  public defaultStateInputDisabled$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$,
    this.formColumnConfig$,
    this.themeDefaults$
  ]).pipe(
    map(([layoutType, columnSectionKey, formColumnConfig, themeDefault]) => {
      return (layoutType?.isGrid() && columnSectionKey === SectionColumnConfigProductInfoKey.Price)
        || formColumnConfig?.defaultState === SectionColumnConfigDefaultState.Disabled
        || themeDefault?.defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public hideColumnName$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === SectionColumnConfigProductInfoKey.Asset;
    })
  );

  public columnSectionKeyIsCannabinoidOrIndividualTerpene$ = this.columnSectionKey$.pipe(
    map(key => {
      const cannabinoidAndTerpeneKeys = [
        ...(Object.values(SectionColumnConfigPrimaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(SectionColumnConfigSecondaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(SectionColumnConfigIndividualTerpeneKey)?.map(key => key?.toString()) || [])
      ];
      return cannabinoidAndTerpeneKeys.includes(key);
    })
  );

  public showColumnDataOptions$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrIndividualTerpene$
  ]).pipe(
    map(([key, isCannabinoidOrTerpeneKey]) => {
      const isAsset = key === SectionColumnConfigProductInfoKey.Asset;
      const isSecondaryPrice = key === SectionColumnConfigProductInfoKey.SecondaryPrice;
      const isStrainType = key === SectionColumnConfigProductInfoKey.StrainType;
      const isCannabinoidOrTerpeneAndNotTAC = isCannabinoidOrTerpeneKey
        && key !== SectionColumnConfigPrimaryCannabinoidKey.TAC;
      return isAsset || isSecondaryPrice || isStrainType || isCannabinoidOrTerpeneAndNotTAC;
    })
  );

  public columnDataOptionsLabel$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrIndividualTerpene$
  ]).pipe(
    map(([key, isCannabinoidOrTerpeneKey]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit';
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Use Value';
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return 'Data Layout';
        case isCannabinoidOrTerpeneKey:
          return 'Display Format';
      }
    })
  );

  public columnDataOptionsToolTipModalTitle$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrIndividualTerpene$
  ]).pipe(
    map(([key, isCannabinoidOrTerpeneKey]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit Information';
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Secondary Price Information';
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return 'Strain Type Layout';
        case isCannabinoidOrTerpeneKey:
          return 'Cannabinoid or Terpene Display Format';
      }
      return null;
    })
  );

  private dataValueDropdowns$ = combineLatest([
    window.types.assetColumnConfigDataValues$,
    window.types.secondaryPriceColumnConfigDataValues$,
    window.types.strainTypeColumnConfigDataValues$,
    window.types.displayFormatTypeColumnConfigDataValues$,
  ]);

  public columnDataOptionsDropdowns$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrIndividualTerpene$,
    this.dataValueDropdowns$
  ]).pipe(
    map(([key, isCannabinoidOrTerpeneKey, [assetOpts, secondaryPriceOpts, strainTypeOpts, displayFormatTypeOpts]]) => {
      switch (true) {
        case key === SectionColumnConfigProductInfoKey.Asset:
          return assetOpts;
        case key === SectionColumnConfigProductInfoKey.SecondaryPrice:
          return secondaryPriceOpts;
        case key === SectionColumnConfigProductInfoKey.StrainType:
          return strainTypeOpts;
        case isCannabinoidOrTerpeneKey:
          return displayFormatTypeOpts;
      }
    })
  );

  public columnDataOptionsToolTipModalItems$ = this.columnDataOptionsDropdowns$.pipe(
    map(dropdowns => {
      return dropdowns?.map(m => new InformationItem(m?.name, m?.getSelectionInformationDescription()));
    })
  );

  public widthRequired$ = this.inputDisabled$.pipe(
    map(inputDisabled => !inputDisabled)
  );

  public minWidth$ = this.themeDefaults$.pipe(
    map(themeDefaults => themeDefaults?.minColumnWidth || 5)
  );
  public maxWidth$ = this.themeDefaults$.pipe(
    map(themeDefaults => themeDefaults?.maxColumnWidth || 75)
  );

  public columnNamePlaceHolder$ = this.themeDefaults$.pipe(
    map(themeDefaults => {
      const defaultColumnName = themeDefaults?.columnName;
      return !!(defaultColumnName) ? defaultColumnName + ' (Theme default)' : 'Enter a column name';
    })
  );

  public columnWidthPlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault || !exists(themeDefaults?.columnWidth)) {
        return 'Enter Column Width';
      }
      return themeDefaults?.columnWidth?.toString() + ' (Theme default)';
    })
  );

  public useValuePlaceholder$ = combineLatest([
    this.themeDefaults$,
    this.managingDefault$
  ]).pipe(
    map(([themeDefaults, managingDefault]) => {
      if (managingDefault || !themeDefaults?.dataValue) {
        return 'Select Value to Use';
      }
      const name = window?.types?.initTypeDefinition(SectionColumnConfigDataValueType, themeDefaults?.dataValue)?.name;
      return `${name} (Theme default)`;
    })
  );

  private getProductInfoToopltip(key: SectionColumnConfigProductInfoKey): string {
    switch (key) {
      case SectionColumnConfigProductInfoKey.Asset:
        return 'Show product images in menu';
      case SectionColumnConfigProductInfoKey.Badges:
        return 'Auto - only appears when 1 or more variants in section have a badge associated';
      case SectionColumnConfigProductInfoKey.Brand:
        return 'Auto - only appears when 1 or more variants in section have a brand associated';
      case SectionColumnConfigProductInfoKey.StrainType:
        return 'Show product strain type in menu';
      case SectionColumnConfigProductInfoKey.Quantity:
        return 'Auto - only if 1 or more variants in section have QTY > 1. Will be disabled if menu is set to grid';
      case SectionColumnConfigProductInfoKey.Size:
        return 'Auto - only if 2 or more sizes exist on section variants';
      case SectionColumnConfigProductInfoKey.Price:
        return 'Show Price in menu';
      case SectionColumnConfigProductInfoKey.SecondaryPrice:
        return 'This column allows you to display a comparative price or display the price per unit of measure. '
          + 'Will be disabled if menu is set to grid.';
    }
  }

  private getCannabinoidTooltip(key: SectionColumnConfigCannabinoidKey): string {
    return 'Auto - only if 1 or more variants in section have cannabinoids and with a value of at least 1';
  }

  private getTerpeneTooltip(key: SectionColumnConfigTerpeneKey): string {
    switch (key) {
      case SectionColumnConfigCollectiveTerpeneKey.TotalTerpene:
        return 'Auto - only if 1 or more variants in section have total terpene amount greater than or equal to 1';
      case SectionColumnConfigCollectiveTerpeneKey.TopTerpene:
        return 'Auto - only if 1 or more variants in section have a top terpene set';
      default:
        return 'Auto - only if 1 or more variants in section have terpenes and with a value of at least 1';
    }
  }

}
