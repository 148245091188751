import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnConfigKey } from '../../../models/enum/dto/section-column-config-key';
import { ColumnAccordionLabelHelper } from '../viewModels/column-options-view-model';

@Pipe({
  name: 'getCurrentlySelectedDefaultStateLabelStyling'
})
export class GetCurrentlySelectedDefaultStateLabelStylingPipe implements PipeTransform {

  transform(
    key: SectionColumnConfigKey,
    defaultStateLabelColorMap: Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>
  ): any {
    return defaultStateLabelColorMap?.get(key)?.labelStyle;
  }

}
