import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Input, OnDestroy, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';
import { SortUtils } from '../../../../../utils/sort-utils';
import { takeUntil } from 'rxjs/operators';
import { PriceFormat } from '../../../../../models/enum/dto/price-format';
import { TableBadgeUtils } from '../../../utils/table-badge-utils';
import { ClientTypeUtils } from '../../../../../utils/client-type-utils';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';
import type { Product } from 'src/app/models/product/dto/product';
import type { Variant } from '../../../../../models/product/dto/variant';

export type SortFunctions = {
  productSort: (a: Product, b: Product) => number,
  variantSort?: { property: string, sortBy: (a: Variant, b: Variant) => number }
}

@Component({
  selector: 'app-all-products-table-header',
  templateUrl: './all-products-table-header.component.html',
  styleUrls: ['./all-products-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableHeaderComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProductsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent implements OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel, // global provided by root
  ) {
    super(viewRef);
    this.viewModel.locationId$.pipe(takeUntil(this.onDestroy)).subscribe(locationId => this.locationId = locationId);
    this.viewModel.companyId$.pipe(takeUntil(this.onDestroy)).subscribe(companyId => this.companyId = companyId);
    this.viewModel.priceFormat$.pipe(takeUntil(this.onDestroy)).subscribe(stream => this.priceFormat = stream);
  }

  @Input() loading: boolean;

  private locationId: number;
  private companyId: number;
  private priceFormat: PriceFormat;
  protected variantProperty = 'variantsFilteredByTable';

  protected readonly ClientTypeUtils = ClientTypeUtils;
  protected readonly PrimaryCannabinoid = PrimaryCannabinoid;

  // Name Sort
  public productNameAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTitle(), b?.getProductTitle());
  };
  public productNameDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTitle(), b?.getProductTitle());
  };
  public variantNameAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringAsc(a?.getDisplayName(), b?.getDisplayName())
  };
  public variantNameDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringDesc(a?.getDisplayName(), b?.getDisplayName())
  };

  // Price Sort
  public productPriceAsc = (a: Product, b: Product) => {
    return SortUtils.numberAscNullsLast(
      a?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false, true),
      b?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false, true)
    );
  };
  public productPriceDesc = (a: Product, b: Product) => {
    return SortUtils.numberDescNullsLast(
      a?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false, true),
      b?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false, true)
    );
  };
  public variantPriceAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsPriceAsc([a, b])
  };
  public variantPriceDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsPriceDesc([a, b])
  };

  // Secondary Price Sort
  public productSecondaryPriceAsc = (a: Product, b: Product) => {
    return SortUtils.numberAscNullsLast(
      a?.getLowestSecondaryPrice(this.locationId, this.companyId, true),
      b?.getLowestSecondaryPrice(this.locationId, this.companyId, true)
    );
  };
  public productSecondaryPriceDesc = (a: Product, b: Product) => {
    return SortUtils.numberDescNullsLast(
      a?.getLowestSecondaryPrice(this.locationId, this.companyId, true),
      b?.getLowestSecondaryPrice(this.locationId, this.companyId, true)
    );
  };
  public variantSecondaryPriceAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsSecondaryPriceAsc([a, b], null)
  };
  public variantSecondaryPriceDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsSecondaryPriceDesc([a, b], null)
  };

  // Brand Sort
  public productBrandAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getBrand(true), b?.getBrand(true));
  };
  public productBrandDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getBrand(true), b?.getBrand(true));
  };
  public variantBrandAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsBrandAsc(a, b)
  };
  public variantBrandDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsBrandDesc(a, b)
  };

  // Type Sort
  public productTypeAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public productTypeDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public variantTypeAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsVariantTypeAsc(a, b)
  };
  public variantTypeDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsVariantTypeDesc(a, b)
  };

  // Strain Sort
  public productStrainAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public productStrainDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public variantStrainAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStrainClassificationAsc(a, b)
  };
  public variantStrainDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStrainClassificationDesc(a, b)
  };

  // Quantity Sort
  public productQtyAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getQuantityInStockString(true), b?.getQuantityInStockString(true));
  };
  public productQtyDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getQuantityInStockString(true), b?.getQuantityInStockString(true));
  };
  public variantQtyAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStockAsc(a, b)
  };
  public variantQtyDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStockDesc(a, b)
  };

  // Top Terpene
  public productTopTerpeneAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.activeTopTerpene, b?.activeTopTerpene);
  };
  public productTopTerpeneDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.activeTopTerpene, b?.activeTopTerpene);
  };
  public variantTopTerpeneAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTopTerpeneAsc(a, b)
  };
  public variantTopTerpeneDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTopTerpeneDesc(a, b)
  };

  // TAC Sort
  public productTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.useRangedCannabinoids() ? v?.activeMinTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.useRangedCannabinoids() ? v?.activeMinTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productTacDesc = (a: Product, b: Product,) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.useRangedCannabinoids() ? v?.activeMaxTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.useRangedCannabinoids() ? v?.activeMaxTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTacAsc(a, b)
  };
  public variantTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTacDesc(a, b)
  };

  // Max TAC Sort
  public productMaxTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productMaxTacDesc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantMaxTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTacAsc(a, b)
  };
  public variantMaxTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTacDesc(a, b)
  };

  // Min TAC Sort
  public productMinTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productMinTacDesc = (a: Product, b: Product,) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantMinTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTacAsc(a, b)
  };
  public variantMinTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTacDesc(a, b)
  };

  // Total Terpene Sort
  public productTotalTerpeneAsc = (a: Product, b: Product) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.useRangedTerpenes() ? v?.activeMinTotalTerpene : v?.activeAvgTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.useRangedTerpenes() ? v?.activeMinTotalTerpene : v?.activeAvgTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinTerpene = !aTotalTerpene?.length ? null : Math.min(...aTotalTerpene);
    const bMinTerpene = !bTotalTerpene?.length ? null : Math.min(...bTotalTerpene);
    return SortUtils.numberAscNullsLast(aMinTerpene, bMinTerpene);
  };

  public productTotalTerpeneDesc = (a: Product, b: Product,) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.useRangedTerpenes() ? v?.activeMaxTotalTerpene : v?.activeAvgTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.useRangedTerpenes() ? v?.activeMaxTotalTerpene : v?.activeAvgTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxTerpene = !aTotalTerpene?.length ? null : Math.max(...aTotalTerpene);
    const bMaxTerpene = !bTotalTerpene?.length ? null : Math.max(...bTotalTerpene);
    return SortUtils.numberDescNullsLast(aMaxTerpene, bMaxTerpene);
  };
  public variantTotalTerpeneAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTotalTerpeneAsc(a, b)
  };
  public variantTotalTerpeneDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTotalTerpeneDesc(a, b)
  };

  // Max Total Terpene Sort
  public productMaxTotalTerpeneAsc = (a: Product, b: Product) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinTerpene = !aTotalTerpene?.length ? null : Math.min(...aTotalTerpene);
    const bMinTerpene = !bTotalTerpene?.length ? null : Math.min(...bTotalTerpene);
    return SortUtils.numberAscNullsLast(aMinTerpene, bMinTerpene);
  };
  public productMaxTotalTerpeneDesc = (a: Product, b: Product) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxTerpene = !aTotalTerpene?.length ? null : Math.max(...aTotalTerpene);
    const bMaxTerpene = !bTotalTerpene?.length ? null : Math.max(...bTotalTerpene);
    return SortUtils.numberDescNullsLast(aMaxTerpene, bMaxTerpene);
  };
  public variantMaxTotalTerpeneAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTotalTerpeneAsc(a, b)
  };
  public variantMaxTotalTerpeneDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTotalTerpeneDesc(a, b)
  };

  // Min Total Terpene Sort
  public productMinTotalTerpeneAsc = (a: Product, b: Product) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinTerpene = !aTotalTerpene?.length ? null : Math.min(...aTotalTerpene);
    const bMinTerpene = !bTotalTerpene?.length ? null : Math.min(...bTotalTerpene);
    return SortUtils.numberAscNullsLast(aMinTerpene, bMinTerpene);
  };
  public productMinTotalTerpeneDesc = (a: Product, b: Product) => {
    const aTotalTerpene = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTotalTerpene = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTotalTerpene)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxTerpene = !aTotalTerpene?.length ? null : Math.max(...aTotalTerpene);
    const bMaxTerpene = !bTotalTerpene?.length ? null : Math.max(...bTotalTerpene);
    return SortUtils.numberDescNullsLast(aMaxTerpene, bMaxTerpene);
  };
  public variantMinTotalTerpeneAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTotalTerpeneAsc(a, b)
  };
  public variantMinTotalTerpeneDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTotalTerpeneDesc(a, b)
  };

  // Label Sort
  public productLabelAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public productLabelDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public variantLabelAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringAsc(
      a?.computedLabelForProductTable?.text,
      b?.computedLabelForProductTable?.text
    )
  };
  public variantLabelDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringDesc(
      a?.computedLabelForProductTable?.text,
      b?.computedLabelForProductTable?.text
    )
  };

  // Badge Sort
  public productBadgeAsc = (a: Product, b: Product) => SortUtils.sortBadgesByNameAscending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );
  public productBadgeDesc = (a: Product, b: Product) => SortUtils.sortBadgesByNameDescending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );

  // Override Group Sort
  public overrideGroupAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.overrideGroupName, b?.overrideGroupName);
  };
  public overrideGroupDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.overrideGroupName, b?.overrideGroupName);
  };

  initializeFromBluePrint(bluePrint: AllProductsTableHeaderComponent): void {
    this.loading = bluePrint.loading;
  }

  changesFromBluePrint(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }

  sendOutputsToBluePrint(bluePrint: AllProductsTableHeaderComponent): void {
  }

}
