import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantMaxTotalTerpeneWithUnits'
})
export class GetVariantMaxTotalTerpeneWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null): string | null {
    return variant?.getMaxTotalTerpeneWithUnits();
  }

}
