import { CompanyDomainModel } from './company-domain-model';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DistinctUtils } from '../utils/distinct-utils';
import { SortUtils } from '../utils/sort-utils';
import { StringUtils } from '../utils/string-utils';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { ProviderUtils } from '../utils/provider-utils';
import { TypeDefinition } from '../models/utils/dto/type-definition';

// Provided by Logged In Scope
@Injectable()
export class CannabinoidsAndTerpenesDomainModel extends BaseDomainModel {

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private readonly companyConfiguration$ = this.companyDomainModel.companyConfiguration$;

  /* ************************* Cannabinoids ************************* */

  public readonly secondaryCannabinoidNames$ = window.types.secondaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.getSelectionTitle())?.sort(SortUtils.sortCannabinoidOrder)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cannabinoidNames$ = this.secondaryCannabinoidNames$.pipe(
    map(cannabinoids => ['THC', 'CBD', ...(cannabinoids || [])]?.sort(SortUtils.sortCannabinoidOrder)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cannabinoidNamesToLowerCase$ = this.secondaryCannabinoidNames$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.toLowerCase())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly posSupportedSecondaryCannabinoids$ = this.companyDomainModel.inventoryProvider$.pipe(
    map(ip => ProviderUtils.getSupportedSecondaryCannabinoids(ip)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledSecondaryCannabinoids$ = combineLatest([
    this.companyConfiguration$.pipe(map(cc => cc?.enabledCannabinoids)),
    window.types.secondaryCannabinoids$
  ]).pipe(
    map(([enabledValues, cannabinoids]) => cannabinoids?.filter(c => enabledValues?.includes(c?.value))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasEnabledSecondaryCannabinoids$ = this.enabledSecondaryCannabinoids$.pipe(
    map(enabledSecondaryCannabinoids => enabledSecondaryCannabinoids?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledCannabinoidsWithoutTAC$ = this.enabledSecondaryCannabinoids$.pipe(
    map(secondaryCannabinoids => {
      return [
        new TypeDefinition(PrimaryCannabinoid.THC, PrimaryCannabinoid.THC),
        new TypeDefinition(PrimaryCannabinoid.CBD, PrimaryCannabinoid.CBD),
        ...(secondaryCannabinoids || [])
      ];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledCannabinoidNamesWithoutTAC$ = this.enabledCannabinoidsWithoutTAC$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.getSelectionTitle())?.sort(SortUtils.sortCannabinoidOrder)),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledSecondaryCannabinoidNames$ = this.enabledSecondaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.getSelectionTitle())?.sort(SortUtils.sortCannabinoidOrder)),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledCannabinoidNames$ = this.enabledSecondaryCannabinoidNames$.pipe(
    map(cannabinoids => [
      ...Object.values(PrimaryCannabinoid),
      ...(cannabinoids || [])
    ]?.sort(SortUtils.sortCannabinoidOrder)),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledCannabinoidNamesToLowerCase$ = this.enabledSecondaryCannabinoidNames$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.toLowerCase())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledPOSSupportedSecondaryCannabinoids$ = combineLatest([
    this.enabledSecondaryCannabinoids$,
    this.posSupportedSecondaryCannabinoids$
  ]).pipe(
    map(([enabledSecondaryCannabinoids, posSupportedSecondaryCannabinoids]) => {
      return enabledSecondaryCannabinoids?.filter(cannabinoid => {
        return posSupportedSecondaryCannabinoids.includes(cannabinoid?.getSelectionValue());
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly posSupportedSecondaryCannabinoidNames$ = this.enabledPOSSupportedSecondaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.getSelectionTitle())),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly otherEnabledSecondaryCannabinoids$ = combineLatest([
    this.enabledSecondaryCannabinoids$,
    this.posSupportedSecondaryCannabinoids$
  ]).pipe(
    map(([enabledSecondaryCannabinoids, posSupportedSecondaryCannabinoids]) => {
      return enabledSecondaryCannabinoids?.filter(cannabinoid => {
        return !posSupportedSecondaryCannabinoids.includes(cannabinoid?.getSelectionValue());
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly otherEnabledSecondaryCannabinoidNames$ = this.otherEnabledSecondaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids?.map(c => c?.getSelectionTitle())),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * return a map of <name, enabled> for all cannabinoids
   */
  public readonly enabledCannabinoidSignals$ = combineLatest([
    this.cannabinoidNames$.pipe(
      map(cannabinoidNames => [...(cannabinoidNames || []), PrimaryCannabinoid.TAC])
    ),
    this.enabledCannabinoidNames$.pipe(
      map(enabledCannabinoidNames => [...(enabledCannabinoidNames || []), PrimaryCannabinoid.TAC])
    )
  ]).pipe(
    map(([cannabinoids, enabledCannabinoids]) => {
      const enabledMap = new Map<string, boolean>();
      cannabinoids?.forEach(c => enabledMap?.set(c, enabledCannabinoids?.includes(c)));
      return enabledMap;
    }),
    shareReplay(1)
  );

  public readonly isCannabinoidEnabled$ = (cannabinoid: string) => this.enabledCannabinoidSignals$.pipe(
    map(signals => signals?.get(cannabinoid))
  );

  /* ************************* Terpenes ************************* */

  public readonly terpeneNames$ = window.types.terpenes$.pipe(
    map(terpenes => terpenes?.map(t => t?.getSelectionTitle())?.sort()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly terpeneNamesPascalCased$ = this.terpeneNames$.pipe(
    map(terpenes => terpenes?.map(terpene => StringUtils.toPascalCase(terpene))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly terpeneNamesCamelCased$ = this.terpeneNames$.pipe(
    map(terpenes => terpenes?.map(terpene => StringUtils.toCamelCase(terpene))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpenes$ = combineLatest([
    this.companyConfiguration$.pipe(map(cc => cc?.enabledTerpenes)),
    window.types.terpenes$
  ]).pipe(
    map(([enabledValues, terpenes]) => terpenes?.filter(t => enabledValues?.includes(t?.value))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly posSupportedTerpenes$ = this.companyDomainModel.inventoryProvider$.pipe(
    map(ip => ProviderUtils.getSupportedIndividualTerpenes(ip)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpeneNames$ = this.enabledTerpenes$.pipe(
    map(terpenes => terpenes?.map(t => t?.getSelectionTitle())?.sort()),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpenesSupportedByPOS$ = combineLatest([
    this.enabledTerpenes$,
    this.posSupportedTerpenes$
  ]).pipe(
    map(([enabledTerpenes, posSupportedTerpenes]) => {
      return enabledTerpenes?.filter(terpene => {
        return posSupportedTerpenes?.includes(terpene?.getSelectionValue());
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledPOSSupportedTerpeneNames$ = this.enabledTerpenesSupportedByPOS$.pipe(
    map(terpenes => terpenes?.map(t => t?.getSelectionTitle())),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly otherEnabledTerpenes$ = combineLatest([
    this.enabledTerpenes$,
    this.posSupportedTerpenes$
  ]).pipe(
    map(([enabledTerpenes, posSupportedTerpenes]) => {
      return enabledTerpenes?.filter(terpene => {
        return !posSupportedTerpenes?.includes(terpene?.getSelectionValue());
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly otherEnabledTerpeneNames$ = this.otherEnabledTerpenes$.pipe(
    map(terpenes => terpenes?.map(t => t?.getSelectionTitle())),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpeneNamesPascalCased$ = this.enabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.map(terpene => StringUtils.toPascalCase(terpene))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpeneNamesCamelCased$ = this.enabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.map(terpene => StringUtils.toCamelCase(terpene))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasEnabledTerpenes$ = this.enabledTerpeneNames$.pipe(
    map(enabledTerpenes => enabledTerpenes?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public cannabinoidAndTerpeneSavingSignals$ = combineLatest([
    this.enabledCannabinoidNames$,
    this.enabledTerpeneNamesCamelCased$,
    this.enabledTerpeneNamesPascalCased$,
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly enabledTerpeneSignals$ = combineLatest([
    this.terpeneNamesCamelCased$.pipe(map(terpenes => [...(terpenes || []), 'totalTerpene'])),
    this.enabledTerpeneNamesCamelCased$.pipe(map(enabledTerpenes => [...(enabledTerpenes || []), 'totalTerpene']))
  ]).pipe(
    map(([terpenesCamelCased, enabledTerpenesCamelCased]) => {
      const enabledMap = new Map<string, boolean>();
      terpenesCamelCased?.forEach(t => enabledMap?.set(t, enabledTerpenesCamelCased?.includes(t)));
      return enabledMap;
    }),
    shareReplay(1)
  );

  public readonly isTerpeneEnabled$ = (terpeneCamelCased: string) => this.enabledTerpeneSignals$.pipe(
    map(signals => signals?.get(terpeneCamelCased))
  );

}
