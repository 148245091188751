<div
  class="flex-container flex-align-center"
  [class.sticky-footer-container]="includeStickyFooterContainerClass"
  [style.padding-top.rem]="includeStickyFooterContainerClass ? 1 : null"
  [class.bg-white]="includeWhiteBackground">
  <div class="text-link" [hidden]="!showBackButton" (click)="goBack.emit()">Go Back</div>
  <div [style.margin-left]="'auto'">
    <lib-button-neutral *ngIf="showCancelButton" (buttonClicked)="cancel.emit()"> Cancel </lib-button-neutral>
    <lib-button-primary
      *ngIf="showPrimaryButton"
      [style.margin-left]="'1rem'"
      [disabled]="disablePrimaryButton"
      (buttonClicked)="primaryButtonClicked.emit()">
      {{ primaryButtonText }}
    </lib-button-primary>
  </div>
</div>
