import { Pipe, PipeTransform } from '@angular/core';
import type { SortFunctions } from '../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import type { Product } from '../../../models/product/dto/product';
import type { Variant } from '../../../models/product/dto/variant';
import { SortUtils } from '../../../utils/sort-utils';
import { TableSortingService } from '../services/table-sorting.service';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'getTableSortingFunctionsForCannabinoid'
})
export class GetTableSortingFunctionsForCannabinoidPipe implements PipeTransform {

  constructor(
    protected tableSortingService: TableSortingService
  ) {
  }

  /**
   * We need asc and desc to return different sort functions in memory. That's why they are separated when accessing
   * them through the tableSortingService. The table uses the memory address to know if the sort function is
   * selected or not, so if both asc and desc are the same in memory, then it can't distinguish which one has been
   * selected.
   */
  transform(
    cannabinoid: string,
    type: 'asc' | 'desc',
    variantProperty: string,
    rangeAccessor?: 'min' | 'max'
  ): SortFunctions {
    const key = exists(rangeAccessor)
      ? `${type}-${rangeAccessor}${cannabinoid}`
      : `${type}-${cannabinoid}`;
    if (this.tableSortingService.hasSortingFunctions(key)) {
      return this.tableSortingService.getSortingFunctions(key);
    }
    const sortingFunctions = {
      productSort: (a: Product, b: Product) => {
        if (type === 'asc') {
          switch (rangeAccessor) {
            case 'min': return this.productMinCannabinoidAsc(a, b, cannabinoid);
            case 'max': return this.productMaxCannabinoidAsc(a, b, cannabinoid);
            default:    return this.productCannabinoidAsc(a, b, cannabinoid);
          }
        }
        if (type === 'desc') {
          switch (rangeAccessor) {
            case 'min': return this.productMinCannabinoidDesc(a, b, cannabinoid);
            case 'max': return this.productMaxCannabinoidDesc(a, b, cannabinoid);
            default:    return this.productCannabinoidDesc(a, b, cannabinoid);
          }
        }
      },
      variantSort: {
        property: variantProperty,
        sortBy: (a: Variant, b: Variant) => {
          if (type === 'asc') {
            switch (rangeAccessor) {
              case 'min': return SortUtils.variantsMinCannabinoidOrTerpeneAsc(a, b, cannabinoid);
              case 'max': return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(a, b, cannabinoid);
              default:    return SortUtils.variantsCannabinoidAsc(a, b, cannabinoid);
            }
          }
          if (type === 'desc') {
            switch (rangeAccessor) {
              case 'min': return SortUtils.variantsMinCannabinoidOrTerpeneDesc(a, b, cannabinoid);
              case 'max': return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(a, b, cannabinoid);
              default:    return SortUtils.variantsCannabinoidDesc(a, b, cannabinoid);
            }
          }
        }
      }
    };
    this.tableSortingService.addSortingFunctions(cannabinoid, sortingFunctions);
    return sortingFunctions;
  }

  protected productCannabinoidAsc = (a: Product, b: Product, cannabinoid: string) => {
    const aCannabinoid = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedCannabinoids()
          ? v?.getNumericMinCannabinoidOrTerpene(cannabinoid)
          : v?.getNumericCannabinoidOrTerpene(cannabinoid);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bCannabinoid = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedCannabinoids()
          ? v?.getNumericMinCannabinoidOrTerpene(cannabinoid)
          : v?.getNumericCannabinoidOrTerpene(cannabinoid);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aCannabinoid?.length ? null : Math.min(...aCannabinoid);
    const bMinCannabinoid = !bCannabinoid?.length ? null : Math.min(...bCannabinoid);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  protected productCannabinoidDesc = (a: Product, b: Product, cannabinoid: string) => {
    const aCannabinoid = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedCannabinoids()
          ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
          : v?.getNumericCannabinoidOrTerpene(cannabinoid);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bCannabinoid = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedCannabinoids()
          ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoid)
          : v?.getNumericCannabinoidOrTerpene(cannabinoid);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aCannabinoid?.length ? null : Math.max(...aCannabinoid);
    const bMaxCannabinoid = !bCannabinoid?.length ? null : Math.max(...bCannabinoid);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };

  public productMinCannabinoidAsc = (a: Product, b: Product, cannabinoid: string) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoid))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinCannabinoid = getFilteredMinValues(a);
    const bMinCannabinoid = getFilteredMinValues(b);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productMinCannabinoidDesc = (a: Product, b: Product, cannabinoid: string) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoid))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinCannabinoid = getFilteredMinValues(a);
    const bMinCannabinoid = getFilteredMinValues(b);
    return SortUtils.numberDescNullsLast(aMinCannabinoid, bMinCannabinoid);
  };

  public productMaxCannabinoidAsc = (a: Product, b: Product, cannabinoid: string) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoid))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxCannabinoid = getFilteredMaxValues(a);
    const bMaxCannabinoid = getFilteredMaxValues(b);
    return SortUtils.numberAscNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public productMaxCannabinoidDesc = (a: Product, b: Product, cannabinoid: string) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoid))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxCannabinoid = getFilteredMaxValues(a);
    const bMaxCannabinoid = getFilteredMaxValues(b);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };

}
