<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | push">
  <div *ngIf="viewModel.showCannabinoidPOSSyncBanner$ | push" class="cannabinoid-disabled-banner">
    <app-lot-info-banner
      [inventoryProvider]="viewModel.container.inventoryProvider$ | push"
      [displayAttribute]="viewModel.container.displayAttribute$ | push"
      [selectedTabId]="ClientTypes.EditVariantModalTabId.Cannabinoids">
    </app-lot-info-banner>
  </div>
  <div class="cannabinoids-form">
    <div class="input-and-then-preview">
      <lib-reactive-form-group
        [bindTo]="viewModel.container.variant$ | push"
        [mergeKey]="'editVariantFormCapture'"
        class="flex-fill">
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-drop-down
            [inputName]="'cannabisUnitOfMeasure'"
            [bindingProperty]="'cannabisUnitOfMeasure'"
            [label]="'Cannabis Unit Of Measure'"
            [required]="true"
            [placeholder]="'Select cannabis unit of measure'"
            [tooltip]="viewModel.CUOMTooltip$ | push"
            [dropdowns]="viewModel.cannabisUnitOfMeasureOptions$ | push"
            [disabled]="viewModel.disableCannabisUnitOrMeasure$ | push"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.cuomSelectionChanged($event)"></lib-reactive-form-drop-down>
          <lib-reactive-form-switch
            *ngIf="viewModel.container.showVariantUseRangeSwitch$ | push"
            [inputName]="'useCannabinoidRange'"
            [bindingProperty]="'useCannabinoidRange'"
            [label]="'Use Cannabinoid Range'"
            [tooltip]="
              'Specific products can be enabled to use cannabinoid range. ' +
              'This is useful for multi-pack products with varying cannabinoids.'
            "
            [disabled]="!(viewModel.container.isCompanyAdmin$ | push)"
            [disabledTooltip]="!(viewModel.container.isCompanyAdmin$ | push) | notAnAdminTooltip"
            [showErrorMessagesOnInitialization]="true"
            (valueChanged)="viewModel.container.setVariantUsesCannabinoidRange($event)"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
      <app-cannabinoid-preview-top></app-cannabinoid-preview-top>
    </div>

    <div class="input-and-then-preview">
      <div class="form-section-sub-title flex-fill">
        <span class="text"> Primary Cannabinoids </span>
      </div>
      <app-cannabinoid-preview-chunk></app-cannabinoid-preview-chunk>
    </div>

    <app-cannabinoids-form
      *rxFor="let cannabinoid of viewModel.primaryCannabinoidsWithoutTAC$; trackBy: viewModel.trackByCannabinoid"
      [cannabinoid]="cannabinoid"
      [mergeKey]="'editVariantFormCapture'">
    </app-cannabinoids-form>

    <div class="input-and-then-preview">
      <div class="form-section-title flex-fill">Cannabinoid Values</div>
      <app-cannabinoid-preview-chunk></app-cannabinoid-preview-chunk>
    </div>

    <div class="input-and-then-preview">
      <lib-reactive-form-column-layout nColumns="2" class="flex-fill">
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantCompanyDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.companyDAUpdated($event, 'presentCannabinoids')"
            [inputName]="'companyPresentCannabinoids'"
            [label]="'Present Cannabinoids (Company)'"
            [tooltip]="
              'Associate cannabinoids with this product. If not explicitly specified, then the list is generated from cannabinoids present on the product'
            "
            [placeholder]="viewModel.companyPresentCannabinoidTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.companyPresentCannabinoidTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.companyPresentCannabinoidPillPlaceholders$ | push"
            [bindingProperty]="'presentCannabinoids'"
            [dropdowns]="viewModel.enabledCannabinoids$ | push"
            [disabled]="
              !(viewModel.container.isCompanyAdmin$ | push) || viewModel.disablePresentCannabinoidInput$ | push
            "></lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
        <lib-reactive-form-group
          [bindTo]="viewModel.container.variantLocationDisplayAttribute$ | push"
          [mergeKey]="'editVariantFormCapture'">
          <lib-reactive-form-pill-drop-down
            (valueChanged)="viewModel.locationDAUpdated($event, 'presentCannabinoids')"
            [inputName]="'locationPresentCannabinoids'"
            [label]="'Present Cannabinoids (Location)'"
            [tooltip]="
              'Associate cannabinoids with this product. If not explicitly specified, then the list is generated from cannabinoids present on the product'
            "
            [placeholder]="viewModel.locationPresentCannabinoidTextPlaceholder$ | push"
            [emptyPlaceholder]="viewModel.locationPresentCannabinoidTextPlaceholder$ | push"
            [pillPlaceholders]="viewModel.locationPresentCannabinoidPillPlaceholders$ | push"
            [bindingProperty]="'presentCannabinoids'"
            [dropdowns]="viewModel.enabledCannabinoids$ | push"
            [disabled]="viewModel.disablePresentCannabinoidInput$ | push"></lib-reactive-form-pill-drop-down>
        </lib-reactive-form-group>
      </lib-reactive-form-column-layout>
      <app-cannabinoid-preview-chunk [cannabinoid]="'presentCannabinoids'"></app-cannabinoid-preview-chunk>
    </div>

    <app-cannabinoids-form [cannabinoid]="PrimaryCannabinoid.TAC" [mergeKey]="'editVariantFormCapture'">
    </app-cannabinoids-form>

    <ng-container *rxIf="viewModel.hasEnabledSecondaryCannabinoids$ | push; else SecondaryCannabinoidsEnableMessage">
      <div class="input-and-then-preview">
        <div class="flex-fill">
          <div *rxIf="viewModel.hasPosSupportedSecondaryCannabinoids$ | push" class="form-section-sub-title flex-fill">
            <span class="text"> POS Managed Secondary Cannabinoids </span>
          </div>
          <app-message-banner
            *rxIf="(viewModel.cannabinoidsWithPOSValueNotEnabledInBudsense$ | push)?.length > 0"
            class="mb-32px">
            The following cannabinoids have values set, but are not enabled within BudSense settings. In order to view
            this value, enable the cannabinoid in Settings > Products > Cannabinoids & Terpenes.
            <ul class="row">
              <li *rxFor="let cannabinoid of viewModel.cannabinoidsWithPOSValueNotEnabledInBudsense$" class="col-lg-6">
                {{ cannabinoid }}
              </li>
            </ul>
          </app-message-banner>
        </div>
        <app-cannabinoid-preview-chunk></app-cannabinoid-preview-chunk>
      </div>
      <app-cannabinoids-form
        *rxFor="
          let secondaryCannabinoid of viewModel.posSupportedSecondaryCannabinoidNames$;
          trackBy: viewModel.trackBySecondaryCannabinoidName
        "
        [cannabinoid]="secondaryCannabinoid"
        [mergeKey]="'editVariantFormCapture'">
      </app-cannabinoids-form>
      <div class="input-and-then-preview">
        <div *rxIf="viewModel.hasOtherEnabledSecondaryCannabinoids$ | push" class="form-section-sub-title flex-fill">
          <span class="text"> BudSense Managed Secondary Cannabinoids </span>
        </div>
        <app-cannabinoid-preview-chunk></app-cannabinoid-preview-chunk>
      </div>

      <app-cannabinoids-form
        *rxFor="
          let secondaryCannabinoid of viewModel.otherEnabledSecondaryCannabinoids$;
          trackBy: viewModel.trackBySecondaryCannabinoidName
        "
        [cannabinoid]="secondaryCannabinoid"
        [mergeKey]="'editVariantFormCapture'"
        class="flex-fill">
      </app-cannabinoids-form>
    </ng-container>

    <ng-template #SecondaryCannabinoidsEnableMessage>
      <div class="input-and-then-preview">
        <div class="secondary-container flex-fill">
          <div class="secondary-message">
            Secondary cannabinoids can be enabled within “Settings > Products > Cannabinoids & Terpenes”. Once enabled
            they will appear here (ie: CBG, CBN, THCA).
          </div>
          <lib-button-neutral
            *rxIf="viewModel.container.isCompanyAdmin$ | push"
            [iconSrc]="'assets/icons/dark/outline/cog.svg'"
            (buttonClicked)="editProductSettings()">
            Product Settings
          </lib-button-neutral>
        </div>
        <app-cannabinoid-preview-chunk></app-cannabinoid-preview-chunk>
      </div>
    </ng-template>

    <div class="input-and-then-preview">
      <div class="flex-fill"></div>
      <app-cannabinoid-preview-bottom></app-cannabinoid-preview-bottom>
    </div>
  </div>
</div>
