import { Component, Input } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { PricePreviewWidgetViewModel } from './price-preview-widget-view-model';

@Component({
  selector: 'app-price-preview-widget',
  templateUrl: './price-preview-widget.component.html',
  styleUrls: ['./price-preview-widget.component.scss'],
  providers: [PricePreviewWidgetViewModel]
})
export class PricePreviewWidgetComponent {

  constructor(
    public viewModel: PricePreviewWidgetViewModel
  ) {
  }

  @Input() price: any;
  @Input() priceSource: string;
  @Input() @ViewModelConnector('connectToPriceFormatText') locPriceFormat: string;

}
