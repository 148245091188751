import { MenuSectionProductInfoPreviewViewModel } from '../menu-section-product-info-preview/menu-section-product-info-preview-view-model';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { SortUtils } from '../../../../../../../utils/sort-utils';

@Injectable()
export class MenuSectionProductTerpenePreviewViewModel extends MenuSectionProductInfoPreviewViewModel {

  constructor(
    private cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private companyConfig$ = this.companyDomainModel.companyConfiguration$;
  private rangeTerpenesAtCompanyLevel$ = this.companyDomainModel.rangeTerpenesAtCompanyLevel$;
  private enabledTerpenesPascalCased$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNamesPascalCased$;

  public terpeneRange$ = combineLatest([
    this.variant$,
    this.rangeTerpenesAtCompanyLevel$
  ]).pipe(
    map(([variant, rangeTerpenes]) => variant?.useTerpeneRange || rangeTerpenes)
  );

  public getTerpeneText$ = (terpeneCamelCased: string): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyConfig$.notNull(),
    ]).pipe(
      map(([variant, companyConfig]) => variant?.getTerpeneValuePreviewText(terpeneCamelCased, companyConfig)),
      distinctUntilChanged()
    );
  };

  public readonly pascalCasedTerpenesThatAreExplicitlySetInDescOrder$ = combineLatest([
    this.variant$,
    this.enabledTerpenesPascalCased$,
    this.companyConfig$,
  ]).pipe(
    map(([variant, enabledTerpenesPascalCased, companyConfig]) => {
      return variant?.pascalCasedTerpenesThatAreExplicitlySetInDescOrder(enabledTerpenesPascalCased, companyConfig);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly pascalCasedTerpsGreaterThanZero$ = combineLatest([
    this.variant$,
    this.enabledTerpenesPascalCased$,
    this.companyConfig$,
  ]).pipe(
    map(([variant, enabledPascalTerps, companyConfig]) => {
      return variant?.pascalCasedTerpenesThatAreExplicitlySetInDescOrder(enabledPascalTerps, companyConfig, true);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly pascalCasedTerpenesExplicitlySetNameAsc$ = this.pascalCasedTerpenesThatAreExplicitlySetInDescOrder$
    .pipe(map(terpenes => terpenes?.shallowCopy()?.sort(SortUtils.numericStringAsc)));

  public readonly hasExplicitlySetTerpenes$ = this.pascalCasedTerpenesThatAreExplicitlySetInDescOrder$.pipe(
    map(pascalCasedTerpenesInDescOrder => pascalCasedTerpenesInDescOrder?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly pascalCasedTerpsGreaterThanZeroNameAsc$ = this.pascalCasedTerpsGreaterThanZero$.pipe(
    map(terpenes => terpenes?.shallowCopy()?.sort(SortUtils.numericStringAsc))
  );

  public readonly hasTerpenesGreaterThanZero$ = this.pascalCasedTerpsGreaterThanZero$.pipe(
    map(pascalCasedTerpenesInDescOrder => pascalCasedTerpenesInDescOrder?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly largestPascalCasedTerpenes$ = this.pascalCasedTerpenesThatAreExplicitlySetInDescOrder$.pipe(
    map(pascalCasedTerpenesInDescOrder => pascalCasedTerpenesInDescOrder?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyTerpenesAreExplicitlySet$ = this.pascalCasedTerpenesThatAreExplicitlySetInDescOrder$.pipe(
    map(pascalCasedTerpenesThatAreExplicitlySet => pascalCasedTerpenesThatAreExplicitlySet?.length ?? 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyTerpenesAreExplicitlySetGreaterThanZero$ = this.pascalCasedTerpsGreaterThanZero$.pipe(
    map(pascalCasedTerpenesThatAreExplicitlySet => pascalCasedTerpenesThatAreExplicitlySet?.length ?? 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyVisiblePropertiesWhenPlusXMoreShown = 1;
  public readonly howManyAdditionalPropertiesBeingShownInPopper = 2; // Top and total terp

  public readonly nMore$ = this.howManyTerpenesAreExplicitlySetGreaterThanZero$.pipe(
    map(nProperties => nProperties - this.howManyVisiblePropertiesWhenPlusXMoreShown),
    map(nMore => nMore + this.howManyAdditionalPropertiesBeingShownInPopper)
  );

  public getTopTerpeneText$ = (): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyConfig$
    ]).pipe(
      map(([variant, companyConfig]) => variant?.getTopTerpeneValuePreviewText(companyConfig))
    );
  };

  public getTotalTerpeneText$ = (): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyConfig$
    ]).pipe(
      map(([variant, companyConfig]) => variant?.getTotalTerpenesValuePreviewText(companyConfig))
    );
  };

}
