import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ModalCustomVisibleColumns } from '../../../../modals/modal-custom-visible-columns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomVisibleColumnsPickerViewModel } from './custom-visible-columns-picker-view-model';
import { ProductTableColumnConfig } from '../../../../models/menu/dto/product-table-column-config';
import { ProductTableColumnConfigsIdValue } from '../../../../models/utils/dto/product-table-column-configs-type';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-custom-visible-columns-picker',
  templateUrl: './custom-visible-columns-picker.component.html',
  styleUrls: ['./custom-visible-columns-picker.component.scss'],
  providers: [CustomVisibleColumnsPickerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomVisibleColumnsPickerComponent extends BaseComponent {

  constructor(
    public viewModel: CustomVisibleColumnsPickerViewModel,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToSelectedProductTableColumnConfigsIdValue')
    currentProductColumnConfigsIdValue: ProductTableColumnConfigsIdValue;
  @Output() productTableColumnConfigSelected: EventEmitter<ProductTableColumnConfigsIdValue>
    = new EventEmitter<ProductTableColumnConfigsIdValue>();

  handleProductTableColumnConfigSelection(data: ProductTableColumnConfigsIdValue) {
    this.productTableColumnConfigSelected.emit(data);
  }

  openCustomVisibleColumnsModal() {
    this.viewModel.customProductTableColumnConfig$.once(customProductTableColumnConfig => {
      const onClose = (customColumnConfig: ProductTableColumnConfig) => {
        this.viewModel.updateCustomProductTableColumnConfig(customColumnConfig);
        this.productTableColumnConfigSelected.emit(ProductTableColumnConfigsIdValue.Custom);
      };
      ModalCustomVisibleColumns.open(this.ngbModal, this.injector, customProductTableColumnConfig, onClose);
    });
  }

}
