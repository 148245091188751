import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductLabelsModule } from '../product-labels.module';
import { SmartFiltersModule } from '../../../views/smart-filters/smart-filters.module';
import { CreateCustomLabelComponent } from './create-custom-label/create-custom-label.component';
import { GetDisplayLabelInterfaceForCreateCustomLabelPipe } from './create-custom-label/get-display-label-interface-for-create-custom-label.pipe';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { ReactiveFormExtensionsModule } from '../../reactive-form-extensions/reactive-form-extensions.module';
import { ManageLabelSmartFiltersModalComponent } from './manage-label-smart-filters-modal/manage-label-smart-filters-modal.component';
import { SharedModule } from '../../../views/shared/shared.module';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { GetDisplayLabelInterfaceForManageLabelSmartFiltersPipe } from './manage-label-smart-filters-modal/get-display-label-interface-for-manage-label-smart-filters.pipe';
import { GetRemoveFromExistingProductsTooltipPipe } from './create-custom-label/get-remove-from-exisiting-products-tooltip.pipe';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';

@NgModule({
  declarations: [
    CreateCustomLabelComponent,
    GetDisplayLabelInterfaceForCreateCustomLabelPipe,
    ManageLabelSmartFiltersModalComponent,
    GetDisplayLabelInterfaceForManageLabelSmartFiltersPipe,
    GetRemoveFromExistingProductsTooltipPipe,
  ],
  imports: [
    CommonModule,
    ProductLabelsModule,
    SmartFiltersModule,
    ReactiveFormModule,
    ReactiveFormExtensionsModule,
    SharedModule,
    ReactiveButtonsModule,
    StickyAutoPositionDirective
  ],
  exports: [
    CreateCustomLabelComponent,
    ManageLabelSmartFiltersModalComponent,
    GetRemoveFromExistingProductsTooltipPipe
  ]
})
export class SmartLabelsModule { }
