import { Pipe, PipeTransform } from '@angular/core';
import type { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'productDisplayAttributesMethod'
})
export class ProductDisplayAttributesMethodPipe implements PipeTransform {

  transform(product: Product, method: string, ...args: any[]): any {
    return product?.displayAttributes?.[method]?.(...args);
  }

}
