<div class="modal-body sticky">
  <app-collection-displays-modal-header
    [title]="'Select Locations'"
    [stickyCollectionKey]="'pending-display-locations'"
    [stickyOrder]="0"
    [stickyZ]="9"
    [showClearButton]="true"
    [disableClearButton]="!(viewModel.nSelectedLocationIds$ | async)"
    (clear)="viewModel.connectToSelectedRequiredLocationIds([])">
  </app-collection-displays-modal-header>

  <div
    class="sticky-body-container bg-white"
    libStickyAutoPosition
    [stickyCollectionKey]="'pending-display-locations'"
    [stickyOrder]="1"
    [stickyZ]="9">
    <div class="pt-16px">
      <app-search-with-look-ahead
        [placeHolder]="'Search for a location'"
        [lookAheadItems]="viewModel.allLocations$ | async"
        [searchTheseProperties]="['name', 'address', 'city', 'state', 'country']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        [outputAllItemsWhenSearchIsEmpty]="true"
        (searchText)="viewModel.connectToSearchString($event)"
        (lookAheadHits)="viewModel.connectToSearchedLocations($event)">
      </app-search-with-look-ahead>
    </div>
    <ng-container [ngSwitch]="(viewModel.searchedLocations$ | async)?.length > 0">
      <div *ngSwitchCase="true" class="select-all-locations-container">
        <div fxLayout="row" fxLayoutAlign="start center">
          <app-group-selection
            #groupSelectionComponent
            [selection]="viewModel.locationsVisibleOnScreen$ | async"
            [selectedIds]="viewModel.selectedRequiredLocationIdsAsStringList$ | async"
            (bulkAdd)="viewModel.addLocationIds($event)"
            (bulkRemove)="viewModel.removeLocationIds($event)">
          </app-group-selection>
          <span *ngIf="viewModel.nSelectedLocationIds$ | async as nSelected" class="checked-count">
            ({{ nSelected }})
          </span>
        </div>
        <div class="select-all-locations-text">Select All Locations</div>
      </div>
      <div *ngSwitchCase="false" class="pt-16px">No locations found.</div>
    </ng-container>
  </div>
  <div class="sticky-body-container bg-white" [style.overflow-y]="'unset'">
    <div class="modal-search-results-container">
      <app-template-location-selection-group
        *ngFor="
          let grouping of viewModel.searchedLocationsGroupedByProvince$ | async | keyvalue : incompleteLocationsLast;
          trackBy: viewModel.trackById
        "
        [province]="grouping?.key"
        [locations]="grouping?.value"
        [updatedRequiredLocationIds]="viewModel.selectedRequiredLocationIds$ | async"
        [selectedIds]="viewModel.selectedRequiredLocationIdsAsStringList$ | async"
        (addLocationIds)="viewModel.addLocationIds($event)"
        (removeLocationIds)="viewModel.removeLocationIds($event)">
      </app-template-location-selection-group>
    </div>
  </div>
</div>
<app-go-back-modal-footer
  [showPrimaryButton]="true"
  [primaryButtonText]="'Done'"
  [showBackButton]="true"
  [disablePrimaryButton]="viewModel.disableSubmit$ | async"
  (primaryButtonClicked)="viewModel.updatePendingDisplay()"
  (goBack)="goBack.emit()"
  (cancel)="cancel.emit()">
</app-go-back-modal-footer>
