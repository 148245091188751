import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditSmartFilterModalOpenedFrom } from '../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { AddEditSmartFilterModalComponent } from '../views/settings/components/settings-automation/smart-filters/manage-smart-filters/add-edit-smart-filter-modal/add-edit-smart-filter-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalCreateSmartFilter {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    openedFrom: AddEditSmartFilterModalOpenedFrom,
    onClose?: (req: HydratedSmartFilter) => void
  ) {
    const modalRef = ngbModal.open(
      AddEditSmartFilterModalComponent,
      ModalUtils.addEditSmartFiltersModalOptions(injector)
    );
    const compInstance = modalRef.componentInstance as AddEditSmartFilterModalComponent;
    compInstance.viewModel.connectToIsCreating(true);
    compInstance.viewModel.connectToOpenedFrom(openedFrom);
    modalRef.result
      .then((smartFilter) => {
        if (!!smartFilter && !!onClose) {
          onClose(smartFilter);
        }
      })
      .catch(() => {});
  }

}
