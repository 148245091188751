import { Injectable, Type } from '@angular/core';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { DisplayContentOption } from '../../../../../../models/enum/shared/display-content-option.enum';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';

@Injectable()
export class SelectMenuOrCollectionViewModel extends BaseViewModel {

  constructor() {
    super();
    combineLatest([
      this.displayContentOption$.pipe(distinctUntilChanged()),
      this.searchedItems$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([option, searchedItems]) => {
        const loadingMenus = 'Loading Menus';
        const loadingTemplateCollections = 'Loading Template Collections';
        switch (option) {
          case DisplayContentOption.Menu: {
            this.updateLoadingStates(searchedItems, loadingMenus, loadingTemplateCollections);
            break;
          }
          case DisplayContentOption.TemplateCollection: {
            this.updateLoadingStates(searchedItems, loadingTemplateCollections, loadingMenus);
            break;
          }
        }
      }
    });
  }

  public override _loadingOpts = new BehaviorSubject(LoadingOptions.defaultWhiteBackground());

  private readonly _displayContentOption = new BehaviorSubject<DisplayContentOption>(DisplayContentOption.Menu);
  public readonly displayContentOption$ = this._displayContentOption as Observable<DisplayContentOption>;
  connectToDisplayContentOptions = (opt: DisplayContentOption) => this._displayContentOption.next(opt);

  private _searchedItems = new BehaviorSubject<Menu[] | TemplateCollection[]>(null);
  public searchedItems$ = this._searchedItems as Observable<Menu[] | TemplateCollection[]>;
  connectToSearchedItems = (items: Menu[] | TemplateCollection[]) => this._searchedItems.next(items);

  public searchedTemplateCollections$ = this.searchedItems$.pipe(
    filter((items): items is TemplateCollection[] => this.isArrayOfType(items, TemplateCollection))
  );

  public searchedMenus$ = this.searchedItems$.pipe(
    filter((items): items is Menu[] => this.isArrayOfType(items, Menu))
  );

  private isArrayOfType(items: Menu[] | TemplateCollection[], type: Type<Menu | TemplateCollection>): boolean {
    if (!(Array.isArray(items))) return false;
    for (const item of items) {
      if (!(item instanceof type)) return false;
    }
    return true;
  }

  private updateLoadingStates(searchedItems: any[], currentLoadingMsg: string, oldLoadingMsg: string): void {
    this._loadingOpts.removeRequest(oldLoadingMsg);
    if (!searchedItems) {
      if (!this._loadingOpts.containsRequest(currentLoadingMsg)) {
        this._loadingOpts.addRequest(currentLoadingMsg);
      }
    } else {
      this._loadingOpts.removeRequest(currentLoadingMsg);
    }
  }

}
