import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../../models/base/base-view-model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PricePreviewWidgetViewModel extends BaseViewModel {

  private _priceFormatText = new BehaviorSubject<string>(null);
  public readonly priceFormatText$ = this._priceFormatText.pipe(
    map((s) => {
      switch (s) {
        case 'TaxesIn':
          return 'Taxes-In';
        case 'TaxesInRounded':
          return 'Taxes-In (Rounded)';
        default:
          return 'Pre-Tax';
      }
    })
  );
  connectToPriceFormatText = (s: string) => this._priceFormatText.next(s);

}
