import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { TemplateRoutingModule } from './template-routing.module';
import { CreateTemplateModalComponent } from './components/modals/create-template-modal/create-template-modal.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { TemplateCreationMethodComponent } from './components/modals/create-template-modal/components/template-creation-method/template-creation-method.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { MenusModule } from '../menu/menus.module';
import { ReactiveTableModule } from '@mobilefirstdev/reactive-table';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisplayModule } from '../display/display.module';
import { DigitalTemplatesComponent } from './components/templates/digital/digital-templates.component';
import { PrintTemplatesComponent } from './components/templates/print/print-templates.component';
import { WebTemplatesComponent } from './components/templates/web/web-templates.component';
import { DigitalTemplatesContainerComponent } from './components/templates/digital/digital-templates-container/digital-templates-container.component';
import { EditTemplateProductMenuComponent } from './components/edit-template-menu/edit-template-product-menu/edit-template-product-menu.component';
import { EditTemplateMenuHeaderComponent } from './components/edit-template-menu/edit-template-menu-header.component';
import { EditTemplateProductMenuHeaderComponent } from './components/edit-template-menu/edit-template-product-menu/edit-template-product-menu-header/edit-template-product-menu-header.component';
import { TemplateStatusComponent } from './components/shared/template-status/template-status.component';
import { StatusLightColorPipe } from './components/shared/template-status/status-light-color.pipe';
import { TemplateNameComponent } from './components/shared/template-name/template-name.component';
import { TemplateInfoBlurbComponent } from './components/shared/template-info-blurb/template-info-blurb.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { EditTemplateProductMenuFooterComponent } from './components/edit-template-menu/edit-template-product-menu/edit-template-product-menu-footer/edit-template-product-menu-footer.component';
import { EditTemplateLocationsSectionComponent } from './components/shared/edit-template-locations-section/edit-template-locations-section.component';
import { EditTemplateLocationsSectionContainerComponent } from './components/shared/edit-template-locations-section-container/edit-template-locations-section-container.component';
import { EditTemplateLocationListItemComponent } from './components/shared/edit-template-locations-section/edit-template-location-list-item/edit-template-location-list-item.component';
import { NumberOfLocationsPipe } from './components/shared/edit-template-locations-section/edit-template-location-list-item/number-of-locations.pipe';
import { TemplateViewLocationsComponent } from './components/shared/template-view-locations/template-view-locations.component';
import { LocationMarkedAsRequiredPipe } from './components/shared/template-view-locations/location-marked-as-required.pipe';
import { TemplateAddOrEditRequiredLocationsComponent } from './components/shared/template-add-or-edit-locations/template-add-or-edit-required-locations.component';
import { TemplateLocationInfoTextComponent } from './components/shared/template-location-info-text/template-location-info-text.component';
import { EditTemplateLocationsFormItemComponent } from './components/shared/edit-template-locations-section/edit-template-locations-form-item/edit-template-locations-form-item.component';
import { TemplateLocationSelectionGroupComponent } from './components/shared/template-location-selection-group/template-location-selection-group.component';
import { TemplateLocationSelectionComponent } from './components/shared/template-location-selection-group/template-location-selection/template-location-selection.component';
import { GetLocationsAsSelectionPipe } from './components/shared/template-location-selection-group/get-loctions-as-selection.pipe';
import { GetLocationGroupSelectedCountPipe } from './components/shared/template-location-selection-group/get-location-group-selected-count.pipe';
import { DigitalProductTemplatesComponent } from './components/templates/digital/digital-product-templates/digital-product-templates.component';
import { DigitalProductTemplateListComponent } from './components/templates/digital/digital-product-templates/digital-product-template-list/digital-product-template-list.component';
import { DigitalMarketingTemplatesComponent } from './components/templates/digital/digital-marketing-templates/digital-marketing-templates.component';
import { DigitalMarketingTemplateListComponent } from './components/templates/digital/digital-marketing-templates/digital-marketing-template-list/digital-marketing-template-list.component';
import { DigitalTemplateCollectionsComponent } from './components/templates/digital/digital-template-collections/digital-template-collections.component';
import { DigitalTemplateCollectionsListComponent } from './components/templates/digital/digital-template-collections/digital-template-collection-list/digital-template-collections-list.component';
import { PrintTemplatesContainerComponent } from './components/templates/print/print-templates-container/print-templates-container.component';
import { PrintProductTemplateListComponent } from './components/templates/print/print-product-templates/print-product-template-list/print-product-template-list.component';
import { PrintProductTemplatesComponent } from './components/templates/print/print-product-templates/print-product-templates.component';
import { WebTemplatesContainerComponent } from './components/templates/web/web-templates-container/web-templates-container.component';
import { WebProductTemplatesComponent } from './components/templates/web/web-product-templates/web-product-templates.component';
import { WebProductTemplateListComponent } from './components/templates/web/web-product-templates/web-product-template-list/web-product-template-list.component';
import { EditTemplateSpotlightMenuComponent } from './components/edit-template-menu/edit-template-spotlight-menu/edit-template-spotlight-menu.component';
import { CreateTemplateCollectionModalComponent } from './components/modals/create-template-collection-modal/create-template-collection-modal.component';
import { EditTemplateSpotlightMenuFooterComponent } from './components/edit-template-menu/edit-template-spotlight-menu/edit-template-spotlight-menu-footer/edit-template-spotlight-menu-footer.component';
import { EditTemplateSpotlightMenuHeaderComponent } from './components/edit-template-menu/edit-template-spotlight-menu/edit-template-spotlight-menu-header/edit-template-spotlight-menu-header.component';
import { EditTemplateMenuSectionComponent } from './components/edit-template-menu-section/edit-template-menu-section.component';
import { EditTemplateMenuSectionHeaderComponent } from './components/edit-template-menu-section/edit-template-menu-section-header/edit-template-menu-section-header.component';
import { EditTemplateMenuSectionFooterComponent } from './components/edit-template-menu-section/edit-template-menu-section-footer/edit-template-menu-section-footer.component';
import { TemplatePublishComponent } from './components/shared/template-publish/template-publish.component';
import { LocationsOnlyPipe } from './components/shared/template-publish/cast-to-locations.pipe';
import { EditTemplateMarketingPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-playlist-menu/edit-template-marketing-playlist-menu.component';
import { EditTemplateMarketingFeaturedProductMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-product-menu/edit-template-marketing-featured-product-menu.component';
import { EditTemplateMarketingFeaturedCategoryMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-category-menu/edit-template-marketing-featured-category-menu.component';
import { EditTemplateMarketingDriveThruMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-drive-thru-menu/edit-template-marketing-drive-thru-menu.component';
import { EditTemplateFeaturedCategorySectionComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-featured-category-menu/edit-template-featured-category-section/edit-template-featured-category-section.component';
import { EditTemplateMarketingMenuHeaderComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-menu-header/edit-template-marketing-menu-header.component';
import { EditTemplateMarketingMenuFooterComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-menu-footer/edit-template-marketing-menu-footer.component';
import { GetLocationsFromProvinceKeyPipe } from './components/shared/edit-template-locations-section/get-locations-from-province-key.pipe';
import { NumberOfNewRequiredLocationsPipe } from './components/shared/edit-template-locations-section/edit-template-location-list-item/number-of-new-required-locations.pipe';
import { NumberOfRemovedRequiredLocationsPipe } from './components/shared/edit-template-locations-section/edit-template-location-list-item/number-of-removed-required-locations.pipe';
import { MenuTemplateRequiredLocationsChangedSaveConfirmationComponent } from './components/modals/menu-template-required-locations-changed-save-confirmation/menu-template-required-locations-changed-save-confirmation.component';
import { GetAddedOrRemovedStatusPipe } from './components/shared/template-location-selection-group/template-location-selection/get-added-or-removed-status.pipe';
import { EditTemplateMarketingSmartPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-smart-playlist-menu/edit-template-marketing-smart-playlist-menu.component';
import { EditTemplateSmartPlaylistMenuSectionComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-smart-playlist-menu/edit-template-smart-playlist-menu-section/edit-template-smart-playlist-menu-section.component';
import { GetSaveTemplateBeforePublishingTooltipPipe } from './pipes/get-unsaved-template-changes-tooltip.pipe';
import { CollectionDetailsFormComponent } from './components/modals/create-template-collection-modal/collection-details/collection-details-form.component';
import { RxIf } from '@rx-angular/template/if';
import { PrintCardTemplatesComponent } from './components/templates/print/print-card-templates/print-card-templates.component';
import { PrintCardTemplateListComponent } from './components/templates/print/print-card-templates/print-card-template-list/print-card-template-list.component';
import { EditTemplateCardStackMenuComponent } from './components/edit-template-menu/edit-template-card-stack-menu/edit-template-card-stack-menu.component';
import { EditTemplateCardStackMenuHeaderComponent } from './components/edit-template-menu/edit-template-card-stack-menu/edit-template-card-stack-menu-header/edit-template-card-stack-menu-header.component';
import { EditTemplateCardStackMenuFooterComponent } from './components/edit-template-menu/edit-template-card-stack-menu/edit-template-card-stack-menu-footer/edit-template-card-stack-menu-footer.component';
import { PrintReportTemplatesComponent } from './components/templates/print/print-report-templates/print-report-templates.component';
import { PrintReportTemplateListComponent } from './components/templates/print/print-report-templates/print-report-template-list/print-report-template-list.component';
import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';
import { PrintLabelTemplatesComponent } from './components/templates/print/print-label-templates/print-label-templates.component';
import { PrintLabelTemplateListComponent } from './components/templates/print/print-label-templates/print-label-template-list/print-label-template-list.component';
import { EditTemplateLabelStackMenuComponent } from './components/edit-template-menu/edit-template-label-stack-menu/edit-template-label-stack-menu.component';
import { EditTemplateLabelStackMenuHeaderComponent } from './components/edit-template-menu/edit-template-label-stack-menu/edit-template-label-stack-menu-header/edit-template-label-stack-menu-header.component';
import { EditTemplateLabelStackMenuFooterComponent } from './components/edit-template-menu/edit-template-label-stack-menu/edit-template-label-stack-menu-footer/edit-template-label-stack-menu-footer.component';
import { EditTemplateMarketingUrlPlaylistMenuComponent } from './components/edit-template-menu/edit-template-marketing-menu/edit-template-marketing-url-playlist-menu/edit-template-marketing-url-playlist-menu.component';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';
import { StickyModalModule } from '@mobilefirstdev/sticky-modal';

@NgModule({
  declarations: [
    CreateTemplateModalComponent,
    CreateTemplateCollectionModalComponent,
    TemplateCreationMethodComponent,
    DigitalTemplatesComponent,
    PrintTemplatesComponent,
    WebTemplatesComponent,
    DigitalTemplatesContainerComponent,
    EditTemplateProductMenuComponent,
    EditTemplateMenuHeaderComponent,
    EditTemplateProductMenuHeaderComponent,
    TemplateStatusComponent,
    StatusLightColorPipe,
    TemplateNameComponent,
    TemplateInfoBlurbComponent,
    EditTemplateProductMenuFooterComponent,
    EditTemplateLocationsSectionComponent,
    EditTemplateLocationsSectionContainerComponent,
    EditTemplateLocationListItemComponent,
    NumberOfLocationsPipe,
    TemplateViewLocationsComponent,
    LocationMarkedAsRequiredPipe,
    TemplateAddOrEditRequiredLocationsComponent,
    TemplateLocationInfoTextComponent,
    EditTemplateLocationsFormItemComponent,
    TemplateLocationSelectionGroupComponent,
    TemplateLocationSelectionComponent,
    GetLocationsAsSelectionPipe,
    DigitalProductTemplatesComponent,
    DigitalProductTemplateListComponent,
    DigitalMarketingTemplatesComponent,
    DigitalMarketingTemplateListComponent,
    DigitalTemplateCollectionsComponent,
    DigitalTemplateCollectionsListComponent,
    PrintTemplatesContainerComponent,
    PrintProductTemplateListComponent,
    PrintProductTemplatesComponent,
    WebTemplatesContainerComponent,
    WebProductTemplatesComponent,
    WebProductTemplateListComponent,
    EditTemplateSpotlightMenuComponent,
    GetLocationGroupSelectedCountPipe,
    LocationMarkedAsRequiredPipe,
    EditTemplateSpotlightMenuFooterComponent,
    EditTemplateSpotlightMenuHeaderComponent,
    EditTemplateMenuSectionComponent,
    EditTemplateMenuSectionHeaderComponent,
    EditTemplateMenuSectionFooterComponent,
    TemplatePublishComponent,
    LocationsOnlyPipe,
    EditTemplateMarketingPlaylistMenuComponent,
    EditTemplateMarketingSmartPlaylistMenuComponent,
    EditTemplateMarketingFeaturedProductMenuComponent,
    EditTemplateMarketingFeaturedCategoryMenuComponent,
    EditTemplateMarketingDriveThruMenuComponent,
    EditTemplateFeaturedCategorySectionComponent,
    EditTemplateMarketingMenuHeaderComponent,
    EditTemplateMarketingMenuFooterComponent,
    GetLocationsFromProvinceKeyPipe,
    NumberOfNewRequiredLocationsPipe,
    NumberOfRemovedRequiredLocationsPipe,
    MenuTemplateRequiredLocationsChangedSaveConfirmationComponent,
    GetAddedOrRemovedStatusPipe,
    EditTemplateSmartPlaylistMenuSectionComponent,
    GetSaveTemplateBeforePublishingTooltipPipe,
    CollectionDetailsFormComponent,
    PrintCardTemplatesComponent,
    PrintCardTemplateListComponent,
    EditTemplateCardStackMenuComponent,
    EditTemplateCardStackMenuHeaderComponent,
    EditTemplateCardStackMenuFooterComponent,
    EditTemplateLabelStackMenuComponent,
    EditTemplateLabelStackMenuHeaderComponent,
    EditTemplateLabelStackMenuFooterComponent,
    PrintReportTemplatesComponent,
    PrintReportTemplateListComponent,
    PrintLabelTemplatesComponent,
    PrintLabelTemplateListComponent,
    EditTemplateMarketingUrlPlaylistMenuComponent,
  ],
  exports: [
    TemplateStatusComponent,
    NumberOfLocationsPipe,
    TemplateLocationSelectionGroupComponent,
    EditTemplateLocationsFormItemComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    TemplateRoutingModule,
    ReactiveButtonsModule,
    ReactiveFormModule,
    MenusModule,
    FlexLayoutModule,
    ReactiveTableModule,
    DisplayModule,
    NgbModule,
    RxPush,
    RxLet,
    RxIf,
    StickyAutoPositionDirective,
    StickyModalModule
  ]
})
export class TemplateModule {
}
