import { Pipe, PipeTransform } from '@angular/core';
import type { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'productMethod'
})
export class ProductMethodPipe implements PipeTransform {

  transform(product: Product, method: string, ...args: any[]): any {
    return product?.[method]?.(...args);
  }

}
