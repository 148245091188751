<div>
  <app-popper-selection
    [data]="ClientTypes.ProductTableColumnConfigIdValue.CompanyDefault"
    [disabled]="
      (viewModel.selectedProductTableColumnConfigsIdValue$ | push) ===
      ClientTypes.ProductTableColumnConfigIdValue.CompanyDefault
    "
    (selected)="handleProductTableColumnConfigSelection($event)">
    Company Default View
  </app-popper-selection>
  <ng-container *ngFor="let productTableColumnConfig of viewModel.productTableColumnConfigs$ | push">
    <app-popper-selection
      [data]="productTableColumnConfig | typeDefinitionBasicMethod : 'getSelectionValue'"
      [disabled]="
        productTableColumnConfig
          | typeDefinitionValueComparison : (viewModel.selectedProductTableColumnConfigsIdValue$ | push)
      "
      (selected)="handleProductTableColumnConfigSelection($event)">
      {{ productTableColumnConfig | typeDefinitionBasicMethod : 'getSelectionTitle' }}
    </app-popper-selection>
  </ng-container>
  <app-popper-selection
    [data]="ClientTypes.ProductTableColumnConfigIdValue.Custom"
    [selectedInUI]="
      (viewModel.selectedProductTableColumnConfigsIdValue$ | push) ===
      ClientTypes.ProductTableColumnConfigIdValue.Custom
    "
    (selected)="openCustomVisibleColumnsModal()">
    Custom
  </app-popper-selection>
</div>
