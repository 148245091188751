import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductMaxTotalTerpeneWithUnits'
})
export class GetProductMaxTotalTerpeneWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): string | null {
    return product?.getMaxTotalTerpeneWithUnits(true);
  }

}
