import { LoadingOptions } from '../../../models/shared/loading-options';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { ToastService } from '../../../services/toast-service';
import { FormGroupComponent } from '../../shared/components/form-group/form-group.component';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { BsError } from '../../../models/shared/bs-error';
import { BehaviorSubject, combineLatest, defer, Observable, of, Subject, throwError } from 'rxjs';
import { HydratedSection } from '../../../models/menu/dto/hydrated-section';
import { BudsenseFile } from '../../../models/shared/budsense-file';
import { ASSET_DELETE_DELAY, ASSET_RETRY_COUNT, ASSET_RETRY_DELAY, MediaUtils } from '../../../utils/media-utils';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Variant } from '../../../models/product/dto/variant';
import { Section } from '../../../models/menu/dto/section';
import { HydratedVariantBadge } from '../../../models/product/dto/hydrated-variant-badge';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '../../../models/shared/stylesheet/breadcrumb';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { Product } from '../../../models/product/dto/product';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { MarketingMenuType } from '../../../models/enum/dto/marketing-menu-type.enum';
import { Theme } from '../../../models/menu/dto/theme';
import { Asset } from '../../../models/image/dto/asset';
import { DropDownMenuItem, DropDownMenuSection } from '../../../models/shared/stylesheet/drop-down-menu-section';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { DisplayAttributesDomainModel } from '../../../domainModels/display-attributes-domain-model';
import { StringUtils } from '../../../utils/string-utils';
import { ModalSectionColumnOptions } from '../../../modals/modal-section-column-options';
import { SectionColumnConfig } from '../../../models/menu/dto/section-column-config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalChangeMedia } from '../../../modals/modal-change-media';
import { ChangeMediaOptions } from '../../../models/shared/stylesheet/change-media-options';
import { AssetPreviewOptions } from '../../../models/shared/stylesheet/asset-preview-options';
import { AutoSaveViewModel } from '../../shared/components/auto-save/auto-save-view-model';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { SectionTemplate } from '../../../models/template/dto/section-template';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { ModalPromptForSectionDelete } from '../../../modals/modal-prompt-for-section-delete';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { NewMenuSectionRequest } from '../../../models/menu/dto/new-menu-section-request';
import { DuplicateMenuSectionRequest } from '../../../models/menu/shared/duplicate-menu-section-request';
import { DuplicateTemplateSectionRequest } from '../../../models/menu/shared/duplicate-template-section-request';
import { ModalNewMenuSection } from '../../../modals/modal-new-menu-section';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { SmartFiltersDomainModel } from '../../../domainModels/smart-filters-domain-model';
import { Size } from '../../../models/shared/size';
import { LiveViewUtils } from '../../../utils/live-view-utils';
import { SectionColumnConfigKey } from '../../../models/enum/dto/section-column-config-key';
import { SectionType } from '../../../models/enum/dto/section-type';
import type { SectionSortOption } from '../../../models/enum/dto/section-sort-option';

@Injectable()
export class EditMenuSectionViewModel extends AutoSaveViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected productDomainModel: ProductDomainModel,
    protected companyDomainModel: CompanyDomainModel,
    protected labelDomainModel: LabelDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected displayAttributeDomainModel: DisplayAttributesDomainModel,
    protected userDomainModel: UserDomainModel,
    protected smartFiltersDomainModel: SmartFiltersDomainModel,
    protected toastService: ToastService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
    this.sectionMode$.pipe(debounceTime(1)).once(sectionMode => {
      if (sectionMode) this._loadingOpts.addRequest('Loading Section');
    });
    this.loadHydratedMenuTemplateUponInitialization();
    this.navigateAwayFromEditSectionIfSectionDoesntBelongToMenu();
  }

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  protected readonly activeHydratedSectionId$ = this.menuDomainModel.activeHydratedSectionId$;
  protected readonly activeHydratedMenuId$ = this.menuDomainModel.activeHydratedMenuId$;
  protected readonly activeHydratedMenu$ = this.menuDomainModel.activeHydratedMenu$;
  protected readonly activeSectionTemplateId$ = this.templateDomainModel.activeSectionTemplateId$;
  protected readonly activeMenuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  protected readonly activeSectionTemplate$ = this.templateDomainModel.activeSectionTemplate$;
  protected readonly activeHydratedSection$ = this.menuDomainModel.activeHydratedSection$;
  public readonly currentLocationMenus$ = this.menuDomainModel.currentLocationMenus$;
  public readonly currentLocationProducts$ = this.productDomainModel.currentLocationProducts$;
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly companyId$ = this.companyDomainModel.companyId$;
  public readonly priceStream$ = this.locationDomainModel.priceFormat$;
  public readonly allLabels$ = this.labelDomainModel.allLabels$;
  public readonly systemLabels$ = this.labelDomainModel.systemLabels$;
  public readonly sectionMode$ = this.templateMode$.pipe(map(templateMode => !templateMode));
  public readonly userIsTemplateAdmin$ = this.userDomainModel.isTemplateAdmin$;

  // Loading
  public hideLoading$ = this.loadingOpts$.pipe(map(it => !it.isLoading));
  public sectionAssetLoadingOpts$ = new BehaviorSubject<LoadingOptions>(this.getSectionAssetLoadingOpts());
  public hideSectionAssetLoading$ = this.sectionAssetLoadingOpts$.pipe(map(it => !it.isLoading));

  // Forms
  public deactivatableForms: FormGroupComponent[] = [];

  /* ************************ Templates ************************* */

  public templates$ = this.templateDomainModel.menuTemplates$;

  /* *************************** Menu *************************** */

  public menu$: Observable<Menu|MenuTemplate> = this.templateMode$.pipe(
    switchMap(templateMode => {
      return templateMode
        ? this.templateDomainModel.activeMenuTemplate$
        : this.menuDomainModel.activeHydratedMenu$;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected menuThemes$ = combineLatest([
    this.menu$,
    this.menuDomainModel.menuThemes$,
  ]).pipe(
    map(([menu, themes]) => {
      return !!menu ? (themes?.filter(t => t.supportedMenuTypes.includes(menu?.type)) ?? []) : [];
    })
  );

  public currentMenuTheme$ = combineLatest([
    this.menu$,
    this.menuThemes$,
  ]).pipe(
    map(([menu, themes]) => themes?.find(th => th.id === menu?.theme) as Theme | undefined)
  );

  public allowLiveView$ = combineLatest([
    this.autoSaveLoadingOpts$,
    this.menu$
  ]).pipe(
    map(([loadingOpts, menu]) => LiveViewUtils.allowLiveView(loadingOpts, menu)),
    distinctUntilChanged()
  );

  public menuType$ = this.menu$.pipe(map(menu => menu?.type));
  public menuSubType$ = this.menu$.pipe(map(menu => menu?.getSubType()));

  public isPrintableMenu$ = this.menu$.pipe(map(menu => menu?.isPrintableMenu()));

  /* *************************** Section *************************** */

  public readonly productsBelowLineWillNotAppearDesc$ = of('Products below line will not appear on menu');

  private sectionStream$ = this.templateMode$.pipe(
    switchMap(templateMode => (templateMode ? this.activeSectionTemplate$ : this.activeHydratedSection$)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public section$: Observable<SectionTemplate|HydratedSection> = combineLatest([
    combineLatest([this.menu$, this.sectionStream$, this.currentLocationProducts$]),
    combineLatest([this.locationId$, this.companyId$, this.priceStream$]),
    combineLatest([this.allLabels$, this.systemLabels$])
  ]).pipe(
    debounceTime(500),
    map(([
      [menu, section, locProducts],
      [locationId, companyId, priceStream],
      [labels, systemLabels]
    ]) => {
      const updatedSection = window.injector.Deserialize.shallowCopyOf(section);
      // Ensure any changes to display attributes are reflected on section products
      if (!!updatedSection && !!locProducts) {
        updatedSection.replaceProductsWithDeepCopyFromPoolButKeepLatestInventory(locProducts);
      }
      if (!!updatedSection && !!menu) {
        updatedSection?.products?.forEach((prod) => {
          prod?.computeLabelPropertyForMenuContext(
            [menu, updatedSection],
            [locationId, companyId, priceStream],
            [labels, systemLabels]
          );
        });
      }
      return updatedSection;
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    tap(section => this._showZeroStockItems.next(section?.showZeroStockItems)),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public isTemplatedSection$ = this.section$.pipe(
    map(section => section?.isTemplatedSection()),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public isPrintReportSection$ = this.section$.pipe(
    map(section => section?.isPrintReportMenuSection()),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public allowAddingProducts$ = this.sectionStream$.pipe(
    map(section => (section?.isTemplatedSection() ? section?.templateSection?.allowAddProducts : true))
  );
  public allowBadgeOverride$ = this.sectionStream$.pipe(
    map(section => (section?.isTemplatedSection() ? section?.templateSection?.allowBadgeOverride : true))
  );
  public allowLabelOverride$ = this.sectionStream$.pipe(
    map(section => (section?.isTemplatedSection() ? section?.templateSection?.allowLabelOverride : true))
  );
  public allowStyleOverride$ = this.sectionStream$.pipe(
    map(section => (section?.isTemplatedSection() ? section?.templateSection?.allowStyleOverride : true))
  );

  public isProductSection$ = this.section$?.pipe(
    map(s => {
      const isProductSection = s?.isProductSection();
      const isCategoryCardSection = s?.sectionType === SectionType.CategoryCard;
      return isProductSection || isCategoryCardSection;
    })
  );

  public defaultColumnConfigs$ = this.companyConfig$.pipe(map(config => config?.defaultColumnConfigs));

  public sectionHasSmartFilters$ = this.section$.pipe(map(section => section?.hasSmartFilters()));

  public sectionHasProductsThatCanBeRemoved$ = combineLatest([
    this.section$,
    this.allowAddingProducts$
  ]).pipe(
    map(([section, allowAddingProducts]) => {
      return allowAddingProducts && (section?.getNonTemplatedVariantIds()?.length > 0);
    })
  );

  public readonly removeAllProductButtonText$ = this.section$.pipe(
    map(section => {
      return section?.isTemplatedSection()
        ? 'Remove All Additional Products'
        : 'Remove All Products';
    })
  );

  public headerText$ = this.isPrintReportSection$.pipe(
    map(isPrintReportSection => isPrintReportSection ? 'Edit Report Section' : 'Edit Menu Section')
  );

  /* **************** Remove Products from Section ***************** */

  private removeVariantPool: Variant[] = [];
  private _removeVariantsPool = new BehaviorSubject<Variant[]>(this.removeVariantPool);
  public removingVariants$ = this._removeVariantsPool.pipe(map(variants => variants?.length > 0));
  private clearPoolData = () => {
    this.removeVariantPool = [];
    this._removeVariantsPool.next(this.removeVariantPool);
  };
  private _removeVariantFromSection = new Subject<Variant>();
  private listenToRemoveVariants = this._removeVariantFromSection.notNull().pipe(
    tap(variant => this.removeVariantPool = [...this.removeVariantPool, variant]?.uniqueByProperty('id')),
    map(variant => this.removeVariantPool),
    tap(variants => this._removeVariantsPool.next(variants)),
    debounceTime(3500),
    switchMap(removeVariantsPool => this.removeVariantsFromSection(removeVariantsPool)),
    takeUntil(this.onDestroy)
  ).subscribe({
    next: this.clearPoolData,
    error: this.clearPoolData
  });

  /* ****************** Supports Section Colors ******************** */

  public sectionSupportsTitleSectionBackgroundColor$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const supportsBgColor = !!theme?.themeFeatures?.sectionBodyBackgroundColor;
      const isTitleSection = section?.sectionType === SectionType.Title;
      return supportsBgColor && isTitleSection;
    })
  );

  public sectionSupportsHeaderBackgroundColor$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const menuSupportsHeaderBgColor = !!theme?.themeFeatures?.sectionHeaderBackgroundColor;
      const isProductSection = section?.isProductSection();
      const isFeaturedCategorySection = section?.sectionType === SectionType.CategoryCard;
      return menuSupportsHeaderBgColor && (isProductSection || isFeaturedCategorySection);
    })
  );

  public sectionSupportsHeaderTextColor$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const menuSupportsHeaderBgColor = !!theme?.themeFeatures?.sectionHeaderTextColor;
      const isProductSection = section?.isProductSection();
      return menuSupportsHeaderBgColor && isProductSection;
    })
  );

  public sectionSupportsProductContainerBackgroundColor$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const supportsBgColor = !!theme?.themeFeatures?.sectionBodyBackgroundColor;
      const isProductSection = section?.isProductSection();
      const isFeaturedCategorySection = section?.sectionType === SectionType.CategoryCard;
      return supportsBgColor && (isProductSection || isFeaturedCategorySection);
    })
  );

  public sectionSupportsBodyTextColor$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const supportsBgColor = !!theme?.themeFeatures?.sectionBodyTextColor;
      const isProductSection = section?.isProductSection();
      return supportsBgColor && isProductSection;
    })
  );

  private areAnyColorsActivated = (toggles: boolean[]) => toggles?.some(toggled => toggled);

  public supportsSectionColors$ = combineLatest([
    this.sectionSupportsTitleSectionBackgroundColor$,
    this.sectionSupportsHeaderBackgroundColor$,
    this.sectionSupportsHeaderTextColor$,
    this.sectionSupportsProductContainerBackgroundColor$,
    this.sectionSupportsBodyTextColor$
  ]).pipe(
    map(data => this.areAnyColorsActivated(data))
  );

  public colorPalette$ = this.menuDomainModel.colorPalette$;

  /* *********************************************************** */

  public breadcrumbs$ = combineLatest([
    this.menu$,
    this.section$,
  ]).pipe(
    map(([menu, section]) => this.getCrumbs(menu, section)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public showColumnOptionsButton$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const isProductSection = section?.isProductSection();
      const isSpotlightSection = section?.sectionType === SectionType.Spotlight;
      const themeSupportsColumnOptions = theme?.supportsDynamicColumns();
      return (isProductSection || isSpotlightSection) && themeSupportsColumnOptions;
    })
  );

  public hasSmartFilters$ = this.section$.pipe(map(section => section?.hasSmartFilters()));
  public productSectionDesc$ = combineLatest([this.hasSmartFilters$, this.menu$]).pipe(
    map(([hasSmartFilters, menu]) => {
      if (hasSmartFilters) {
        return 'You currently have smart filters enabled which manages products for you. '
          + 'All products added to this section will appear here. When smart filters are active '
          + 'on a section, products may not be manually added or removed.';
      }
      switch (true) {
        case menu?.isPrintCardMenu():
          return 'Add products to your card stack to showcase the products using the defined layout and styles.';
        case menu?.isPrintLabelMenu():
          return 'Add products to your label stack to define which products will be printed as labels.';
        default:
          return 'All products added to this section will appear here.';
      }
    })
  );

  protected _showZeroStockItems = new BehaviorSubject<boolean>(false);
  public showZeroStockProducts$ = this._showZeroStockItems.pipe(distinctUntilChanged());
  protected _primarySortOption = new BehaviorSubject<SectionSortOption>(null);
  public primarySortOption$ = this._primarySortOption.pipe(distinctUntilChanged());
  protected _secondarySortOption = new BehaviorSubject<SectionSortOption>(null);
  public secondarySortOption$ = this._secondarySortOption.pipe(distinctUntilChanged());
  private _sectionMedia = new BehaviorSubject<Asset>(null);
  public sectionMedia$ = this._sectionMedia as Observable<Asset>;
  private _sectionSecondaryMedia = new BehaviorSubject<Asset>(null);
  public sectionSecondaryMedia$ = this._sectionSecondaryMedia as Observable<Asset>;
  public hideProductSection$ = this.section$.pipe(
    map(section => {
      const isProductSection = section?.isProductSection();
      const isCategorySection = section?.sectionType === SectionType.CategoryCard;
      return !(isProductSection || isCategorySection);
    })
  );

  // section quick nav dropdown menu

  public menuSections$ = combineLatest([
    this.templateMode$,
    this.menu$
  ]).pipe(
    map(([templateMode, menu]) => {
      const sortByPriority = (a, b) => a.priority - b.priority;
      const sections = (templateMode && menu instanceof MenuTemplate) ? menu?.templateSections : menu?.sections;
      return sections?.sort(sortByPriority);
    })
  );
  public disabledSectionNavOptionIds$ = combineLatest([
    this.section$,
    this.menuSections$
  ]).pipe(
    map(([currSection, sections]) => {
      const pageBreakSecIds = sections?.filter(sec => sec?.sectionType === SectionType.PageBreak).map(sec => sec?.id);
      return pageBreakSecIds?.concat(currSection?.id);
    })
  );
  public sectionNavOptions$ = this.menuSections$.pipe(
    map(sections => {
      const secItems = sections?.map(s => {
        return new DropDownMenuItem(s?.getSectionTitle(), s?.id, null, s?.getIconSrc(), s?.id);
      });
      return [new DropDownMenuSection(null, secItems)];
    })
  );
  public hideSectionNavButton$ = this.menuSections$.pipe(map(sections => sections?.length === 1));

  public hideAddProductsButton$ = this.section$.pipe(
    map(section => !section?.productIds?.length || section?.isPrintReportMenuSection())
  );
  public disableAddProductsButton$ = combineLatest([
    this.section$,
    this.removingVariants$,
    this.allowAddingProducts$
  ]).pipe(
    map(([section, removingVariants, allowAddingProducts]) => {
      return section?.hasSmartFilters() || removingVariants || !allowAddingProducts;
    })
  );

  public showDisabledTooltip$ = this.allowAddingProducts$.pipe(map((allow) => !allow));

  public showPrimarySectionAssetSection$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      switch (section?.sectionType) {
        case SectionType.Media:
          return true;
        case SectionType.Product:
          return theme?.themeFeatures?.sectionImage;
        case SectionType.CategoryCard:
          return theme?.themeFeatures?.sectionImage ?? false;
        case SectionType.Title:
          return theme?.themeFeatures?.titleSectionBackgroundImage ?? false;
        default:
          return false;
      }
    }),
    map(it => !it)
  );

  public supportsSecondarySectionAsset$ = combineLatest([
    this.section$,
    this.currentMenuTheme$.notNull(),
    this.menu$.notNull(),
    window.types.featuredCategoryMenuCardTypes$
  ]).pipe(
    map(([section, theme, menu, cardTypes]) => {
      switch (section?.sectionType) {
        case SectionType.CategoryCard:
          const currentCardType = cardTypes?.find(ct => ct.value === menu?.metadata?.cardType);
          return (theme?.themeFeatures?.sectionSecondaryImage && currentCardType?.supportsSecondaryAsset()) ?? false;
        default:
          return false;
      }
    })
  );

  public allowPrimarySectionMedia$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      const isTitleSection = section?.sectionType === SectionType.Title;
      const productSectionSupportsMedia = !isTitleSection && theme?.themeFeatures.sectionImage;
      const titleSectionSupportsMedia = isTitleSection && theme?.themeFeatures?.titleSectionBackgroundImage;
      const isMediaSection = section?.sectionType === SectionType.Media;
      return productSectionSupportsMedia || titleSectionSupportsMedia || isMediaSection;
    }),
  );

  public sectionMediaPreview$ = this.allowPrimarySectionMedia$.pipe(
    map(allowMedia => {
      const sectionMediaPreviewOptions = new AssetPreviewOptions();
      sectionMediaPreviewOptions.primaryButtonText = 'Remove Media';
      sectionMediaPreviewOptions.primaryButtonDestructive = true;
      if (allowMedia) {
        sectionMediaPreviewOptions.secondaryButtonText = 'Change Media';
      }
      return sectionMediaPreviewOptions;
    })
  );

  protected allowSecondarySectionMedia: boolean = false;
  public allowSecondarySectionMedia$ = combineLatest([
    this.section$,
    this.currentMenuTheme$
  ]).pipe(
    map(([section, theme]) => {
      return section?.sectionType === SectionType.CategoryCard && theme?.themeFeatures?.sectionSecondaryImage;
    }),
    tap(it => this.allowSecondarySectionMedia = it)
  );

  public sectionPrimaryAssetTitle$ = combineLatest([
    this.menuSubType$,
    this.allowPrimarySectionMedia$,
  ]).pipe(
    map(([subType, allowMedia]) => {
      if (allowMedia) {
        if (subType === MarketingMenuType.Category) {
          return 'Header Asset';
        } else {
          return `Section Media`;
        }
      } else {
        return '';
      }
    })
  );

  public sectionSecondaryAssetTitle$ = combineLatest([
    this.menuSubType$,
    this.allowSecondarySectionMedia$,
  ]).pipe(
    map(([subType, allowMedia]) => {
      if (allowMedia) {
        if (subType === MarketingMenuType.Category) {
          return 'Secondary Asset';
        } else {
          return `Section Secondary Media`;
        }
      } else {
        return '';
      }
    })
  );

  public sectionPrimaryAssetDesc$ = combineLatest([
    this.menuSubType$,
    this.allowPrimarySectionMedia$
  ]).pipe(
    map(([subType, allowMedia]) => {
      if (allowMedia) {
        if (subType === MarketingMenuType.Category) {
          return 'Add media that will appear at the top of the category card.';
        } else {
          return `Upload media to make each section unique.`;
        }
      } else {
        return '';
      }
    })
  );

  public sectionSecondaryAssetDesc$ = combineLatest([
    this.menuSubType$,
    this.allowSecondarySectionMedia$
  ]).pipe(
    map(([subType, allowMedia]) => {
      if (allowMedia) {
        if (subType === MarketingMenuType.Category) {
          return 'Add media that will appear at the bottom of the category card.';
        } else {
          return `Upload secondary media to make each section unique.`;
        }
      } else {
        return '';
      }
    })
  );

  // Upload Assets
  public hidePrimaryUploadAsset$ = combineLatest([
    this._sectionMedia,
    this.allowPrimarySectionMedia$
  ]).pipe(map(([sectionImg, allowSectionMedia]) => !!sectionImg || !allowSectionMedia));

  public hideSecondaryUploadAsset$ = combineLatest([
    this._sectionSecondaryMedia,
    this.allowSecondarySectionMedia$
  ]).pipe(map(([sectionSecImg, allowSectionMedia]) => !!sectionSecImg || !allowSectionMedia));

  // Subjects
  public dismissModalSubject: Subject<Section> = new Subject<Section>();

  /* ***************** Local Threads of Execution ****************** */

  protected fireIfTemplateMode$ = this.templateMode$.pipe(
    debounceTime(100),
    filter(templateMode => templateMode),
    distinctUntilChanged()
  );
  protected fireIfRegularSectionMode$ = this.templateMode$.pipe(debounceTime(100), filter(tMode => !tMode));
  protected fireIfNotSpotlight$ = this.menu$.pipe(debounceTime(100), filter(menu => !menu?.isSpotlightMenu()));

  private listenToLoadingProducts = this.productDomainModel.loadingCurrentLocationProducts$
    .pipe(distinctUntilChanged())
    .notNull()
    .subscribe(loading => {
      const lm = 'Loading Products';
      loading ? this._loadingOpts.addRequest(lm) : this._loadingOpts.removeRequest(lm);
    });

  private listenToSectionMedia = this.section$
    .distinctUniquelyIdentifiable()
    .subscribeWhileAlive({
      owner: this,
      next: sec => {
        this._sectionMedia.next(sec?.image);
        this._sectionSecondaryMedia.next(sec?.secondaryImage);
      }
    });

  private menuBelongsToLocationId = this.fireIfRegularSectionMode$
    .pipe(switchMap(_ => combineLatest([this.menu$.notNull(), this.locationId$.notNull()])))
    .subscribeWhileAlive({
      owner: this,
      next: ([menu, locationId]) => {
        if (menu?.locationId !== locationId) {
          this.router.navigate([menu?.getViewAllNavigationUrl()], { replaceUrl: true }).then(() => {
            this.menuDomainModel.deselectActiveHydratedMenu();
          });
        }
      }
    });

  protected navigateAwayFromEditSectionIfSectionDoesntBelongToMenu() {
    this.fireIfRegularSectionMode$
      .pipe(switchMap(_ => combineLatest([this.menu$.notNull(), this.section$.notNull()])))
      .subscribeWhileAlive({
        owner: this,
        next: ([menu, section]) => {
          if (menu?.id !== section?.configurationId && menu?.templateId !== section?.configurationId) {
            this.router.navigate([menu?.getViewAllNavigationUrl()], { replaceUrl: true }).then(() => {
              this.menuDomainModel.deselectActiveHydratedMenu();
            });
          }
        }
      });
  }

  private selectActiveMenuTemplateFromRouteParam = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.templates$))
    .notNull() // wait for templates to load in before trying to select one
    .pipe(switchMap(_ => this.route.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const templateId = params?.menuTemplateId;
        if (!!templateId) this.templateDomainModel.selectActiveMenuTemplate(templateId);
      }
    });

  protected loadHydratedMenuTemplateUponInitialization(): void {
    this.fireIfTemplateMode$
      .pipe(switchMap(_ => this.activeMenuTemplateId$))
      .subscribeWhileAlive({
        owner: this,
        next: templateId => {
          if (!!templateId) this.loadHydratedMenuTemplate();
        }
      });
  }

  private selectActiveMenuFromRouteParam = this.fireIfNotSpotlight$
    .pipe(switchMap(_ => this.currentLocationMenus$))
    .notNull()  // wait for menus to load in before trying to select one
    .pipe(switchMap(_ => this.route.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const menuId = params?.menuId;
        if (!!menuId) this.menuDomainModel.selectActiveHydratedMenu(menuId);
      }
    });

  private selectActiveSectionTemplateFromRouteParam = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.route.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const sectionTemplateId = params?.sectionTemplateId;
        if (!!sectionTemplateId) this.templateDomainModel.selectActiveSectionTemplate(sectionTemplateId);
      }
    });

  private dataUsedToFetchSectionTemplate$ = combineLatest([
    this.locationId$.notNull(),
    this.activeSectionTemplateId$.notNull().pipe(
      switchMap(activeSectionId => {
        return this.activeMenuTemplateId$.notNull().pipe(
          take(1),
          map(menuTemplateId => [menuTemplateId, activeSectionId] as [string, string])
        );
      })
    )
  ]);

  private loadSectionTemplateUponInitialization = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.dataUsedToFetchSectionTemplate$))
    .subscribeWhileAlive({
      owner: this,
      next: ([locationId, [menuTemplateId, sectionTemplateId]]) => {
        if (!!menuTemplateId && !!sectionTemplateId) {
          this.loadSectionTemplate(locationId, menuTemplateId, sectionTemplateId, false);
        }
      }
    });

  private loadHydratedMenuUponInitialization = this.fireIfRegularSectionMode$
    .pipe(switchMap(_ => this.activeHydratedMenu$))
    .pipe(filter(menu => !menu))
    .pipe(switchMap(_ => this.activeHydratedMenuId$))
    .subscribeWhileAlive({
      owner: this,
      next: menuId => {
        if (!!menuId) this.loadHydratedMenu();
      }
    });

  private selectActiveSectionFromRouteParam = this.fireIfRegularSectionMode$
    .pipe(switchMap(_ => this.route.params))
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: params => {
        const sectionId = params?.sectionId;
        if (!!sectionId) this.menuDomainModel.selectActiveHydratedSection(sectionId);
      }
    });

  private menuAndSectionId$ = combineLatest([
    this.activeHydratedMenu$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctByMenuId)),
    this.activeHydratedSectionId$.notNull().pipe(distinctUntilChanged())
  ]);

  private loadHydratedSectionUponInitialization = this.fireIfRegularSectionMode$
    .pipe(switchMap(_ => this.menuAndSectionId$))
    .subscribeWhileAlive({
      owner: this,
      next: ([menu, sectionId]) => {
        if (menu?.sections?.some(s => s?.id === sectionId)) {
          this.loadHydratedSection(menu?.id, sectionId, false);
        }
      }
    });

  private listenForUnfetchedHiddenSmartFilters = combineLatest([
    this.section$,
    this.smartFiltersDomainModel.curatedSmartFilters$,
    this.smartFiltersDomainModel.fetchingSmartFilters$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([section, allCuratedFilters, smartFiltersBeingFetched]) => {
      const menuIsNotPrintReport = !section?.isPrintReportMenuSection();
      if (menuIsNotPrintReport || smartFiltersBeingFetched) return;
      const hiddenSmartFilters = section?.smartFilters?.filter(sf => sf?.hidden);
      const hiddenSmartFilterIds = hiddenSmartFilters?.map(sf => sf?.id);
      const hasNotBeenFetched = (id: string): boolean => !allCuratedFilters?.some((sf) => sf?.id === id);
      const unfetchedHiddenSmartFilterIds = hiddenSmartFilterIds?.filter(hasNotBeenFetched);
      if (unfetchedHiddenSmartFilterIds?.length > 0) {
        this.fetchHiddenSmartFilters(unfetchedHiddenSmartFilterIds);
      }
    }
  });

  /* *************************************************************** */

  private fetchHiddenSmartFilters(filterIds: string[]) {
    this.locationId$.once(locationId => {
      if (!locationId) return;
      const lm = 'Loading Smart Filters';
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        this.smartFiltersDomainModel.getSpecificCuratedSmartFilters(locationId.toString(), filterIds).subscribe({
          complete: () => this._loadingOpts.removeRequest(lm),
          error: (err: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  clearActiveSection() {
    this.menuDomainModel.deselectActiveHydratedSection();
  }

  public loadHydratedMenuTemplate(background: boolean = false) {
    combineLatest([this.locationId$.notNull(), this.activeMenuTemplateId$,]).once(([locationId, menuTemplateId]) => {
      const lm = 'Loading Menu Template';
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      if (!loadingOpts.containsRequest(lm)) loadingOpts.addRequest(lm);
      this.templateDomainModel.getHydratedMenuTemplate(locationId, menuTemplateId).subscribe({
        complete: () => loadingOpts.removeRequest(lm),
        error: (err: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(err);
        }
      });
    });
  }

  protected loadHydratedMenu(background: boolean = false) {
    this.activeHydratedMenuId$.once(menuId => {
      const lm = 'Loading Menu';
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      if (!loadingOpts.containsRequest(lm)) loadingOpts.addRequest(lm);
      this.menuDomainModel.getHydratedMenu(menuId).subscribe({
        complete: () => {
          loadingOpts.removeRequest(lm);
        },
        error: (err: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(err);
        }
      });
    });
  }

  getSectionAssetLoadingOpts(): LoadingOptions {
    // Setup Background Asset loading opts
    const loadingOpts = LoadingOptions.getAssetLoadingOpts();
    loadingOpts.backgroundColor = '#FFF';
    return loadingOpts;
  }

  getCrumbs(menu: Menu, section: Section): Breadcrumb[] {
    const breadCrumbs = [];
    if (!!menu && !!section) {
      breadCrumbs.push(menu?.getViewAllBreadcrumb(false));
      breadCrumbs.push(menu?.getEditBreadcrumb(false));
      breadCrumbs.push(section?.getEditBreadcrumb(menu, true));
      return breadCrumbs;
    }
  }

  public override canDeactivate(): boolean | Promise<any> {
    return !this.unsavedChanges;
  }

  public removeVariantFromSection = (variant: Variant): Observable<string[]> => {
    this._removeVariantFromSection.next(variant);
    return this._removeVariantsPool.pipe(map(variants => variants?.map(v => v?.id)));
  };

  public removeVariantsFromSection(variants: Variant[]): Observable<HydratedSection> {
    const lm = `Removing Variants`;
    const loadingOpts = this._autoSaveLoadingOpts;
    loadingOpts.addRequest(lm);
    const updateMenusVariantFeature = ([menu, s, products]: [Menu, Section, Product[]]): Observable<Section> => {
      const section = window?.injector?.Deserialize?.instanceOf(Section, s);
      variants?.forEach(variant => {
        if (this.shouldRemoveProductId(products, variant, section)) {
          const removeProductId = section.productIds.indexOf(variant.productId);
          if (removeProductId > -1) section.productIds.splice(removeProductId, 1);
        }
        const removeVariantId = section.enabledVariantIds.indexOf(variant.id);
        if (removeVariantId > -1) section.enabledVariantIds.splice(removeVariantId, 1);
        // Remove the variant's badges if applicable
        section.variantBadgeIdsMap?.delete(variant.id);
      });
      // Perform update
      const updateMenuVariantFeature$ = (menu instanceof MenuTemplate)
        ? this.templateDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu))
        : this.menuDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu));
      return updateMenuVariantFeature$.pipe(switchMap(_ => of(section)));
    };
    const updateSection = (sectionCopy: Section) => {
      const updateSection$ = (sectionCopy instanceof SectionTemplate)
        ? this.templateDomainModel.updateMenuSectionTemplate(sectionCopy)
        : this.menuDomainModel.updateMenuSection(sectionCopy);
      return updateSection$.pipe(
        tap((updatedSection) => {
          loadingOpts.removeRequest(lm);
          this.setLastAutoSavedTimestampToNow();
          const variantOrVariants = variants.length > 1 ? 'variants' : 'variant';
          const nVariants = `${variants.length} ${variantOrVariants}`;
          this.toastService.publishSuccessMessage(
            `${nVariants} removed from ${sectionCopy?.getSectionTitle()}`,
            `${StringUtils.capitalize(variantOrVariants)} Removed`
          );
        })
      );
    };
    return combineLatest([this.menu$, this.section$, this.currentLocationProducts$]).pipe(
      take(1),
      switchMap(updateMenusVariantFeature),
      switchMap(updateSection),
      catchError((e) => {
        loadingOpts.removeRequest(lm);
        return throwError(e);
      }),
      take(1)
    );
  }

  public addVariantsToSection(variantIds: string[]): Observable<boolean> {
    return combineLatest([this.menu$, this.section$]).pipe(
      take(1),
      switchMap(([menu, s]) => {
        const section = window?.injector?.Deserialize?.instanceOf(Section, s);
        return this.getProductIdsForVariantIds(variantIds).pipe(
          switchMap(productIds => {
            section.productIds = productIds;
            section.enabledVariantIds = section.enabledVariantIds.concat(variantIds).unique();
            return menu instanceof MenuTemplate
              ? this.templateDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu))
              : this.menuDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu));
          }),
          switchMap(_ => {
            return section instanceof SectionTemplate
              ? this.templateDomainModel.updateMenuSectionTemplate(section)
              : this.menuDomainModel.updateMenuSection(section);
          }),
          tap(_ => this.setLastAutoSavedTimestampToNow()),
          switchMap(() => of(true))
        );
      }),
      take(1)
    );
  }

  showLiveView = (
    allPristine: boolean,
    formsAutoSubmitted$: Observable<any[]>,
    submitForms: EventEmitter<boolean>
  ): void => {
    LiveViewUtils.openLiveView(allPristine, formsAutoSubmitted$, submitForms, this.openLiveViewModal.bind(this));
  };

  public saveSection = (
    background: boolean = true,
    updatedBadgeMap?: Map<string, string[]>,
    updatedColumnConfig?: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): void => {
    combineLatest([this.menu$, this.section$]).once(([menu, s]) => {
      const loadingOpts = background ? this._autoSaveLoadingOpts : this._loadingOpts;
      const lm = (s instanceof SectionTemplate) ? 'Saving Section Template' : 'Saving Section';
      const section = window?.injector?.Deserialize?.instanceOf(Section, s);
      if (!!updatedBadgeMap) section.variantBadgeIdsMap = updatedBadgeMap;
      if (!!updatedColumnConfig) section.columnConfig = updatedColumnConfig;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const updateMenuVariantFeature$ = menu instanceof MenuTemplate
          ? this.templateDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu))
          : this.menuDomainModel.updateMenuVariantFeature(menu, section.parseFeaturedVariants(menu));
        // Grid column names are automatically validated on the API side based on enabled variants
        updateMenuVariantFeature$.pipe(
          switchMap(_ => {
            return (section instanceof SectionTemplate)
              ? this.templateDomainModel.updateMenuSectionTemplate(section)
              : this.menuDomainModel.updateMenuSection(section);
          }),
          take(1)
        ).subscribe({
          complete: () => {
            if (!background) {
              const noun = (section instanceof SectionTemplate) ? 'Section Template' : 'Section';
              this.toastService.publishSuccessMessage(`${noun} successfully updated.`, `Save ${noun}`);
            }
            loadingOpts.removeRequest(lm);
            this.destroyAllTimerSubs();
            this.setLastAutoSavedTimestampToNow();
          },
          error: (error: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  };

  public openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locationId$, this.menu$]).once(([locationId, menu]) => {
      LiveViewUtils.openLiveViewModal(this.ngbModal, this.injector, locationId, menu, sizeOverride);
    });
  }

  promptForSectionDelete(): void {
    this.section$.once(section => {
      const confirmation = (cont: boolean) => { if (cont) { this.deleteSection(); } };
      ModalPromptForSectionDelete.open(this.ngbModal, this.injector, section, confirmation);
    });
  }

  public deleteSection() {
    combineLatest([this.menu$, this.section$]).once(([menu, section]) => {
      const loadingOpts = this._loadingOpts;
      const noun = (section instanceof SectionTemplate) ? 'Section Template' : 'Section';
      const lm = `Deleting ${noun}`;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const deleteSection$: Observable<Menu|string> = (section instanceof SectionTemplate)
          ? this.templateDomainModel.deleteMenuSectionTemplate(section, menu as MenuTemplate)
          : this.menuDomainModel.deleteMenuSection(section, menu as Menu);
        deleteSection$.subscribe({
          complete: () => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage('Section deleted successfully', 'Section Deleted');
            this.unsavedChanges = false;
            this.navigateToEditMenu(menu);
          },
          error: (err: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(err);
          }
        });
      }
    });
  }

  public openDuplicateSectionModal() {
    combineLatest([
      this.menu$,
      this.section$,
      this.allowAutoSaving$
    ]).once(([menu, section, allowAutoSaving]) => {
      if (allowAutoSaving) this.saveSection(true);
      const onClose = (sec) => {
        const url = sec?.getEditSectionUrl(menu);
        if (!!url) this.router.navigate([url]).then();
      };
      ModalNewMenuSection.open(this.ngbModal, this.injector, menu, section, onClose);
    });
  }

  public createNewSection(
    createReq?: NewMenuSectionRequest,
    duplicateReq?: DuplicateMenuSectionRequest | DuplicateTemplateSectionRequest
  ) {
    this.menu$.once(menu => {
      const loadingOpts = this._loadingOpts;
      const lm = 'Duplicating Section';
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const dup$ = defer(() => {
          return duplicateReq instanceof DuplicateTemplateSectionRequest
            ? this.templateDomainModel.duplicateSectionTemplate(duplicateReq)
            : this.menuDomainModel.duplicateMenuSection(duplicateReq);
        });
        dup$.subscribe({
          next: (newSection) => {
            if (newSection instanceof Section) {
              this.dismissModalSubject.next(newSection);
              menu instanceof MenuTemplate ? this.loadHydratedMenuTemplate() : this.loadHydratedMenu();
              loadingOpts.removeRequest(lm);
            }
          },
          error: (error: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(() => error);
          }
        });
      }
    });
  }

  public duplicateSection(section: Section) {
    const req = section instanceof SectionTemplate
      ? new DuplicateTemplateSectionRequest(section)
      : new DuplicateMenuSectionRequest(section);
    this.createNewSection(undefined, req);
  }

  public removeAllSectionProducts() {
    this.section$.once(section => {
      section.products = [];
      section.productIds = [];
      section.enabledVariantIds = [];
      section.variantBadgeMap = new Map<string, HydratedVariantBadge[]>();
      section.variantBadgeIdsMap = new Map<string, string[]>();
      this.saveSection(false);
    });
  }

  public deleteSectionAsset(isSecondaryMedia: boolean = false) {
    combineLatest([this.menu$, this.section$, this.locationId$]).once(([menu, section, locationId]) => {
      const lm = 'Deleting Section Asset';
      const loadingOpts = this.sectionAssetLoadingOpts$;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const mediaToDelete = isSecondaryMedia ? section?.secondaryImage : section?.image;
        this.menuDomainModel
          .deleteAsset(mediaToDelete, menu)
          .pipe(delay(ASSET_DELETE_DELAY * 1000))
          .subscribe({
            complete: () => {
              loadingOpts.removeRequest(lm);
              this.toastService.publishSuccessMessage('Section asset deleted.', 'Asset Deleted');
              !isSecondaryMedia ? this._sectionMedia.next(null) : this._sectionSecondaryMedia.next(null);
              (menu instanceof MenuTemplate)
                ? this.loadSectionTemplate(locationId, section.configurationId, section.id)
                : this.loadHydratedSection(menu.id, section.id, true);
            },
            error: (err: BsError) => {
              loadingOpts.removeRequest(lm);
              this.toastService.publishError(err);
              throwError(err);
            }
          });
      }
    });
  }

  public uploadSectionAsset(f: BudsenseFile, isSecondaryMedia: boolean = false): Observable<string[]> {
    return combineLatest([
      this.menu$,
      this.section$,
      this.supportsSecondarySectionAsset$
    ]).pipe(
      take(1),
      switchMap(([menu, section, supportsSecondary]) => {
        // If secondary asset is not supported, allow upload without check
        const checkSecondaryAssetSupport$ = supportsSecondary
          ? this.menuDomainModel.newAssetIsUnique(section, f, isSecondaryMedia)
          : of(true);
        return checkSecondaryAssetSupport$.pipe(
          switchMap((isUnique) => {
            if (isUnique) {
              // Allow upload of unique new section Media
              const loadingOpts = this.sectionAssetLoadingOpts$;
              const lm = 'Uploading Section Asset';
              if (!loadingOpts.containsRequest(lm)) {
                loadingOpts.addRequest(lm);
                return this.menuDomainModel.uploadSectionAsset(f, menu, section, isSecondaryMedia).pipe(
                  map((_) => {
                    loadingOpts.removeRequest(lm);
                    if (f.isVideo()) {
                      f.replaceWithWebm();
                    }
                    return [f.name];
                  }),
                  catchError((err: BsError) => {
                    loadingOpts.removeRequest(lm);
                    return throwError(err);
                  })
                );
              }
            } else {
              const err = BsError.NewLocalBsError(
                'Asset Upload Error',
                'Section assets must be unique. This media is already used in this section.'
              );
              this.toastService.publishError(err);
              return throwError(err);
            }
          })
        );
      })
    );
  }

  public getRefreshedSectionAsset(newFileName: string, remainingRetries: number, isSecondaryMedia: boolean = false) {
    combineLatest([this.section$, this.templateMode$, this.locationId$]).once(([section, templateMode, locationId]) => {
      const lm = MediaUtils.getRefreshAssetLoadingMessage(remainingRetries);
      if (!this.sectionAssetLoadingOpts$.containsRequest(lm)) {
        this.sectionAssetLoadingOpts$.addRequest(lm);
        const getSection$ = templateMode
            ? this.templateDomainModel.getHydratedSectionTemplate(locationId, section.configurationId, section.id)
            : this.menuDomainModel.getHydratedSection(section.configurationId, section.id);
        getSection$
          .pipe(delay(ASSET_RETRY_DELAY * 1000))
          .subscribe(updatedSection => {
            this.sectionAssetLoadingOpts$.removeRequest(lm);
            const media = isSecondaryMedia ? updatedSection.secondaryImage : updatedSection.image;
            const noMediaOrUpdatedFileName = !media || (newFileName !== media?.fileName);
            const authorizedToFetch = remainingRetries > 0;
            if (noMediaOrUpdatedFileName && authorizedToFetch) {
              this.getRefreshedSectionAsset(newFileName, remainingRetries - 1, isSecondaryMedia);
            }
          });
      }
    });
  }

  protected shouldRemoveProductId(products: Product[], variant: Variant, section: Section): boolean {
    const productMatch = products?.find(p => p.id === variant.productId);
    if (productMatch) {
      const productVariantIds = productMatch.variants.map(v => v.id);
      // If variant is the only one in this section, remove productId
      return section.enabledVariantIds.intersection(productVariantIds).length === 1;
    } else {
      return false;
    }
  }

  protected getProductIdsForVariantIds(variantIds: string[]): Observable<string[]> {
    return this.productDomainModel.currentLocationProducts$.pipe(
      map(products => {
        const productIds: string[] = [];
        products.forEach((prod) => {
          variantIds.forEach((addedVariantId) => {
            if (prod.variants.map(v => v.id).contains(addedVariantId)) {
              productIds.push(prod.id);
            }
          });
        });
        return productIds;
      })
    );
  }

  protected loadHydratedSection(menuId: string, sectionId: string, background: boolean = true) {
    const loadingOptions = background ? this._autoSaveLoadingOpts : this._loadingOpts;
    const lm = 'Loading Section';
    if (!loadingOptions.containsRequest(lm)) loadingOptions.addRequest(lm);
    this.menuDomainModel.getHydratedSection(menuId, sectionId).pipe(delay(500)).subscribe({
      complete: () => loadingOptions.removeRequest(lm),
      error: (err: BsError) => {
        loadingOptions.removeRequest(lm);
        this.toastService.publishError(err);
        throwError(err);
      }
    });
  }

  public loadSectionTemplate(
    locationId: number,
    menuTemplateId: string,
    sectionTemplateId: string,
    background: boolean = true
  ) {
    const loadingOptions = background ? this._autoSaveLoadingOpts : this._loadingOpts;
    const lm = 'Loading Section Template';
    if (!loadingOptions.containsRequest(lm)) loadingOptions.addRequest(lm);
    this.templateDomainModel
      .getHydratedSectionTemplate(locationId, menuTemplateId, sectionTemplateId)
      .pipe(delay(500))
      .subscribe({
        complete: () => loadingOptions.removeRequest(lm),
        error: (err: BsError) => {
          loadingOptions.removeRequest(lm);
          this.toastService.publishError(err);
          throwError(err);
        }
      });
  }

  /* arrow function to keep lexical scope */
  handleDropdownSelection = (sectionId: string): void => {
    const section$ = this.menuSections$.pipe(
      map(sections => sections?.find(section => section?.id === sectionId))
    );
    combineLatest([this.menu$, section$]).once(([menu, section]) => {
      this.router.navigate([section?.getEditSectionUrl(menu)]).then();
    });
  };

  navigateToEditMenu(menu: Menu) {
    this.router.navigate([menu?.getEditNavigationUrl()], {replaceUrl: true}).then();
  }

  navigateToEditTemplateSection(s: SectionTemplate) {
    this.menu$.once((menu) => {
      this.router.navigate([s?.getEditSectionUrl(menu?.template)], {replaceUrl: true}).then();
    });
  }

  getAllowSectionMedia(): Observable<boolean> {
    return this.allowPrimarySectionMedia$;
  }

  showZeroStockChanged(showZeroStock: boolean) {
    this._showZeroStockItems.next(showZeroStock);
  }

  openEditColumnOptionsModal() {
    combineLatest([this.menu$, this.section$]).once(([menu, section]) => {
      ModalSectionColumnOptions.open(
        this.ngbModal,
        this.injector,
        section,
        menu?.hydratedTheme,
        (columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>): void => {
          this.saveSection(false, null, columnConfig);
        }
      );
    });
  }

  public handleDeleteSectionAsset(isSecondaryMedia: boolean = false) {
    this.deleteSectionAsset(isSecondaryMedia);
  }

  public handleChangeSectionAsset(isSecondaryMedia: boolean = false) {
    const opts = this.getChangeSectionAssetOptions();
    const changeMediaOperation = (files: BudsenseFile[]): Observable<string[]> => {
      if (files.length === 1) {
        return this.uploadSectionAsset(files[0], isSecondaryMedia);
      } else {
        return of(null);
      }
    };
    const onClose = (uploadedFileNames) => {
      if (uploadedFileNames?.length > 0) {
        this.getRefreshedSectionAsset(uploadedFileNames[0], ASSET_RETRY_COUNT, isSecondaryMedia);
      }
    };
    ModalChangeMedia.open(this.ngbModal, this.injector, true, opts, changeMediaOperation, onClose);
  }

  protected getChangeSectionAssetOptions(): ChangeMediaOptions {
    const opts = new ChangeMediaOptions();
    opts.allowVideo$ = this.getAllowSectionMedia();
    opts.allowImage$ = this.getAllowSectionMedia();
    opts.modalTitle = 'Change Section Asset';
    opts.loadingMess = 'Uploading Section Asset';
    opts.successTitle = 'Upload Successful';
    opts.successMess = 'Uploaded new section asset.';
    opts.failureTitle = 'Upload Failed';
    opts.failureMess = 'Upload new section asset failed.';
    return opts;
  }

  public handleMenuSectionAssetRefresh(fileName: string, isSecondaryMedia: boolean = false) {
    this.getRefreshedSectionAsset(fileName, 0, isSecondaryMedia);
  }

  primarySortChanged(sort: SectionSortOption) {
    this._primarySortOption.next(sort);
  }

  secondarySortChanged(sort: SectionSortOption) {
    this._secondarySortOption.next(sort);
  }

  override destroy() {
    super.destroy();
    this.sectionMode$.once(sectionMode => this.clearActiveSection());
  }

}
