import { Component, Input } from '@angular/core';
import { MenuSectionProductInfoPreviewViewModel } from './menu-section-product-info-preview-view-model';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import type { Variant } from '../../../../../../../models/product/dto/variant';
import { PopperUtils } from '../../../../../../../utils/popper-utils';

@Component({ template: '' })
export abstract class MenuSectionProductInfoPreviewComponent extends BaseComponent {

  constructor(
    public viewModel: MenuSectionProductInfoPreviewViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToVariant') variant: Variant;

  public popperModifier = [PopperUtils.flipModifier(['bottom-end', 'top-end', 'left'])];
  public popperStyles = { 'background-color': '#FFFFFF' };

}
