import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { CannabinoidDisplayType } from '../../utils/dto/cannabinoid-display-type-definition';
import { TerpeneDisplayType } from '../../utils/dto/terpene-display-type-definition';

export class CompoundDisplayType implements Deserializable, UniquelyIdentifiable {

  cannabinoidDisplayType: CannabinoidDisplayType;
  terpeneDisplayType: TerpeneDisplayType;

  onDeserialize(): void {
  }

  rangedCannabinoids(): boolean {
    return this.cannabinoidDisplayType === CannabinoidDisplayType.Range;
  }

  rangedTerpenes(): boolean {
    return this.terpeneDisplayType === TerpeneDisplayType.Range;
  }

  getUniqueIdentifier(): string {
    return JSON.stringify(this);
  }

}
