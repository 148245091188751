<tr class="table-item clickable" [ngClass]="{ 'ignored-row': !(isVisible$ | async) }">
  <td class="fixed-column">
    <div fxLayout="row">
      <app-display-name
        [displayName]="variantName$ | push | doubleDashIfEmpty"
        [isMedical]="isMedical$ | push"
        [truncate]="true">
      </app-display-name>
      <app-label-preview
        *ngIf="!!(computeLabelInterface$ | push)"
        class="mx-2 mt-2px"
        [computeLabelInterface]="computeLabelInterface$ | push"></app-label-preview>
      <button
        *ngIf="!!(variantNameTooltip$ | push)"
        [ngbTooltip]="variantNameTooltip$ | push"
        [container]="'body'"
        class="inline-tooltip"
        placement="right"
        triggers="hover">
        <img alt="" src="assets/icons/dark/outline/bookmark.svg" />
      </button>
    </div>
    <div class="cell-subtext mt-2px">
      {{ variantSubText$ | push }}
    </div>
  </td>
  <td
    *rxFor="let column of tableViewModel.columns$; trackBy: tableViewModel.trackColumnByKey"
    [ngClass]="cellClass$ | push">
    <div class="cannabinoid-cell">
      {{ column?.columnValues | getCannabinoidTerpeneValueFromMap : (id$ | push) | doubleDashIfEmpty }}
      <button
        *ngIf="!!(column?.columnTooltips | getCannabinoidTerpeneValueFromMap : (id$ | push))"
        [ngbTooltip]="column?.columnTooltips | getCannabinoidTerpeneValueFromMap : (id$ | push)"
        [container]="'body'"
        class="inline-tooltip"
        placement="right"
        triggers="hover">
        <img alt="" src="assets/icons/dark/outline/bookmark.svg" />
      </button>
    </div>
  </td>

  <td class="price-cell">
    <div fxLayout="row">
      {{ variantPrice$ | push | doubleDashIfEmpty }}
      <button
        *ngIf="!!(variantPriceTooltip$ | push)"
        [ngbTooltip]="variantPriceTooltip$ | push"
        [container]="'body'"
        class="inline-tooltip"
        placement="right"
        triggers="hover">
        <img alt="" src="assets/icons/dark/outline/bookmark.svg" />
      </button>
    </div>
  </td>
  <td class="fixed-column visible-cell">
    <lib-reactive-form-switch
      [dispersedKey]="tableViewModel.dispersedKey$ | push"
      [inputName]="'visibilitySwitch' | randomString"
      [disabled]="variantToggleDisabled$ | push"
      [disabledTooltip]="variantToggleDisabledTooltip$ | push"
      [labelOnRight]="true"
      [programmaticallyChangeValue]="isVisible$ | push"
      (clicked)="visibilityToggled()">
    </lib-reactive-form-switch>
  </td>
</tr>
