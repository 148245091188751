import { Pipe, PipeTransform } from '@angular/core';
import type { SortFunctions } from '../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import type { Product } from '../../../models/product/dto/product';
import type { Variant } from '../../../models/product/dto/variant';
import { SortUtils } from '../../../utils/sort-utils';
import { StringUtils } from '../../../utils/string-utils';
import { exists } from '../../../functions/exists';
import { TableSortingService } from '../services/table-sorting.service';

@Pipe({
  name: 'getTableSortingFunctionsForTerpene'
})
export class GetTableSortingFunctionsForTerpenePipe implements PipeTransform {

  constructor(
    protected tableSortingService: TableSortingService
  ) {
  }

  /**
   * We need asc and desc to return different sort functions in memory. That's why they are separated when accessing
   * them through the tableSortingService. The table uses the memory address to know if the sort function is
   * selected or not, so if both asc and desc are the same in memory, then it can't distinguish which one has been
   * selected.
   */
  transform(
    terpeneAsSplitPascalCaseSentence: string,
    type: 'asc' | 'desc',
    variantProperty: string,
    rangeAccessor?: 'min' | 'max'
  ): SortFunctions {
    const terpenePascalCased = StringUtils.toPascalCase(terpeneAsSplitPascalCaseSentence);
    const terpeneCamelCased = StringUtils.toCamelCase(terpeneAsSplitPascalCaseSentence);
    const key = exists(rangeAccessor)
      ? `${type}-${rangeAccessor}${terpenePascalCased}`
      : `${type}-${terpeneCamelCased}`;
    if (this.tableSortingService.hasSortingFunctions(key)) {
      return this.tableSortingService.getSortingFunctions(key);
    }
    const sortingFunctions = {
      productSort: (a: Product, b: Product) => {
        if (type === 'asc') {
          switch (rangeAccessor) {
            case 'min': return this.productMinTerpeneAsc(a, b, terpeneCamelCased);
            case 'max': return this.productMaxTerpeneAsc(a, b, terpeneCamelCased);
            default:    return this.productTerpeneAsc(a, b, terpeneCamelCased);
          }
        }
        if (type === 'desc') {
          switch (rangeAccessor) {
            case 'min': return this.productMinTerpeneDesc(a, b, terpeneCamelCased);
            case 'max': return this.productMaxTerpeneDesc(a, b, terpeneCamelCased);
            default:    return this.productTerpeneDesc(a, b, terpeneCamelCased);
          }
        }
      },
      variantSort: {
        property: variantProperty,
        sortBy: (a: Variant, b: Variant) => {
          if (type === 'asc') {
            switch (rangeAccessor) {
              case 'min': return SortUtils.variantsMinCannabinoidOrTerpeneAsc(a, b, terpenePascalCased);
              case 'max': return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(a, b, terpenePascalCased);
              default:    return SortUtils.variantsTerpeneAsc(a, b, terpeneCamelCased);
            }
          }
          if (type === 'desc') {
            switch (rangeAccessor) {
              case 'min': return SortUtils.variantsMinCannabinoidOrTerpeneDesc(a, b, terpenePascalCased);
              case 'max': return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(a, b, terpenePascalCased);
              default:    return SortUtils.variantsTerpeneDesc(a, b, terpeneCamelCased);
            }
          }
        }
      }
    };
    this.tableSortingService.addSortingFunctions(key, sortingFunctions);
    return sortingFunctions;
  }

  protected productTerpeneAsc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const aTerpene = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedTerpenes()
          ? v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTerpene = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedTerpenes()
          ? v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinTerpene = !aTerpene?.length ? null : Math.min(...aTerpene);
    const bMinTerpene = !bTerpene?.length ? null : Math.min(...bTerpene);
    return SortUtils.numberAscNullsLast(aMinTerpene, bMinTerpene);
  };
  protected productTerpeneDesc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const aTerpene = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedTerpenes()
          ? v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTerpene = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useRangedTerpenes()
          ? v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(terpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxTerpene = !aTerpene?.length ? null : Math.max(...aTerpene);
    const bMaxTerpene = !bTerpene?.length ? null : Math.max(...bTerpene);
    return SortUtils.numberDescNullsLast(aMaxTerpene, bMaxTerpene);
  };

  public productMinTerpeneAsc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinTerpene = getFilteredMinValues(a);
    const bMinTerpene = getFilteredMinValues(b);
    return SortUtils.numberAscNullsLast(aMinTerpene, bMinTerpene);
  };
  public productMinTerpeneDesc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(terpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinTerpene = getFilteredMinValues(a);
    const bMinTerpene = getFilteredMinValues(b);
    return SortUtils.numberDescNullsLast(aMinTerpene, bMinTerpene);
  };

  public productMaxTerpeneAsc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxTerpene = getFilteredMaxValues(a);
    const bMaxTerpene = getFilteredMaxValues(b);
    return SortUtils.numberAscNullsLast(aMaxTerpene, bMaxTerpene);
  };
  public productMaxTerpeneDesc = (a: Product, b: Product, terpeneCamelCased: string) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxTerpene = getFilteredMaxValues(a);
    const bMaxTerpene = getFilteredMaxValues(b);
    return SortUtils.numberDescNullsLast(aMaxTerpene, bMaxTerpene);
  };

}
