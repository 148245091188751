<div class="modal-body sticky">
  <div
    #modalHeader
    libStickyAutoPosition
    [stickyCollectionKey]="'menu-template-save-confirmation'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="modal-title" ngbAutofocus>Required Location Changes</div>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'menu-template-save-confirmation'"
    [stickChildClassInstead]="'desc-and-search'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    [style.overflow-y]="'unset'">
    <div class="desc-and-search" fxLayout="column" [style.gap.rem]="1">
      <div class="modal-description">
        <p>The following changes will be made to this templates required locations.</p>
      </div>
      <hr class="no-margin" />
      <app-search-with-look-ahead
        [placeHolder]="'Search for changed required location'"
        [lookAheadItems]="viewModel.addedOrRemovedLocations$ | async"
        [searchTheseProperties]="['name', 'address', 'city', 'state', 'country']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        [outputAllItemsWhenSearchIsEmpty]="true"
        (searchText)="viewModel.connectToSearchString($event)"
        (lookAheadHits)="viewModel.connectToSearchedLocations($event)">
      </app-search-with-look-ahead>
      <div *ngIf="!(viewModel.searchedLocations$ | async)?.length" class="empty-search-state">No locations found.</div>
    </div>
    <app-template-location-selection-group
      *ngFor="
        let grouping of viewModel.searchedLocationsGroupedByProvince$ | async | keyvalue;
        trackBy: viewModel.trackById
      "
      [province]="grouping?.key"
      [locations]="grouping?.value"
      [viewOnlyMode]="true"
      [showAddedOrRemovedStatus]="true"
      [expanded]="true"
      [originalRequiredLocationIds]="viewModel.originalRequiredLocationIds$ | async"
      [updatedRequiredLocationIds]="viewModel.updatedRequiredLocationIds$ | async">
    </app-template-location-selection-group>
  </div>

  <div #footerContainer class="sticky-footer-container modal-footer-flex-end" [style.gap.rem]="1">
    <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary (buttonClicked)="close(true)"> Save Template </lib-button-primary>
  </div>
</div>
