import { Observable } from 'rxjs';
import { VariantGroupProperties } from '../models/protocols/variant-group-properties';
import { VariantProperties } from '../models/protocols/variant-properties';
import { PrioritySortableVariantProperties } from '../models/protocols/priority-sortable-variant-properties';
import { LabelProperties } from '../models/protocols/label-properties';
import { ProductProperties } from '../models/protocols/product-properties';
import { DefaultPrintStackSize } from '../models/enum/dto/default-print-stack-size';
import { ThemeProperties } from '../models/protocols/theme-properties';
import { SectionProperties } from '../models/protocols/section-properties';
import { LocationProperties } from '../models/protocols/location-properties';
import { MenuProperties } from '../models/protocols/menu-properties';
import { OrderableMenuAssetProperties } from '../models/protocols/orderable-menu-asset-properties';
import { BaseDisplayProperties } from '../models/protocols/base-display-properties';
import { VariantBadgeProperties } from '../models/protocols/variant-badge-properties';
import { SelectableSmartFilter } from '../models/automation/protocols/selectable-smart-filter';
import { HydratedSmartFilterProperties } from '../models/protocols/hydrated-smart-filter-properties';
import { SmartFilterGroupingProperties } from '../models/protocols/smart-filter-grouping-properties';
import { BulkPrintJobProperties } from '../models/protocols/bulk-print-job-properties';
import { AssetProperties } from '../models/protocols/asset-properties';
import { SectionSortOption } from '../models/enum/dto/section-sort-option';
import { StrainClassification } from '../models/enum/dto/strain-classification';
import { MenuLabel } from '../models/enum/dto/menu-label';
import { SyncType } from '../models/enum/dto/sync-type';
import { DefaultPrintLabelSize } from '../models/enum/dto/default-print-label-size';
import { DefaultPrintCardSize } from '../models/enum/dto/default-print-card-size';
import { SectionColumnConfigSecondaryPricingData } from '../models/enum/dto/section-column-config-secondary-pricing-data';
import { CuratedVariantBadgeSectionProperties } from '../models/protocols/curated-variant-badge-section-properties';
import { SectionSortProductInfo } from '../models/enum/dto/section-sort-product-info';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../models/enum/dto/secondary-cannabinoid';
import { SectionSortSecondaryCannabinoids } from '../models/enum/dto/section-sort-secondary-cannabinoids';
import { SectionSortTerpenes } from '../models/enum/dto/section-sort-terpenes';
import { SectionColumnConfigProductInfoKey } from '../models/enum/dto/section-column-config-key';
import { StringUtils } from '../utils/string-utils';

/**
 * A simplified interface for sorting in JavaScript.
 *
 * Sort functions in JavaScript take in two parameters, a and b, and return a number.
 * If the number is negative, a is sorted before b.
 * If the number is positive, b is sorted before a.
 * If the number is zero, a and b are sorted in the same order.
 * Therefore, this is a handy dandy enum to transform these numbers into a human-readable format.
 */
export enum Move {
  ALeft = -1,
  ARight = 1,
  BLeft = 1,
  BRight = -1,
  Nothing = 0
}

export enum SortOrderPosition {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3
}

export abstract class WorkerSortUtils {

  static DEFAULT_PRIMARY_SORT = SectionSortProductInfo.BrandAsc;
  static DEFAULT_SECONDARY_SORT = SectionSortProductInfo.THCAsc;
  static DEFAULT_SECTION_TERTIARY_SORT = SectionSortProductInfo.TitleAsc;
  static CANNABINOID_SORT_ORDER = [...Object.values(PrimaryCannabinoid), ...Object.values(SecondaryCannabinoid)];

  protected static getMovement(num: number): Move {
    if (num < 0) return Move.ALeft;
    if (num > 0) return Move.ARight;
    return Move.Nothing;
  }

  protected static getNonNullStringMovement(a: string, b: string): Move {
    if (!a) return Move.ARight;
    if (!b) return Move.BRight;
    return WorkerSortUtils.getMovement(a.localeCompare(b));
  }

  static sharedSortId(s: string) {
    return s?.replace(/_((asc|ASC)|(desc|DESC))/, '');
  }

  static sortSpecifiedStringKeyLast = <V>(value: string) => (
    a: { key: string; value: V; },
    b: { key: string; value: V; }
  ) => {
    const aKey = a?.key;
    const bKey = b?.key;
    return WorkerSortUtils.sortSpecifiedStringLast(value)(aKey, bKey);
  };

  /* *******************************************************************************************
   *                                  Variant - Sort Options                                   *
   * *******************************************************************************************/

  static sortCannabinoidOrder = (
    a: PrimaryCannabinoid | SecondaryCannabinoid,
    b: PrimaryCannabinoid | SecondaryCannabinoid
  ) => {
    const aIndex = WorkerSortUtils.CANNABINOID_SORT_ORDER.indexOf(a);
    const bIndex = WorkerSortUtils.CANNABINOID_SORT_ORDER.indexOf(b);
    return aIndex - bIndex;
  };

  static getCannabinoidAccessor = (sortType: SectionSortOption): { cannabinoid: string, order: 'ASC'|'DESC' } => {
    const [cannabinoid, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    return { cannabinoid, order };
  };

  static getTerpeneAccessor = (sortType: SectionSortOption): { terpeneCamelCased: string, order: 'ASC'|'DESC' } => {
    const capitalize = (s: string): string => (s?.length > 1) ? s.charAt(0).toUpperCase() + s.slice(1) : s;
    const sentenceCase = (s: string): string => capitalize(s?.toLowerCase());
    const [terpene, order] = sortType.split(/_(?=ASC|DESC$)/) as [string, 'ASC'|'DESC'];
    const terpeneCamelCased = terpene
      .split('_')
      .map((it, index) => index === 0 ? it.toLowerCase() : sentenceCase(it))
      .join('');
    return { terpeneCamelCased, order };
  };

  static variantsBrandAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.brand, b?.brand);
  };
  static variantsBrandDesc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.brand, b?.brand);
  };

  static variantsTacAsc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useCannabinoidRange || a?.companyConfigRangedCannabinoids) ? a?.activeMinTAC : a?.activeAvgTAC;
    const bNumeric = (b?.useCannabinoidRange || b?.companyConfigRangedCannabinoids) ? b?.activeMinTAC : b?.activeAvgTAC;
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsTacDesc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useCannabinoidRange || a?.companyConfigRangedCannabinoids) ? a?.activeMaxTAC : a?.activeAvgTAC;
    const bNumeric = (b?.useCannabinoidRange || b?.companyConfigRangedCannabinoids) ? b?.activeMaxTAC : b?.activeAvgTAC;
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsTotalTerpeneAsc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMinTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMinTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsTotalTerpeneDesc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMaxTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMaxTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsMaxTotalTerpeneAsc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMaxTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMaxTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsMaxTotalTerpeneDesc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMaxTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMaxTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsMinTotalTerpeneAsc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMinTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMinTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsMinTotalTerpeneDesc = (a: VariantProperties, b: VariantProperties): Move => {
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.activeMinTotalTerpene
      : a?.activeAvgTotalTerpene;
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.activeMinTotalTerpene
      : b?.activeAvgTotalTerpene;
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsMaxTacAsc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.numberAscending(a?.activeMaxTAC, b?.activeMaxTAC);
  };
  static variantsMaxTacDesc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.numberDescending(a?.activeMaxTAC, b?.activeMaxTAC);
  };

  static variantsMinTacAsc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.numberAscNullsLast(a?.activeMinTAC, b?.activeMinTAC);
  };
  static variantsMinTacDesc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.numberDescNullsLast(a?.activeMinTAC, b?.activeMinTAC);
  };

  static variantsCannabinoidAsc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoid: string
  ): Move => {
    const aNumeric = (a?.useCannabinoidRange || a?.companyConfigRangedCannabinoids)
      ? a?.[`activeMin${cannabinoid}`]
      : a?.[`activeAvg${cannabinoid}`];
    const bNumeric = (b?.useCannabinoidRange || b?.companyConfigRangedCannabinoids)
      ? b?.[`activeMin${cannabinoid}`]
      : b?.[`activeAvg${cannabinoid}`];
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsCannabinoidDesc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoid: string
  ): Move => {
    const aNumeric = (a?.useCannabinoidRange || a?.companyConfigRangedCannabinoids)
      ? a?.[`activeMax${cannabinoid}`]
      : a?.[`activeAvg${cannabinoid}`];
    const bNumeric = (b?.useCannabinoidRange || b?.companyConfigRangedCannabinoids)
      ? b?.[`activeMax${cannabinoid}`]
      : b?.[`activeAvg${cannabinoid}`];
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsTerpeneAsc = (a: VariantProperties, b: VariantProperties, terpeneCamelCased: string): Move => {
    const terpenePascalCased = StringUtils.toPascalCase(terpeneCamelCased);
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.[`activeMin${terpenePascalCased}`]
      : a?.[`activeAvg${terpenePascalCased}`];
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.[`activeMin${terpenePascalCased}`]
      : b?.[`activeAvg${terpenePascalCased}`];
    return WorkerSortUtils.numberAscNullsLast(aNumeric, bNumeric);
  };
  static variantsTerpeneDesc = (a: VariantProperties, b: VariantProperties, terpeneCamelCased: string): Move => {
    const terpenePascalCased = StringUtils.toPascalCase(terpeneCamelCased);
    const aNumeric = (a?.useTerpeneRange || a?.companyConfigRangedTerpenes)
      ? a?.[`activeMax${terpenePascalCased}`]
      : a?.[`activeAvg${terpenePascalCased}`];
    const bNumeric = (b?.useTerpeneRange || b?.companyConfigRangedTerpenes)
      ? b?.[`activeMax${terpenePascalCased}`]
      : b?.[`activeAvg${terpenePascalCased}`];
    return WorkerSortUtils.numberDescNullsLast(aNumeric, bNumeric);
  };

  static variantsTopTerpeneAsc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.activeTopTerpene, b?.activeTopTerpene);
  };
  static variantsTopTerpeneDesc = (a: VariantProperties, b: VariantProperties): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.activeTopTerpene, b?.activeTopTerpene);
  };

  static variantsMinCannabinoidOrTerpeneAsc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberAscNullsLast(
      a?.[`activeMin${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMin${cannabinoidOrTerpenePascalCased}`]
    );
  };
  static variantsMinCannabinoidOrTerpeneDesc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberDescNullsLast(
      a?.[`activeMin${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMin${cannabinoidOrTerpenePascalCased}`]
    );
  };

  static variantsMinCannabinoidOrTerpeneAscNullsLast = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberAscNullsLast(
      a?.[`activeMin${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMin${cannabinoidOrTerpenePascalCased}`]
    );
  };
  static variantsMinCannabinoidOrTerpeneDescNullsLast = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberDescNullsLast(
      a?.[`activeMin${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMin${cannabinoidOrTerpenePascalCased}`]
    );
  };

  static variantsMaxCannabinoidOrTerpeneAsc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberAscNullsLast(
      a?.[`activeMax${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMax${cannabinoidOrTerpenePascalCased}`]
    );
  };
  static variantsMaxCannabinoidOrTerpeneDesc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberDescNullsLast(
      a?.[`activeMax${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMax${cannabinoidOrTerpenePascalCased}`]
    );
  };

  static variantsMaxCannabinoidOrTerpeneAscNullsLast = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberAscNullsLast(
      a?.[`activeMax${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMax${cannabinoidOrTerpenePascalCased}`]
    );
  };
  static variantsMaxCannabinoidOrTerpeneDescNullsLast = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidOrTerpenePascalCased: string
  ): Move => {
    return WorkerSortUtils.numberDescNullsLast(
      a?.[`activeMax${cannabinoidOrTerpenePascalCased}`],
      b?.[`activeMax${cannabinoidOrTerpenePascalCased}`]
    );
  };

  /** Explicitly defined ordering */
  protected static strainClassificationSortAsc = (
    a: StrainClassification,
    b: StrainClassification
  ): Move => {
    const getOrderNumber = (strainClassification: StrainClassification) => {
      // Strain Classification is sorted in the following order: Sativa - Hybrid - Blend - Indica - CBD - Other
      switch (strainClassification) {
        case StrainClassification.Sativa:         return 1;
        case StrainClassification.SativaDominant: return 2;
        case StrainClassification.Hybrid:         return 3;
        case StrainClassification.Blend:          return 4;
        case StrainClassification.Indica:         return 5;
        case StrainClassification.IndicaDominant: return 6;
        case StrainClassification.CBD:            return 7;
        default:                                  return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return WorkerSortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };
  /** Explicitly defined ordering */
  protected static strainClassificationSortDesc = (
    a: StrainClassification,
    b: StrainClassification
  ): Move => {
    const getOrderNumber = (strainClassification: StrainClassification) => {
      // Strain Classification is sorted in the following order: CBD - Indica - Blend - Hybrid - Sativa - Other
      switch (strainClassification) {
        case StrainClassification.CBD:            return 1;
        case StrainClassification.IndicaDominant: return 2;
        case StrainClassification.Indica:         return 3;
        case StrainClassification.Blend:          return 4;
        case StrainClassification.Hybrid:         return 5;
        case StrainClassification.SativaDominant: return 6;
        case StrainClassification.Sativa:         return 7;
        default:                                  return 8;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return WorkerSortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

  static variantsManufacturerAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.manufacturer, b?.manufacturer);
  };
  static variantsManufacturerDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.manufacturer, b?.manufacturer);
  };

  static variantsPackagedQuantityAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.packagedQuantity, b?.packagedQuantity);
  };
  static variantsPackagedQuantityDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.packagedQuantity, b?.packagedQuantity);
  };

  static variantsPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.activePrice, b?.activePrice);
  };

  static variantsPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.activePrice, b?.activePrice);
  };

  static variantsPriceAscNullsLast = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscNullsLast(a?.activePrice, b?.activePrice);
  };

  static variantsPriceDescNullsLast = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescNullsLast(a?.activePrice, b?.activePrice);
  };

  static variantsPricePerUOMAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.activePricePerUOM, b?.activePricePerUOM);
  };
  static variantsPricePerUOMDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.activePricePerUOM, b?.activePricePerUOM);
  };

  static variantsOriginalPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.priceWithoutDiscounts, b?.priceWithoutDiscounts);
  };
  static variantsOriginalPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.priceWithoutDiscounts, b?.priceWithoutDiscounts);
  };

  static variantsSaleOriginalPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.saleOriginalPriceOrNull, b?.saleOriginalPriceOrNull);
  };
  static variantsSaleOriginalPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.saleOriginalPriceOrNull, b?.saleOriginalPriceOrNull);
  };

  static variantsOriginalAndSalePriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.activePrice, b?.activePrice);
  };
  static variantsOriginalAndSalePriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.activePrice, b?.activePrice);
  };

  static variantsTaxesInPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.taxesInPrice, b?.taxesInPrice);
  };
  static variantsTaxesInPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.taxesInPrice, b?.taxesInPrice);
  };

  static variantsTaxesInRoundedPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.taxesInRoundedPrice, b?.taxesInRoundedPrice);
  };
  static variantsTaxesInRoundedPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.taxesInRoundedPrice, b?.taxesInRoundedPrice);
  };

  static variantsPreTaxPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.preTaxPrice, b?.preTaxPrice);
  };
  static variantsPreTaxPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.preTaxPrice, b?.preTaxPrice);
  };

  static variantsProductTypeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.productType, b?.productType);
  };
  static variantsProductTypeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.productType, b?.productType);
  };

  static variantsRotationPriorityAsc = (
    a: PrioritySortableVariantProperties,
    b: PrioritySortableVariantProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.priority, b?.priority);
  };
  static variantsRotationPriorityDesc = (
    a: PrioritySortableVariantProperties,
    b: PrioritySortableVariantProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.priority, b?.priority);
  };

  static variantsSecondaryPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
    section: SectionProperties,
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return WorkerSortUtils.variantsPricePerUOMAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return WorkerSortUtils.variantsOriginalPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return WorkerSortUtils.variantsSaleOriginalPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return WorkerSortUtils.variantsOriginalAndSalePriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return WorkerSortUtils.variantsTaxesInPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return WorkerSortUtils.variantsTaxesInRoundedPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return WorkerSortUtils.variantsPreTaxPriceAsc([a, b]);
      default:
        return a?.activeSecondaryPrice - b?.activeSecondaryPrice;
    }
  };
  static variantsSecondaryPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
    section: SectionProperties,
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return WorkerSortUtils.variantsPricePerUOMDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return WorkerSortUtils.variantsOriginalPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return WorkerSortUtils.variantsSaleOriginalPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return WorkerSortUtils.variantsOriginalAndSalePriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return WorkerSortUtils.variantsTaxesInPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return WorkerSortUtils.variantsTaxesInRoundedPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return WorkerSortUtils.variantsPreTaxPriceDesc([a, b]);
      default:
        return b?.activeSecondaryPrice - a?.activeSecondaryPrice;
    }
  };

  static variantsStockAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };
  static variantsStockDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.inventory?.quantityInStock, b?.inventory?.quantityInStock);
  };

  static variantsStrainClassificationAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortAsc(a?.classification, b?.classification);
  };
  static variantsStrainClassificationDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortDesc(a?.classification, b?.classification);
  };

  static variantsTitleAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.activeVariantName?.trim(), b?.activeVariantName?.trim());
  };
  static variantsTitleDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.activeVariantName?.trim(), b?.activeVariantName?.trim());
  };

  static variantsVariantTypeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.variantType, b?.variantType);
  };
  static variantsVariantTypeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.variantType, b?.variantType);
  };

  static variantsUnitSizeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move =>
    WorkerSortUtils.numberAscending(a?.unitSize, b?.unitSize);
  static variantsUnitSizeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move =>
    WorkerSortUtils.numberDescending(a?.unitSize, b?.unitSize);

  static sortVariantsByUnitSizeAscElsePackagedQuantityAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsUnitSizeAsc(a, b) || WorkerSortUtils.variantsPackagedQuantityAsc(a, b);
  };

  /* *******************************************************************************************
   *                            Section Variants - Sort Options                                *
   * *******************************************************************************************/

  static sectionVariantsByBrandAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsBrandAsc(a, b);
  };
  static sectionVariantsByBrandDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsBrandDesc(a, b);
  };

  static sectionVariantsByClassificationAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortAsc(a?.classification, b?.classification);
  };
  static sectionVariantsByClassificationDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortDesc(a?.classification, b?.classification);
  };

  static sectionVariantsByManufacturerAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsManufacturerAsc(a, b);
  };
  static sectionVariantsByManufacturerDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsManufacturerDesc(a, b);
  };

  static sectionVariantsByPackagedQuantityAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsPackagedQuantityAsc(a, b);
  };
  static sectionVariantsByPackagedQuantityDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsPackagedQuantityDesc(a, b);
  };

  static sectionVariantsByPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.variantsPriceAsc([a, b]);
  };
  static sectionVariantsByPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
  ): Move => {
    return WorkerSortUtils.variantsPriceDesc([a, b]);
  };

  static sectionVariantsByProductTypeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsProductTypeAsc(a, b);
  };
  static sectionVariantsByProductTypeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsProductTypeDesc(a, b);
  };

  static sectionVariantsBySecondaryPriceAsc = (
    [a, b]: [VariantProperties, VariantProperties],
    section: SectionProperties,
  ): Move => {
    return WorkerSortUtils.variantsSecondaryPriceAsc([a, b], section);
  };
  static sectionVariantsBySecondaryPriceDesc = (
    [a, b]: [VariantProperties, VariantProperties],
    section: SectionProperties,
  ): Move => {
    return WorkerSortUtils.variantsSecondaryPriceDesc([a, b], section);
  };

  static sectionVariantsByStockAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsStockAsc(a, b);
  };
  static sectionVariantsByStockDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsStockDesc(a, b);
  };

  static sectionVariantsByTitleAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsTitleAsc(a, b);
  };
  static sectionVariantsByTitleDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsTitleDesc(a, b);
  };

  static sectionVariantsCannabinoidAsc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantsCannabinoidAsc(a, b, cannabinoidCamelCased);
  };
  static sectionVariantsCannabinoidDesc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantsCannabinoidDesc(a, b, cannabinoidCamelCased);
  };

  static sectionVariantsTerpeneAsc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantsTerpeneAsc(a, b, cannabinoidCamelCased);
  };
  static sectionVariantsTerpeneDesc = (
    a: VariantProperties,
    b: VariantProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantsTerpeneDesc(a, b, cannabinoidCamelCased);
  };

  static sectionVariantsTopTerpeneAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsTopTerpeneAsc(a, b);
  };
  static sectionVariantsTopTerpeneDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsTopTerpeneDesc(a, b);
  };

  static sectionVariantsByUnitSizeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsUnitSizeAsc(a, b);
  };
  static sectionVariantsByUnitSizeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsUnitSizeDesc(a, b);
  };

  static sectionVariantsVariantTypeAsc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsVariantTypeAsc(a, b);
  };
  static sectionVariantsVariantTypeDesc = (
    a: VariantProperties,
    b: VariantProperties
  ): Move => {
    return WorkerSortUtils.variantsVariantTypeDesc(a, b);
  };

  private static sectionVariantSorter<T extends VariantProperties, U extends SectionProperties>(
    sortOption: SectionSortOption,
    [a, b]: [T, T],
    section: U,
  ): Move {
    const getProduct = () => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return WorkerSortUtils.sectionVariantsByBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return WorkerSortUtils.sectionVariantsByBrandDesc(a, b);
        case SectionSortProductInfo.CBDAsc:
          return WorkerSortUtils.sectionVariantsCannabinoidAsc(a, b, 'CBD');
        case SectionSortProductInfo.CBDDesc:
          return WorkerSortUtils.sectionVariantsCannabinoidDesc(a, b, 'CBD');
        case SectionSortProductInfo.ClassificationAsc:
          return WorkerSortUtils.sectionVariantsByClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return WorkerSortUtils.sectionVariantsByClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return WorkerSortUtils.sectionVariantsByManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return WorkerSortUtils.sectionVariantsByManufacturerDesc(a, b);
        case SectionSortProductInfo.PackagedQuantityAsc:
          return WorkerSortUtils.sectionVariantsByPackagedQuantityAsc(a, b);
        case SectionSortProductInfo.PackagedQuantityDesc:
          return WorkerSortUtils.sectionVariantsByPackagedQuantityDesc(a, b);
        case SectionSortProductInfo.PriceAsc:
          return WorkerSortUtils.sectionVariantsByPriceAsc([a, b]);
        case SectionSortProductInfo.PriceDesc:
          return WorkerSortUtils.sectionVariantsByPriceDesc([a, b]);
        case SectionSortProductInfo.ProductTypeAsc:
          return WorkerSortUtils.sectionVariantsByProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return WorkerSortUtils.sectionVariantsByProductTypeDesc(a, b);
        case SectionSortProductInfo.SecondaryPriceAsc:
          return WorkerSortUtils.sectionVariantsBySecondaryPriceAsc([a, b], section);
        case SectionSortProductInfo.SecondaryPriceDesc:
          return WorkerSortUtils.sectionVariantsBySecondaryPriceDesc([a, b], section);
        case SectionSortProductInfo.StockAsc:
          return WorkerSortUtils.sectionVariantsByStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return WorkerSortUtils.sectionVariantsByStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return WorkerSortUtils.sectionVariantsByTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return WorkerSortUtils.sectionVariantsByTitleDesc(a, b);
        case SectionSortProductInfo.TACAsc:
          return WorkerSortUtils.variantsTacAsc(a, b);
        case SectionSortProductInfo.TACDesc:
          return WorkerSortUtils.variantsTacDesc(a, b);
        case SectionSortProductInfo.THCAsc:
          return WorkerSortUtils.sectionVariantsCannabinoidAsc(a, b, 'THC');
        case SectionSortProductInfo.THCDesc:
          return WorkerSortUtils.sectionVariantsCannabinoidDesc(a, b, 'THC');
        case SectionSortProductInfo.TopTerpeneAsc:
          return WorkerSortUtils.sectionVariantsTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return WorkerSortUtils.sectionVariantsTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpenesAsc:
          return WorkerSortUtils.variantsTotalTerpeneAsc(a, b);
        case SectionSortProductInfo.TotalTerpenesDesc:
          return WorkerSortUtils.variantsTotalTerpeneDesc(a, b);
        case SectionSortProductInfo.UnitSizeAsc:
          return WorkerSortUtils.sectionVariantsByUnitSizeAsc(a, b);
        case SectionSortProductInfo.UnitSizeDesc:
          return WorkerSortUtils.sectionVariantsByUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return WorkerSortUtils.sectionVariantsVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return WorkerSortUtils.sectionVariantsVariantTypeDesc(a, b);
        default:
          return WorkerSortUtils.variantsTitleAsc(a, b);
      }
    };

    const getSecondaryCannabinoid = (): Move =>  {
      const { cannabinoid, order } = WorkerSortUtils.getCannabinoidAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? WorkerSortUtils.sectionVariantsCannabinoidAsc(a, b, cannabinoid)
        : WorkerSortUtils.sectionVariantsCannabinoidDesc(a, b, cannabinoid);
    };

    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = WorkerSortUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? WorkerSortUtils.sectionVariantsTerpeneAsc(a, b, terpeneCamelCased)
        : WorkerSortUtils.sectionVariantsTerpeneDesc(a, b, terpeneCamelCased);
    };

    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return getProduct();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  static sortVariantsBySectionSortOptions = <T extends VariantProperties, U extends SectionProperties>(
    variants: T[],
    section: U,
  ): T[] => {
    const sorter = WorkerSortUtils.sectionVariantSorter;
    return WorkerSortUtils.sortVariantsInSection(sorter, variants, section);
  };

  static sortVariantsInSectionBy = <T extends VariantProperties, U extends SectionProperties>(
    primarySortOption: SectionSortOption,
    secondarySortOption: SectionSortOption,
    variants: T[],
    section: U,
  ): T[] => {
    const tertiarySortOption = WorkerSortUtils.DEFAULT_SECTION_TERTIARY_SORT;
    const secondarySortId = WorkerSortUtils.sharedSortId(primarySortOption);
    const tertiarySortId = WorkerSortUtils.sharedSortId(tertiarySortOption);
    const sortByTertiary = secondarySortId !== tertiarySortId;
    const sorter = WorkerSortUtils.sectionVariantSorter;
    const sortVariantsInSection = (a: T, b: T) => {
      // short-circuit calculation
      return sorter(primarySortOption, [a, b], section)
        || (!!secondarySortOption ? sorter(secondarySortOption, [a, b], section) : Move.Nothing)
        || (sortByTertiary ? sorter(tertiarySortOption, [a, b], section) : Move.Nothing);
    };
    return variants?.sort(sortVariantsInSection) || [];
  };

  /* *******************************************************************************************
   *                             Variant Groups - Sort Options                                 *
   * *******************************************************************************************/

  static variantGroupPropertiesBrandAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.groupBrand, b?.groupBrand);
  };
  static variantGroupPropertiesBrandDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.groupBrand, b?.groupBrand);
  };

  static variantGroupPropertiesCannabinoidAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.numberAscending(
      a?.[`min${cannabinoidCamelCased}`],
      b?.[`min${cannabinoidCamelCased}`]
    );
  };
  static variantGroupPropertiesCannabinoidDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    cannabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.numberDescending(
      a?.[`max${cannabinoidCamelCased}`],
      b?.[`max${cannabinoidCamelCased}`]
    );
  };

  static variantGroupPropertiesClassificationAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortAsc(a?.groupClassification, b?.groupClassification);
  };
  static variantGroupPropertiesClassificationDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.strainClassificationSortDesc(a?.groupClassification, b?.groupClassification);
  };

  static variantGroupPropertiesManufacturerAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.groupManufacturer, b?.groupManufacturer);
  };
  static variantGroupPropertiesManufacturerDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.groupManufacturer, b?.groupManufacturer);
  };

  static variantGroupPropertiesPackagedQuantityAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minPackageQuantity, b?.minPackageQuantity);
  };
  static variantGroupPropertiesPackagedQuantityDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxPackageQuantity, b?.maxPackageQuantity);
  };

  static variantGroupPropertiesPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minPrice, b?.minPrice);
  };
  static variantGroupPropertiesPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxPrice, b?.maxPrice);
  };

  static variantGroupPropertiesPricePerUOMAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minPricePerUOM, b?.minPricePerUOM);
  };
  static variantGroupPropertiesPricePerUOMDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxPricePerUOM, b?.maxPricePerUOM);
  };

  static variantGroupPropertiesOriginalPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minOriginalPrice, b?.minOriginalPrice);
  };
  static variantGroupPropertiesOriginalPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxOriginalPrice, b?.maxOriginalPrice);
  };

  static variantGroupPropertiesSaleOriginalPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minSaleOriginalPrice, b?.minSaleOriginalPrice);
  };
  static variantGroupPropertiesSaleOriginalPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxSaleOriginalPrice, b?.maxSaleOriginalPrice);
  };

  static variantGroupPropertiesOriginalAndSalePriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minPriceWithSale, b?.minPriceWithSale);
  };
  static variantGroupPropertiesOriginalAndSalePriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxPriceWithSale, b?.maxPriceWithSale);
  };

  static variantGroupPropertiesTaxesInPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minTaxesInPrice, b?.minTaxesInPrice);
  };
  static variantGroupPropertiesTaxesInPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxTaxesInPrice, b?.maxTaxesInPrice);
  };

  static variantGroupPropertiesTaxesInRoundedPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minTaxesInRoundedPrice, b?.minTaxesInRoundedPrice);
  };
  static variantGroupPropertiesTaxesInRoundedPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxTaxesInRoundedPrice, b?.maxTaxesInRoundedPrice);
  };

  static variantGroupPropertiesPreTaxPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minPreTaxPrice, b?.minPreTaxPrice);
  };
  static variantGroupPropertiesPreTaxPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxPreTaxPrice, b?.maxPreTaxPrice);
  };

  static variantGroupPropertiesMinSecondaryPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minSecondaryPrice, b?.minSecondaryPrice);
  };
  static variantGroupPropertiesMinSecondaryPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.minSecondaryPrice, b?.minSecondaryPrice);
  };

  static variantGroupPropertiesMaxSecondaryPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.maxSecondaryPrice, b?.maxSecondaryPrice);
  };
  static variantGroupPropertiesMaxSecondaryPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxSecondaryPrice, b?.maxSecondaryPrice);
  };

  static variantGroupPropertiesProductTypeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.productType, b?.productType);
  };
  static variantGroupPropertiesProductTypeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.productType, b?.productType);
  };

  static variantGroupPropertiesSecondaryPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
    section: SectionProperties,
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return WorkerSortUtils.variantGroupPropertiesPricePerUOMAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return WorkerSortUtils.variantGroupPropertiesOriginalPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return WorkerSortUtils.variantGroupPropertiesSaleOriginalPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return WorkerSortUtils.variantGroupPropertiesOriginalAndSalePriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return WorkerSortUtils.variantGroupPropertiesTaxesInPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return WorkerSortUtils.variantGroupPropertiesTaxesInRoundedPriceAsc([a, b]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return WorkerSortUtils.variantGroupPropertiesPreTaxPriceAsc([a, b]);
      default:
        return WorkerSortUtils.variantGroupPropertiesMinSecondaryPriceAsc([a, b]);
    }
  };
  static variantGroupPropertiesSecondaryPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
    section: SectionProperties,
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return WorkerSortUtils.variantGroupPropertiesPricePerUOMDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return WorkerSortUtils.variantGroupPropertiesOriginalPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return WorkerSortUtils.variantGroupPropertiesSaleOriginalPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return WorkerSortUtils.variantGroupPropertiesOriginalAndSalePriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return WorkerSortUtils.variantGroupPropertiesTaxesInPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return WorkerSortUtils.variantGroupPropertiesTaxesInRoundedPriceDesc([a, b]);
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return WorkerSortUtils.variantGroupPropertiesPreTaxPriceDesc([a, b]);
      default:
        return WorkerSortUtils.variantGroupPropertiesMaxSecondaryPriceDesc([a, b]);
    }
  };

  static variantGroupPropertiesStockAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.maxVariantStock, b?.maxVariantStock);
  };
  static variantGroupPropertiesStockDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxVariantStock, b?.maxVariantStock);
  };

  static variantGroupPropertiesTitleAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.groupTitle, b?.groupTitle);
  };
  static variantGroupPropertiesTitleDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.groupTitle, b?.groupTitle);
  };

  static variantGroupPropertiesUnitSizeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberAscending(a?.minUnitSize, b?.minUnitSize);
  };
  static variantGroupPropertiesUnitSizeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.numberDescending(a?.maxUnitSize, b?.maxUnitSize);
  };

  static variantGroupsVariantTypeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.variantType, b?.variantType);
  };
  static variantGroupsVariantTypeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.variantType, b?.variantType);
  };

  static variantGroupsCannabinoidAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    cannabinoid: string
  ): Move => {
    return WorkerSortUtils.numberAscending(
      a?.[`min${cannabinoid}`],
      b?.[`min${cannabinoid}`]
    );
  };
  static variantGroupsCannabinoidDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    cannabinoid: string
  ): Move => {
    return WorkerSortUtils.numberDescending(
      a?.[`max${cannabinoid}`],
      b?.[`max${cannabinoid}`]
    );
  };

  static variantGroupsTerpeneAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    terpeneCamelCased: string
  ): Move => {
    return WorkerSortUtils.numberAscending(
      a?.[`min${terpeneCamelCased.charAt(0).toUpperCase() + terpeneCamelCased.slice(1)}`],
      b?.[`min${terpeneCamelCased.charAt(0).toUpperCase() + terpeneCamelCased.slice(1)}`]
    );
  };
  static variantGroupsTerpeneDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    terpeneCamelCased: string
  ): Move => {
    return WorkerSortUtils.numberDescending(
      a?.[`max${terpeneCamelCased.charAt(0).toUpperCase() + terpeneCamelCased.slice(1)}`],
      b?.[`max${terpeneCamelCased.charAt(0).toUpperCase() + terpeneCamelCased.slice(1)}`]
    );
  };

  static variantGroupsTopTerpeneAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.topTerpene, b?.topTerpene);
  };
  static variantGroupsTopTerpeneDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.topTerpene, b?.topTerpene);
  };

  static variantGroupsTitleAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.groupTitle, b?.groupTitle);
  };
  static variantGroupsTitleDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringDescending(a?.groupTitle, b?.groupTitle);
  };

  /* *******************************************************************************************
   *                          Section Variant Groups - Sort Options                            *
   * *******************************************************************************************/

  static sectionVariantGroupPropertiesBrandAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesBrandAsc(a, b);
  };
  static sectionVariantGroupPropertiesBrandDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesBrandDesc(a, b);
  };

  static sectionVariantGroupPropertiesCannabinoidAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    canabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesCannabinoidAsc(a, b, canabinoidCamelCased);
  };
  static sectionVariantGroupPropertiesCannabinoidDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    canabinoidCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesCannabinoidDesc(a, b, canabinoidCamelCased);
  };

  static sectionVariantGroupPropertiesClassificationAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesClassificationAsc(a, b);
  };
  static sectionVariantGroupPropertiesClassificationDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesClassificationDesc(a, b);
  };

  static sectionVariantGroupPropertiesManufacturerAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesManufacturerAsc(a, b);
  };
  static sectionVariantGroupPropertiesManufacturerDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesManufacturerDesc(a, b);
  };

  static sectionVariantGroupPropertiesPackagedQuantityAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesPackagedQuantityAsc(a, b);
  };
  static sectionVariantGroupPropertiesPackagedQuantityDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesPackagedQuantityDesc(a, b);
  };

  static sectionVariantGroupPropertiesPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesPriceAsc([a, b]);
  };
  static sectionVariantGroupPropertiesPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesPriceDesc([a, b]);
  };

  static sectionVariantGroupPropertiesProductTypeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesProductTypeAsc(a, b);
  };
  static sectionVariantGroupPropertiesProductTypeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesProductTypeDesc(a, b);
  };

  static sectionVariantGroupPropertiesSecondaryPriceAsc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
    section: SectionProperties,
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesSecondaryPriceAsc([a, b], section);
  };
  static sectionVariantGroupPropertiesSecondaryPriceDesc = (
    [a, b]: [VariantGroupProperties, VariantGroupProperties],
    section: SectionProperties,
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesSecondaryPriceDesc([a, b], section);
  };

  static sectionVariantGroupPropertiesStockAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesStockAsc(a, b);
  };
  static sectionVariantGroupPropertiesStockDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesStockDesc(a, b);
  };

  static sectionVariantGroupPropertiesTitleAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesTitleAsc(a, b);
  };
  static sectionVariantGroupPropertiesTitleDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesTitleDesc(a, b);
  };

  static sectionVariantGroupPropertiesUnitSizeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesUnitSizeAsc(a, b);
  };
  static sectionVariantGroupPropertiesUnitSizeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupPropertiesUnitSizeDesc(a, b);
  };

  static sectionVariantGroupPropertiesVariantTypeAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupsVariantTypeAsc(a, b);
  };
  static sectionVariantGroupPropertiesVariantTypeDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupsVariantTypeDesc(a, b);
  };

  static sectionVariantGroupPropertiesTerpeneAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    terpeneCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantGroupsTerpeneAsc(a, b, terpeneCamelCased);
  };
  static sectionVariantGroupPropertiesTerpeneDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties,
    terpeneCamelCased: string
  ): Move => {
    return WorkerSortUtils.variantGroupsTerpeneDesc(a, b, terpeneCamelCased);
  };

  static sectionVariantGroupPropertiesTopTerpeneAsc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupsTopTerpeneAsc(a, b);
  };
  static sectionVariantGroupPropertiesTopTerpeneDesc = (
    a: VariantGroupProperties,
    b: VariantGroupProperties
  ): Move => {
    return WorkerSortUtils.variantGroupsTopTerpeneDesc(a, b);
  };

  protected static sectionVariantGroupPropertiesSorter<T extends VariantGroupProperties>(
    sortOption: SectionSortOption,
    [a, b]: [T, T],
    section: SectionProperties,
  ): Move {
    const productSortType = (): Move => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesBrandDesc(a, b);
        // CBDAsc uses min CBD
        case SectionSortProductInfo.CBDAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidAsc(a, b, PrimaryCannabinoid.CBD);
        // CBDDesc uses max CBD
        case SectionSortProductInfo.CBDDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidDesc(a, b, PrimaryCannabinoid.CBD);
        case SectionSortProductInfo.ClassificationAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesManufacturerDesc(a, b);
        // PackagedQuantityAsc uses min packaged quantity
        case SectionSortProductInfo.PackagedQuantityAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesPackagedQuantityAsc(a, b);
        // PackagedQuantityDesc uses max packaged quantity
        case SectionSortProductInfo.PackagedQuantityDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesPackagedQuantityDesc(a, b);
        // PriceAsc uses min price
        case SectionSortProductInfo.PriceAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesPriceAsc([a, b]);
        // PriceDesc uses max price
        case SectionSortProductInfo.PriceDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesPriceDesc([a, b]);
        case SectionSortProductInfo.ProductTypeAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesProductTypeDesc(a, b);
        // PriceAsc uses min secondary price
        case SectionSortProductInfo.SecondaryPriceAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesSecondaryPriceAsc([a, b], section);
        // PriceDesc uses max secondary price
        case SectionSortProductInfo.SecondaryPriceDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesSecondaryPriceDesc([a, b], section);
        case SectionSortProductInfo.StockAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTitleDesc(a, b);
        case SectionSortProductInfo.TACAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidAsc(a, b, PrimaryCannabinoid.TAC);
        case SectionSortProductInfo.TACDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidDesc(a, b, PrimaryCannabinoid.TAC);
        // THCAsc uses min THC
        case SectionSortProductInfo.THCAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidAsc(a, b, PrimaryCannabinoid.THC);
        // THCDesc uses max THC
        case SectionSortProductInfo.THCDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidDesc(a, b,  PrimaryCannabinoid.THC);
        case SectionSortProductInfo.TopTerpeneAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpenesAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTerpeneAsc(a, b, 'totalTerpene');
        case SectionSortProductInfo.TotalTerpenesDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesTerpeneDesc(a, b, 'totalTerpene');
        // UnitSizeAsc uses min unit size
        case SectionSortProductInfo.UnitSizeAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesUnitSizeAsc(a, b);
        // UnitSizeDesc uses max unit size
        case SectionSortProductInfo.UnitSizeDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return WorkerSortUtils.sectionVariantGroupPropertiesVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return WorkerSortUtils.sectionVariantGroupPropertiesVariantTypeDesc(a, b);
        default:
          return WorkerSortUtils.variantGroupsTitleAsc(a, b);
      }
    };

    const getSecondaryCannabinoid = (): Move =>  {
      const { cannabinoid, order } = WorkerSortUtils.getCannabinoidAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidAsc(a, b, cannabinoid)
        : WorkerSortUtils.sectionVariantGroupPropertiesCannabinoidDesc(a, b, cannabinoid);
    };
    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = WorkerSortUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? WorkerSortUtils.sectionVariantGroupPropertiesTerpeneAsc(a, b, terpeneCamelCased)
        : WorkerSortUtils.sectionVariantGroupPropertiesTerpeneDesc(a, b, terpeneCamelCased);
    };

    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return productSortType();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  static variantGroupsBySortOptions = <T extends VariantGroupProperties, U extends SectionProperties>(
    variantGroups: T[],
    section: U,
  ): T[] => {
    const sorter = WorkerSortUtils.sectionVariantGroupPropertiesSorter;
    const sortedVariantGroupProperties = WorkerSortUtils.sortVariantsInSection(sorter, variantGroups, section);
    const sortVariantsInGrouping = (variantGroup: VariantGroupProperties) => {
      WorkerSortUtils.sortVariantsBySectionSortOptions(variantGroup.variants, section);
    };
    sortedVariantGroupProperties.forEach(sortVariantsInGrouping);
    return sortedVariantGroupProperties;
  };

  /**
   * Generic, can sort a list of variants or a list of variant groups.
   * If there is no secondary sort, then the tertiary sort becomes the secondary sort.
   * Don't do the tertiary sort if it's the same as secondary sort, otherwise always do tertiary sort.
   * Short-circuit evaluation used to prevent unnecessary function calls.
   * Primary sort always exists.
   * Secondary sort is optional.
   * Tertiary sort always exists and is hard-coded to title asc.
   *
   * @param sorter - the function that is responsible for sorting the variants
   * @param data - the list of variants or variant groups to sort
   * @param sec - the section the variants belong to
   * @returns the sorted list of variants or variant groups
   */
  static sortVariantsInSection<T>(
    sorter: (
      sortOption: SectionSortOption,
      [a, b]: [T, T],
      section: SectionProperties,
    ) => Move,
    data: T[],
    sec: SectionProperties,
  ): T[] {
    const primary = sec?.sorting;
    const secondary = sec?.secondarySorting;
    const tertiary = WorkerSortUtils.DEFAULT_SECTION_TERTIARY_SORT;
    const secondarySortId = WorkerSortUtils.sharedSortId(secondary);
    const tertiarySortId = WorkerSortUtils.sharedSortId(tertiary);
    const sortByTertiary = secondarySortId !== tertiarySortId;
    const sortVariantsInSection = (a: T, b: T) => {
      // short-circuit calculation
      return sorter(primary, [a, b], sec)
        || (!!secondary ? sorter(secondary, [a, b], sec) : Move.Nothing)
        || (sortByTertiary ? sorter(tertiary, [a, b], sec) : Move.Nothing);
    };
    return data?.sort(sortVariantsInSection) || [];
  }

  /* *******************************************************************************************
   *                                  Miscellaneous Sorts                                      *
   * *******************************************************************************************/

  static nameAscending = (a: { name?: string }, b: { name?: string }): Move => {
    return WorkerSortUtils.getMovement(a?.name?.localeCompare(b?.name));
  };

  static nameDescending = (a: { name?: string }, b: { name?: string }): Move => {
    return WorkerSortUtils.getMovement(b?.name?.localeCompare(a?.name));
  };

  static menuAssets = (a: OrderableMenuAssetProperties, b: OrderableMenuAssetProperties): Move => {
    return WorkerSortUtils.getMovement(a?.priority - b?.priority);
  };

  static byActive = (a: { displayableItemIsActive(): boolean }, b: { displayableItemIsActive(): boolean }): Move => {
    if (a?.displayableItemIsActive() && !b?.displayableItemIsActive()) return Move.ALeft;
    if (b?.displayableItemIsActive() && !a?.displayableItemIsActive()) return Move.BLeft;
    return Move.Nothing;
  };
  static menusByNameAsc = (a: { name?: string }, b: { name?: string }): Move => a?.name?.localeCompare(b?.name);
  static byActiveThenByName = (
    a: { displayableItemIsActive(): boolean; name?: string },
    b: { displayableItemIsActive(): boolean; name?: string }
  ): Move => {
    return WorkerSortUtils.byActive(a, b) || WorkerSortUtils.menusByNameAsc(a, b);
  };

  static menuSectionsByPriorityAsc = (a: SectionProperties, b: SectionProperties): Move => {
    return WorkerSortUtils.numberAscending(a?.priority, b?.priority);
  };

  static menuSectionsByDateCreatedDesc = (a: SectionProperties, b: SectionProperties): Move => {
    return WorkerSortUtils.numberDescending(a?.dateCreated, b?.dateCreated);
  };

  static sortMenusByDisplayPriority = (a: MenuProperties, b: MenuProperties): Move => {
    return WorkerSortUtils.numberAscending(a?.displayPriority, b?.displayPriority);
  };

  static sortDisplaysByPriority = (
    a: BaseDisplayProperties,
    b: BaseDisplayProperties
  ): Move => WorkerSortUtils.getMovement(a?.priority - b?.priority);

  static sortBadges = (a: VariantBadgeProperties, b: VariantBadgeProperties): Move => {
    return a.id.localeCompare(b.id);
  };

  static sortBadgesByNameAscending = (
    a: VariantBadgeProperties,
    b: VariantBadgeProperties
  ): Move => {
    return WorkerSortUtils.nullsLast(a?.name, b?.name) || a?.name?.localeCompare(b?.name);
  };

  static sortBadgesByNameDescending = (
    a: VariantBadgeProperties,
    b: VariantBadgeProperties
  ): Move => {
    return b?.name?.localeCompare(a?.name ?? '') ?? Move.BRight;
  };

  static sortCuratedBadgeSections = (
    a: CuratedVariantBadgeSectionProperties,
    b: CuratedVariantBadgeSectionProperties
  ): Move => {
    return a?.title?.localeCompare(b?.title);
  };

  static sortTheirsAndCuratedBadgeSections = (
    a: CuratedVariantBadgeSectionProperties,
    b: CuratedVariantBadgeSectionProperties
  ): Move => {
    const aIsTheirs = a?.title === 'Your Badges';
    const bIsTheirs = b?.title === 'Your Badges';
    if (aIsTheirs && !bIsTheirs) return Move.ALeft;
    if (bIsTheirs && !aIsTheirs) return Move.BLeft;
    return a?.title?.localeCompare(b?.title);
  };

  static sortSelectableSmartFilterByName = (
    a: SelectableSmartFilter,
    b: SelectableSmartFilter
  ): Move => {
    return a?.getSelectionName()?.localeCompare(b?.getSelectionName());
  };

  static sortHydratedSmartFiltersByName = (
    a: HydratedSmartFilterProperties,
    b: HydratedSmartFilterProperties
  ): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.name, b?.name);
  };

  static sortSmartFilterGroupingsByPriority = (
    a: SmartFilterGroupingProperties,
    b: SmartFilterGroupingProperties
  ): Move => {
    return a?.priority < b?.priority ? Move.ALeft : Move.BLeft;
  };

  /**
   * Nulls sort after everything else.
   * Include negative numbers as "nulls", since when dealing with cannabis data, negative numbers are not valid.
   * Current null list: null, undefined, empty string, -, --, n < 0
   */
  static nullsLast(a: any, b: any): Move {
    const aIsNull = a === null || a === undefined || a === '' || a === '-' || a === '--' || a < 0;
    const bIsNull = b === null || b === undefined || b === '' || b === '-' || b === '--' || b < 0;
    if (aIsNull && !bIsNull) return Move.ARight;
    if (bIsNull && !aIsNull) return Move.BRight;
    return Move.Nothing;
  }

  static compareNumerically = (a: string, b: string): Move => {
    return a?.trim()?.localeCompare('' + b?.trim(), 'en', {numeric: true});
  };

  static numericStringAsc = (a: string, b: string): Move => {
    return WorkerSortUtils.nullsLast(a, b) || WorkerSortUtils.compareNumerically(a, b);
  };

  static numberAscending = (a: number, b: number): Move => WorkerSortUtils.getMovement(a - b);
  static numberDescending = (a: number, b: number): Move => WorkerSortUtils.getMovement(b - a);

  static nonNullStringAscending = (a: string, b: string): Move => {
    return WorkerSortUtils.getNonNullStringMovement(a, b);
  };
  static nonNullStringDescending = (a: string, b: string): Move => {
    return WorkerSortUtils.getNonNullStringMovement(b, a);
  };

  // Sort nulls last is done on purpose here
  static numericStringDesc = (a: string, b: string): Move => {
    return WorkerSortUtils.nullsLast(a, b) || WorkerSortUtils.compareNumerically(b, a);
  };
  static numberAscNullsLast = (a: number, b: number): Move => {
    return WorkerSortUtils.nullsLast(a, b) || WorkerSortUtils.getMovement(a - b);
  };
  static numberDescNullsLast = (a: number, b: number): Move => {
    return WorkerSortUtils.nullsLast(a, b) || WorkerSortUtils.getMovement(b - a);
  };

  // sort locationPicker locations
  static sortLocationByNameAsc = (a: LocationProperties, b: LocationProperties): Move => {
    return WorkerSortUtils.nonNullStringAscending(a?.name, b?.name);
  };
  static sortActiveLocationToStart = (
    a: LocationProperties,
    b: LocationProperties,
    activeId: number
  ): Move => {
    if ((a?.id === activeId) && (b?.id !== activeId)) return Move.ALeft;
    if ((b?.id === activeId) && (a?.id !== activeId)) return Move.BLeft;
    return Move.Nothing;
  };
  static sortLocationPicker = (
    a: LocationProperties,
    b: LocationProperties,
    activeId: number
  ): Move => {
    return WorkerSortUtils.sortActiveLocationToStart(a, b, activeId) || WorkerSortUtils.sortLocationByNameAsc(a, b);
  };

  // Labels
  static sortSystemLabelsByDefaultPriority = (
    a: LabelProperties,
    b: LabelProperties
  ): Move => {
    const getOrderNumber = (labelSystemKey: MenuLabel) => {
      switch (labelSystemKey) {
        case MenuLabel.Sale:      return 0;
        case MenuLabel.Featured:  return 1;
        case MenuLabel.New:       return 2;
        case MenuLabel.LowStock:  return 3;
        case MenuLabel.Restocked: return 4;
      }
    };
    const aOrderNumber = getOrderNumber(MenuLabel[a.id]);
    const bOrderNumber = getOrderNumber(MenuLabel[b.id]);
    return WorkerSortUtils.getMovement(aOrderNumber - bOrderNumber);
  };

  static sortLabelsAlphabetically = (a: LabelProperties, b: LabelProperties): Move => {
    return a?.text?.localeCompare(b?.text, 'en', {numeric: true});
  };

  static sortLabelsByPriority = (a: LabelProperties, b: LabelProperties): Move => {
    // Move all labels with priority of -1 to the end of the list
    if ((a?.priority === -1) && (b?.priority !== -1)) return Move.ARight;
    if ((b?.priority === -1) && (a?.priority !== -1)) return Move.BRight;
    // If the labels have the same priority then sort them alphabetically
    if (a?.priority === b?.priority) return WorkerSortUtils.compareNumerically(a?.text, b?.text);
    return WorkerSortUtils.numberAscending(a?.priority, b?.priority);
  };

  static sortLabelsForLabelPicker = (a: LabelProperties, b: LabelProperties): Move => {
    // We want the featured label to be at the start of the list, followed by the rest alphabetically
    if ((a?.id === MenuLabel.Featured) && (b?.id !== MenuLabel.Featured)) return Move.ALeft;
    if ((b?.id === MenuLabel.Featured) && (a?.id !== MenuLabel.Featured)) return Move.BLeft;
    return a?.text?.localeCompare(b?.text, 'en', {numeric: true});
  };

  static getSortableProductTitleObject = (product: ProductProperties): any => {
    return {
      id: product?.id,
      title: product?.title
    };
  };

  /**
   * Sort products by title on a background thread, then emit the sorted products as a list of ids.
   */
  static productNameSortWorker(products: Map<string, ProductProperties>, n: number): Observable<string[]> {
    const productData = [...(products?.values() || [])].map(p => WorkerSortUtils.getSortableProductTitleObject(p));
    return new Observable(subscriber => {
      const myWorker = new Worker(
        new URL('./../worker/product-name-sorter.worker', import.meta.url),
        {type: 'module', name: `product-worker-${n}`}
      );
      myWorker.onmessage = result => {
        subscriber.next(result?.data);
        subscriber.complete();
        myWorker.terminate();
      };
      myWorker.postMessage(productData);
    });
  }

  static sortSyncTypes = (a: SyncType, b: SyncType): Move => {
    const getOrderNumber = (syncType: SyncType) => {
      switch (syncType) {
        case SyncType.FullProductInfo:        return 0;
        case SyncType.Product:                return 1;
        case SyncType.Inventory:              return 2;
        case SyncType.LotInfo:                return 3;
        case SyncType.Pricing:                return 4;
        case SyncType.DisplayNames:           return 5;
        case SyncType.SmartFilters:           return 6;
        case SyncType.SmartDisplayAttributes: return 7;
        case SyncType.Promotions:             return 8;
        case SyncType.Labels:                 return 9;
        case SyncType.Location:               return 10;
      }
    };
    const aOrderNumber = getOrderNumber(a);
    const bOrderNumber = getOrderNumber(b);
    return WorkerSortUtils.numberAscending(aOrderNumber, bOrderNumber);
  };

  static sortBulkPrintJobsByMostRecent = (a: BulkPrintJobProperties, b: BulkPrintJobProperties): Move => {
    return WorkerSortUtils.numberDescending(a?.dateCreated, b?.dateCreated);
  };

  static sortPrintStackThemePreviewImages(
    a: AssetProperties,
    b: AssetProperties,
    previewImageToCardSizeMap: Map<string, DefaultPrintStackSize>
  ): Move {
    const aCardSize = previewImageToCardSizeMap?.get(a?.md5Hash);
    const bCardSize = previewImageToCardSizeMap?.get(b?.md5Hash);
    const getOrderNumber = (size: DefaultPrintStackSize) => {
      switch (size) {
        case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4: return 0;
        case DefaultPrintCardSize.DefaultSize_AddressCard:     return 1;
        case DefaultPrintCardSize.DefaultSize_Custom2x2:       return 2;
        case DefaultPrintCardSize.DefaultSize_BusinessCard:    return 3;
        case DefaultPrintCardSize.DefaultSize_IndexCard:       return 4;
        case DefaultPrintCardSize.DefaultSize_PostCard:        return 5;
        case DefaultPrintCardSize.DefaultSize_Custom5x5:       return 6;
      }
    };
    const aOrderNumber = getOrderNumber(aCardSize);
    const bOrderNumber = getOrderNumber(bCardSize);
    if (aOrderNumber === bOrderNumber) return WorkerSortUtils.numberDescending(a?.timestamp, b?.timestamp);
    return WorkerSortUtils.numberAscending(aOrderNumber, bOrderNumber);
  }

  static sortSpecifiedStringLast = (value: string) => (a: string, b: string): Move => {
    const aIsSpecified = a === value;
    const bIsSpecified = b === value;
    if (aIsSpecified && !bIsSpecified) return Move.ARight;
    if (bIsSpecified && !aIsSpecified) return Move.BRight;
    return a.localeCompare(b);
  };

  /**
   * Priority: Private > Custom > Numeric String Asc
   */
  static sortPrintCardSizes(a: string, b: string, privateSizes: string[]): Move {
    const aIsPrivate = privateSizes?.includes(a);
    const bIsPrivate = privateSizes?.includes(b);
    if (aIsPrivate && !bIsPrivate) return Move.ALeft;
    if (bIsPrivate && !aIsPrivate) return Move.BLeft;
    const aIsCustom = a?.includes('Custom');
    const bIsCustom = b?.includes('Custom');
    if (aIsCustom && !bIsCustom) return Move.ALeft;
    if (bIsCustom && !aIsCustom) return Move.BLeft;
    return WorkerSortUtils.numericStringAsc(a, b);
  }

  /**
   * Priority: Private > Numeric String Asc
   */
  static sortThemes(a: ThemeProperties, b: ThemeProperties): Move {
    const aIsPrivate = a?.isPrivateTheme;
    const bIsPrivate = b?.isPrivateTheme;
    if (aIsPrivate && !bIsPrivate) return Move.ALeft;
    if (bIsPrivate && !aIsPrivate) return Move.BLeft;
    return WorkerSortUtils.numericStringAsc(a?.name, b?.name);
  }

  static sortOutOfStockToEnd = (a: VariantProperties, b: VariantProperties): Move => {
    const aIsOutOfStock = !a?.inventory?.quantityInStock;
    const bIsOutOfStock = !b?.inventory?.quantityInStock;
    if (aIsOutOfStock && !bIsOutOfStock) return Move.ARight;
    if (bIsOutOfStock && !aIsOutOfStock) return Move.BRight;
    return Move.Nothing;
  };

  static productTableColumnSorting(a: string, b: string): Move {
    const getProductTableColumnOrderNumber = (colName: string) => {
      switch (colName) {
        case 'name': return 0;
        case 'brand': return 1;
        case 'producttype': return 2;
        case 'straintype': return 3;
        case 'quantity': return 4;
        case 'thc': return 5;
        case 'cbd': return 6;
        case 'tac': return 7;
        case 'cannabinoid': return 8;
        case 'totalterpene': return 9;
        case 'topterpene': return 10;
        case 'terpene': return 11;
        case 'price': return 12;
        case 'secondaryprice': return 13;
        case 'label': return 14;
        case 'badge': return 15;
        default: return 100;
      }
    };
    const aColOrderNum = getProductTableColumnOrderNumber(a);
    const bColOrderNum = getProductTableColumnOrderNumber(b);
    return WorkerSortUtils.numberAscending(aColOrderNum, bColOrderNum);
  }

}
