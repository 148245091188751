import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-horizontal-scrolling-reactive-table',
  templateUrl: './horizontal-scrolling-reactive-table.component.html',
  styleUrls: ['./horizontal-scrolling-reactive-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrollingReactiveTableComponent extends ReactiveTableComponent {

  @Input() makeTableElementVerticallyScrollable: boolean = false;
  @Input() addExtraSpaceToBottomOfTableInRem: number;
  @Input() set showLoadingStateAndSetTableDataToHidden(value: boolean) {
    if (value !== undefined && value !== null) this._showLoadingStateAndSetTableDataToHidden.next(value);
  }
  @Input() set tableColumnsChanged(value: boolean) {
    if (value !== undefined && value !== null) this._tableColumnsChanged.next(value);
  }

  protected readonly _showLoadingStateAndSetTableDataToHidden = new BehaviorSubject<boolean>(false);
  public readonly showLoadingStateAndSetTableDataToHidden$ =
    this._showLoadingStateAndSetTableDataToHidden as Observable<boolean>;

  protected readonly _tableColumnsChanged = new BehaviorSubject<boolean>(false);
  public readonly tableColumnsChanged$ = this._tableColumnsChanged as Observable<boolean>;

  public readonly loading$: Observable<boolean> = combineLatest([
    this.loadingData$,
    this.showLoadingStateAndSetTableDataToHidden$,
    this.tableColumnsChanged$
  ]).pipe(
    map(([loadingData, showLoadingStateAndSetTableDataToHidden, tableColumnsChanged]) => {
      return loadingData || showLoadingStateAndSetTableDataToHidden || tableColumnsChanged;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
