<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-group
  #formGroupComponent
  [mergeKey]="'manageLabelSmartFilters'"
  [dispersedKey]="'manageLabelSmartFilters'"
  [bindTo]="viewModel.label$ | async"
  (formSubmitted$)="viewModel.saveSmartLabelChanges()">
</lib-reactive-form-group>

<div class="modal-body sticky">
  <div
    #modalHeader
    libStickyAutoPosition
    [stickyCollectionKey]="'manageLabelSmartFilters'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container add-new-label-header">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">Manage Label Smart Filters</div>
    </div>
    <hr class="no-margin" />
  </div>

  <div #contentContainer class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="label-preview-container mt-4">
      <app-label-inflator
        [zoom]="1"
        [displayLabelInterface]="
          viewModel.label$
            | async
            | getDisplayLabelInterfaceForManageLabelSmartFilters : viewModel.displayLabelData$
            | async
        ">
      </app-label-inflator>
    </div>

    <app-reactive-form-smart-filter-list
      #smartFilters
      class="smart-filter-list pt-12px"
      [dispersedKey]="'manageLabelSmartFilters'"
      [inputName]="'smartFilterIds'"
      [bindingProperty]="'smartFilterIds'">
    </app-reactive-form-smart-filter-list>

    <lib-reactive-form-switch
      class="remove-existing-products-switch"
      [class.disabled]="!(smartFilters.smartFilterIds$ | async)?.length"
      [disabled]="!(smartFilters.smartFilterIds$ | async)?.length"
      [dispersedKey]="'manageLabelSmartFilters'"
      [inputName]="'removeExistingProductsSwitch'"
      [bindingProperty]="'removeExistingOnSync'"
      [labelOnRight]="true"
      [label]="'Remove from existing products'"
      [programmaticallyChangeValue]="!(smartFilters.smartFilterIds$ | async)?.length ? false : undefined"
      [tooltip]="
        smartFilters.smartFilterIds$ | async | getRemoveFromExistingProductsTooltip : ClientTypes.SmartDataType.Labels
      ">
    </lib-reactive-form-switch>
  </div>

  <div
    #footerContainer
    class="sticky-footer-container modal-footer-flex-end"
    [style.padding-top.rem]="1"
    [style.z-index]="viewModel.stickyZIndex$ | async">
    <div class="button-holder">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary
        [disabled]="formGroupComponent.disableSubmit$ | async"
        (buttonClicked)="formGroupComponent.submitForm()">
        Done
      </lib-button-primary>
    </div>
  </div>
</div>
