<div class="modal-padding">
  <div class="terpene-title">
    <span class="text"> Primary Terpene Info </span>
  </div>
  <app-terpene-min-max-form
    [smartFilter]="viewModel.smartFilter$ | push"
    [terpene]="viewModel.totalTerpeneInfo$ | push"
    [dispersedKey]="dispersedKey"
    [isViewOnly]="viewModel.isViewOnly$ | push"></app-terpene-min-max-form>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-pill-drop-down
      [inputName]="'topTerpene'"
      [label]="'Top Terpene'"
      [placeholder]="'Select a top terpene'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [tooltip]="'The smart filter will include products with a top terpene set here.'"
      [dropdowns]="viewModel.enabledTerpenes$ | push"
      [delimitedPillBackgroundColors]="viewModel.topTerpeneDisabledBackgroundColors$ | push"
      [delimitedPillTextColors]="viewModel.topTerpeneDisabledTextColors$ | push"
      [delimitedPillTooltips]="viewModel.topTerpeneDisabledTooltips$ | push"
      [clearable]="true"
      [submitAsDelimitedString]="true"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'topTerpene'"
      [dispersedKey]="dispersedKey"
      [limitNumberOfSelectedItemsTo]="1">
    </lib-reactive-form-pill-drop-down>

    <lib-reactive-form-pill-drop-down
      [inputName]="'presentTerpenes'"
      [label]="'Present Terpenes'"
      [emptyPlaceholder]="'Enter present terpenes'"
      [placeholder]="'Enter present terpenes'"
      [dropdowns]="viewModel.enabledTerpenes$ | push"
      [addToAtLeastNItemsFilledInPool]="true"
      [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
      [delimitedPillTextColors]="viewModel.terpenePillTextColors$ | push"
      [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
      [tooltip]="'If any of the Present Terpenes exist on a product, it will be considered a match.'"
      [bindingProperty]="'presentTerpenes'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-pill-drop-down>
  </lib-reactive-form-column-layout>

  <div *ngIf="viewModel.hasEnabledTerpenes$ | async" class="terpene-title">
    <span class="text"> Individual Terpenes </span>
  </div>
  <ng-container *rxFor="let terpene of viewModel.terpenes$ | push">
    <app-terpene-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [terpene]="terpene"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-terpene-min-max-form>
  </ng-container>
</div>
