<app-loading *ngIf="container.isLoading$ | async" [options]="container.loadingOpts$ | async"> </app-loading>

<div class="modal-body sticky">
  <div
    class="sticky-header-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'new-menu-section-modal'"
    [stickyOrder]="0"
    [style.overflow-y]="'unset'">
    <div class="modal-header">
      <div class="modal-title">
        {{ container.modalTitle$ | async }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container" [style.overflow-y]="'unset'">
    <app-section-creation-method
      *ngIf="container?.creationStepIsHowToCreate$ | async"
      (selectedCreationType)="setSectionCreationType($event)"
      [paddingLeft]="'0'"
      [paddingRight]="'0'"></app-section-creation-method>

    <h5 *ngIf="container.creationStepIsNewSection$ | async" class="mt-3 mb-3"><b>Section Info</b></h5>
    <!-- Modal Body -->
    <lib-reactive-form-group
      #newSectionForm
      [bindTo]="container.newSectionRequest$ | async"
      [dispersedKey]="container.dispersedKey$ | async"
      (canSubmit$)="container.connectToFormIsValid($event)"
      (hasErrors$)="container.connectToFormHasErrors($event)"
      (formSubmitted$)="addSection()">
      <app-new-menu-section-form
        *ngIf="container.creationStepIsNewSection$ | async"
        [dispersedKey]="container.dispersedKey$ | async"
        [isDuplicating]="container.isDuplicating$ | async"
        [menuTheme]="container.menuTheme$ | async"
        [sectionTypes]="container.sectionTypes$ | async"
        [columnOptions]="container.columnOptions$ | async"
        [selectedSectionType]="container.newSectionType$ | async"></app-new-menu-section-form>
    </lib-reactive-form-group>
  </div>

  <app-searchable-single-select
    [hidden]="!(container.creationStepIsSelectBlueprint$ | async)"
    [searchableGroupedItems]="container.productBlueprintsGroupedByCategory$ | async"
    [noItemsBody]="'No Blueprints'"
    [noItemsTitle]="'Blueprints will appear here'"
    [stickyCollectionKey]="'new-menu-section-modal'"
    [stickyOrder]="1"
    [stickyZ]="9"
    (selectedItem)="container.setSelectedBlueprint($event)"></app-searchable-single-select>
</div>

<div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 1rem">
  <div>
    <lib-button-text [hidden]="container.hideGoBack$ | async" (buttonClicked)="goBack()"> Go Back </lib-button-text>
  </div>
  <div [style.margin-left]="'auto'">
    <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary
      *ngIf="!(container.creationStepIsHowToCreate$ | async)"
      [style.margin-left]="'1rem'"
      [disabled]="!(container.canSubmitForm$ | async)"
      (buttonClicked)="newSectionForm.submitForm()">
      Add Section
    </lib-button-primary>
  </div>
</div>
