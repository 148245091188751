import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { PosProductCategoryProperties } from '../../protocols/pos-product-category-properties';

export class PosProductCategory implements Deserializable, UniquelyIdentifiable, PosProductCategoryProperties {

  public categoryId: string;
  public categoryName: string;
  public parentCategoryId: string;
  public parentCategoryName: string;
  public treeId: string;
  public productType: string;
  public variantType: string;
  // Unique identifier for the category that is set on deserialize
  public uniqueEntityIdentifier: string;

  onDeserialize() {
    this.uniqueEntityIdentifier = `${this.categoryId}-${this.treeId}`;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.categoryId}
      -${this.categoryName}
      -${this.parentCategoryId}
      -${this.parentCategoryName}
      -${this.treeId}
      -${this.productType}
      -${this.variantType}
    `;
  }

}
