<div class="modal-body" [style.padding]="0">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">Custom Visible Columns</div>
      <br />
      <div>Select the columns you want to view in this table.</div>
      <br />
      <div>
        These changes will only be applied throughout this session. Next session this table will be reset to the default
        columns for the company.
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mt-4">
    <lib-reactive-form-group
      #customColumnForm
      [bindTo]="viewModel.customProductTableColumnConfig$ | push"
      [atLeastNItemsFilledIn]="1"
      (formChanged$)="viewModel.formChanged($event)"
      (formSubmitted$)="saveCustomProductTableColumnConfig()">
      <div class="column-switches-container">
        <div class="bs-bold f14px">Product Info</div>
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-switch
            [inputName]="'name'"
            [label]="'Name'"
            [bindingProperty]="'name'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'brand'"
            [label]="'Brand'"
            [bindingProperty]="'brand'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'productType'"
            [label]="'Product Type'"
            [bindingProperty]="'productType'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'strainType'"
            [label]="'Strain Type'"
            [bindingProperty]="'strainType'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'quantity'"
            [label]="'Quantity'"
            [bindingProperty]="'quantity'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'price'"
            [label]="'Price'"
            [bindingProperty]="'price'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'secondaryPrice'"
            [label]="'Secondary Price'"
            [bindingProperty]="'secondaryPrice'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'label'"
            [label]="'Label'"
            [bindingProperty]="'label'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'badge'"
            [label]="'Badge'"
            [bindingProperty]="'badge'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'THC'"
            [label]="'THC'"
            [bindingProperty]="'THC'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'CBD'"
            [label]="'CBD'"
            [bindingProperty]="'CBD'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'TAC'"
            [label]="'TAC'"
            [bindingProperty]="'TAC'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'totalTerpene'"
            [label]="'Total Terpene'"
            [bindingProperty]="'totalTerpene'"
            [labelOnRight]="true"></lib-reactive-form-switch>

          <lib-reactive-form-switch
            [inputName]="'topTerpene'"
            [label]="'Top Terpene'"
            [bindingProperty]="'topTerpene'"
            [labelOnRight]="true"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </div>
      <div
        *ngIf="(viewModel.enabledCannabinoids$ | async)?.length > 0"
        class="column-switches-container"
        [style.margin-top.rem]="1.25">
        <div class="bs-bold f14px">Cannabinoids</div>
        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-switch
            *rxFor="let cannabinoid of viewModel.enabledCannabinoids$ | push"
            [inputName]="cannabinoid"
            [label]="cannabinoid"
            [bindingProperty]="cannabinoid"
            [labelOnRight]="true"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </div>
      <div
        *ngIf="(viewModel.enabledTerpenes$ | async)?.length > 0"
        class="column-switches-container"
        [style.margin-top.rem]="1.25">
        <div class="bs-bold f14px">Terpenes</div>

        <lib-reactive-form-column-layout nColumns="2">
          <lib-reactive-form-switch
            *rxFor="let terpene of viewModel.enabledTerpenes$ | push"
            [inputName]="terpene?.accessor"
            [label]="terpene?.name"
            [bindingProperty]="terpene?.accessor"
            [labelOnRight]="true"></lib-reactive-form-switch>
        </lib-reactive-form-column-layout>
      </div>
    </lib-reactive-form-group>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end mt-3">
    <div class="button-container">
      <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
      <lib-button-primary [disabled]="!(viewModel.canSubmit$ | push)" (buttonClicked)="customColumnForm.submitForm()">
        Save Changes
      </lib-button-primary>
    </div>
  </div>
</div>
