<div [hidden]="true">
  <ng-content></ng-content>
  <ng-container #headerDuplicator></ng-container>
  <ng-container #rowDuplicator></ng-container>
  <ng-container #detachedItems></ng-container>
  <ng-container #loadingDuplicator></ng-container>
  <ng-container #emptyStateDuplicator></ng-container>
  <ng-container #paginatorDuplicator></ng-container>
</div>
<div #tableContainer class="reactive-table-container">
  <div class="reactive-table">
    <table
      [style.min-width.%]="100"
      [style.table-layout]="layoutType"
      [style.overflow-y]="makeTableElementVerticallyScrollable ? 'scroll' : null">
      <ng-container #headerContainer></ng-container>
      <tbody [hidden]="loading$ | push : 'immediate'">
        <ng-container #bodyContainer></ng-container>
      </tbody>
    </table>
    <div *ngIf="addExtraSpaceToBottomOfTableInRem" [style.height.rem]="addExtraSpaceToBottomOfTableInRem"></div>
  </div>
  <div [hidden]="!(loading$ | push : 'immediate')">
    <ng-container #loadingStateContainer></ng-container>
  </div>
  <div
    [hidden]="
      (thereIsDataToDisplay$ | push : 'immediate') ||
      (showLoadingStateAndSetTableDataToHidden$ | push : 'immediate') ||
      (tableColumnsChanged$ | push : 'immediate')
    ">
    <ng-container #emptyStateContainer></ng-container>
  </div>
  <div [hidden]="(nItemsToDisplay$ | push : 'immediate') <= 0">
    <ng-container #paginationContainer></ng-container>
  </div>
</div>
