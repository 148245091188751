import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-popper-terpene-preview-list-item',
  templateUrl: './popper-terpene-preview-list-item.component.html',
  styleUrls: [
    '../../menu-section-product-preview.component.scss',
    './popper-terpene-preview-list-item.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopperTerpenePreviewListItemComponent {

  @Input() terpeneTitle: string;
  @Input() terpeneValue: string;

}
