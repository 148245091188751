import { Pipe, PipeTransform } from '@angular/core';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'variantMethod'
})
export class VariantMethodPipe implements PipeTransform {

  transform(variant: Variant, method: string, ...args: any[]): any {
    return variant?.[method]?.(...args);
  }

}
