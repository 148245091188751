import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductMinTotalTerpeneWithUnits'
})
export class GetProductMinTotalTerpeneWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): string | null {
    return product?.getMinTotalTerpeneWithUnits(true);
  }

}
