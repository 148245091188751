// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { ProductDomainModel } from '../../../../../../../domainModels/product-domain-model';
import { HydratedSmartFilter } from '../../../../../../../models/automation/hydrated-smart-filter';
import { SmartFiltersDomainModel } from '../../../../../../../domainModels/smart-filters-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { SmartFilterUtils } from '../../../../../../../utils/smart-filter-utils';
import { ConfirmationOptions } from '../../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../../modals/modal-confirmation';
import { UserDomainModel } from '../../../../../../../domainModels/user-domain-model';
import { StringifyUtils } from '../../../../../../../utils/stringify-utils';
import { fromWorker } from 'observable-webworker';
import { SmartFilterWorkerInput, SmartFilterWorkerOutput } from '../../../../../../../worker/smart-filter-variant-matcher.worker';
import { exists } from '../../../../../../../functions/exists';
import { iiif } from '../../../../../../../utils/observable.extensions';

export const BUDSENSE_CURATED_ID = -1;

@Injectable()
export class AddEditSmartFilterViewModel extends BaseViewModel {

  constructor(
    protected activeModal: NgbActiveModal,
    private userDomainModel: UserDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel,
    private smartFilterDomainModel: SmartFiltersDomainModel,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private injector: Injector,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private readonly isCompanyAdmin$ = this.userDomainModel.isCompanyAdmin$;
  private readonly locationId$ = this.locationDomainModel.locationId$;

  // Edit Form
  public priceFormat$ = this.locationDomainModel.priceFormat$;
  private _existingSmartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  connectToExistingSmartFilter = (existingSmartFilter: HydratedSmartFilter) => {
    this._existingSmartFilter.next(existingSmartFilter);
    this.connectToIgnoredVariantIds(existingSmartFilter?.ignoredVariantIds);
  };

  private _variantVisibilityToggled = new BehaviorSubject<boolean>(false);
  public variantVisibilityToggled$ = this._variantVisibilityToggled as Observable<boolean>;
  connectToVariantVisibilityToggled = (toggled: boolean) => this._variantVisibilityToggled.next(toggled);

  private readonly _hasErrors = new BehaviorSubject<boolean>(true);
  public readonly hasErrors$ = this._hasErrors as Observable<boolean>;
  connectToHasErrors = (hasErrors: boolean) => this._hasErrors.next(hasErrors);

  private _canSubmitForm = new BehaviorSubject<boolean>(false);
  public canSubmitForm$ = this._canSubmitForm as Observable<boolean>;
  connectToCanSubmit = (canSubmit: boolean) => this._canSubmitForm.next(canSubmit);

  private _hydrateObject = new BehaviorSubject<boolean>(false);
  public hydrateObject$ = this._hydrateObject as Observable<boolean>;

  // Opened From
  public _openedFrom = new BehaviorSubject<AddEditSmartFilterModalOpenedFrom>(null);
  public openedFrom$ = this._openedFrom as Observable<AddEditSmartFilterModalOpenedFrom>;
  connectToOpenedFrom = (openedFrom: AddEditSmartFilterModalOpenedFrom) => this._openedFrom.next(openedFrom);

  public contextRequiresCompanySmartFilter$ = this.openedFrom$.pipe(
    map(openedFrom => SmartFilterUtils.contextRequiresCompanySmartFilter(openedFrom))
  );

  public isOpenedFromSectionEdit$ = this.openedFrom$.pipe(
    map(openedFrom => openedFrom === AddEditSmartFilterModalOpenedFrom.SectionEdit)
  );

  public isOpenedFromTemplatedSectionFlow$ = this.openedFrom$.pipe(
    map(openedFrom => {
      return openedFrom === AddEditSmartFilterModalOpenedFrom.TemplatedSectionEdit;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _smartFilterReq = new BehaviorSubject<HydratedSmartFilter>(new HydratedSmartFilter());
  public readonly generateSmartFilterReq$ = combineLatest([
    this._smartFilterReq,
    this.companyDomainModel.companyId$,
    this.openedFrom$
  ]).pipe(
    map(([smartFilterReq, companyId, openedFrom]) => {
      if (SmartFilterUtils.contextRequiresCompanySmartFilter(openedFrom)) {
        smartFilterReq.locationId = companyId;
      }
      return smartFilterReq;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _isCreating = new BehaviorSubject<boolean>(false);
  public isCreating$ = this._isCreating as Observable<boolean>;
  connectToIsCreating = (_isCreating: boolean) => this._isCreating.next(_isCreating);

  private _isEditing = new BehaviorSubject<boolean>(false);
  public isEditing$ = this._isEditing as Observable<boolean>;
  connectToIsEditing = (isEditing: boolean) => this._isEditing.next(isEditing);

  public readonly isSmartFilterEmpty$ = iiif(
    this.isEditing$,
    this._existingSmartFilter,
    this.generateSmartFilterReq$
  ).pipe(
    map(smartFilter => smartFilter?.isEmpty() || false),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly smartFilterWorkerMessage$ = combineLatest([
    iiif(this.isEditing$, this._existingSmartFilter, this._smartFilterReq),
    this.smartFilterDomainModel.stringifiedVariantSourceForSmartFilterMatching$,
    this.locationId$.pipe(distinctUntilChanged()),
    this.companyDomainModel.companyConfiguration$,
    this.companyDomainModel.posSupportsIndividualTerpeneValues$
  ]).pipe(
    debounceTime(100),
    filter(([smartFilter]) => exists(smartFilter)),
    map(([smartFilter, locVariantsStringified, locationId, companyConfig, supportsIndividualTerpeneValues]) => {
      // Set the containsEnabledProperties flag to determine if the smart filter matcher should skip this filter
      smartFilter.containsEnabledProperties = smartFilter?.hasEnabledPropertySet(
        companyConfig?.enabledCannabinoids,
        companyConfig?.enabledTerpenes
      );
      const stringify = (str: any) => JSON.stringify(str, StringifyUtils.webWorkerReplacer);
      return {
        groupingId: 'Add/Edit Smart Filter',
        smartFilters: !smartFilter ? null : stringify([smartFilter]),
        locationVariants: locVariantsStringified,
        locationId: !locationId ? null : locationId,
        companyConfig: !companyConfig ? null : stringify(companyConfig),
        supportsIndividualTerpeneValues
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly existingSmartFilterWithVariants$ = this.buildWorkerPipeline$(
    'Edit Smart Filter Matcher',
    'Edit Smart Filter',
    this._existingSmartFilter
  );

  public readonly existingSmartFilter$ = iiif(
    this.isCreating$,
    of(null),
    merge(this._existingSmartFilter, this.existingSmartFilterWithVariants$)
  ).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly createAndAttachMatchingVariants$ = this.buildWorkerPipeline$(
    'Create Smart Filter Matcher',
    'Create Smart Filter',
    this.generateSmartFilterReq$
  );

  public readonly smartFilterReq$ = merge(this.generateSmartFilterReq$, this.createAndAttachMatchingVariants$).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected buildWorkerPipeline$(
    name: string,
    groupingId: string,
    smartFilterInput$: Observable<HydratedSmartFilter>
  ): Observable<HydratedSmartFilter> {
    return fromWorker<SmartFilterWorkerInput, SmartFilterWorkerOutput>(
      () => new Worker(
        new URL('./../../../../../../../worker/smart-filter-variant-matcher.worker', import.meta.url),
        { name, type: 'module' }
      ),
      this.smartFilterWorkerMessage$
    ).pipe(
      startWith({
        groupingId,
        smartFilterMatches: []
      }),
      withLatestFrom(smartFilterInput$),
      filter(([_, smartFilter]) => exists(smartFilter)),
      map(([{ smartFilterMatches }, smartFilter]) => {
        const match = smartFilterMatches?.firstOrNull();
        const inStockVariantIds = match?.inStockVariantIds?.filterFalsies();
        const outOfStockVariantIds = match?.outOfStockVariantIds?.filterFalsies();
        return smartFilter?.setSmartFilterVariantMatchesForEditedSmartFilter(inStockVariantIds, outOfStockVariantIds);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.onDestroy)
    );
  }

  public isCuratedSmartFilter$ = this.existingSmartFilter$.pipe(
    map(sf => sf?.locationId === BUDSENSE_CURATED_ID),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * If the smart filter is curated, the user is not an admin, or the section is templated
   * the user should be restricted 'View Only'
   */
  public isViewOnly$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      return curatedSF || !isAdminUser || openedFromTemplatedSection;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public viewOnlyBanner$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$,
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      if (openedFromTemplatedSection) {
        return 'The current Smart Filter is in View Only mode because it is applied to a templated section. To '
          + 'modify the smart filter, do so from Edit Template or Settings.';
      } else if (curatedSF) {
        return 'The current Smart Filter is in View Only mode as it is a BudSense curated smart filter. In order '
          + 'to modify the smart filter, create a new one with the same criteria.';
      } else if (!isAdminUser) {
        return 'The current Smart Filter is in View Only mode as only company admins can create/edit Smart Filters.';
      } else {
        return null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public viewOnlyTooltip$ = combineLatest([
    this.isCuratedSmartFilter$,
    this.isCompanyAdmin$,
    this.isOpenedFromTemplatedSectionFlow$,
  ]).pipe(
    map(([curatedSF, isAdminUser, openedFromTemplatedSection]) => {
      if (openedFromTemplatedSection) {
        return 'Smart Filters applied to templated menus most be edited on the template.';
      } else if (curatedSF) {
        return 'Curated Smart Filters can not be edited. Create a new smart filter to edit.';
      } else if (!isAdminUser) {
        return 'Smart Filters can only be updated by Admin users';
      } else {
        return null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public modalTitle$ = combineLatest([
    this.isEditing$,
    this.isViewOnly$
  ]).pipe(
    map(([isEditing, isViewOnly]) => {
      if (isEditing && !isViewOnly) {
        return 'Edit Smart Filter';
      } else if (isViewOnly) {
        return 'View Smart Filter';
      } else {
        return 'Add Smart Filter';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Variants
  public smartFilterVariants$ = defer(() => {
    return combineLatest([
      iiif(this.isEditing$, this.existingSmartFilter$, this.smartFilterReq$),
      this.productDomainModel.currentLocationVariants$,
    ]).pipe(
      filter(([smartFilter, vars]) => exists(smartFilter?.appliedVariantIdsForAddEditSmartFilter) && exists(vars)),
      debounceTime(100),
      map(([smartFilter, locationVariants]) => {
        if (!smartFilter || !locationVariants || locationVariants?.length <= 0) {
          return this._isCreating?.value ? [] : null;
        }
        // Only include variants with IDs that are on the Smart Filter option
        const smartFilterVariants = locationVariants
          ?.filter(v => smartFilter?.appliedVariantIdsForAddEditSmartFilter?.indexOf(v.id) !== -1);
        const sortedVariantIds = smartFilter?.appliedVariantIdsForAddEditSmartFilter;
        // Ensuring that list of hydrated variants matches the same order as smart filter applied variant Ids
        smartFilterVariants.sort((a, b) => sortedVariantIds?.indexOf(a?.id) - sortedVariantIds?.indexOf(b?.id));
        return smartFilterVariants;
      }),
      startWith(this._isCreating?.value ? [] : null)
    );
  }).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public hideSmartFilterVariantTable$ = this.smartFilterVariants$.pipe(
    map((smartFilterVariants) => smartFilterVariants?.length <= 0)
  );

  private _ignoredVariantIds = new BehaviorSubject<string[]>([]);
  public ignoredVariantIds$ = this._ignoredVariantIds as Observable<string[]>;
  connectToIgnoredVariantIds = (variantIds: string[]) => this._ignoredVariantIds.next(variantIds);

  // Accordion

  private _openProductAccordion = new BehaviorSubject<boolean>(false);
  public openProductAccordion$ = this._openProductAccordion as Observable<boolean>;

  private _openCannabinoidAccordion = new BehaviorSubject<boolean>(false);
  public openCannabinoidAccordion$ = this._openCannabinoidAccordion as Observable<boolean>;

  private _openTerpeneAccordion = new BehaviorSubject<boolean>(false);
  public openTerpeneAccordion$ = this._openTerpeneAccordion as Observable<boolean>;

  private _openAdvancedAccordion = new BehaviorSubject<boolean>(false);
  public openAdvancedAccordion$ = this._openAdvancedAccordion as Observable<boolean>;

  public ctaText$ = combineLatest([
    this.openedFrom$,
    this.isEditing$
  ]).pipe(
    map(([openedFrom, isEditing]) => {
      switch (openedFrom) {
        case AddEditSmartFilterModalOpenedFrom.Products:
          return 'Add Filter';
        case AddEditSmartFilterModalOpenedFrom.SectionCreate:
          return 'Add Smart Filter';
        case AddEditSmartFilterModalOpenedFrom.SectionEdit:
          return 'Save Changes';
        case AddEditSmartFilterModalOpenedFrom.Settings: {
          if (isEditing) {
            return 'Save Changes';
          } else {
            return 'Create Smart Filter';
          }
        }
        default:
          return 'Save Changes';
      }
    })
  );

  public readonly canSubmit$ = combineLatest([
    this.canSubmitForm$,
    this.variantVisibilityToggled$,
    this.hasErrors$
  ]).pipe(
    map(([canSubmit, variantVisibilityToggled, hasErrors]) => {
      return canSubmit || (variantVisibilityToggled && !hasErrors);
    }),
    distinctUntilChanged()
  );

  public disableSubmitButton$ = this.canSubmit$.pipe(map(canSubmit => !canSubmit));

  public showDeleteSmartFilterCTA$ = combineLatest([
    this.isEditing$,
    this.isViewOnly$,
    this.isOpenedFromSectionEdit$
  ]).pipe(
    map(([isEditing, isViewOnly, isOpenedFromSectionEdit]) => {
      return isEditing && !isViewOnly && !isOpenedFromSectionEdit;
    })
  );

  listenForProperties = this.isEditing$.pipe(
    filter((isEditing) => isEditing),
    switchMap(() => {
      return combineLatest([
        this.existingSmartFilter$.notNull(),
        this.companyDomainModel.companyConfiguration$.notNull(),
      ]);
    })
  ).subscribeWhileAlive({
    owner: this,
    next: ([sf, cc]) => {
      if (sf?.hasProductPropertySet()) this._openProductAccordion.next(true);
      if (sf?.shouldAutoExpandCannabinoidProperties(cc?.enabledCannabinoids)) this._openCannabinoidAccordion.next(true);
      if (sf?.shouldAutoExpandTerpeneProperties(cc?.enabledTerpenes)) this._openTerpeneAccordion.next(true);
      if (sf?.hasAdvancedPropertySet()) this._openAdvancedAccordion.next(true);
    }
  });

  saveChanges() {
    this.existingSmartFilter$.once(existingSmartFilter => {
      this.createOrUpdateSmartFilter(existingSmartFilter);
    });
  }

  private createOrUpdateSmartFilter(existingSmartFilter: HydratedSmartFilter) {
    combineLatest([
      this.smartFilterReq$,
      this.ignoredVariantIds$,
      this.isEditing$
    ]).pipe(
      take(1),
      switchMap(([smartFilterReq, updatedSmartFilterIgnoredVariantIds, isEditing]) => {
        this.addLoadingReq();
        isEditing
          ? existingSmartFilter.ignoredVariantIds = updatedSmartFilterIgnoredVariantIds?.shallowCopy()
          : smartFilterReq.ignoredVariantIds = updatedSmartFilterIgnoredVariantIds?.shallowCopy();
        const updatePipe$ = defer(() => this.smartFilterDomainModel.updateSmartFilter(existingSmartFilter));
        const createPipe$ = defer(() => this.smartFilterDomainModel.createSmartFilter(smartFilterReq));
        return isEditing ? updatePipe$ : createPipe$;
      }),
      take(1)
    ).subscribe({
      next: (returnedSmartFilter) => {
        this._variantVisibilityToggled.next(false);
        this.removeLoadingReq();
        this.publishToastMessage();
        this.activeModal.close(returnedSmartFilter);
      },
      error: (err) => {
        this.removeLoadingReq();
        this.toastService.publishErrorMessage(err, 'Error');
      }
    });
  }

  showDeleteModal() {
    this.existingSmartFilter$.once(sf => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Smart Filter';
      opts.bodyText = `Deleting a Smart Filter will remove it from any menus that it's been applied to `
        + `across all company locations.\n\n`
        + `This action cannot be undone.\n\n`
        + `Are you sure you want to delete '${sf.name}'?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont: boolean) => {
        if (cont) {
          this.deleteSmartFilter(sf);
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  deleteSmartFilter(sf: HydratedSmartFilter) {
    const lm = 'Deleting your Smart Filter';
    this._loadingOpts.addRequest(lm);
    this.smartFilterDomainModel.deleteSmartFilter(sf).subscribe({
      next: _ => {
        this.toastService.publishBannerSuccess('Your Smart Filter was deleted');
        this._loadingOpts.removeRequest(lm);
        this.activeModal.close();
      },
      error: err => {
        this.toastService.publishBannerFailed('There was an error deleting your Smart Filter');
        this._loadingOpts.removeRequest(lm);
      }
    });
  }

  private readonly _regenerateSmartFilter = new Subject<void>();
  regenerateSmartFilter(allPristine$: Observable<boolean>): void {
    allPristine$?.once(allPristine => !allPristine && this._regenerateSmartFilter.next());
  }
  private readonly listenForRegeneration = this._regenerateSmartFilter.pipe(
    debounceTime(100)
  ).subscribeWhileAlive({
    owner: this,
    next: _ => this._hydrateObject.next(true)
  });

  setFormObject(hydratedFilters: HydratedSmartFilter[]) {
    this.isEditing$.once(isEditing => {
      isEditing
        ? this._existingSmartFilter.next(hydratedFilters?.firstOrNull())
        : this._smartFilterReq.next(hydratedFilters?.firstOrNull());
      this._hydrateObject.next(false);
    });
  }

  addLoadingReq() {
    this.isEditing$.once(isEditing => {
      const lm = isEditing ? 'Saving Changes' : 'Creating Smart Filter';
      this._loadingOpts.addRequest(lm);
    });
  }

  removeLoadingReq() {
    this.isEditing$.once(isEditing => {
      const lm = isEditing ? 'Saving Changes' : 'Creating Smart Filter';
      this._loadingOpts.removeRequest(lm);
    });
  }

  publishToastMessage() {
    this.isEditing$.once(isEditing => {
      const successMsg = isEditing ? 'Your smart filter was updated' : 'Your smart filter was created';
      this.toastService.publishSuccessMessage(successMsg, 'Success!');
    });
  }

}
