import { Injectable, Injector } from '@angular/core';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { ToastService } from '../../../services/toast-service';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { SectionColumnConfig } from '../../../models/menu/dto/section-column-config';
import { HydratedSection } from '../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { SortUtils } from '../../../utils/sort-utils';
import { SectionLayoutType } from '../../../models/utils/dto/section-layout-type';
import { ResetColumnConfigMode } from 'src/app/models/enum/dto/reset-column-config-mode.enum';
import { DefaultSectionColumnConfig } from '../../../models/menu/dto/default-section-column-config';
import { Theme } from '../../../models/menu/dto/theme';
import { ConfirmationOptions } from 'src/app/models/shared/stylesheet/confirmation-options';
import { CannabinoidsAndTerpenesDomainModel } from '../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { exists } from '../../../functions/exists';
import { BsError } from '../../../models/shared/bs-error';
import { ModalConfirmation } from '../../../modals/modal-confirmation';
import { SectionTemplate } from '../../../models/template/dto/section-template';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { SectionColumnConfigKey, SectionColumnConfigProductInfoKey } from '../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigDefaultState } from '../../../models/enum/dto/section-column-config-default-state';
import { SectionType } from '../../../models/enum/dto/section-type';
import { SectionColumnConfigKeyType } from '../../../models/utils/dto/section-column-config-key-type';
import { StringUtils } from '../../../utils/string-utils';
import { ThemeSectionColumnConfig } from '../../../models/menu/dto/theme-section-column-config';

export interface ColumnChanges {
  defaultState: SectionColumnConfigDefaultState;
  columnWidth?: number;
}

interface ResetColumnConfig {
  resetMode: ResetColumnConfigMode;
  defaultColumnConfig: DefaultSectionColumnConfig;
}

type ColumnConfigHelper = {
  key: any,
  name: string,
  config: SectionColumnConfig,
  themeDefaults: ThemeSectionColumnConfig
}

export type ColumnAccordionLabelHelper = {
  defaultState: SectionColumnConfigDefaultState,
  labelStyle: any
}

export enum ColumnConfigDefaultStateLabelColor {
  DisabledLabelColor= '#DADBDE',
  OffLabelColor = '#FA5555',
  AutoLabelColor = '#2C4058',
  OnLabelColor = '#63D58F'
}

export const NAME_COLUMN_DEFAULT_WIDTH = 15;

@Injectable()
export class ColumnOptionsViewModel extends BaseModalViewModel {

  constructor(
    protected toastService: ToastService,
    protected injector: Injector,
    protected menuDomainModel: MenuDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel,
    protected activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public mergeKey = 'column-options-form';
  public enabledSecondaryCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoids$;
  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpenes$;
  public enabledTerpeneNames$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;
  public enabledSecondaryCannabinoidNames$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoidNames$;

  private _formIsValid = new BehaviorSubject<boolean>(false);
  public formIsValid$ = this._formIsValid as Observable<boolean>;
  connectToFormatIsValid = (formIsValid: boolean) => this._formIsValid.next(formIsValid);

  private _unsavedChangesInTab = new BehaviorSubject<boolean>(false);
  public unsavedChangesInTab$ = this._unsavedChangesInTab as Observable<boolean>;
  connectToUnsavedChangesInTab = (unsavedChangesInTab: boolean) => this._unsavedChangesInTab.next(unsavedChangesInTab);

  private _unsavedChangesInModal = new BehaviorSubject<boolean>(false);
  public unsavedChangesInModal$ = this._unsavedChangesInModal as Observable<boolean>;

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;
  connectToSection = (section: HydratedSection) => {
    this._section.next(section);
  };

  public gridColumnCount$ = this.section$.pipe(
    map(section => section?.metadata?.gridColumnNames.split(',').length)
  );

  public message$ = of('By default, the product name column must be at least 15% and will expand '
    + 'to take up any additional available width.');

  private _columnConfigMap = new BehaviorSubject<Map<SectionColumnConfigKey, SectionColumnConfig>>(null);
  public columnConfigMap$ = this._columnConfigMap as Observable<Map<SectionColumnConfigKey, SectionColumnConfig>>;

  private _theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme as Observable<Theme>;
  connectToTheme = (theme: Theme) => this._theme.next(theme);

  public themeColumnConfig$ = this.theme$.pipe(map(theme => theme?.sectionColumnConfig));

  private _managingDefault: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public managingDefault$ = this._managingDefault as Observable<boolean>;
  connectToManagingDefault = (managingDefault: boolean) => this._managingDefault.next(managingDefault);

  private _defaultSectionConfig = new BehaviorSubject<DefaultSectionColumnConfig>(new DefaultSectionColumnConfig());
  public defaultSectionConfig$ = this._defaultSectionConfig as Observable<DefaultSectionColumnConfig>;
  connectToDefaultSectionConfig = (defaultSectionConfig: DefaultSectionColumnConfig) => {
    this._defaultSectionConfig.next(defaultSectionConfig);
  };

  public layoutType$ = this.section$.pipe(map(section => section?.getLayoutType()));

  public columnConfig$ = combineLatest([
    this.section$,
    this.managingDefault$,
    this.defaultSectionConfig$
  ]).pipe(
    map(([section, managingDefault, defaultSectionConfig]) => {
      return managingDefault ? defaultSectionConfig?.columnConfig : section?.columnConfig;
    })
  );

  public columnConfigBeingManaged$ = combineLatest([
    this.columnConfig$,
    window.types.sectionColumnConfigKeys$
  ]).subscribeWhileAlive({
    owner: this,
    next: ([columnConfig, keys]) => {
      const newConfigMap = new Map<SectionColumnConfigKey, SectionColumnConfig>();
      keys?.forEach(k => {
        const key = k?.getSelectionValue();
        if (columnConfig?.has(key)) {
          const config = columnConfig?.get(key);
          newConfigMap.set(key, window?.injector?.Deserialize?.instanceOf(SectionColumnConfig, config));
        } else {
          newConfigMap.set(key, new SectionColumnConfig());
        }
      });
      this._columnConfigMap.next(newConfigMap);
    }
  });

  private _resetSubject = new Subject<ResetColumnConfig>();

  private _currentlySelectedTabIndex = new BehaviorSubject<number>(0);
  public currentlySelectedTabIndex$ = this._currentlySelectedTabIndex as Observable<number>;
  connectToCurrentlySelectedTabIndex = (id: number) => this._currentlySelectedTabIndex.next(id);

  private _previouslySelectedTabIndex = new BehaviorSubject<number>(0);
  public previouslySelectedTabIndex$ = this._previouslySelectedTabIndex as Observable<number>;
  connectToPreviouslySelectedTabIndex = (id: number) => this._previouslySelectedTabIndex.next(id);

  private _columnChanges: BehaviorSubject<Map<SectionColumnConfigKey, ColumnChanges>>
    = new BehaviorSubject<Map<SectionColumnConfigKey, ColumnChanges>>(new Map<SectionColumnConfigKey, ColumnChanges>());
  public columnChanges$ = this._columnChanges as Observable<Map<SectionColumnConfigKey, ColumnChanges>>;
  connectToColumnChanges = (key: SectionColumnConfigKey, changes: ColumnChanges) => {
    this.columnChanges$.once(columnChanges => {
      columnChanges.set(key, changes);
      this._columnChanges.next(columnChanges);
    });
  };

  private trackModalChanges = this.unsavedChangesInTab$.subscribe(unsavedChanges => {
    if (unsavedChanges) {
      this._unsavedChangesInModal.next(true);
    }
  });

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = this._bottomButtonPosition.pipe(distinctUntilChanged());
  connectToBottomButtonPosition = (position: string) => this._bottomButtonPosition.next(position);

  public canChangeTabs$ = combineLatest([this.unsavedChangesInTab$, this.formIsValid$]).pipe(
    map(([hasChanges, formIsValid]) => {
      if (!hasChanges) {
        return true;
      } else {
        return formIsValid;
      }
    })
  );

  public columnsWidthTotal$ = combineLatest([
    this.columnConfigMap$,
    this.themeColumnConfig$,
    this.layoutType$,
    this.gridColumnCount$,
    this.columnChanges$
  ]).pipe(
    map(([
      columnConfigMap,
      themeColumnConfig,
      layoutType,
      gridColumnCount,
      columnChanges
    ]) => {
      let columnWidthTotal = NAME_COLUMN_DEFAULT_WIDTH;
      columnConfigMap?.forEach((columnConfig, key) => {
        const themeDefaults = themeColumnConfig?.get(key);
        let columnState = columnConfig?.defaultState || themeDefaults?.defaultState;
        const changes = columnChanges?.get(key);
        if (exists(changes)) {
          columnState = changes.defaultState;
        }
        const canBeOn = !!columnState
          && columnState !== SectionColumnConfigDefaultState.Disabled
          && columnState !== SectionColumnConfigDefaultState.Off;
        if (canBeOn) {
          let widthToBeAdded = columnConfig?.columnWidth || themeDefaults?.columnWidth;
          if (exists(changes)) {
            widthToBeAdded = changes.columnWidth;
          }
          if (key === SectionColumnConfigProductInfoKey.Price && layoutType?.isGrid()) {
            widthToBeAdded = widthToBeAdded * gridColumnCount;
          }
          if (exists(widthToBeAdded)) columnWidthTotal += widthToBeAdded;
        }
      });
      return columnWidthTotal;
    })
  );

  public columnsWidthTotalError$ = this.columnsWidthTotal$.pipe(
    map(columnsWidthTotal => columnsWidthTotal > 100)
  );

  public columnsWidthTotalErrorMessage$ = this.columnsWidthTotal$.pipe(
    map(columnsWidthTotal => {
      return `All enabled columns, including the name column (15%), add up to ${columnsWidthTotal}% `
        + `of the available space. When all columns are present, the menu will not format properly.`;
    })
  );

  public sectionColumnConfigKeys$ = combineLatest([
    this.section$,
    window?.types?.sectionColumnConfigKeys$,
  ]).pipe(
    map(([section, keys]) => {
      const sectionType = section?.sectionType;
      const sectionTypeNotReportNewProducts = sectionType !== SectionType.NewProducts;
      const sectionTypeNotReportRestockedProducts = sectionType !== SectionType.RestockedProducts;
      let keysToSort = keys;
      if (sectionTypeNotReportNewProducts && sectionTypeNotReportRestockedProducts) {
        keysToSort = keys?.filter(k => !k?.isStockKey());
      }
      return keysToSort?.sort(SortUtils.columnOptionKeySortAsc);
    })
  );

  public tabNames$ = combineLatest([
    this.section$,
    this.sectionColumnConfigKeys$
  ]).pipe(
    map(([section, keys]) => {
      return keys?.map(key => {
        if (key.isCannabinoidKey()) return 'Cannabinoids';
        if (key.isTerpeneKey()) return 'Terpenes';
        return key.getSelectionTitle();
      }).unique();
    })
  );

  private columnSectionKeyType$ = combineLatest([
    this.tabNames$,
    this.sectionColumnConfigKeys$,
    this.currentlySelectedTabIndex$
  ]).pipe(
    map(([tabNames, keys, currentIndex]) => {
      const currentTabName = tabNames[currentIndex];
      switch (currentTabName) {
        case 'Cannabinoids':
        case 'Terpenes':
          return new SectionColumnConfigKeyType(currentTabName, currentTabName);
        default:
          return keys?.find(k => k?.getSelectionTitle() === currentTabName);
      }
    })
  );

  public columnSectionKey$ = this.columnSectionKeyType$.pipe(
    map(keyType => keyType?.getSelectionValue()),
    filter(key => SectionColumnConfigKeyType.isKey(key))
  );

  public columnSectionName$ = this.columnSectionKeyType$.pipe(
    map(keyType => keyType?.getSelectionTitle())
  );

  public canSubmitForm$ = combineLatest([
    this.unsavedChangesInModal$,
    this.canChangeTabs$,
    this.columnsWidthTotalError$,
  ]).pipe(
    map(([unsavedChanges, canChangeTabs, columnWidthTotalError]) => {
      return unsavedChanges && canChangeTabs && !columnWidthTotalError;
    })
  );

  public colorPalette$ = this.menuDomainModel.colorPalette$;

  private columnConfigResetHandler = this._resetSubject.pipe(
    startWith<ResetColumnConfig>(null),
    withLatestFrom(combineLatest([this.columnConfigMap$, this.themeColumnConfig$]))
  ).subscribeWhileAlive({
    owner: this,
    next: ([resetSubject, [columnConfigMap, themeColumnConfig]]) => {
      const resetColumnConfigMode = resetSubject?.resetMode;
      const defaultColumnConfigToSet = resetSubject?.defaultColumnConfig;
      if (!!resetColumnConfigMode) {
        switch (resetColumnConfigMode) {
          case ResetColumnConfigMode.ThemeDefault:
            themeColumnConfig?.forEach((_, key) => columnConfigMap?.set(key, null));
            break;
          case ResetColumnConfigMode.PreDefined:
            const defaultColumnConfigCopy = defaultColumnConfigToSet?.columnConfig?.deepCopy();
            defaultColumnConfigCopy?.forEach((columnConfig, key) => {
              const themeDefaults = themeColumnConfig.get(key);
              const themeMax = themeDefaults?.maxColumnWidth;
              const themeMin = themeDefaults?.minColumnWidth;
              if (columnConfig?.columnWidth > themeMax) columnConfig.columnWidth = themeMax;
              if (columnConfig?.columnWidth < themeMin) columnConfig.columnWidth = themeMin;
              columnConfigMap?.set(key, columnConfig);
            });
            break;
        }
        this.saveColumnChanges(columnConfigMap, resetColumnConfigMode, defaultColumnConfigToSet);
      }
    }
  });

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    // Only the Price dropdown should be disabled, it should always have the value of 'On'
    // Therefore we only disable the dropdown itself in the HTML component, and leave Price name and width enabled
    return layoutType?.isAnyGrid()
      && (
        key === SectionColumnConfigProductInfoKey.Quantity
        || key === SectionColumnConfigProductInfoKey.SecondaryPrice
        || key === SectionColumnConfigProductInfoKey.Size
        || key === SectionColumnConfigProductInfoKey.QuantityAndSize
      );
  }

  public static getStateLabelBackgroundColor(
    defaultState: SectionColumnConfigDefaultState,
    isThemeDefault: boolean = false
  ): any {
    switch (defaultState) {
      case SectionColumnConfigDefaultState.Auto:
        return {
          'background-color': `${ColumnConfigDefaultStateLabelColor.AutoLabelColor}`,
          'opacity': isThemeDefault ? 0.5 : 1
        };
      case SectionColumnConfigDefaultState.On:
        return {
          'background-color': `${ColumnConfigDefaultStateLabelColor.OnLabelColor}`,
          'opacity': isThemeDefault ? 0.5 : 1
        };
      default:
        return {
          'background-color': '#FFFFFF',
          'opacity': isThemeDefault ? 0.5 : 1
        };
    }
  }

  public makeColumnOptionFormFromKeys$(keys: SectionColumnConfigKeyType[]): Observable<ColumnConfigHelper[]> {
    return combineLatest([
      this.columnConfigMap$,
      this.themeColumnConfig$,
      this.enabledSecondaryCannabinoidNames$,
      this.enabledTerpeneNames$
    ]).pipe(
      map(([configMap, themeConfigMap, secondaryCannabinoids, terpenes]) => {
        return keys?.map(keyType => {
          const key = keyType?.getSelectionValue();
          const name = keyType?.getSelectionTitle();
          const config = this.getMenuColumnConfig(configMap, key);
          const themeDefaults = this.getThemeDefaultColumnConfig(themeConfigMap, key, secondaryCannabinoids, terpenes);
          const themeDefaultsCopy =  window?.injector?.Deserialize?.instanceOf(ThemeSectionColumnConfig, themeDefaults);
          if (secondaryCannabinoids.includes(name) || terpenes.includes(name)) {
            themeDefaultsCopy.columnName = name; // Handling the placeholder
          }
          return { key, name, config, themeDefaults: themeDefaultsCopy };
        });
      })
    );
  }

  private getMenuColumnConfig(
    columnConfigMap: Map<string, SectionColumnConfig>,
    key: SectionColumnConfigKey
  ): SectionColumnConfig {
    const columnConfig = columnConfigMap?.get(key) ?? new SectionColumnConfig();
    if (Number.isFinite(columnConfig?.columnOpacity) && columnConfig?.columnOpacity <= 1) {
      columnConfig.columnOpacity = Math.round(columnConfig.columnOpacity * 100);
    }
    return columnConfig;
  }

  private getThemeDefaultColumnConfig(
    themeColumnConfigMap: Map<string, ThemeSectionColumnConfig>,
    key: string,
    enabledSecondaryCannabinoidNames: string[],
    enabledTerpenesNames: string[],
  ): ThemeSectionColumnConfig {
    const terpeneDefaultOrNull = () => enabledTerpenesNames?.includes(StringUtils.splitPascalCaseIntoSentence(key))
      ? themeColumnConfigMap?.get('IndividualTerpenes')
      : null;
    const secondaryCannabinoidDefaultOrNull = () => enabledSecondaryCannabinoidNames?.includes(key)
      ? themeColumnConfigMap?.get('SecondaryCannabinoids')
      : null;
    return themeColumnConfigMap?.get(key) || terpeneDefaultOrNull() || secondaryCannabinoidDefaultOrNull();
  }

  saveChangesWithinModal() {
    const columnConfigMap = this._columnConfigMap.getValue();
    columnConfigMap?.forEach((columnConfig) => {
      if (columnConfig?.columnOpacity === 0) columnConfig.columnOpacity = null;
    });
    this._columnConfigMap.next(columnConfigMap);
  }

  submitForms() {
    const managingDefault = this._managingDefault.getValue();
    const sectionColumnConfig = this._columnConfigMap.getValue();
    if (managingDefault) {
      const defaultColumnConfig = this._defaultSectionConfig.getValue();
      defaultColumnConfig.columnConfig = this.setUnassignedColumnsToOff(sectionColumnConfig);
      this.activeModal.close(defaultColumnConfig);
    } else {
      this.activeModal.close(sectionColumnConfig);
    }
  }

  private setUnassignedColumnsToOff(
    sectionColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): Map<SectionColumnConfigKey, SectionColumnConfig> {
    const newDefaultColumnConfigMap = new Map<SectionColumnConfigKey, SectionColumnConfig>;
    sectionColumnConfig?.forEach((columnConfig, key) => {
      const columnConfigCopy = window.injector.Deserialize.instanceOf(SectionColumnConfig, columnConfig);
      if (!columnConfig?.defaultState) {
        columnConfigCopy.defaultState = SectionColumnConfigDefaultState.Off;
      }
      newDefaultColumnConfigMap.set(key, columnConfigCopy);
    });
    return newDefaultColumnConfigMap;
  }

  resetToDefaultColumnConfig(defaultColumnConfig: DefaultSectionColumnConfig) {
    this.section$.once(section => {
      const opts = new ConfirmationOptions();
      opts.title = 'Reset Column Options';
      const sectionName = section?.title;
      opts.bodyText = `Are you sure you want to reset the Column Options for section '${sectionName}' `
        + `to '${defaultColumnConfig.name}'?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Reset';
      const confirmed = (cont: boolean) => {
        if (cont) {
          // when defaultColumnConfig.id set explicitly to -1, then reset to theme default
          if (defaultColumnConfig.id === '-1') {
            this.resetToThemeDefault();
          } else {
            this.resetToDefault(defaultColumnConfig);
          }
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmed);
    });
  }

  private resetToThemeDefault(): void {
    this._resetSubject.next({resetMode: ResetColumnConfigMode.ThemeDefault, defaultColumnConfig: null});
  }

  private resetToDefault(defaultColumnConfig: DefaultSectionColumnConfig) {
    this._resetSubject.next({resetMode: ResetColumnConfigMode.PreDefined, defaultColumnConfig});
  }

  private saveColumnChanges(
    columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>,
    resetColumnConfigMode: ResetColumnConfigMode,
    defaultToSet: DefaultSectionColumnConfig
  ): void {
    this.section$.once(s => {
      const loadingOpts = this._loadingOpts;
      const lm = 'Updating Column Options';
      const Deserialize = window?.injector?.Deserialize;
      const section = Deserialize?.instanceOf(HydratedSection, s);
      section.columnConfig = Deserialize?.mapOf(SectionColumnConfig, columnConfig);
      loadingOpts.addRequest(lm);
      const update$ = section instanceof SectionTemplate
        ? this.templateDomainModel.updateMenuSectionTemplate(section)
        : this.menuDomainModel.updateMenuSection(section);
      update$.subscribe({
        next: updatedSection => {
          if (resetColumnConfigMode === ResetColumnConfigMode.PreDefined) {
            this.identifyDiscrepanciesInAppliedDefault(defaultToSet, updatedSection.columnConfig);
          }
          this.connectToSection(updatedSection);
          this._unsavedChangesInModal.next(false);
          this._resetSubject.next(null);
          loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Column options successfully updated.', 'Reset Column Options');
        },
        error: (error: BsError) => {
          loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    });
  }

  /**
   * The intention is to catch cases where the config wants to set columnX to 'On' or 'Auto' but the theme
   * doesn't support it. In this case, the data coming back will have auto updated these cases to 'Off'.
   * Therefore, we need to let the user know that there were discrepancies and we've updated them.
   */
  private identifyDiscrepanciesInAppliedDefault(
    config: DefaultSectionColumnConfig,
    incomingConfig: Map<SectionColumnConfigKey, SectionColumnConfig>
  ) {
    if (!config) return;
    const outgoingConfig = window?.injector?.Deserialize?.instanceOf(DefaultSectionColumnConfig, config);
    const columnsUnableToEnable = [];
    for (const [configKey, outboundSectionColumnConfig] of outgoingConfig.columnConfig) {
      const inboundSectionColumnConfig = window
        ?.injector
        ?.Deserialize
        ?.instanceOf(SectionColumnConfig, incomingConfig.get(configKey));
      const outboundOn = outboundSectionColumnConfig?.defaultState === SectionColumnConfigDefaultState.On;
      const outboundAuto = outboundSectionColumnConfig?.defaultState === SectionColumnConfigDefaultState.Auto;
      const inboundOn = inboundSectionColumnConfig?.defaultState === SectionColumnConfigDefaultState.On;
      const inboundAuto = inboundSectionColumnConfig?.defaultState === SectionColumnConfigDefaultState.Auto;
      const outboundVisible = outboundOn || outboundAuto;
      const inboundVisible = inboundOn || inboundAuto;
      if (incomingConfig.has(configKey) && outboundVisible && !inboundVisible) {
        columnsUnableToEnable.push(configKey);
      }
    }
    if (columnsUnableToEnable.length > 0) {
      this.toastService.publishBannerSuccess(
        `The section columns have been reset to '${outgoingConfig.name}', however some of the options are not `
        + `supported in the current theme. We've automatically updated the following columns to match what this `
        + `theme supports: ${columnsUnableToEnable.join(', ')}`
      );
    }
  }

}
