import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrimaryCannabinoid } from '../../../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../../../../../../../../../models/enum/dto/secondary-cannabinoid';
import { CannabinoidsPreviewViewModel } from '../cannabinoids-preview-view-model';

@Component({
  selector: 'app-cannabinoid-preview-chunk',
  templateUrl: './cannabinoid-preview-chunk.component.html',
  styleUrls: ['./cannabinoid-preview-chunk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidPreviewChunkComponent {

  constructor(
    public viewModel: CannabinoidsPreviewViewModel // provided by parent component
  ) {
  }

  @Input() cannabinoid: PrimaryCannabinoid | SecondaryCannabinoid | 'presentCannabinoids' | string;

}
