import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { Product } from '../../../../models/product/dto/product';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent extends BaseComponent {

  @ViewChild(ReactiveTableComponent) table: ReactiveTableComponent;
  @Input() products: Product[];
  @Input() nItemsToDisplay: number;
  @Input() resetPageOnNewData: boolean = false;
  @Input() resetPageOnSortingChange: boolean = true;
  @Input() showLoadingStateAndSetTableDataToHidden: boolean = false;
  @Input() tableColumnsChanged: boolean = false;

  toggleChangeDetection(): void {
    this.table?.fireChangeDetectionForEntireTable();
  }

  resetPageNumber(): void {
    this.table?.resetPageNumber();
  }

}
