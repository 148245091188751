import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MenusRoutingModule } from './menus-routing.module';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EditProductMenuComponent } from './components/edit-menu/edit-product-menu/edit-product-menu.component';
import { NewMenuModalComponent } from './components/modals/new-menu-modal/new-menu-modal.component';
import { MenuMediaComponent } from './components/shared/menu-media/menu-media.component';
import { ChangeMediaModalComponent } from './components/modals/change-media-modal/change-media-modal.component';
import { MenuSectionPreviewComponent } from './components/edit-menu/shared/menu-section-preview/menu-section-preview.component';
import { NewMenuSectionModalComponent } from './components/modals/new-menu-section-modal/new-menu-section-modal.component';
import { EditMenuSectionComponent } from './components/edit-menu-section/edit-menu-section.component';
import { MenuSectionProductPreviewComponent } from './components/edit-menu/shared/menu-section-product-preview/menu-section-product-preview.component';
import { FeaturedProductComponent } from './components/shared/featured-product/featured-product.component';
import { EditMenuStyleModalComponent } from './components/modals/edit-menu-style-modal/edit-menu-style-modal.component';
import { EditMenuStyleViewModel } from './components/modals/edit-menu-style-modal/edit-menu-style-view-model';
import { VariantBadgeModalComponent } from './components/modals/variant-badge-modal/variant-badge-modal.component';
import { DuplicateMenuModalComponent } from './components/modals/duplicate-menu-modal/duplicate-menu-modal.component';
import { ThemePreviewComponent } from './components/theme-preview/theme-preview.component';
import { ThemeDetailsModalComponent } from './components/modals/theme-details-modal/theme-details-modal.component';
import { EditLabelModalComponent } from './components/edit-menu-section/edit-label-modal/edit-label-modal.component';
import { EditLabelViewModel } from './components/edit-menu-section/edit-label-modal/edit-label-view-model';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MenuSectionVariantGroupingComponent } from './components/edit-menu/shared/menu-section-variant-grouping/menu-section-variant-grouping.component';
import { VariantGroupingHeaderComponent } from './components/edit-menu/shared/menu-section-variant-grouping/variant-grouping-header/variant-grouping-header.component';
import { EditSpotlightMenuComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu.component';
import { MenuSectionVariantGroupsComponent } from './components/edit-menu/shared/menu-section-variant-groups/menu-section-variant-groups.component';
import { ThemePickerComponent } from './components/modals/new-menu-modal/theme-picker/theme-picker.component';
import { MenuSectionBackgroundComponent } from './components/edit-menu/shared/menu-section-background/menu-section-background.component';
import { IsMarketingMenuPipe } from './pipes/is-marketing-menu.pipe';
import { IsPrintMenuPipe } from './pipes/is-print-menu.pipe';
import { IsProductMenuPipe } from './pipes/is-product-menu.pipe';
import { IsSpotlightMenuPipe } from './pipes/is-spotlight-menu.pipe';
import { IsDriveThruMenuPipe } from './pipes/is-combo-menu.pipe';
import { DriveThruProductComponent } from './components/shared/drive-thru-product/drive-thru-product.component';
import { EditPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu.component';
import { EditFeaturedProductMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu.component';
import { EditDriveThruMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu.component';
import { EditFeaturedProductsComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-products/edit-featured-products.component';
import { EditPlaylistComponent } from './components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist/edit-playlist.component';
import { EditDriveThruProductsComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-products/edit-drive-thru-products.component';
import { EditDriveThruProductsViewModel } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-products/edit-drive-thru-products-view-model';
import { DriveThruProductMediaComponent } from './components/shared/drive-thru-product/drive-thru-product-media/drive-thru-product-media.component';
import { BaseProductCardComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/base-product-card/base-product-card.component';
import { CardNumberComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/card-number/card-number.component';
import { CardPriceComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/card-price/card-price.component';
import { EmbeddedImageCardComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/embedded-image-card/embedded-image-card.component';
import { FullImageCardComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/full-image-card/full-image-card.component';
import { HalfImageCardComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/menu-cards/half-image-card/half-image-card.component';
import { FitTextModule } from '../../modules/scale-up-text/fit-text.module';
import { IsEmbeddedImageCardPipe } from './components/edit-menu/edit-marketing-menu/pipes/is-embedded-image-card.pipe';
import { IsFullImageCardPipe } from './components/edit-menu/edit-marketing-menu/pipes/is-full-image-card.pipe';
import { IsHalfImageCardPipe } from './components/edit-menu/edit-marketing-menu/pipes/is-half-image-card.pipe';
import { CardPreviewComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/card-preview/card-preview.component';
import { CastToProductCardPipe } from './components/edit-menu/edit-marketing-menu/pipes/cast-to-product-card.pipe';
import { CardTypeSelectionComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/card-type-selection/card-type-selection.component';
import { EditFeaturedProductMenuViewModel } from './components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-view-model';
import { EditPlaylistMenuViewModel } from './components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu-view-model';
import { SmartFiltersModule } from '../smart-filters/smart-filters.module';
import { MenuSectionSmartFiltersComponent } from './components/edit-menu/shared/menu-section-smart-filters/menu-section-smart-filters.component';
import { IsProductSectionPipe } from './pipes/is-product-section.pipe';
import { EditMenuSectionFormComponent } from './components/edit-menu-section/edit-menu-section-form/edit-menu-section-form.component';
import { EditProductSectionFormComponent } from './components/edit-menu-section/edit-menu-section-form/edit-product-section-form/edit-product-section-form.component';
import { EditMediaSectionFormComponent } from './components/edit-menu-section/edit-menu-section-form/edit-media-section-form/edit-media-section-form.component';
import { IsMediaSectionPipe } from './pipes/is-media-section.pipe';
import { EditTitleSectionFormComponent } from './components/edit-menu-section/edit-menu-section-form/edit-title-section-form/edit-title-section-form.component';
import { IsTitleSectionPipe } from './pipes/is-title-section.pipe';
import { SpotlightMenuDetailsFormComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-forms/spotlight-menu-details-form/spotlight-menu-details-form.component';
import { SpotlightSectionFormComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-forms/spotlight-section-form/spotlight-section-form.component';
import { MenuTitlesFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-titles-form/menu-titles-form.component';
import { MenuDetailsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-details-form/menu-details-form.component';
import { IsDisplayMenuPipe } from './pipes/is-display-menu.pipe';
import { IsWebMenuPipe } from './pipes/is-web-menu.pipe';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { DigitalComponent } from './components/menus/digital/digital.component';
import { PrintComponent } from './components/menus/print/print.component';
import { WebComponent } from './components/menus/web/web.component';
import { DigitalProductMenusComponent } from './components/menus/digital/digital-product-menus/digital-product-menus.component';
import { DigitalMarketingMenusComponent } from './components/menus/digital/digital-marketing-menus/digital-marketing-menus.component';
import { PrintProductMenusComponent } from './components/menus/print/print-product-menus/print-product-menus.component';
import { WebProductMenusComponent } from './components/menus/web/web-product-menus/web-product-menus.component';
import { DigitalMenusContainerComponent } from './components/menus/digital/digital-menus-container/digital-menus-container.component';
import { PrintMenusContainerComponent } from './components/menus/print/print-menus-container/print-menus-container.component';
import { WebMenusContainerComponent } from './components/menus/web/web-menus-container/web-menus-container.component';
import { DigitalProductMenuListComponent } from './components/menus/digital/digital-product-menus/digital-product-menu-list/digital-product-menu-list.component';
import { DigitalMarketingMenuListComponent } from './components/menus/digital/digital-marketing-menus/digital-marketing-menu-list/digital-marketing-menu-list.component';
import { PrintProductMenuListComponent } from './components/menus/print/print-product-menus/print-product-menu-list/print-product-menu-list.component';
import { WebProductMenuListComponent } from './components/menus/web/web-product-menus/web-product-menu-list/web-product-menu-list.component';
import { BulkPrintJobsListComponent } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-list.component';
import { BulkPrintJobsDataTableComponent } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/bulk-print-jobs-data-table.component';
import { BulkPrintJobsTableHeaderComponent } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/bulk-print-jobs-table-header/bulk-print-jobs-table-header.component';
import { BulkPrintJobsTableItemComponent } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/bulk-print-jobs-table-item/bulk-print-jobs-table-item.component';
import { BulkPrintJobsTableEmptyStateComponent } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/bulk-print-jobs-table-empty-state/bulk-print-jobs-table-empty-state.component';
import { ReactiveTableModule, ResizableModule } from '@mobilefirstdev/reactive-table';
import { TablesModule } from '../tables/tables.module';
import { MarketingMenuHasAdditionalOptionsPipe } from './pipes/marketing-menu-has-additional-options.pipe';
import { EditFeaturedCategoryMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu.component';
import { FeaturedCategoryComponent } from './components/shared/featured-category/featured-category.component';
import { EditFeaturedCategorySectionComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section.component';
import { EditFeaturedCategoryCardsComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-cards/edit-featured-category-cards.component';
import { EditFeaturedCategoryMenuViewModel } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu-view-model';
import { FeaturedCategoryCardsDetailsFormComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-cards/forms/featured-category-cards-details-form/featured-category-cards-details-form.component';
import { EditFeaturedCategorySectionFormComponent } from './components/edit-menu-section/edit-menu-section-form/edit-featured-category-section-form/edit-featured-category-section-form.component';
import { IsFeaturedCategorySectionPipe } from './pipes/is-featured-category-section.pipe';
import { MenuMediaFormComponent } from './components/shared/menu-media-form/menu-media-form.component';
import { FeaturedProductFormComponent } from './components/shared/featured-product/featured-product-form/featured-product-form.component';
import { EditDriveThruProductsFormComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-products/edit-drive-thru-products-form/edit-drive-thru-products-form.component';
import { ReactiveFormCardTypeComponent } from './components/shared/reactive-form-card-type/reactive-form-card-type.component';
import { DriveThruProductFormComponent } from './components/shared/drive-thru-product/drive-thru-product-form/drive-thru-product-form.component';
import { ColumnOptionsModalComponent } from './components/modals/column-options-modal/column-options-modal.component';
import { IsPlantlifeMenuPipe } from './pipes/is-plantlife-menu.pipe';
import { IsPlantlifeNonSmokableMenuPipe } from './pipes/is-plantlife-non-smokable-menu.pipe';
import { BasePipesModule } from '../../modules/base-pipes/base-pipes.module';
import { MenuSectionColorsComponent } from './components/edit-menu/shared/menu-section-colors/menu-section-colors.component';
import { ReplaceDotWithDashPipe } from '../tables/pipes/replace-dash-with-dot.pipe';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { MenuSectionProductCardColorComponent } from './components/edit-menu/shared/menu-section-product-card-color/menu-section-product-card-color.component';
import { EditFeaturedCategoryEditAssetComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-edit-asset/edit-featured-category-edit-asset.component';
import { MenuSupportsProductRowCountPipe } from './pipes/menu-supports-product-row-count.pipe';
import { ProductLabelsModule } from '../../modules/product-labels/product-labels.module';
import { MenuAdditionalOptionsAccordionComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-additional-options-accordion/menu-additional-options-accordion.component';
import { MenuHideShowOptionsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-hide-show-options-form/menu-hide-show-options-form.component';
import { MenuLayoutOptionsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-layout-options-form/menu-layout-options-form.component';
import { MenuZoomSpacingOptionsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-zoom-spacing-options-form/menu-zoom-spacing-options-form.component';
import { MenuPrintOptionsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-print-options-form/menu-print-options-form.component';
import { MenuAdditionalOptionsComponent } from './components/forms/menu-additional-options/menu-additional-options.component';
import { DecodeSectionSortingIntoReadableStringPipe } from './pipes/decode-section-sorting-into-readable-string.pipe';
import { EditProductMenuSectionsComponent } from './components/edit-menu/edit-product-menu/edit-product-menu-sections/edit-product-menu-sections.component';
import { EditProductMenuDetailsComponent } from './components/edit-menu/edit-product-menu/edit-product-menu-details/edit-product-menu-details.component';
import { MenuLinksComponent } from './components/edit-menu/shared/menu-links/menu-links.component';
import { EditPrintMenuSectionDetailsActionsComponent } from './components/edit-menu/edit-product-menu/edit-product-menu-details/edit-print-menu-details-actions/edit-print-menu-section-details-actions.component';
import { MenuDetailsSectionComponent } from './components/edit-menu/shared/menu-section-details/menu-details-section.component';
import { EditProductMenuFooterComponent } from './components/edit-menu/edit-product-menu/edit-product-menu-footer/edit-product-menu-footer.component';
import { EditMenuContainerComponent } from './components/edit-menu/edit-menu-container/edit-menu-container.component';
import { EditMenuHeaderActionsComponent } from './components/edit-menu/shared/edit-menu-header/edit-menu-header-actions/edit-menu-header-actions.component';
import { EditProductMenuBodyComponent } from './components/edit-menu/edit-product-menu/edit-product-menu-body/edit-product-menu-body.component';
import { EditMenuHeaderComponent } from './components/edit-menu/shared/edit-menu-header/edit-menu-header.component';
import { EditMenuHeaderNameAndActiveComponent } from './components/edit-menu/shared/edit-menu-header/edit-menu-header-name-and-active/edit-menu-header-name-and-active.component';
import { EditSpotlightMenuBodyComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-body/edit-spotlight-menu-body.component';
import { EditSpotlightMenuDetailsComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-details/edit-spotlight-menu-details.component';
import { EditSpotlightMenuProductsComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-products/edit-spotlight-menu-products.component';
import { EditMenuSectionSectionDetailsComponent } from './components/edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component';
import { EditSpotlightMenuSectionDetailsComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-section-details/edit-spotlight-menu-section-details.component';
import { IsSpotlightSectionPipe } from './pipes/is-spotlight-section.pipe';
import { EditMenuSectionContainerComponent } from './components/edit-menu-section/edit-menu-section-container/edit-menu-section-container.component';
import { EditMenuSectionHeaderComponent } from './components/edit-menu-section/edit-menu-section-header/edit-menu-section-header.component';
import { EditMenuSectionProductsComponent } from './components/edit-menu-section/edit-menu-section-products/edit-menu-section-products.component';
import { EditMenuSectionFooterComponent } from './components/edit-menu-section/edit-menu-section-footer/edit-menu-section-footer.component';
import { EditMenuSectionBodyComponent } from './components/edit-menu-section/edit-menu-section-body/edit-menu-section-body.component';
import { EditFeaturedCategorySectionBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-body/edit-featured-category-section-body.component';
import { EditFeaturedCategorySectionSectionDetailsComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-section-details/edit-featured-category-section-section-details.component';
import { EditFeaturedCategorySectionProductsComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-products/edit-featured-category-section-products.component';
import { GetSectionPreviewTitlePipe } from './components/edit-menu/shared/menu-section-preview/get-section-preview-title.pipe';
import { GetSectionPreviewFormattedSectionTypePipe } from './components/edit-menu/shared/menu-section-preview/get-section-preview-formatted-section-type.pipe';
import { NewMenuSectionFormComponent } from './components/modals/new-menu-section-modal/components/new-menu-section-form/new-menu-section-form.component';
import { SectionCreationMethodComponent } from './components/modals/new-menu-section-modal/components/section-creation-method/section-creation-method.component';
import { MenuSectionLocationPermissionsComponent } from './components/edit-menu/shared/menu-section-location-permissions/menu-section-location-permissions.component';
import { CastToSectionTemplatePipe } from './pipes/cast-to-section-template.pipe';
import { IsSectionTemplatePipe } from './pipes/is-section-template.pipe';
import { GetSectionPreviewVariantCountPipe } from './components/edit-menu/shared/menu-section-preview/get-section-preview-variant-count.pipe';
import { EditMenuFooterComponent } from './components/edit-menu/shared/edit-menu-footer/edit-menu-footer.component';
import { EditMenuFooterActionsComponent } from './components/edit-menu/shared/edit-menu-footer/edit-menu-footer-actions/edit-menu-footer-actions.component';
import { EditDriveThruMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-drive-thru-menu/edit-drive-thru-menu-body/edit-drive-thru-menu-body.component';
import { EditFeaturedCategoryMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-menu-body/edit-featured-category-menu-body.component';
import { EditPlaylistMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu-body/edit-playlist-menu-body.component';
import { EditMenuWarningBannerComponent } from './components/edit-menu/shared/edit-menu-warning-banner/edit-menu-warning-banner.component';
import { EditFeaturedProductMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-featured-product-menu/edit-featured-product-menu-body/edit-featured-product-menu-body.component';
import { MenuCreationMethodComponent } from './components/modals/new-menu-modal/menu-creation-method/menu-creation-method.component';
import { ShowProductOverflowPipe } from './pipes/show-product-overflow.pipe';
import { SectionCanContainProductsPipe } from './pipes/section-can-contain-products.pipe';
import { EditSmartPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu.component';
import { EditSmartPlaylistMenuViewModel } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-view-model';
import { EditSmartPlaylistMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-body/edit-smart-playlist-menu-body.component';
import { EditSmartPlaylistMenuContentComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-content/edit-smart-playlist-menu-content.component';
import { ProductGroupingComponent } from './components/shared/product-grouping/product-grouping.component';
import { SectionMediaComponent } from './components/shared/section-media/section-media.component';
import { SectionMediaFormComponent } from './components/shared/section-media/section-media-form/section-media-form.component';
import { EditSmartPlaylistMenuSectionComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section.component';
import { EditSmartPlaylistMenuSectionBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section-body/edit-smart-playlist-menu-section-body.component';
import { IsProductGroupSectionPipe } from './pipes/is-product-group-section.pipe';
import { EditSmartPlaylistMenuSectionFormComponent } from './components/edit-menu/edit-marketing-menu/edit-smart-playlist-menu/edit-smart-playlist-menu-section/edit-smart-playlist-menu-section-form/edit-smart-playlist-menu-section-form.component';
import { EditMenuSectionPrimaryAssetComponent } from './components/edit-menu-section/edit-menu-section-primary-asset/edit-menu-section-primary-asset.component';
import { DisableGridColumnCheckboxPipe } from './pipes/disable-grid-column-checkbox.pipe';
import { DisableGridColumnCheckboxTooltipPipe } from './pipes/disable-grid-column-checkbox-tooltip.pipe';
import { BulkPrintFilterFormComponent } from './components/menus/print/bulk-print-filter-form/bulk-print-filter-form.component';
import { RxFor } from '@rx-angular/template/for';
import { RxPush } from '@rx-angular/template/push';
import { ShowWillNotAppearDividerPipe } from './components/edit-menu/shared/menu-section-variant-groups/show-will-not-appear-divider.pipe';
import { RxIf } from '@rx-angular/template/if';
import { MenuSectionVariantGroupsEmptySearchComponent } from './components/edit-menu/shared/menu-section-variant-groups/menu-section-variant-groups-empty-search/menu-section-variant-groups-empty-search.component';
import { PrintCardsComponent } from './components/menus/print/print-cards/print-cards.component';
import { PrintCardsListComponent } from './components/menus/print/print-cards/print-cards-list/print-cards-list.component';
import { EditCardStackMenuComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-menu.component';
import { EditCardStackMenuBodyComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-menu-body/edit-card-stack-menu-body.component';
import { CardStackDetailsSectionComponent } from './components/edit-menu/edit-card-stack-menu/card-stack-details-section/card-stack-details-section.component';
import { CardStackSectionFormComponent } from './components/edit-menu/edit-card-stack-menu/card-stack-details-form/card-stack-section-form.component';
import { IsPrintCardMenuPipe } from './pipes/is-print-card-menu.pipe';
import { EditCardStackProductsComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-products/edit-card-stack-products.component';
import { EditCardStackMenuFooterComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-menu-footer/edit-card-stack-menu-footer.component';
import { EditSpotlightMenuFooterComponent } from './components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-footer/edit-spotlight-menu-footer.component';
import { StackSizePickerComponent } from './components/shared/stack-size-picker/stack-size-picker.component';
import { EditCardStackPrintSectionComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section.component';
import { PrintJobTypeStringPipe } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/pipes/print-type-string.pipe';
import { GetCardStackPreviewJobPipe } from './components/menus/print/bulk-print-jobs-list/bulk-print-jobs-data-table/pipes/get-card-stack-preview-job.pipe';
import { EditPrintStackStyleComponent } from './components/edit-menu/edit-card-stack-menu/modals/edit-print-card-style/edit-print-stack-style.component';
import { RxLet } from '@rx-angular/template/let';
import { CreateViewStackPrintJobComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { CreateViewStackPrintJobHeaderComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job-header/create-view-stack-print-job-header.component';
import { EditStackPrintJobDetailsComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/edit-stack-print-job-details/edit-stack-print-job-details.component';
import { SingleStackSmartPrintJobComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/single-stack-smart-print-job/single-stack-smart-print-job.component';
import { StackSmartPrintOptionsFormComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-smart-print-options-form/stack-smart-print-options-form.component';
import { SingleStackManualPrintJobComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/single-stack-manual-print-job/single-stack-manual-print-job.component';
import { StackPrintJobProductsFormComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/stack-print-job-products-form.component';
import { CardStackPrintJobSelectAllProductsComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/card-stack-print-job-select-all-products/card-stack-print-job-select-all-products.component';
import { CardStackPrintJobSelectIndividualProductComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/card-stack-print-job-select-individual-product/card-stack-print-job-select-individual-product.component';
import { CardStackProductAsSelectionItemPipe } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/pipes/product-as-selection-item.pipe';
import { SingleStackPrintJobAppliedProductsComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-applied-products/single-stack-print-job-applied-products.component';
import { AllCardStackPrintJobsModalComponent } from './components/edit-menu/edit-card-stack-menu/modals/all-card-stack-print-jobs-modal/all-card-stack-print-jobs-modal.component';
import { AllCardStackPrintJobsHeaderComponent } from './components/edit-menu/edit-card-stack-menu/modals/all-card-stack-print-jobs-modal/all-card-stack-print-jobs-header/all-card-stack-print-jobs-header.component';
import { VariantsInCardStackPipe } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/pipes/variants-in-card-stack.pipe';
import { EditCardStackAssetSectionComponent } from './components/edit-menu/edit-card-stack-menu/edit-card-stack-asset-section/edit-card-stack-asset-section.component';
import { ShowProductPreviewRedBorderPipe } from './components/edit-menu/shared/menu-section-variant-grouping/show-product-preview-red-border.pipe';
import { PrintReportMenusComponent } from './components/menus/print/print-report-menus/print-report-menus.component';
import { PrintReportMenuListComponent } from './components/menus/print/print-report-menus/print-report-menu-list/print-report-menu-list.component';
import { ShowTitleSectionPageBreakPipe } from '../shared/pipes/show-title-section-page-break.pipe';
import { SupportsDescriptionOptionsPipe } from './pipes/supports-description-options.pipe';
import { IsFeaturedProductMenuPipe } from './pipes/is-featured-product-menu.pipe';
import { MenuMediaOptionsFormComponent } from './components/forms/menu-additional-options/additional-option-forms/menu-media-options-form/menu-media-options-form.component';
import { GetCannabinoidValuePreviewTextPipe } from './pipes/get-cannabinoid-value-preview-text.pipe';
import { PrintLabelsComponent } from './components/menus/print/print-labels/print-labels.component';
import { PrintLabelsListComponent } from './components/menus/print/print-labels/print-labels-list/print-labels-list.component';
import { EditLabelStackMenuComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-menu.component';
import { EditLabelStackMenuBodyComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-menu-body/edit-label-stack-menu-body.component';
import { LabelStackDetailsSectionComponent } from './components/edit-menu/edit-label-stack-menu/label-stack-details-section/label-stack-details-section.component';
import { IsPrintLabelMenuPipe } from './pipes/is-print-label-menu.pipe';
import { LabelStackSectionFormComponent } from './components/edit-menu/edit-label-stack-menu/label-stack-section-form/label-stack-section-form.component';
import { EditLabelStackPrintSectionComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-print-section/edit-label-stack-print-section.component';
import { EditLabelStackProductsComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-products/edit-label-stack-products.component';
import { EditLabelStackMenuFooterComponent } from './components/edit-menu/edit-label-stack-menu/edit-label-stack-menu-footer/edit-label-stack-menu-footer.component';
import { LabelStackPrintJobSelectAllProductsComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/label-stack-print-job-select-all-products/label-stack-print-job-select-all-products.component';
import { LabelStackPrintJobSelectVariantCountsComponent } from './components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/stack-print-job-products-form/label-stack-print-job-select-variant-counts/label-stack-print-job-select-variant-counts.component';
import { EditUrlPlaylistMenuComponent } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu.component';
import { EditUrlPlaylistMenuViewModel } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu-view-model.service';
import { EditUrlPlaylistMenuBodyComponent } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu-body/edit-url-playlist-menu-body.component';
import { EditEmbeddedUrlComponent } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-embedded-url/edit-embedded-url.component';
import { UrlValidatorDirective } from './components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-embedded-url/url-validator.directive';
import { TerpeneColumnOptionsFormComponent } from './components/modals/column-options-modal/components/terpene-column-options-form/terpene-column-options-form.component';
import { SingleColumnOptionsFormComponent } from './components/modals/column-options-modal/components/single-column-options-form/single-column-options-form.component';
import { ColumnStyleOptionsFormComponent } from './components/modals/column-options-modal/components/column-style-options-form/column-style-options-form.component';
import { ColumnOptionsFormComponent } from './components/modals/column-options-modal/components/column-options-form/column-options-form.component';
import { ColumnGeneralOptionsFormComponent } from './components/modals/column-options-modal/components/column-general-options-form/column-general-options-form.component';
import { CannabinoidColumnOptionsFormComponent } from './components/modals/column-options-modal/components/cannabinoid-column-options-form/cannabinoid-column-options-form.component';
import { GetCurrentlySelectedDefaultStatePipe } from './pipes/get-currently-selected-default-state.pipe';
import { MenuSectionProductCannabinoidPreviewComponent } from './components/edit-menu/shared/menu-section-product-preview/menu-section-product-cannabinoid-preview/menu-section-product-cannabinoid-preview.component';
import { MenuSectionProductTerpenePreviewComponent } from './components/edit-menu/shared/menu-section-product-preview/menu-section-product-terpene-preview/menu-section-product-terpene-preview.component';
import { GetTerpeneValuePreviewTextPipe } from './pipes/get-terpene-value-preview-text.pipe';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { GetTacValuePreviewTextPipe } from './pipes/get-tac-value-preview-text.pipe';
import { PopperCannabinoidPreviewListItemComponent } from './components/edit-menu/shared/menu-section-product-preview/menu-section-product-cannabinoid-preview/popper-cannabinoid-preview-list-item/popper-cannabinoid-preview-list-item.component';
import { PopperTerpenePreviewListItemComponent } from './components/edit-menu/shared/menu-section-product-preview/menu-section-product-terpene-preview/popper-terpene-preview-list-item/popper-terpene-preview-list-item.component';
import { GetTopTerpeneValuePreviewTextPipe } from './pipes/get-top-terpene-value-preview-text.pipe';
import { GetTotalTerpeneValuePreviewTextPipe } from './pipes/get-total-terpene-value-preview-text.pipe';
import { GetCurrentlySelectedDefaultStateLabelStylingPipe } from './pipes/get-currently-selected-default-state-label-styling.pipe';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';

@NgModule({
  declarations: [
    EditProductMenuComponent,
    NewMenuModalComponent,
    MenuMediaComponent,
    SectionMediaComponent,
    ChangeMediaModalComponent,
    MenuSectionPreviewComponent,
    NewMenuSectionModalComponent,
    NewMenuSectionFormComponent,
    SectionCreationMethodComponent,
    EditMenuSectionComponent,
    MenuSectionProductPreviewComponent,
    FeaturedProductComponent,
    EditMenuStyleModalComponent,
    VariantBadgeModalComponent,
    DuplicateMenuModalComponent,
    EditPlaylistComponent,
    EditFeaturedProductsComponent,
    ThemePreviewComponent,
    ThemeDetailsModalComponent,
    EditLabelModalComponent,
    MenuSectionVariantGroupingComponent,
    VariantGroupingHeaderComponent,
    EditSpotlightMenuComponent,
    MenuSectionVariantGroupsComponent,
    MenuCreationMethodComponent,
    ThemePickerComponent,
    MenuSectionBackgroundComponent,
    IsMarketingMenuPipe,
    IsPrintMenuPipe,
    IsProductMenuPipe,
    IsSpotlightMenuPipe,
    IsDriveThruMenuPipe,
    IsFeaturedProductMenuPipe,
    EditDriveThruProductsComponent,
    DriveThruProductComponent,
    EditPlaylistMenuComponent,
    EditSmartPlaylistMenuComponent,
    EditFeaturedProductMenuComponent,
    EditDriveThruMenuComponent,
    DriveThruProductMediaComponent,
    BaseProductCardComponent,
    CardNumberComponent,
    CardPriceComponent,
    EmbeddedImageCardComponent,
    FullImageCardComponent,
    HalfImageCardComponent,
    IsEmbeddedImageCardPipe,
    CastToProductCardPipe,
    IsFullImageCardPipe,
    IsHalfImageCardPipe,
    CardPreviewComponent,
    CardTypeSelectionComponent,
    MenuSectionProductCardColorComponent,
    MenuSectionSmartFiltersComponent,
    EditProductSectionFormComponent,
    IsProductSectionPipe,
    EditMenuSectionFormComponent,
    EditMediaSectionFormComponent,
    IsMediaSectionPipe,
    EditTitleSectionFormComponent,
    IsTitleSectionPipe,
    SpotlightMenuDetailsFormComponent,
    SpotlightSectionFormComponent,
    MenuTitlesFormComponent,
    MenuDetailsFormComponent,
    IsDisplayMenuPipe,
    IsWebMenuPipe,
    DigitalComponent,
    PrintComponent,
    WebComponent,
    DigitalProductMenusComponent,
    DigitalMarketingMenusComponent,
    PrintProductMenusComponent,
    PrintCardsComponent,
    PrintLabelsComponent,
    WebProductMenusComponent,
    DigitalMenusContainerComponent,
    PrintMenusContainerComponent,
    WebMenusContainerComponent,
    DigitalProductMenuListComponent,
    DigitalMarketingMenuListComponent,
    PrintProductMenuListComponent,
    PrintCardsListComponent,
    PrintLabelsListComponent,
    WebProductMenuListComponent,
    BulkPrintJobsListComponent,
    BulkPrintJobsDataTableComponent,
    BulkPrintJobsTableHeaderComponent,
    BulkPrintJobsTableItemComponent,
    BulkPrintJobsTableEmptyStateComponent,
    MarketingMenuHasAdditionalOptionsPipe,
    EditFeaturedCategoryMenuComponent,
    FeaturedCategoryComponent,
    ProductGroupingComponent,
    EditFeaturedCategorySectionComponent,
    EditFeaturedCategoryCardsComponent,
    FeaturedCategoryCardsDetailsFormComponent,
    EditFeaturedCategorySectionFormComponent,
    IsFeaturedCategorySectionPipe,
    MenuMediaFormComponent,
    SectionMediaFormComponent,
    FeaturedProductFormComponent,
    EditDriveThruProductsFormComponent,
    ReactiveFormCardTypeComponent,
    DriveThruProductFormComponent,
    ColumnOptionsModalComponent,
    ColumnOptionsFormComponent,
    ColumnGeneralOptionsFormComponent,
    ColumnStyleOptionsFormComponent,
    SingleColumnOptionsFormComponent,
    CannabinoidColumnOptionsFormComponent,
    TerpeneColumnOptionsFormComponent,
    IsPlantlifeMenuPipe,
    IsPlantlifeNonSmokableMenuPipe,
    MenuSectionColorsComponent,
    ReplaceDotWithDashPipe,
    EditFeaturedCategoryEditAssetComponent,
    MenuSupportsProductRowCountPipe,
    MenuAdditionalOptionsAccordionComponent,
    MenuHideShowOptionsFormComponent,
    MenuLayoutOptionsFormComponent,
    MenuZoomSpacingOptionsFormComponent,
    MenuPrintOptionsFormComponent,
    MenuMediaOptionsFormComponent,
    MenuAdditionalOptionsComponent,
    DecodeSectionSortingIntoReadableStringPipe,
    EditProductMenuSectionsComponent,
    EditProductMenuDetailsComponent,
    MenuLinksComponent,
    EditPrintMenuSectionDetailsActionsComponent,
    MenuDetailsSectionComponent,
    EditProductMenuFooterComponent,
    EditMenuContainerComponent,
    EditMenuHeaderActionsComponent,
    EditProductMenuBodyComponent,
    EditMenuHeaderComponent,
    EditMenuFooterComponent,
    EditMenuHeaderNameAndActiveComponent,
    EditMenuHeaderNameAndActiveComponent,
    EditSpotlightMenuBodyComponent,
    EditSpotlightMenuDetailsComponent,
    EditSpotlightMenuProductsComponent,
    EditMenuSectionSectionDetailsComponent,
    EditSpotlightMenuSectionDetailsComponent,
    IsSpotlightSectionPipe,
    EditMenuSectionContainerComponent,
    EditMenuSectionHeaderComponent,
    EditMenuSectionProductsComponent,
    EditMenuSectionFooterComponent,
    EditMenuSectionBodyComponent,
    EditFeaturedCategorySectionBodyComponent,
    EditFeaturedCategorySectionSectionDetailsComponent,
    EditFeaturedCategorySectionProductsComponent,
    GetSectionPreviewTitlePipe,
    GetSectionPreviewFormattedSectionTypePipe,
    GetSectionPreviewVariantCountPipe,
    MenuSectionLocationPermissionsComponent,
    CastToSectionTemplatePipe,
    IsSectionTemplatePipe,
    EditMenuFooterActionsComponent,
    EditDriveThruMenuBodyComponent,
    EditFeaturedCategoryMenuBodyComponent,
    EditPlaylistMenuBodyComponent,
    EditSmartPlaylistMenuBodyComponent,
    EditSmartPlaylistMenuContentComponent,
    EditMenuWarningBannerComponent,
    EditFeaturedProductMenuBodyComponent,
    ShowProductOverflowPipe,
    SectionCanContainProductsPipe,
    EditSmartPlaylistMenuSectionComponent,
    EditSmartPlaylistMenuSectionBodyComponent,
    IsProductGroupSectionPipe,
    EditSmartPlaylistMenuSectionFormComponent,
    EditMenuSectionPrimaryAssetComponent,
    DisableGridColumnCheckboxPipe,
    DisableGridColumnCheckboxTooltipPipe,
    BulkPrintFilterFormComponent,
    ShowWillNotAppearDividerPipe,
    MenuSectionVariantGroupsEmptySearchComponent,
    EditCardStackMenuComponent,
    EditCardStackMenuBodyComponent,
    CardStackDetailsSectionComponent,
    CardStackSectionFormComponent,
    EditLabelStackMenuComponent,
    EditLabelStackMenuBodyComponent,
    LabelStackDetailsSectionComponent,
    LabelStackSectionFormComponent,
    IsPrintCardMenuPipe,
    IsPrintLabelMenuPipe,
    ShowTitleSectionPageBreakPipe,
    EditCardStackProductsComponent,
    EditLabelStackProductsComponent,
    EditCardStackMenuFooterComponent,
    EditLabelStackMenuFooterComponent,
    EditSpotlightMenuFooterComponent,
    StackSizePickerComponent,
    EditCardStackPrintSectionComponent,
    EditLabelStackPrintSectionComponent,
    PrintJobTypeStringPipe,
    GetCardStackPreviewJobPipe,
    EditPrintStackStyleComponent,
    CreateViewStackPrintJobComponent,
    CreateViewStackPrintJobHeaderComponent,
    EditStackPrintJobDetailsComponent,
    SingleStackSmartPrintJobComponent,
    StackSmartPrintOptionsFormComponent,
    SingleStackManualPrintJobComponent,
    StackPrintJobProductsFormComponent,
    CardStackPrintJobSelectAllProductsComponent,
    CardStackPrintJobSelectIndividualProductComponent,
    CardStackProductAsSelectionItemPipe,
    SingleStackPrintJobAppliedProductsComponent,
    AllCardStackPrintJobsModalComponent,
    AllCardStackPrintJobsHeaderComponent,
    VariantsInCardStackPipe,
    EditCardStackAssetSectionComponent,
    ShowProductPreviewRedBorderPipe,
    PrintReportMenusComponent,
    PrintReportMenuListComponent,
    SupportsDescriptionOptionsPipe,
    GetCannabinoidValuePreviewTextPipe,
    LabelStackPrintJobSelectAllProductsComponent,
    LabelStackPrintJobSelectVariantCountsComponent,
    EditUrlPlaylistMenuComponent,
    EditUrlPlaylistMenuBodyComponent,
    EditEmbeddedUrlComponent,
    UrlValidatorDirective,
    GetCurrentlySelectedDefaultStatePipe,
    GetCurrentlySelectedDefaultStateLabelStylingPipe,
    MenuSectionProductCannabinoidPreviewComponent,
    MenuSectionProductTerpenePreviewComponent,
    GetTerpeneValuePreviewTextPipe,
    GetTacValuePreviewTextPipe,
    PopperCannabinoidPreviewListItemComponent,
    PopperTerpenePreviewListItemComponent,
    GetTopTerpeneValuePreviewTextPipe,
    GetTotalTerpeneValuePreviewTextPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MenusRoutingModule,
    NgbTooltipModule,
    NgbAccordionModule,
    FlexLayoutModule,
    FitTextModule,
    SmartFiltersModule,
    ReactiveFormModule,
    ReactiveTableModule,
    ResizableModule,
    TablesModule,
    BasePipesModule,
    ReactiveButtonsModule,
    ProductLabelsModule,
    RxFor,
    RxPush,
    RxIf,
    RxLet,
    NgxPopperjsModule,
    StickyAutoPositionDirective,
  ],
  providers: [
    EditMenuStyleViewModel,
    EditLabelViewModel,
    EditDriveThruProductsViewModel,
    EditFeaturedProductMenuViewModel,
    EditPlaylistMenuViewModel,
    EditSmartPlaylistMenuViewModel,
    EditFeaturedCategoryMenuViewModel,
    EditUrlPlaylistMenuViewModel,
    ColumnOptionsModalComponent,
  ],
  exports: [
    CastToProductCardPipe,
    ThemePickerComponent,
    EditMenuContainerComponent,
    EditProductMenuBodyComponent,
    EditSpotlightMenuBodyComponent,
    EditMenuSectionContainerComponent,
    EditMenuSectionBodyComponent,
    EditPlaylistMenuBodyComponent,
    EditSmartPlaylistMenuBodyComponent,
    EditDriveThruMenuBodyComponent,
    EditFeaturedProductMenuBodyComponent,
    EditFeaturedCategoryMenuBodyComponent,
    EditFeaturedCategorySectionBodyComponent,
    EditSmartPlaylistMenuSectionBodyComponent,
    EditCardStackMenuBodyComponent,
    EditLabelStackMenuBodyComponent,
    LabelStackPrintJobSelectAllProductsComponent,
    EditUrlPlaylistMenuBodyComponent,
  ]
})
export class MenusModule {
}
