<div class="custom-visible-columns-option">
  <div
    class="text-link"
    [popper]="popperContent"
    [popperShowOnStart]="false"
    [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
    [popperModifiers]="popperModifier"
    [popperHideOnClickOutside]="true"
    [popperHideOnScroll]="false"
    [popperPreventOverflow]="true"
    [popperPositionFixed]="true"
    [popperApplyClass]="'options-picker-popper'"
    [popperStyles]="popperStyles"
    [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
    Custom Visible Columns
  </div>
  <popper-content #popperContent [style.z-index]="15">
    <app-custom-visible-columns-picker
      class="custom-visible-columns-picker"
      [currentProductColumnConfigsIdValue]="currentProductColumnConfigsIdValue"
      (productTableColumnConfigSelected)="productTableColumnConfigSelected.emit($event); popperContent.hide()">
    </app-custom-visible-columns-picker>
  </popper-content>
</div>
