import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuSectionProductCannabinoidPreviewViewModel } from '../components/edit-menu/shared/menu-section-product-preview/menu-section-product-cannabinoid-preview/menu-section-product-cannabinoid-preview-view-model';
import { Cannabinoid } from '../../../models/enum/dto/cannabinoid';

@Pipe({
  name: 'getCannabinoidValuePreviewText'
})
export class GetCannabinoidValuePreviewTextPipe implements PipeTransform {

  transform(vm: MenuSectionProductCannabinoidPreviewViewModel, cannabinoid: Cannabinoid): Observable<string> {
    return vm.getCannabinoidValuePreviewText$(cannabinoid);
  }

}
