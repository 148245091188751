<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"></app-loading>

<lib-reactive-form-group
  #form
  [bindTo]="viewModel.creationRequest$ | push"
  [dispersedKey]="'create-product-grouping'"
  (formSubmitted$)="viewModel.createOverrideProductGroup()">
</lib-reactive-form-group>

<div class="modal-body sticky">
  <div
    libStickyAutoPosition
    [stickyCollectionKey]="'create-product-grouping'"
    [stickyOrder]="0"
    [stickyZ]="2"
    class="modal-header no-bottom-border">
    <div class="modal-title">Create Product Grouping</div>
  </div>

  <div
    libStickyAutoPosition
    [stickyCollectionKey]="'create-product-grouping'"
    [stickyOrder]="1"
    [stickyZ]="2"
    class="spacer"></div>

  <lib-reactive-form-text
    libStickyAutoPosition
    [stickyCollectionKey]="'create-product-grouping'"
    [stickyOrder]="2"
    [stickyZ]="2"
    [dispersedKey]="'create-product-grouping'"
    [inputName]="'productGroupingName'"
    [label]="'Product Grouping Name'"
    [bindingProperty]="'name'"
    [required]="true"
    [placeholder]="'Enter Product Grouping Name'"
    [maxLength]="128"
    (valueChanged)="viewModel.connectToName($event)">
  </lib-reactive-form-text>

  <div #modalBody class="sticky-body-container pt-16px">
    <app-selected-override-products-form-component
      *ngIf="viewModel.hasProductIds$ | push"
      [dispersedKey]="'create-product-grouping'"
      [inputName]="'productIds'"
      [label]="'Product Ids'"
      [bindingProperty]="'productIds'"
      (updatedProductIds)="viewModel.connectToSelectedProductIds($event)">
    </app-selected-override-products-form-component>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <div class="button-spreader">
      <lib-button-inline-text class="add-products" [textColor]="'#2c4058'" (buttonClicked)="viewModel.addProducts()">
        + Add Products
      </lib-button-inline-text>
      <div>
        <lib-button-neutral [style.margin-right]="'0.5rem'" (buttonClicked)="cancel()">Cancel</lib-button-neutral>
        <lib-button-primary (buttonClicked)="form.submitForm()" [disabled]="viewModel.disabled$ | async">
          Create
        </lib-button-primary>
      </div>
    </div>
  </div>
</div>
