<div
  class="section-product-preview-detail"
  [popper]="popperContent"
  [popperDisabled]="!(viewModel.hasCannabinoidsThatAreGreaterThanZero$ | push)"
  [popperShowOnStart]="false"
  [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
  [popperModifiers]="popperModifier"
  [popperHideOnClickOutside]="true"
  [popperHideOnScroll]="false"
  [popperPreventOverflow]="true"
  [popperPositionFixed]="true"
  [popperApplyClass]="'product-info-popper'"
  [popperStyles]="popperStyles"
  [popperPlacement]="ClientTypes.NgxPopperjsPlacements.BOTTOMEND">
  <img [src]="'assets/icons/dark/outline/beaker.svg'" alt="" />
  <ng-container *rxIf="!(viewModel.hasCannabinoidsThatAreGreaterThanZero$ | push); else hasCannabinoidsSet">
    <span>No Cannabinoid Info</span>
  </ng-container>
  <ng-template #hasCannabinoidsSet>
    <span *rxLet="viewModel.largestExplicitlySetCannabinoid$; let largestCannabinoid">
      {{ viewModel | getCannabinoidValuePreviewText : largestCannabinoid | push }} {{ largestCannabinoid }}
    </span>
    <span>
      +{{ viewModel.nMore$ | push }} more
      <img class="info-icon" src="assets/icons/dark/outline/information-circle.svg" />
    </span>
  </ng-template>
</div>

<popper-content #popperContent [style.z-index]="2">
  <app-popper-cannabinoid-preview-list-item
    [cannabinoidTitle]="ClientTypes.PrimaryCannabinoid.CBD"
    [cannabinoidValue]="viewModel | getCannabinoidValuePreviewText : ClientTypes.PrimaryCannabinoid.CBD | push" />
  <app-popper-cannabinoid-preview-list-item
    [cannabinoidTitle]="ClientTypes.PrimaryCannabinoid.THC"
    [cannabinoidValue]="viewModel | getCannabinoidValuePreviewText : ClientTypes.PrimaryCannabinoid.THC | push" />
  <app-popper-cannabinoid-preview-list-item
    [cannabinoidTitle]="ClientTypes.PrimaryCannabinoid.TAC"
    [cannabinoidValue]="viewModel | getTACValuePreviewText | push" />
  <ng-container *rxIf="viewModel.hasSecondaryCannabinoidsGreaterThanZero$">
    <hr class="no-margin ml-12px mr-12px" />
    <app-popper-cannabinoid-preview-list-item
      *rxFor="let cannabinoid of viewModel.secondaryCannabinoidsGreaterThanZeroNameAsc$"
      [cannabinoidTitle]="cannabinoid"
      [cannabinoidValue]="viewModel | getCannabinoidValuePreviewText : cannabinoid | push" />
  </ng-container>
</popper-content>
