import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ColumnAccordionLabelHelper, ColumnChanges, ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { combineLatest } from 'rxjs';
import { SectionColumnConfigKey } from '../../../../../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/enum/dto/section-column-config-default-state';
import type { SectionColumnConfigKeyType } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import type { TerpeneTypeDefinition } from '../../../../../../../models/utils/dto/terpene-type-definition';
import type { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import type { ThemeSectionColumnConfig } from '../../../../../../../models/menu/dto/theme-section-column-config';

@Injectable()
export class TerpeneColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  public collectiveTerpeneColumnConfigsAndKeys$ = window.types.terpeneSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(k => k?.isCollectiveTerpeneKey()));
    })
  );

  public individualTerpeneColumnConfigsAndKeys$ = combineLatest([
    this.container.enabledTerpenes$,
    window.types.terpeneSectionColumnConfigKeys$
  ]).pipe(
    switchMap(([enabledTerpenes, keys]) => {
      const filteredKeys = keys
        ?.filter(keyType => keyType?.isIndividualTerpeneKey())
        ?.filter(keyType => {
          const individualTerpeneValues = enabledTerpenes?.map(et => et?.getSelectionValue()?.stripWhiteSpace());
          const keyTypeValue = keyType?.getSelectionValue();
          return individualTerpeneValues?.includes(keyTypeValue);
        });

      return this.container.makeColumnOptionFormFromKeys$(filteredKeys);
    })
  );

  public currentDefaultStateMap$ = combineLatest([
    this.container.columnConfig$,
    this.container.columnChanges$,
    this.container.themeColumnConfig$,
    this.container.enabledTerpenes$,
    window.types.terpeneSectionColumnConfigKeys$
  ]).pipe(
    map(([sectionColumnConfigs, columnChanges, themeColumnConfigs, enabledTerpenes, keys]) => {
      const collectiveKeys = this.collectiveTerpeneStateKeys(keys);
      const individualKeys = this.individualTerpeneStateKeys(keys, enabledTerpenes);
      const defaultStateMap = new Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>();
      collectiveKeys?.forEach(key => {
        this.addToDefaultStateMap(key, columnChanges, sectionColumnConfigs, themeColumnConfigs, defaultStateMap);
      });
      individualKeys?.forEach(key => {
        this.addToDefaultStateMap(key, columnChanges, sectionColumnConfigs, themeColumnConfigs, defaultStateMap, true);
      });
      return defaultStateMap;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private collectiveTerpeneStateKeys(keys: SectionColumnConfigKeyType[]): SectionColumnConfigKey[] {
    return keys
      ?.filter(k => k?.isCollectiveTerpeneKey())
      ?.map(k => k?.getSelectionValue());
  }

  private individualTerpeneStateKeys(
    keys: SectionColumnConfigKeyType[],
    enabledTerpenes: TerpeneTypeDefinition[]
  ): SectionColumnConfigKey[] {
    return keys
      ?.filter(keyType => keyType?.isIndividualTerpeneKey())
      ?.filter(keyType => {
        const individualTerpeneValues = enabledTerpenes?.map(et => et?.getSelectionValue()?.stripWhiteSpace());
        const keyTypeValue = keyType?.getSelectionValue();
        return individualTerpeneValues?.includes(keyTypeValue);
      })
      ?.map(keyType => keyType?.getSelectionValue());
  }

  private addToDefaultStateMap(
    key: SectionColumnConfigKey,
    columnChanges: Map<string, ColumnChanges>,
    sectionColumnConfigs: Map<String, SectionColumnConfig>,
    themeColumnConfigs: Map<String, ThemeSectionColumnConfig>,
    currentDefaultStateMap: Map<SectionColumnConfigKey, ColumnAccordionLabelHelper>,
    isIndividualTerpeneKey = false
  ): void {
    let defaultStateIsFromTheme = false;
    let currentDefaultState = columnChanges?.get(key)?.defaultState || sectionColumnConfigs?.get(key)?.defaultState;
    if (!currentDefaultState) {
      if (isIndividualTerpeneKey) {
        const themeConfig = themeColumnConfigs?.get(key);
        const individualTerpenesConfig = themeColumnConfigs?.get('IndividualTerpenes');
        currentDefaultState = themeConfig?.defaultState || individualTerpenesConfig?.defaultState;
      } else {
        currentDefaultState = themeColumnConfigs?.get(key)?.defaultState;
      }
      defaultStateIsFromTheme = true;
    }
    const currentDefaultOn = currentDefaultState === SectionColumnConfigDefaultState.On;
    const currentDefaultAuto = currentDefaultState === SectionColumnConfigDefaultState.Auto;
    if (currentDefaultOn || currentDefaultAuto) {
      const helper = {
        defaultState: currentDefaultState,
        labelStyle: ColumnOptionsViewModel.getStateLabelBackgroundColor(
          currentDefaultState,
          defaultStateIsFromTheme
        )
      };
      currentDefaultStateMap?.set(key, helper);
    }
  }

}
