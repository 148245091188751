<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | async">
  <lib-reactive-form-group [bindTo]="viewModel.variantFormObject$ | async" [mergeKey]="'editVariantFormCapture'">
    <div class="banner-row mt-4">
      <app-price-preview-widget
        [price]="viewModel.variantPreviewPrice$ | async"
        [priceSource]="viewModel.variantPreviewPriceSource$ | async"
        [locPriceFormat]="viewModel.locationPriceFormat$ | async">
      </app-price-preview-widget>
      <div *ngIf="viewModel.showBanner$ | async" class="label-banner">
        <img class="me-2" [src]="'assets/icons/dark/outline/currency-dollar.svg'" alt="icon" />
        <div class="">
          When a sale and promotion are both applied to a variant price, the lower of the two values will be used on
          checkout. A sale and promotion cannot be stacked.
        </div>
      </div>
    </div>

    <div *ngIf="viewModel.isPromo$ | async" class="row">
      <app-label-group
        [labelItems]="viewModel.promotionLabelGroupItems$ | async"
        [styling]="viewModel.promotionGroupStyling">
      </app-label-group>
    </div>

    <div class="row">
      <div class="column me-4">
        <app-label-group
          [labelItems]="viewModel.locationLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling">
        </app-label-group>
        <div class="secondary-price-input">
          <lib-reactive-form-number
            [inputName]="'locationSecondaryPrice'"
            [label]="'Secondary Price'"
            [disabled]="viewModel.container.secondaryPriceDisabled$ | async"
            [disabledTooltip]="viewModel.container.secondaryPriceDisabledTooltip$ | async"
            [placeholder]="'Enter location secondary price'"
            [bindingProperty]="'locationSecondaryPrice'"
            [tooltip]="
              'Secondary pricing is typically used for comparative pricing. ie. members only pricing, medical/rec, etc.'
            ">
          </lib-reactive-form-number>
        </div>
      </div>
      <div class="column" *ngIf="(viewModel.locationPricingTierLabelGroupItems$ | async)?.length > 0">
        <app-label-group
          [labelItems]="viewModel.locationPricingTierLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling">
        </app-label-group>
      </div>
      <div
        class="column"
        [style.margin-top.rem]="(viewModel.locationPricingTierLabelGroupItems$ | async)?.length > 0 ? '-2.5' : '0'"
        *ngIf="(viewModel.locationTaxLabelGroupItems$ | async)?.length > 0">
        <app-label-group
          [labelItems]="viewModel.locationTaxLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling">
        </app-label-group>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="column me-4">
        <app-label-group
          [labelItems]="viewModel.companyLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling">
        </app-label-group>
        <div class="secondary-price-input" *ngIf="viewModel.container.supportsCompanySecondaryPrice$ | async">
          <lib-reactive-form-number
            [inputName]="'companySecondaryPrice'"
            [label]="'Secondary Price'"
            [disabled]="viewModel.container.companySecondaryPriceDisabled$ | async"
            [disabledTooltip]="viewModel.container.secondaryPriceDisabledTooltip$ | async"
            [placeholder]="'Enter company secondary price'"
            [bindingProperty]="'companySecondaryPrice'"
            [tooltip]="
              'Secondary pricing is typically used for comparative pricing. ie. members only pricing, medical/rec, etc.'
            ">
          </lib-reactive-form-number>
        </div>
      </div>
      <div class="column" *ngIf="(viewModel.companyPricingTierLabelGroupItems$ | async)?.length > 0">
        <app-label-group
          [labelItems]="viewModel.companyPricingTierLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling">
        </app-label-group>
      </div>
      <div
        class="column"
        [style.margin-top.rem]="(viewModel.companyPricingTierLabelGroupItems$ | async)?.length > 0 ? '-2.5' : '0'"
        *ngIf="(viewModel.companyTaxLabelGroupItems$ | async)?.length > 0">
        <app-label-group
          [labelItems]="viewModel.companyTaxLabelGroupItems$ | async"
          [styling]="viewModel.labelGroupStyling"
          *ngIf="(viewModel.companyTaxLabelGroupItems$ | async)?.length > 0">
        </app-label-group>
      </div>
    </div>
  </lib-reactive-form-group>
</div>
