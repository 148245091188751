import { Pipe, PipeTransform } from '@angular/core';
import { MenuSectionProductTerpenePreviewViewModel } from '../components/edit-menu/shared/menu-section-product-preview/menu-section-product-terpene-preview/menu-section-product-terpene-preview-view-model';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getTotalTerpeneValuePreviewText'
})
export class GetTotalTerpeneValuePreviewTextPipe implements PipeTransform {

  transform(vm: MenuSectionProductTerpenePreviewViewModel): Observable<string> {
    return vm.getTotalTerpeneText$();
  }

}
