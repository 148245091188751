<div class="modal-body sticky">
  <div
    #modalHeader
    libStickyAutoPosition
    [stickyCollectionKey]="'add-edit-locations'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="modal-title" ngbAutofocus>Edit Required Locations</div>
      <div
        class="text-link"
        [class.disabled]="!(viewModel.nSelectedLocationIds$ | async)"
        appThrottleClick
        (throttleClick)="viewModel.connectToSelectedRequiredLocationIds([])">
        Clear
      </div>
    </div>
  </div>

  <div
    #contentContainer
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'add-edit-locations'"
    [stickyZ]="viewModel.stickyZIndex$ | async"
    [style.overflow-y]="'unset'">
    <div class="desc-and-search" fxLayout="column" [style.gap.rem]="1">
      <div class="modal-description">
        <p>
          Select which locations the templated menu should be <span class="required-text">required</span> at. The
          templated menu will automatically be created for all selected locations and cannot be deleted from those
          locations.
        </p>
        For locations that are not required, the user can add and remove the template at their location.
      </div>
      <hr class="no-margin" />
      <app-search-with-look-ahead
        [placeHolder]="'Search for a location'"
        [lookAheadItems]="viewModel.allLocations$ | async"
        [searchTheseProperties]="['name', 'address', 'city', 'state', 'country']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        [outputAllItemsWhenSearchIsEmpty]="true"
        (searchText)="viewModel.connectToSearchString($event)"
        (lookAheadHits)="viewModel.connectToSearchedLocations($event)">
      </app-search-with-look-ahead>
      <ng-container [ngSwitch]="(viewModel.searchedLocations$ | async)?.length > 0">
        <div *ngSwitchCase="true" class="select-all-locations-container">
          <div fxLayout="row" fxLayoutAlign="start center">
            <app-group-selection
              #groupSelectionComponent
              [selection]="viewModel.locationsVisibleOnScreen$ | async"
              [selectedIds]="viewModel.selectedRequiredLocationIdsAsStringList$ | async"
              (bulkAdd)="viewModel.addLocationIds($event)"
              (bulkRemove)="viewModel.removeLocationIds($event)">
            </app-group-selection>
            <div class="n-selected">
              <span *ngIf="viewModel.nSelectedLocationIds$ | async as nSelected" class="checked-count">
                ({{ nSelected }})
              </span>
            </div>
          </div>
          <div class="select-all-locations-text">Select All Locations Below</div>
        </div>
        <div *ngSwitchCase="false" class="empty-search-state">No locations found.</div>
      </ng-container>
    </div>
  </div>
  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="modal-search-results-container">
      <app-template-location-selection-group
        *ngFor="
          let grouping of viewModel.searchedLocationsGroupedByProvince$ | async | keyvalue : incompleteLocationsLast;
          trackBy: viewModel.trackByProvince
        "
        [province]="grouping?.key"
        [locations]="grouping?.value"
        [updatedRequiredLocationIds]="viewModel.selectedRequiredLocationIds$ | async"
        [selectedIds]="viewModel.selectedRequiredLocationIdsAsStringList$ | async"
        (addLocationIds)="viewModel.addLocationIds($event)"
        (removeLocationIds)="viewModel.removeLocationIds($event)">
      </app-template-location-selection-group>
    </div>
  </div>
</div>

<div #footerContainer class="sticky-footer-container modal-footer-flex-end" [style.gap.rem]="1">
  <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
  <lib-button-primary (buttonClicked)="update()"> Done </lib-button-primary>
</div>
