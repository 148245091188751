import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TabBarItem } from '../../../../models/shared/stylesheet/tab-bar-item';
import { BaseComponent } from '../../../../models/base/base-component';
import { NavigationExtras, Router } from '@angular/router';
import { StringUtils } from '../../../../utils/string-utils';
import { BehaviorSubject, defer, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { MatLegacyTab as MatTab, MatLegacyTabGroup as MatTabGroup, MatLegacyTabHeader as MatTabHeader } from '@angular/material/legacy-tabs';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: [
    './tab-bar.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabBarComponent extends BaseComponent implements OnChanges {

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  @Input() public tabs: TabBarItem[] = [];
  @Input() public hideTabBarHeader: boolean = false;
  @Input() public skipFragmentAppending: boolean = false;
  @Input() public showLoadingSpinnerOnTabChange: boolean = false;
  @Input() public loadingMessage: string = '';
  @Input() public loadingTimeMs: number = 500;
  @Input() public canChangeTabs: boolean = true;
  @Input() public canChangeTabsErrorMsg: string;
  @Input() public delayTabClick: number = null;
  @Input() public useDefaultHeight: boolean = true;
  @Input() public portalKey: string;
  @Output() public selectedTab = new EventEmitter<number>(true);
  @Output() public previousTab = new EventEmitter<number>(true);
  public currentSelectedTab: number = 0;
  private _appendFragmentToUrl = new Subject<TabBarItem>();

  // Loading Options
  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = defer(() => this.loadingOpts$).pipe(map(it => it?.isLoading), startWith(false));

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    super();
  }

  tabSelected(index: number): void {
    if (index === null || index === undefined) return;
    if (this.canChangeTabs) {
      if (this.showLoadingSpinnerOnTabChange) {
        this._loadingOpts.addRequest(this.loadingMessage);
        setTimeout(() => {
          this._loadingOpts.removeRequest(this.loadingMessage);
        }, this.loadingTimeMs);
      }
      this.selectedTab.emit(index);
      this.currentSelectedTab = index;
      this.tabs?.map(t => t.active = false);
      if (!!this.tabs && this.tabs?.length > this.currentSelectedTab) {
        this.tabs[this.currentSelectedTab].active = true;
        this._appendFragmentToUrl.next(this.tabs[this.currentSelectedTab]);
      }
    }
    this.cdr.detectChanges();
  }

  override setupBindings() {
    this._appendFragmentToUrl
      .pipe(takeUntil(this.onDestroy))
      .pipe(distinctUntilChanged())
      .subscribe(tab => this.appendFragmentToUrl(tab));
    if (!!this.delayTabClick) {
      // eslint-disable-next-line no-underscore-dangle
      this.tabGroup._handleClick = this.interceptTabClick.bind(this);
    }
  }

  override setupViews() {
    const selected = this.tabs?.findIndex(tab => tab.active);
    if (selected > -1) {
      this.tabSelected(selected);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabs) this.setupViews();
  }

  private appendFragmentToUrl(tab: TabBarItem) {
    if (!this.skipFragmentAppending) {
      const frag = StringUtils.toCamelCase(tab.title);
      const navigationExtras: NavigationExtras = {replaceUrl: true, fragment: frag};
      const current = this.router.parseUrl(this.router.url);
      this.router.navigate(current.root.segments, navigationExtras).then();
    }
  }

  private interceptTabClick(tab: MatTab, tabHeader: MatTabHeader, index: number) {
    this.previousTab.emit(this.currentSelectedTab);
    setTimeout(() => {
      // eslint-disable-next-line no-underscore-dangle
      MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }, this.delayTabClick);
  }

}
