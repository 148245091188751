<div class="modal-body sticky">
  <div
    libStickyAutoPosition
    class="sticky-header-container"
    [stickyCollectionKey]="'view-collection-displays'"
    [stickyZ]="9">
    <div class="modal-header no-bottom-border">
      <div class="modal-title">
        {{ viewModel.province$ | async }}
      </div>
    </div>
  </div>

  <div
    #modalBody
    class="sticky-body-container"
    libStickyAutoPosition
    [stickyCollectionKey]="'view-collection-displays'"
    [stickyZ]="9"
    [style.overflow-y]="'unset'">
    <div class="pb-16px search-and-description bg-white" fxLayout="column" [style.gap.rem]="1">
      <div class="asterisk-description">
        Required displays are indicated with a red asterisk (<span class="asterisk">*</span>).
      </div>
      <hr class="no-margin" />
      <div class="pb-16px bg-white">
        <app-search-with-look-ahead
          [placeHolder]="'Search by display name'"
          [lookAheadItems]="viewModel.displays$ | async"
          [searchTheseProperties]="['name']"
          [disablePopper]="true"
          [nLookAheadItems]="500"
          [outputAllItemsWhenSearchIsEmpty]="true"
          (searchText)="viewModel.connectToSearchString($event)"
          (lookAheadHits)="viewModel.connectToSearchedDisplays($event)"></app-search-with-look-ahead>
      </div>
    </div>
  </div>
  <div class="sticky-body-container" [style.overflow-y]="'unset'">
    <div class="modal-search-results-container">
      <div *ngIf="!(viewModel.searchedDisplays$ | async)?.length" class="empty-search-state">No displays found.</div>
      <ng-container
        *ngFor="
          let grouping of viewModel.searchedDisplaysGroupedByLocation$ | async;
          trackBy: viewModel.trackDisplayGroupingById
        ">
        <mat-accordion multi [displayMode]="'flat'">
          <mat-expansion-panel #panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="panel-header" [collapsedHeight]="'5rem'" [expandedHeight]="'5rem'">
              <div class="d-flex flex-column pt-16px">
                <div class="location-name">{{ grouping.location.name }}</div>
                <div class="location-address">{{ grouping.location.address }}</div>
                <div class="location-city">{{ grouping.location.getCityProv() }}</div>
              </div>
              <hr class="display-item no-margin" />
            </mat-expansion-panel-header>
            <app-location-display-list-item
              *ngFor="let display of grouping.displays; trackBy: viewModel.trackByDisplayId"
              [display]="display"
              [displayStatusText]="display?.id ? '' : 'New'"
              [required]="display | displayMarkedAsRequired : viewModel.requiredDisplayIds$ | async">
            </app-location-display-list-item>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </div>
  </div>
</div>

<div class="sticky-footer-container modal-footer-flex-end">
  <lib-button-primary (buttonClicked)="this.cancel()"> Done </lib-button-primary>
</div>
