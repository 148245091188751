import { Card } from './card';
import { DisplayableProductCard } from '../../menu-cards/displayable-product-card';
import { CompanyConfiguration } from '../../../../../../../../models/company/dto/company-configuration';
import { Asset } from '../../../../../../../../models/image/dto/asset';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { Menu } from '../../../../../../../../models/menu/dto/menu';
import { HydratedVariantBadge } from '../../../../../../../../models/product/dto/hydrated-variant-badge';
import { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';
import { PriceUtils } from '../../../../../../../../utils/price-utils';
import { Section } from '../../../../../../../../models/menu/dto/section';
import { CannabinoidDisplayType } from '../../../../../../../../models/utils/dto/cannabinoid-display-type-definition';
import { CannabisUnitOfMeasure } from '../../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { ProductType } from '../../../../../../../../models/enum/dto/product-type';

export class ProductCard extends Card implements DisplayableProductCard {

    menu: Menu;
    companyConfig: CompanyConfiguration;
    locationConfig: LocationConfiguration;
    variant: Variant;
    asset: Asset;

    constructor() {
        super();
    }

    // Displayable product card interface

    getAsset(): Asset {
        return this.asset;
    }

    getBadges(): HydratedVariantBadge[] {
        const badges = this.variant.getAllVariantBadges(1, null)?.filter(b => b instanceof HydratedVariantBadge);
        return badges as HydratedVariantBadge[];
    }

    hideLabels(): boolean {
        return this.menu?.menuOptions?.hideLabel;
    }

    getIsThcAndCbdProduct(): boolean {
        return this.getProductType() !== ProductType.Seed && this.getProductType() !== ProductType.Accessories;
    }

    getCardColor(): string {
        return this.menu?.variantFeature?.getVariantColor(this.variant?.id);
    }

    getCardTextColor(): string {
        return this.menu?.menuOptions?.bodyTextColor;
    }

    getCbdString(): string {
      if (this.variant.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
        return 'CBD --';
      } else {
        return 'CBD ' + this.variant.getCbd(this.menu);
      }
    }

    getThcString(): string {
      if (this.variant.cannabisUnitOfMeasure === CannabisUnitOfMeasure.NA) {
        return 'THC --';
      } else {
        return 'THC ' + this.variant.getThc(this.menu);
      }
    }

    getProductType(): ProductType {
        return this.variant.productType;
    }

    getOriginalPrice(): string {
        if (!!this.menu && !!this.variant) {
          const locationId = this.menu?.locationId;
          const priceStream = this.locationConfig?.priceFormat;
          const priceWithoutDiscounts = this.variant?.getPriceWithoutDiscounts(locationId, null, priceStream);
          return PriceUtils.formatPrice(priceWithoutDiscounts) || null;
        }
        return null;
    }

    getProductDesc(): string {
        // get description override if applicable
        const descriptionOverride = this.menu.variantFeature.descriptionOverrideMap.get(this.variant.id);
        if (descriptionOverride) {
            return descriptionOverride;
        } else {
            return this.variant.description;
        }
    }

    getProductBrand(): string {
        return this.variant.brand;
    }

    getProductName(): string {
        return this.variant.getVariantTitle();
    }

    getSalePrice(): string {
        if (!!this.menu && !!this.variant) {
            const priceStream = this.locationConfig?.priceFormat;
            const salePriceOrNull = this.variant?.getDiscountedPriceOrNull(
                this.menu?.locationId,
                this.companyConfig?.companyId,
                priceStream
            );
            return PriceUtils.formatPrice(salePriceOrNull) || null;
        }
        return null;
    }

    getSizeString(): string {
        if (this.variant.packagedQuantity > 1) {
            return `${this.variant.getFormattedUnitSize()} (x${this.variant.packagedQuantity})`;
        } else {
            return this.variant.getFormattedUnitSize();
        }
    }

    getIsCannabinoidRange(): boolean {
      const companyUsesCannabinoidRange = this.companyConfig?.cannabinoidDisplayType === CannabinoidDisplayType.Range;
      const variantUsesCannabinoidRange = this.variant?.useCannabinoidRange;
      return companyUsesCannabinoidRange || variantUsesCannabinoidRange;
    }

    getHidePrice(): boolean {
        const regEx = /^\$?0*\.?0*$/;
        const originalZero = regEx.exec(this.getOriginalPrice())?.length > 0;
        const saleZero = regEx.exec(this.getSalePrice())?.length > 0;
        return originalZero || saleZero;
    }

    getMenuForLabelComponent = (): Menu => this.menu;
    getSectionForLabelComponent = (): Section => null;
    getVariantsForLabelComponent = (): Variant[] => [this.variant];
    getLocationConfigForLabelComponent = (): LocationConfiguration => this.locationConfig;
    getCompanyConfigForLabelComponent = (): CompanyConfiguration => this.companyConfig;
    getShutOffLabelForLabelComponent = (): boolean => false;
    getClearableForLabelComponent = (): boolean => false;

}
