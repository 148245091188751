import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { Injectable } from '@angular/core';
import { MenuSectionProductInfoPreviewViewModel } from '../menu-section-product-info-preview/menu-section-product-info-preview-view-model';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Cannabinoid } from '../../../../../../../models/enum/dto/cannabinoid';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { SortUtils } from '../../../../../../../utils/sort-utils';

@Injectable()
export class MenuSectionProductCannabinoidPreviewViewModel extends MenuSectionProductInfoPreviewViewModel {

  constructor(
    private cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel,
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  private readonly rangeCannabinoidsAtCompanyLevel$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;
  public readonly enabledCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledCannabinoidNamesWithoutTAC$;
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;

  public readonly cannabinoidRange$ = combineLatest([
    this.variant$,
    this.rangeCannabinoidsAtCompanyLevel$
  ]).pipe(
    map(([variant, rangeCannabinoids]) => variant?.useCannabinoidRange || rangeCannabinoids)
  );

  public readonly getCannabinoidValuePreviewText$ = (cannabinoid: Cannabinoid): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyConfig$.notNull(),
    ]).pipe(
      map(([variant, companyConfig]) => variant?.getCannabinoidValuePreviewText(cannabinoid, companyConfig)),
      distinctUntilChanged()
    );
  };

  public readonly getTACValuePreviewText$ = (): Observable<string> => {
    return combineLatest([
      this.variant$,
      this.companyConfig$.notNull(),
    ]).pipe(
      map(([variant, companyConfig]) => variant?.getTACValuePreviewText(companyConfig)),
      distinctUntilChanged()
    );
  };

  public readonly cannabinoidsThatAreExplicitlySetInDescOrder$ = combineLatest([
    this.variant$,
    this.enabledCannabinoids$,
    this.companyConfig$,
  ]).pipe(
    map(([variant, enabledCannabinoids, companyConfig]) => {
      return variant?.cannabinoidsThatAreExplicitlySetInDescOrder(enabledCannabinoids, companyConfig);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cannabinoidsThatAreGreaterThanZero$ = combineLatest([
    this.variant$,
    this.enabledCannabinoids$,
    this.companyConfig$,
  ]).pipe(
    map(([variant, enabledCannabinoids, companyConfig]) => {
      return variant?.cannabinoidsThatAreExplicitlySetInDescOrder(enabledCannabinoids, companyConfig, true);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasCannabinoidsThatAreGreaterThanZero$ = this.cannabinoidsThatAreGreaterThanZero$.pipe(
    map(cannabinoidsGreaterThanZero => cannabinoidsGreaterThanZero?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly largestExplicitlySetCannabinoid$ = this.cannabinoidsThatAreExplicitlySetInDescOrder$.pipe(
    map(cannabinoidsThatAreExplicitlySet => cannabinoidsThatAreExplicitlySet?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly secondaryCannabinoidsThatAreGreaterThanZero$ = this.cannabinoidsThatAreGreaterThanZero$.pipe(
    map(cannabinoidsGreaterThanZero => {
      return cannabinoidsGreaterThanZero?.filter(cannabinoid => {
        const THC = cannabinoid === PrimaryCannabinoid.THC;
        const CBD = cannabinoid === PrimaryCannabinoid.CBD;
        return !THC && !CBD;
      });
    }),
  );

  public readonly secondaryCannabinoidsThatAreExplicitlySet$ = this.cannabinoidsThatAreExplicitlySetInDescOrder$.pipe(
    map(cannabinoidsThatAreExplicitlySet => {
      return cannabinoidsThatAreExplicitlySet?.filter(cannabinoid => {
        const THC = cannabinoid === PrimaryCannabinoid.THC;
        const CBD = cannabinoid === PrimaryCannabinoid.CBD;
        return !THC && !CBD;
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly secondaryCannabinoidsExplicitlySetNameAsc$ = this.secondaryCannabinoidsThatAreExplicitlySet$.pipe(
    map(cannabinoids => cannabinoids?.shallowCopy()?.sort(SortUtils.numericStringAsc)),
  );

  public readonly hasSecondaryCannabinoidsThatAreExplicitlySet$ = this.secondaryCannabinoidsThatAreExplicitlySet$.pipe(
    map(secondaryCannabinoidsThatAreExplicitlySet => secondaryCannabinoidsThatAreExplicitlySet?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly secondaryCannabinoidsGreaterThanZeroNameAsc$ = this.secondaryCannabinoidsThatAreGreaterThanZero$.pipe(
    map(cannabinoids => cannabinoids?.shallowCopy()?.sort(SortUtils.numericStringAsc)),
  );

  public readonly hasSecondaryCannabinoidsGreaterThanZero$ = this.secondaryCannabinoidsThatAreGreaterThanZero$.pipe(
    map(secondaryCannabinoidsThatAreGreaterThanZero => secondaryCannabinoidsThatAreGreaterThanZero?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyCannabinoidsAreExplicitlySet$ = this.cannabinoidsThatAreExplicitlySetInDescOrder$.pipe(
    map(cannabinoidsThatAreExplicitlySet => cannabinoidsThatAreExplicitlySet?.length ?? 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyCannabinoidsAreGreaterThanZero$ = this.cannabinoidsThatAreGreaterThanZero$.pipe(
    map(cannabinoidsThatAreGreaterThanZero => cannabinoidsThatAreGreaterThanZero?.length ?? 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly howManyVisiblePropertiesWhenPlusXMoreShown = 1;
  public readonly howManyAdditionalPropertiesBeingShownInPopper = 1; // TAC

  public readonly nMore$ = this.howManyCannabinoidsAreGreaterThanZero$.pipe(
    map(nProperties => nProperties - this.howManyVisiblePropertiesWhenPlusXMoreShown),
    map(nMore => nMore + this.howManyAdditionalPropertiesBeingShownInPopper)
  );

  public readonly oneOrLessActiveCannabinoid$ = this.howManyCannabinoidsAreExplicitlySet$.pipe(
    map(howManyCannabinoidsAreExplicitlySet => howManyCannabinoidsAreExplicitlySet <= 1),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
