import { Observable, of } from 'rxjs';
import { InventoryProviderType } from '../models/utils/dto/inventory-provider-type';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { HasId } from '../models/protocols/has-id';
import { map, switchMap, take } from 'rxjs/operators';
import { Deserializable } from '../models/protocols/deserializable';
import { EditVariantModalTabId } from '../models/enum/shared/edit-variant-modal-tab-id.enum';
import { StringUtils } from './string-utils';
import { environment } from '../../environments/environment';
import type { CustomVariantInput } from '../views/product/components/modals/edit-variant-modal/edit-variant-container';
import type { DisplayAttribute } from '../models/display/dto/display-attribute';
import type { InventoryProviderConfiguration } from '../models/company/dto/inventory-provider-configuration';
import { SyncType } from '../models/enum/dto/sync-type';
import { SyncTypeType } from '../models/utils/dto/sync-type-type';
import { InventoryProvider } from '../models/enum/dto/inventory-provider';
import { exists } from '../functions/exists';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import type { Variant } from '../models/product/dto/variant';
import { Terpene } from '../models/enum/dto/terpene';
import { SecondaryCannabinoid } from '../models/enum/dto/secondary-cannabinoid';

/**
 * All logic around Provider specific functionality should be contained in one place.
 * This will ensure new POS providers are accounted for in all scenarios of the software.
 */
export class ProviderUtils {

  // General POS Provider Helper Methods

  static getAllInventoryProviderHolders(): Observable<InventoryProviderType[]> {
    return window.types.inventoryProviders$;
  }

  static getInventoryProviderFromProviderConfigurations(configs: InventoryProviderConfiguration[]): InventoryProvider {
    const provider = configs?.firstOrNull()?.provider;
    const isDutchie = provider === InventoryProvider.DutchieMed || provider === InventoryProvider.DutchieRec;
    return isDutchie ? InventoryProvider.Dutchie : provider;
  }

  static getInventoryProviderLogoPath(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
        return 'assets/img/settings/all-leaves.png';
      case InventoryProvider.Blaze:
        return 'assets/img/settings/blaze.png';
      case InventoryProvider.Cova:
        return 'assets/img/settings/cova.png';
      case InventoryProvider.FlowHub:
        return 'assets/img/settings/flowhub.png';
      case InventoryProvider.Greenline:
        return 'assets/img/settings/greenline.png';
      case InventoryProvider.GrowFlow:
        return 'assets/img/settings/growflow.png';
      case InventoryProvider.IndicaOnline:
        return 'assets/img/settings/indicaonline.png';
      case InventoryProvider.Dutchie:
        return 'assets/img/settings/dutchie.png';
      case InventoryProvider.TechPOS:
        return 'assets/img/settings/techpos.png';
      case InventoryProvider.TendyPOS:
        return 'assets/img/settings/tendypos.png';
      case InventoryProvider.Treez:
        return 'assets/img/settings/treez.png';
    }
    return '';
  }

  static isPOSProvider(ip: InventoryProvider): boolean {
    return ip !== InventoryProvider.BudSense;
  }

  static supportsPromotions(inventoryProvider: InventoryProvider): boolean {
    // Blaze & Treez support promotions, but not implemented yet
    // TODO support TendyPOS when added to API
    // TODO support GrowFlow when access granted to API
    // TODO enable when AllLeaves promotions are hooked up
    return inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.Dutchie;
  }

  static getPromotionsTabName(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Dutchie:
      case InventoryProvider.Greenline:
        return 'Discounts';
      default:
        return 'Promotions';
    }
  }

  static supportsCompanySecondaryPrice(inventoryProvider: InventoryProvider): boolean {
    // Should be supported when POS shares product/variant ids across multiple locations
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.GrowFlow
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.IndicaOnline
        || inventoryProvider === InventoryProvider.TechPOS
        || inventoryProvider === InventoryProvider.TendyPOS
        || inventoryProvider === InventoryProvider.BudSense; // Non-integrated accounts can support this as well
  }

  static supportsPricingGroups(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Cova;
  }

  static posSupportsTaxesInPricing(inventoryProvider: InventoryProvider): boolean {
    // Blaze supports taxes, but not implemented yet
    // IndicaOnline does not expose the “Enter Prices Inclusive Of Tax” setting so can't know which is being returned
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.FlowHub;
  }

  /**
   * @returns [medConfig, recConfig]
   */
  static getMedAndRecConfigsFromProviderConfigurations(
    configs: InventoryProviderConfiguration[]
  ): [InventoryProviderConfiguration, InventoryProviderConfiguration] {
    const ip = ProviderUtils.getInventoryProviderFromProviderConfigurations(configs);
    switch (ip) {
      case InventoryProvider.Dutchie:
        const medIPC = configs?.find(i => i.provider === InventoryProvider.DutchieMed);
        const recIPC = configs?.find(i => i.provider === InventoryProvider.DutchieRec);
        return [medIPC, recIPC];
      default:
        return [null, null];
    }
  }

  // POS Sync Functionality

  static supportsPOSSync(inventoryProvider: InventoryProvider): boolean {
    return ProviderUtils.isPOSProvider(inventoryProvider);
  }

  static supportsLocationSpecificPOSSyncing(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.FlowHub
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.GrowFlow;
  }

  static supportsPOSLotInfo(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.GrowFlow
        || inventoryProvider === InventoryProvider.TendyPOS;
  }

  static supportsPOSLabels(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.FlowHub
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.IndicaOnline
        || inventoryProvider === InventoryProvider.Treez;
  }

  static supportsPOSLocationSync(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.FlowHub
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.GrowFlow
        || inventoryProvider === InventoryProvider.IndicaOnline
        || inventoryProvider === InventoryProvider.TechPOS
        || inventoryProvider === InventoryProvider.TendyPOS
        || inventoryProvider === InventoryProvider.Treez;
  }

  static supportsUsername(inventoryProvider: InventoryProvider): boolean {
    // Treez stores dispensary name in username.
    // GrowFlow stores retail organization name in username/
    // All Leaves stores the user's email in username.
    // Note that Cova technically supports username, but not something we want to show in the UI.
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.GrowFlow
        || inventoryProvider === InventoryProvider.Treez;
  }

  static getUserNameLabel(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Treez:
        return 'Dispensary Name';
      case InventoryProvider.GrowFlow:
        return 'Retail Organization Name';
      default:
        return 'Username';
    }
  }

  static supportsClientId(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Cova;
  }

  static supportsClientSecret(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.FlowHub
        || inventoryProvider === InventoryProvider.IndicaOnline
        || inventoryProvider === InventoryProvider.TendyPOS
        || inventoryProvider === InventoryProvider.TechPOS;
  }

  static supportsMedRecProducts(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.FlowHub
        || inventoryProvider === InventoryProvider.GrowFlow
        || inventoryProvider === InventoryProvider.IndicaOnline
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.Treez;
  }

  static requiresSeparateMedRecAPIKeys(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Dutchie;
  }

  static supportsLocationPrimaryAPIKey(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.Treez;
  }

  static getPrimaryAPIKeyLabel(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Dutchie:
        return 'Rec API Key';
      default:
        return 'API Key';
    }
  }

  static supportsLocationSecondaryAPIKey(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Dutchie;
  }

  static getSecondaryAPIKeyLabel(inventoryProvider: InventoryProvider): string {
    switch (inventoryProvider) {
      case InventoryProvider.Dutchie:
        return 'Med API Key';
      default:
        return 'API Key';
    }
  }

  static getSyncFullProductInfoSyncTypes(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider
  ): SyncType[] {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
        return [SyncType.Product, SyncType.Pricing];
      case InventoryProvider.Greenline:
      case InventoryProvider.GrowFlow: {
        return [SyncType.Product, SyncType.Pricing];
      }
      case InventoryProvider.TechPOS: {
        return [SyncType.Product, SyncType.Inventory, SyncType.Pricing];
      }
      case InventoryProvider.Cova:
      case InventoryProvider.TendyPOS:
      case InventoryProvider.FlowHub: {
        return [SyncType.Inventory, SyncType.Pricing];
      }
      case InventoryProvider.Treez: {
        return [
          SyncType.Product,
          SyncType.Inventory,
          SyncType.Pricing,
          SyncType.Labels,
          ...companyConfig?.syncPOSCannabinoid ? [SyncType.LotInfo] : []
        ];
      }
      case InventoryProvider.Blaze: {
        return [
          SyncType.Product,
          SyncType.Inventory,
          SyncType.Pricing,
          ...companyConfig?.syncPOSCannabinoid ? [SyncType.LotInfo] : [],
          ...companyConfig?.syncPOSLabels ? [SyncType.Labels] : []
        ];
      }
      case InventoryProvider.IndicaOnline: {
        return [
          SyncType.Product,
          SyncType.Pricing,
          ...companyConfig?.syncPOSLabels ? [SyncType.Labels] : []
        ];
      }
      default:
        return [];
    }
  }

  static getUserAllowableManualSyncTypes(inventoryProvider: InventoryProvider): SyncType[] {
    return [
      SyncType.FullProductInfo,
      SyncType.Product,
      SyncType.Inventory,
      SyncType.Pricing,
      ...ProviderUtils.supportsPOSLocationSync(inventoryProvider) ? [SyncType.Location] : []
    ];
  }

  static getManualSyncTypeGroupings(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
  ): SyncTypeGrouping[] {
    const groupings = ProviderUtils.getUserAllowableManualSyncTypes(inventoryProvider)
      ?.map(primarySyncType => new SyncTypeGrouping(companyConfig, inventoryProvider, primarySyncType))
      ?.filter(grouping => !grouping?.isEmpty()) ?? [];
    const duplicatesRemoved = groupings.shallowCopy();
    groupings.forEach(grouping => {
      const findDuplicates = duplicatesRemoved
        ?.filter(g => g?.childSyncTypes?.intersection(grouping?.childSyncTypes)?.length > 0)
        ?.sort((a, b) => a?.childSyncTypes?.length - b?.childSyncTypes?.length);
      if (findDuplicates?.length > 1) {
        duplicatesRemoved?.remove(findDuplicates?.firstOrNull());
      }
    });
    return duplicatesRemoved;
  }

  // Display Attribute Functionality

  static shouldDisableDisplayAttributeCannabinoidInputs(
    syncPOSCannabinoid: boolean,
    lotInfoExists: boolean,
    inventoryProvider: InventoryProvider
  ): boolean {
    const supportsLotInfo = ProviderUtils.supportsPOSLotInfo(inventoryProvider);
    // Always disabled inputs for POS provider that doesn't support lot info and sync is enabled
    const nonLotInfoProviderDisabled = syncPOSCannabinoid && !supportsLotInfo;
    // Only disable inputs for POS provider that supports lot info if lot info is present
    const lotInfoProviderDisabled = supportsLotInfo && syncPOSCannabinoid && lotInfoExists;
    return nonLotInfoProviderDisabled || lotInfoProviderDisabled;
  }

  static getDisplayAttributeCannabinoidPlaceholder(
    inventoryProvider: InventoryProvider,
    inputIsDisabled: boolean,
    companyDisplayAttr: DisplayAttribute,
    companyDisplayAttrPropertyValue: string,
    propertyName: string,
    disableTACInput: boolean,
    locationTAC?: string,
    variant?: Variant
  ): string {
    const isLocationCannabinoidProvider = inventoryProvider === InventoryProvider.FlowHub
      || inventoryProvider === InventoryProvider.Greenline
      || inventoryProvider === InventoryProvider.IndicaOnline
      || inventoryProvider === InventoryProvider.TechPOS;
    switch (true) {
      case exists(companyDisplayAttr) && exists(companyDisplayAttrPropertyValue):
        return `${companyDisplayAttrPropertyValue} (Company Default)`;
      case exists(locationTAC) && disableTACInput && propertyName === PrimaryCannabinoid.TAC:
      case exists(locationTAC) && disableTACInput && propertyName === `Min ${PrimaryCannabinoid.TAC}`:
      case exists(locationTAC) && disableTACInput && propertyName === `Max ${PrimaryCannabinoid.TAC}`:
        return `${locationTAC} (Auto-calculated)`;
      case exists(locationTAC) && propertyName === PrimaryCannabinoid.TAC:
      case exists(locationTAC) && propertyName === `Min ${PrimaryCannabinoid.TAC}`:
      case exists(locationTAC) && propertyName === `Max ${PrimaryCannabinoid.TAC}`:
        return `${locationTAC} (Auto-calculated) Enter value to override`;
      case exists(variant?.THC) && propertyName === PrimaryCannabinoid.THC:
        return `${variant?.THC} (Variant Default)`;
      case exists(variant?.minTHC) && propertyName === `Min ${PrimaryCannabinoid.THC}`:
        return `${variant?.minTHC} (Variant Default)`;
      case exists(variant?.maxTHC) && propertyName === `Max ${PrimaryCannabinoid.THC}`:
        return `${variant?.maxTHC} (Variant Default)`;
      case exists(variant?.CBD) && propertyName === PrimaryCannabinoid.CBD:
        return `${variant?.CBD} (Variant Default)`;
      case exists(variant?.minCBD) && propertyName === `Min ${PrimaryCannabinoid.CBD}`:
        return `${variant?.minCBD} (Variant Default)`;
      case exists(variant?.maxCBD) && propertyName === `Max ${PrimaryCannabinoid.CBD}`:
        return `${variant?.maxCBD} (Variant Default)`;
      case inputIsDisabled && isLocationCannabinoidProvider:
        return `Override location ${propertyName} in ${inventoryProvider}`;
      default:
        return `Enter the override ${propertyName} content`;
    }
  }

  static getDisplayAttributeTerpenePlaceholder(
    inventoryProvider: InventoryProvider,
    inputIsDisabled: boolean,
    companyDisplayAttr: DisplayAttribute,
    companyDisplayAttrPropertyValue: string,
    propertyName: string,
    locationTotalTerpenes?: string,
  ): string {
    const isLocationTerpeneProvider = ProviderUtils.supportsIndividualTerpeneValues(inventoryProvider);
    const totalTerpeneSecondHalfText = inputIsDisabled
      ? `Override ${propertyName} in ${inventoryProvider}`
      : 'Enter value to override';
    if (exists(companyDisplayAttr) && exists(companyDisplayAttrPropertyValue)) {
      return `${companyDisplayAttrPropertyValue} (Company Default)`;
    } else if (exists(locationTotalTerpenes) && propertyName === 'Total Terpene') {
      return `${locationTotalTerpenes} (Auto-calculated) ` + totalTerpeneSecondHalfText;
    } else if (exists(locationTotalTerpenes) && propertyName === `Min Total Terpene`) {
      return `${locationTotalTerpenes} (Auto-calculated) `  + totalTerpeneSecondHalfText;
    } else if (exists(locationTotalTerpenes) && propertyName === `Max Total Terpene`) {
      return `${locationTotalTerpenes} (Auto-calculated) `  + totalTerpeneSecondHalfText;
    } else if (isLocationTerpeneProvider && inputIsDisabled) {
      return `Override location ${propertyName} in ${inventoryProvider}`;
    } else {
      return `Enter the override ${propertyName} content`;
    }
  }

  static getDisplayAttributeLotInfoBannerText(
    inventoryProvider: InventoryProvider,
    displayAttribute: DisplayAttribute,
    selectedTabId: EditVariantModalTabId
  ): string {
    const noun = selectedTabId === EditVariantModalTabId.Terpenes ? 'terpene' : 'cannabinoid';
    const companyValueStatement = noun === 'terpene'
      ? ''
      : ` Company ${StringUtils.capitalize(noun)} values are disabled.`;
    switch (inventoryProvider) {
      case InventoryProvider.FlowHub:
      case InventoryProvider.Greenline:
      case InventoryProvider.IndicaOnline:
      case InventoryProvider.TechPOS:
        return `Sync ${StringUtils.capitalize(noun)}s from POS is enabled in settings. The ${noun} values below `
          + `are pulled from the location specific values in ${inventoryProvider} and can not be `
          + `edited in BudSense.`
          + companyValueStatement;
      case InventoryProvider.AllLeaves:
      case InventoryProvider.Cova:
      case InventoryProvider.Dutchie:
      case InventoryProvider.GrowFlow:
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
      case InventoryProvider.TendyPOS:
        if (!displayAttribute?.lotInfo && displayAttribute.isCompanyDA()) {
          return `Sync ${StringUtils.capitalize(noun)}s from POS is enabled in settings. The ${noun} values below `
            + `are pulled from the inventory lot if applicable. The Location ${noun} values `
            + `will override these values if lot information is available. `
            + companyValueStatement;
        } else if (!displayAttribute?.lotInfo && displayAttribute.isLocationDA()) {
          return `Sync ${StringUtils.capitalize(noun)}s from POS is enabled in settings. The ${noun} `
            + `values below are pulled from the inventory lot if applicable. If no lot information is `
            + `available, these values can be manually overridden.`;
        } else if (!!displayAttribute?.lotInfo) {
          return `Sync ${StringUtils.capitalize(noun)}s from POS is enabled in settings. The ${noun} values below `
            + `are pulled from the inventory lot if applicable. If values are set in the oldest `
            + `inventory lot, they will appear below and the values may not be edited in BudSense. `
            + `Information about the specific lot is included below.`;
        }
    }
    return '';
  }

  // Variant Inventory Limitations

  static getVariantInventoryUpperBound(inventoryProvider: InventoryProvider): number {
    switch (inventoryProvider) {
      case InventoryProvider.Treez:
        // Treez does not return inventory levels over 30
        return 30;
      default:
        return undefined;
    }
  }

  static applyVariantInventoryDecorator(inventoryProvider: InventoryProvider, qty: number | string): string {
    const upperBound = ProviderUtils.getVariantInventoryUpperBound(inventoryProvider);
    if (upperBound && Number(qty) >= upperBound) {
      return `${qty}+`;
    }
    return `${qty}`;
  }

  /**
   * PosProductCategories are saved on the company config. If this is true, then the PosProductCategories saved on
   * the company config are location specific. The treeId on the PosProductCategory will be equal the locationId.
   * The PosProductCategories presented to the user within: setting -> products -> product categories, will be
   * filtered based on which location the user is currently viewing. When a user updates these categories, all
   * PosProductCategories will get sent to the backend, so that other location categories are not nuked out of
   * the system.
   */
  static posProductCategoriesAreSetPerLocation(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Dutchie;
  }

  /**
   * If true, then the start time and end time of a sale will be displayed in edit variant -> pricing.
   */
  static supportsExplicitSaleStartEndTime(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.Greenline
        || inventoryProvider === InventoryProvider.TechPOS
        || inventoryProvider === InventoryProvider.Treez;
  }

  static supportsInventoryRooms(inventoryProvider: InventoryProvider): boolean {
    return inventoryProvider === InventoryProvider.AllLeaves
        || inventoryProvider === InventoryProvider.Blaze
        || inventoryProvider === InventoryProvider.Cova
        || inventoryProvider === InventoryProvider.Dutchie
        || inventoryProvider === InventoryProvider.Treez;
  }

  static supportsPresentTerpenes(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      case InventoryProvider.Cova:
      case InventoryProvider.Greenline:
      case InventoryProvider.TendyPOS:
        return true;
      default:
        return false;
    }
  }

  static supportsIndividualTerpeneValues(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
      case InventoryProvider.Dutchie:
      case InventoryProvider.DutchieMed:
      case InventoryProvider.DutchieRec:
      case InventoryProvider.IndicaOnline:
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
      case InventoryProvider.TechPOS:
      case InventoryProvider.FlowHub:
        return true;
      default:
        return false;
    }
  }

  static supportsPresentSecondaryCannabinoids(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
      case InventoryProvider.Cova:
      case InventoryProvider.Greenline:
      case InventoryProvider.Dutchie:
      case InventoryProvider.DutchieMed:
      case InventoryProvider.DutchieRec:
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
      case InventoryProvider.FlowHub:
      case InventoryProvider.GrowFlow:
        return true;
      default:
        return false;
    }
  }

  static getSupportedSecondaryCannabinoids(inventoryProvider: InventoryProvider): SecondaryCannabinoid[] {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
        return [
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBGA,
          SecondaryCannabinoid.CBL,
          SecondaryCannabinoid.CBLA,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.CBNA,
          SecondaryCannabinoid.CBT,
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBCA,
          SecondaryCannabinoid.CBCV,
          SecondaryCannabinoid.CBDV,
          SecondaryCannabinoid.THC8,
          SecondaryCannabinoid.THC9,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THCV,
        ];
      case InventoryProvider.Cova:
        return [
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBDV,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.THCV,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THC8,
        ];
      case InventoryProvider.Greenline:
        return [
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBGA,
          SecondaryCannabinoid.CBL,
          SecondaryCannabinoid.CBLA,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.CBNA,
          SecondaryCannabinoid.CBT,
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBCA,
          SecondaryCannabinoid.CBCV,
          SecondaryCannabinoid.CBDV,
          SecondaryCannabinoid.THC8,
          SecondaryCannabinoid.THC9,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THCV,
        ];
      case InventoryProvider.Dutchie:
        return [
          SecondaryCannabinoid.CBL,
          SecondaryCannabinoid.CBLA,
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBDV,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBGA,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.CBNA,
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBCA,
          SecondaryCannabinoid.CBCV,
          SecondaryCannabinoid.CBT,
          SecondaryCannabinoid.THC8,
          SecondaryCannabinoid.THC9,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THCV,
        ];
      case InventoryProvider.Treez:
        return [
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBGA,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBCV,
          SecondaryCannabinoid.CBDV,
          SecondaryCannabinoid.THC8,
          SecondaryCannabinoid.THC9,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THCV,
        ];
      case InventoryProvider.Blaze:
        return [
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.THCA,
        ];
      case InventoryProvider.FlowHub:
        return [
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBG,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.THCA,
          SecondaryCannabinoid.THCV,
        ];
      case InventoryProvider.GrowFlow:
        return [
          SecondaryCannabinoid.CBC,
          SecondaryCannabinoid.CBDA,
          SecondaryCannabinoid.CBN,
          SecondaryCannabinoid.THCA,
        ];
      default:
        return [];
    }
  }

  static supportsTotalActiveCannabinoids(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
      case InventoryProvider.Dutchie:
      case InventoryProvider.DutchieMed:
      case InventoryProvider.DutchieRec:
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
      case InventoryProvider.GrowFlow:
      case InventoryProvider.FlowHub:
        return true;
      default:
        return false;
    }
  }

  static supportsTotalTerpene(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
      case InventoryProvider.Dutchie:
      case InventoryProvider.Treez:
      case InventoryProvider.Blaze:
      case InventoryProvider.TendyPOS:
      case InventoryProvider.FlowHub:
      case InventoryProvider.GrowFlow:
      case InventoryProvider.IndicaOnline:
        return true;
      default:
        return false;
    }
  }

  static supportsTopTerpene(inventoryProvider: InventoryProvider): boolean {
    switch (inventoryProvider) {
      // Currently no providers support top terpenes in that they have an explicit field for it
      default:
        return false;
    }
  }

  static getSupportedIndividualTerpenes(inventoryProvider: InventoryProvider): Terpene[] {
    switch (inventoryProvider) {
      case InventoryProvider.AllLeaves:
        return [
          Terpene.AlphaCedrene,
          Terpene.AlphaHumulene,
          Terpene.AlphaPhellandrene,
          Terpene.AlphaPinene,
          Terpene.AlphaTerpinene,
          Terpene.BetaCaryophyllene,
          Terpene.BetaEudesmol,
          Terpene.BetaMyrcene,
          Terpene.BetaOcimene,
          Terpene.BetaPinene,
          Terpene.Bisabolol,
          Terpene.Borneol,
          Terpene.Camphene,
          Terpene.Caryophyllene,
          Terpene.CaryophylleneOxide,
          Terpene.CisNerolidol,
          Terpene.Delta3Carene,
          Terpene.Eucalyptol,
          Terpene.Fenchol,
          Terpene.Geraniol,
          Terpene.Guaiol,
          Terpene.Humulene,
          Terpene.Isopulegol,
          Terpene.Limonene,
          Terpene.Linalool,
          Terpene.Myrcene,
          Terpene.Nerol,
          Terpene.Nerolidol,
          Terpene.Ocimene,
          Terpene.ParaCymenene,
          Terpene.Phytol,
          Terpene.Pinene,
          Terpene.Pulegone,
          Terpene.Sabinene,
          Terpene.Terpinene,
          Terpene.Terpineol,
          Terpene.Terpinolene,
          Terpene.TransCaryophyllene,
          Terpene.TransNerolidol,
          Terpene.Valencene,
        ];
      case InventoryProvider.Dutchie:
        return [
          Terpene.AlphaPinene,
          Terpene.AlphaTerpinene,
          Terpene.BetaPinene,
          Terpene.BetaMyrcene,
          Terpene.BetaEudesmol,
          Terpene.BetaCaryophyllene,
          Terpene.Bisabolol,
          Terpene.Camphene,
          Terpene.CaryophylleneOxide,
          Terpene.CisNerolidol,
          Terpene.CisOcimene,
          Terpene.Eucalyptol,
          Terpene.Fenchol,
          Terpene.Geraniol,
          Terpene.GeranylAcetate,
          Terpene.Guaiol,
          Terpene.Humulene,
          Terpene.Isopulegol,
          Terpene.Linalool,
          Terpene.Limonene,
          Terpene.Nerol,
          Terpene.Nerolidol,
          Terpene.Ocimene,
          Terpene.ParaCymenene,
          Terpene.Phytol,
          Terpene.Sabinene,
          Terpene.ThreeCarene,
          Terpene.Terpinene,
          Terpene.Terpineol,
          Terpene.Terpinolene,
          Terpene.TransNerolidol,
          Terpene.Valencene,
        ];
      case InventoryProvider.Treez:
        return [
          Terpene.AlphaBisabolol,
          Terpene.AlphaCaryophyllene,
          Terpene.AlphaCedrene,
          Terpene.AlphaHumulene,
          Terpene.AlphaPhellandrene,
          Terpene.AlphaPinene,
          Terpene.AlphaTerpinene,
          Terpene.AlphaTerpineol,
          Terpene.Bergamotene,
          Terpene.BetaCaryophyllene,
          Terpene.BetaMyrcene,
          Terpene.BetaPinene,
          Terpene.Borneol,
          Terpene.Cadinene,
          Terpene.Camphene,
          Terpene.Camphor,
          Terpene.Carvacrol,
          Terpene.Carvone,
          Terpene.Caryophyllene,
          Terpene.CaryophylleneOxide,
          Terpene.Cedrene,
          Terpene.Cedrol,
          Terpene.CisNerolidol,
          Terpene.CisOcimene,
          Terpene.Citral,
          Terpene.Citronellol,
          Terpene.Cymene,
          Terpene.Delta3Carene,
          Terpene.DeltaLimonene,
          Terpene.EndoFenchyl,
          Terpene.Eucalyptol,
          Terpene.Eugenol,
          Terpene.Farnesene,
          Terpene.Farnesol,
          Terpene.Fenchol,
          Terpene.Fenchone,
          Terpene.Geraniol,
          Terpene.GeranylAcetate,
          Terpene.Guaiol,
          Terpene.Humulene,
          Terpene.Isopulegol,
          Terpene.Limonene,
          Terpene.Linalool,
          Terpene.Myrcene,
          Terpene.Nerol,
          Terpene.Nerolidol,
          Terpene.Ocimene,
          Terpene.ParaCymenene,
          Terpene.Phellandrene,
          Terpene.Phytol,
          Terpene.Pinene,
          Terpene.Pulegone,
          Terpene.Sabinene,
          Terpene.Terpinene,
          Terpene.Terpineol,
          Terpene.Terpinolene,
          Terpene.TransCaryophyllene,
          Terpene.TransNerolidol,
          Terpene.Valencene,
        ];
      case InventoryProvider.Blaze:
        return [
          Terpene.AlphaBisabolol,
          Terpene.AlphaHumulene,
          Terpene.AlphaPinene,
          Terpene.BetaCaryophyllene,
          Terpene.BetaMyrcene,
          Terpene.BetaPinene,
          Terpene.Bisabolol,
          Terpene.Borneol,
          Terpene.Camphene,
          Terpene.Camphor,
          Terpene.Carene,
          Terpene.CaryophylleneOxide,
          Terpene.Cedrene,
          Terpene.Cymene,
          Terpene.Eucalyptol,
          Terpene.Fenchol,
          Terpene.Geraniol,
          Terpene.GeranylAcetate,
          Terpene.Guaiol,
          Terpene.Humulene,
          Terpene.Isopulegol,
          Terpene.Limonene,
          Terpene.Linalool,
          Terpene.Myrcene,
          Terpene.Nerolidol,
          Terpene.Ocimene,
          Terpene.Phellandrene,
          Terpene.Phytol,
          Terpene.Pinene,
          Terpene.Pulegone,
          Terpene.Sabinene,
          Terpene.Terpinene,
          Terpene.Terpineol,
          Terpene.Terpinolene,
          Terpene.Valencene,
        ];
      case InventoryProvider.TechPOS:
        return Object.values(Terpene);
      case InventoryProvider.FlowHub:
        return [
          Terpene.AlphaBisabolol,
          Terpene.AlphaCedrene,
          Terpene.AlphaHumulene,
          Terpene.AlphaPinene,
          Terpene.AlphaPhellandrene,
          Terpene.AlphaTerpinene,
          Terpene.AlphaTerpineol,
          Terpene.BetaCaryophyllene,
          Terpene.BetaMyrcene,
          Terpene.BetaOcimene,
          Terpene.BetaPinene,
          Terpene.Borneol,
          Terpene.CaryophylleneOxide,
          Terpene.Camphene,
          Terpene.CisOcimene,
          Terpene.CisNerolidol,
          Terpene.Citral,
          Terpene.Carvone,
          Terpene.Carvacrol,
          Terpene.Cedrol,
          Terpene.Carene,
          Terpene.Delta3Carene,
          Terpene.DeltaLimonene,
          Terpene.Eucalyptol,
          Terpene.Fenchol,
          Terpene.Fenchone,
          Terpene.Guaiol,
          Terpene.Geraniol,
          Terpene.GeranylAcetate,
          Terpene.Isopulegol,
          Terpene.Limonene,
          Terpene.Linalool,
          Terpene.Cymene,
          Terpene.Pulegone,
          Terpene.ParaCymenene,
          Terpene.Sabinene,
          Terpene.Terpineol,
          Terpene.Terpinolene,
          Terpene.TransNerolidol,
          Terpene.Ocimene,
          Terpene.Valencene,
        ];
      case InventoryProvider.IndicaOnline:
        return [
          Terpene.AlphaBisabolol,
          Terpene.AlphaHumulene,
          Terpene.AlphaPinene,
          Terpene.AlphaTerpinene,
          Terpene.BetaPinene,
          Terpene.BetaCaryophyllene,
          Terpene.BetaMyrcene,
          Terpene.CaryophylleneOxide,
          Terpene.Eucalyptol,
          Terpene.Limonene,
          Terpene.Linalool,
          Terpene.Myrcene,
          Terpene.Nerolidol,
          Terpene.Phytol,
          Terpene.Pinene,
          Terpene.BetaOcimene,
          Terpene.Camphene,
          Terpene.CisNerolidol,
          Terpene.DeltaLimonene,
          Terpene.Delta3Carene,
          Terpene.Geraniol,
          Terpene.Guaiol,
          Terpene.Isopulegol,
          Terpene.Ocimene,
          Terpene.Terpinolene,
          Terpene.TransNerolidol,
          Terpene.AlphaPhellandrene,
          Terpene.AlphaTerpineol,
          Terpene.BetaEudesmol,
          Terpene.Borneol,
          Terpene.Camphor,
          Terpene.CisOcimene,
          Terpene.Farnesene,
          Terpene.Nerol,
          Terpene.Valencene,
          Terpene.GeranylAcetate,
          Terpene.AlphaCedrene,
          Terpene.Cedrol,
          Terpene.Fenchone,
          Terpene.Sabinene,
          Terpene.Pulegone,
        ];
      default:
        return [];
    }
  }

  /**
   * The Variant.ts object has custom1, custom2, and custom3 as addition string properties. These properties are
   * used to store additional information about the variant. This method will return the supported custom
   * variant inputs for the given inventory provider. The order in which they are returned is the order that they are
   * displayed in the UI.
   */
  static getSupportedCustomVariantInputs(inventoryProvider: InventoryProvider): CustomVariantInput[] | null {
    switch (true) {
      case !environment.production && inventoryProvider === InventoryProvider.FlowHub:
        return [
          { label: 'FlowHub Variant Type', bindToVariantProperty: 'custom1', disabled: true },
          { label: 'Custom 2', bindToVariantProperty: 'custom2', disabled: false },
          { label: 'Custom 3', bindToVariantProperty: 'custom3', disabled: false }
        ];
      default:
        return null;
    }
  }

}

// Needs to be in this file to avoid circular import
export class SyncTypeGrouping implements Deserializable, HasId {

  constructor(
    compConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
    public parentSyncType: SyncType,
  ) {
    const fullProductInfoSyncTypes = ProviderUtils.getSyncFullProductInfoSyncTypes(compConfig, inventoryProvider);
    if (parentSyncType === SyncType.FullProductInfo) {
      const getSubtypeGrouping = (type) => SyncTypeGrouping.getSubtypeGrouping(compConfig, inventoryProvider, type);
      const groupings = fullProductInfoSyncTypes?.length > 0
        ? fullProductInfoSyncTypes?.map(getSubtypeGrouping)
        : [];
      const duplicatedRemoved = groupings.shallowCopy();
      groupings.forEach(grouping => {
        const findDuplicates = duplicatedRemoved
          ?.filter(g => g?.intersection(grouping)?.length > 0)
          ?.sort((a, b) => a?.length - b?.length);
        if (findDuplicates?.length > 1) {
          duplicatedRemoved?.remove(findDuplicates?.firstOrNull());
        }
      });
      this.childSyncTypes = duplicatedRemoved;
      return;
    }
    if (!fullProductInfoSyncTypes?.includes(parentSyncType)) {
      this.childSyncTypes = [SyncTypeGrouping.getSubtypeGrouping(compConfig, inventoryProvider, parentSyncType)];
    }
  }

  public childSyncTypes: SyncType[][];

  static getSubtypeGrouping(
    companyConfig: CompanyConfiguration,
    inventoryProvider: InventoryProvider,
    primarySyncType: SyncType
  ): SyncType[] {
    switch (primarySyncType) {
      case SyncType.Product: {
        return [
          SyncType.Product,
          ...companyConfig?.syncPOSLabels ? [SyncType.Labels] : []
        ];
      }
      case SyncType.Inventory: {
        const supportsLotInfo = ProviderUtils.supportsPOSLotInfo(inventoryProvider);
        const syncPOSLotInfo = companyConfig?.syncPOSCannabinoid || companyConfig?.syncPOSTerpene;
        return [
          SyncType.Inventory,
          ...(supportsLotInfo && syncPOSLotInfo) ? [SyncType.LotInfo] : []
        ];
      }
      case SyncType.Pricing: {
        return [
          SyncType.Pricing,
          ...ProviderUtils.supportsPromotions(inventoryProvider) ? [SyncType.Promotions] : []
        ];
      }
    }
    return [primarySyncType];
  }

  static buildFromId(id: string): SyncTypeGrouping {
    return window.injector.Deserialize.instanceOf(SyncTypeGrouping, JSON.parse(id));
  }

  onDeserialize() {
  }

  getId(): string {
    return JSON.stringify(this);
  }

  getHumanReadableParentName(provider: InventoryProvider): Observable<string> {
    return window.types.syncTypes$.pipe(
      switchMap(syncTypes => {
        const child = this.childSyncTypes?.firstOrNull();
        const isSingleLineMultiSync = (this.childSyncTypes?.length === 1) && (child?.length > 1);
        return isSingleLineMultiSync
          ? this.getChildSyncTypeNames(provider).pipe(map(childNames => childNames?.firstOrNull()))
          : this.getParentSyncTypeName(syncTypes, provider);
      }),
      take(1)
    );
  }

  private getHumanReadableChildSyncTypeNames = (syncTypes: SyncTypeType[], provider: InventoryProvider): string[] => {
    return this.childSyncTypes?.map(types => {
      return types
        ?.map(subType => {
          return subType === SyncType.Promotions
            ? ProviderUtils.getPromotionsTabName(provider)
            : syncTypes?.find(t => t?.value === subType)?.name;
        })
        ?.filterNulls()
        ?.join(' / ');
    });
  };

  getParentSyncTypeName(syncTypes: SyncTypeType[], provider: InventoryProvider): Observable<string> {
    const syncType = syncTypes?.find(t => t?.value === this.parentSyncType);
    switch (true) {
      case syncType?.value === SyncType.Location && ProviderUtils.supportsInventoryRooms(provider):
        return of(`${syncType?.name} / Inventory Rooms`);
      default:
        return of(syncType?.name);
    }
  }

  getChildSyncTypeNames(provider: InventoryProvider): Observable<string[]> {
    return window.types.syncTypes$.pipe(
      map(types => this.getHumanReadableChildSyncTypeNames(types, provider)),
      take(1)
    );
  }

  hasDisplayableChildren(): boolean {
    return this.childSyncTypes?.length > 1;
  }

  isEmpty(): boolean {
    return !this.childSyncTypes?.length;
  }

}
