<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<app-search-with-look-ahead
  style="display: block"
  class="bg-white pt-16px pb-8px"
  [class.pb-16px]="displayContentOption === ClientTypes.DisplayContentOption.Menu"
  libStickyAutoPosition
  [stickyCollectionKey]="stickyCollectionKey"
  [stickyZ]="9"
  [stickyOrder]="1"
  [style.overflow-y]="'unset'"
  [placeHolder]="searchPlaceholder"
  [lookAheadItems]="itemsToSearch"
  [searchTheseProperties]="['name']"
  [disablePopper]="true"
  [nLookAheadItems]="10000"
  [outputAllItemsWhenSearchIsEmpty]="true"
  (lookAheadHits)="viewModel.connectToSearchedItems($event)">
</app-search-with-look-ahead>

<app-collection-results
  *ngIf="displayContentOption === ClientTypes.DisplayContentOption.TemplateCollection"
  [display]="display"
  [emptyStateText]="emptyStateText"
  [previouslySelectedItemIds]="itemIdsToAdd"
  [searchedItems]="viewModel.searchedTemplateCollections$ | push"
  (itemClicked)="itemClicked.emit($event)">
</app-collection-results>

<app-menu-results
  *ngIf="displayContentOption === ClientTypes.DisplayContentOption.Menu"
  [collectionMode]="collectionMode"
  [display]="display"
  [emptyStateText]="emptyStateText"
  [previouslySelectedItemIds]="itemIdsToAdd"
  [searchedItems]="viewModel.searchedMenus$ | push"
  (itemClicked)="itemClicked.emit($event)">
</app-menu-results>

<br />
