import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantTopTerpene'
})
export class GetVariantTopTerpenePipe implements PipeTransform {

  transform(variant: Variant | null): string | null {
    return variant?.activeTopTerpene;
  }

}
