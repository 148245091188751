import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject } from 'rxjs';
import type { Variant } from '../../../../../../../models/product/dto/variant';

@Injectable()
export abstract class MenuSectionProductInfoPreviewViewModel extends BaseViewModel {

  private readonly _variant = new BehaviorSubject<Variant>(null);
  public readonly variant$ = this._variant as BehaviorSubject<Variant>;
  connectToVariant = (variant: Variant) => this._variant.next(variant);

}
