import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicRoundBorderLabelComponent } from './basic-round-border-label/basic-round-border-label.component';
import { FlagLabelComponent } from './flag-label/flag-label.component';
import { LabelInflatorComponent } from './label-inflator/label-inflator.component';
import { FitTextModule } from '../scale-up-text/fit-text.module';
import { LabelInflatorContainerDirective } from './label-inflator/label-inflator-container.directive';
import { LabelThrottleClickDirective } from './label-remove-button/label-throttle-click.directive';
import { LabelRemoveButtonComponent } from './label-remove-button/label-remove-button.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatRippleModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LabelLockedComponent } from './label-locked/label-locked.component';
import { IsSaleSystemLabelPipe } from './pipes/is-sale-system-label.pipe';
import { LabelAddedBySmartFilterBadgeComponent } from './label-added-by-smart-filter-badge/label-added-by-smart-filter-badge.component';
import { AddCompanyLabelComponent } from './add-new-label-modal/add-company-label/add-company-label.component';
import { AddNewLabelModalComponent } from './add-new-label-modal/add-new-label-modal.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { SharedModule } from '../../views/shared/shared.module';
import { LabelPickerComponent } from './label-picker/label-picker.component';
import { ConnectLabelPreviewToDisplayLabelInterfacePipe } from './label-preview/connect-label-preview-to-display-label-interface.pipe';
import { ConnectLabelPreviewToDisplaySaleLabelInterfacePipe } from './label-preview/connect-label-preview-to-display-sale-label-interface.pipe';
import { LabelPreviewComponent } from './label-preview/label-preview.component';
import { InlineLabelPickerComponent } from './inline-label-picker/inline-label-picker.component';
import { LabelActionCardComponent } from './label-action-card/label-action-card.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { LabelHierarchyBannerComponent } from './label-hierarchy-banner/label-hierarchy-banner.component';
import { GetDisplayLabelInterfaceForLabelActionCardPipe } from './label-action-card/get-display-label-interface-for-label-action-card.pipe';
import { GetDisplayLabelInterfaceForLabelHierarchyPipe } from './label-hierarchy-banner/get-display-label-interface-for-label-hierarchy.pipe';
import { SimpleLabelPreviewComponent } from './simple-label-preview/simple-label-preview.component';
import { ReactiveFormInlineLabelsPickerComponent } from './inline-labels-picker/reactive-form-inline-labels-picker.component';
import { ReactiveFormLabelsPickerComponent } from './labels-picker/reactive-form-labels-picker.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { IsCompanyOrphanPipe } from './pipes/is-company-orphan.pipe';
import { IsSmartLabelWithRemoveExistingOnSyncEnabledPipe } from './label-picker/is-smart-label-with-remove-existing-on-sync-enabled.pipe';
import { SmartLabelDisabledTooltipPipe } from './label-picker/smart-label-disabled-tooltip.pipe';
import { IsReactiveFormCheckboxSelectedPipe } from './pipes/is-reactive-form-checkbox-selected.pipe';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';

@NgModule({
  imports: [
    CommonModule,
    FitTextModule,
    FlexLayoutModule,
    MatRippleModule,
    NgbModule,
    ReactiveFormModule,
    SharedModule,
    NgxPopperjsModule,
    ReactiveButtonsModule,
    StickyAutoPositionDirective
  ],
  declarations: [
    LabelInflatorContainerDirective,
    BasicRoundBorderLabelComponent,
    FlagLabelComponent,
    LabelInflatorComponent,
    LabelThrottleClickDirective,
    LabelRemoveButtonComponent,
    LabelLockedComponent,
    IsSaleSystemLabelPipe,
    LabelAddedBySmartFilterBadgeComponent,
    AddCompanyLabelComponent,
    AddNewLabelModalComponent,
    LabelPickerComponent,
    ConnectLabelPreviewToDisplayLabelInterfacePipe,
    ConnectLabelPreviewToDisplaySaleLabelInterfacePipe,
    LabelPreviewComponent,
    InlineLabelPickerComponent,
    LabelActionCardComponent,
    LabelHierarchyBannerComponent,
    GetDisplayLabelInterfaceForLabelActionCardPipe,
    GetDisplayLabelInterfaceForLabelHierarchyPipe,
    SimpleLabelPreviewComponent,
    ReactiveFormInlineLabelsPickerComponent,
    ReactiveFormLabelsPickerComponent,
    IsCompanyOrphanPipe,
    IsSmartLabelWithRemoveExistingOnSyncEnabledPipe,
    SmartLabelDisabledTooltipPipe,
    IsReactiveFormCheckboxSelectedPipe
  ],
  exports: [
    BasicRoundBorderLabelComponent,
    FlagLabelComponent,
    LabelInflatorComponent,
    IsSaleSystemLabelPipe,
    AddCompanyLabelComponent,
    AddNewLabelModalComponent,
    LabelPickerComponent,
    ConnectLabelPreviewToDisplayLabelInterfacePipe,
    ConnectLabelPreviewToDisplaySaleLabelInterfacePipe,
    LabelPreviewComponent,
    InlineLabelPickerComponent,
    LabelActionCardComponent,
    LabelHierarchyBannerComponent,
    GetDisplayLabelInterfaceForLabelActionCardPipe,
    GetDisplayLabelInterfaceForLabelHierarchyPipe,
    ReactiveFormInlineLabelsPickerComponent,
    LabelThrottleClickDirective
  ]
})
export class ProductLabelsModule { }
