import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuSectionProductCannabinoidPreviewViewModel } from '../components/edit-menu/shared/menu-section-product-preview/menu-section-product-cannabinoid-preview/menu-section-product-cannabinoid-preview-view-model';

@Pipe({
  name: 'getTACValuePreviewText'
})
export class GetTacValuePreviewTextPipe implements PipeTransform {

  transform(vm: MenuSectionProductCannabinoidPreviewViewModel): Observable<string> {
    return vm.getTACValuePreviewText$();
  }

}
