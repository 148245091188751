import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DisplayRoutingModule } from './display-routing.module';
import { AllDisplaysComponent } from './components/all-displays/all-displays.component';
import { NewDisplayModalComponent } from './components/modals/new-display-modal/new-display-modal.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LiveViewModalComponent } from './components/modals/live-view-modal/live-view-modal.component';
import { EditDisplayComponent } from './components/edit-display/edit-display.component';
import { AddMenuOrCollectionModalComponent } from './components/modals/add-menu-or-collection-modal/add-menu-or-collection-modal.component';
import { EditDisplayMainSectionFormComponent } from './components/forms/edit-display-main-section-form/edit-display-main-section-form.component';
import { MenuAddedToDisplayFormComponent } from './components/forms/menu-added-to-display-form/menu-added-to-display-form.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { EditDisplayContainerComponent } from './components/edit-display/edit-display-container/edit-display-container.component';
import { EditDisplayHeaderComponent } from './components/edit-display/edit-display-header/edit-display-header.component';
import { EditDisplayActionsComponent } from './components/edit-display/edit-display-actions/edit-display-actions.component';
import { EditDisplayDetailsComponent } from './components/edit-display/edit-display-details/edit-display-details.component';
import { EditDisplayMenuDetailsComponent } from './components/edit-display/edit-display-menu-details/edit-display-menu-details.component';
import { EditDisplayMenuDetailsActionComponent } from './components/edit-display/edit-display-menu-details/edit-display-menu-details-action/edit-display-menu-details-action.component';
import { DisplayMenuSectionComponent } from './components/display-section/display-menu-section/display-menu-section.component';
import { DisplayMenuSectionHeaderComponent } from './components/display-section/display-menu-section/display-menu-section-header/display-menu-section-header.component';
import { DisplayMenuSectionBodyComponent } from './components/display-section/display-menu-section/display-menu-section-body/display-menu-section-body.component';
import { MenuIncludedInDisplayPipe } from './pipes/menu-included-in-display.pipe';
import { TemplateImportedAtMyLocationPipe } from './pipes/template-imported-at-my-location.pipe';
import { GetMenuOptionsForDisplayPipe } from './pipes/get-menu-options-for-display.pipe';
import { ChooseDisplayContentOptionComponent } from './components/modals/add-menu-or-collection-modal/choose-display-content-option/choose-display-content-option.component';
import { AddMenuOrCollectionModalHeaderComponent } from './components/modals/add-menu-or-collection-modal/add-menu-or-collection-modal-header/add-menu-or-collection-modal-header.component';
import { SelectMenuOrCollectionComponent } from './components/modals/add-menu-or-collection-modal/select-menu-or-collection/select-menu-or-collection.component';
import { CollectionIncludedInDisplayPipe } from './pipes/collection-included-in-display.pipe';
import { DisplayTemplateCollectionSectionComponent } from './components/display-section/display-template-collection-section/display-template-collection-section.component';
import { CollectionRequiredInDisplayPipe } from './pipes/collection-required-in-display.pipe';
import { DisplayMenuSectionInsideTemplateCollectionComponent } from './components/display-section/display-template-collection-section/display-menu-section-inside-template-collection/display-menu-section-inside-template-collection.component';
import { DisplayMenuSectionInsideTemplateCollectionHeaderComponent } from './components/display-section/display-template-collection-section/display-menu-section-inside-template-collection/display-menu-section-inside-template-collection-header/display-menu-section-inside-template-collection-header.component';
import { DisplayMenuSectionInsideTemplateCollectionBodyComponent } from './components/display-section/display-template-collection-section/display-menu-section-inside-template-collection/display-menu-section-inside-template-collection-body/display-menu-section-inside-template-collection-body.component';
import { DisplayMenuSectionOptionsComponent } from './components/display-section/display-menu-section/display-menu-section-body/display-menu-section-options/display-menu-section-options.component';
import { DisplayMenuSectionBodyContainerComponent } from './components/display-section/display-menu-section/display-menu-section-body/display-menu-section-body-container/display-menu-section-body-container.component';
import { DisplayMenuSectionInsideTemplateCollectionOptionsComponent } from './components/display-section/display-template-collection-section/display-menu-section-inside-template-collection/display-menu-section-inside-template-collection-body/display-menu-section-inside-template-collection-options/display-menu-section-inside-template-collection-options.component';
import { DisplayMenuSectionOptionsContainerComponent } from './components/display-section/display-menu-section/display-menu-section-body/display-menu-section-options/display-menu-section-options-container/display-menu-section-options-container.component';
import { MenuWithinCollectionAddedToDisplayFormComponent } from './components/forms/menu-within-collection-added-to-display-form/menu-within-collection-added-to-display-form.component';
import { DisplayMenuSectionHeaderContainerComponent } from './components/display-section/display-menu-section/display-menu-section-header/display-menu-section-header-container/display-menu-section-header-container.component';
import { MenuResultsComponent } from './components/modals/add-menu-or-collection-modal/select-menu-or-collection-results/menu-results/menu-results.component';
import { RxPush } from '@rx-angular/template/push';
import { IsPublishedCollectionWithDraftTemplatePipe } from './pipes/is-published-collection-with-draft-template.pipe';
import { CollectionResultsComponent } from './components/modals/add-menu-or-collection-modal/select-menu-or-collection-results/collection-results/collection-results.component';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { DraftTemplateTooltipPipe } from './pipes/draft-template-tooltip.pipe';
import { ReactiveFormExtensionsModule } from '../../modules/reactive-form-extensions/reactive-form-extensions.module';
import { EditSpecificDateTimeModalComponent } from './components/modals/edit-specific-date-time-modal/edit-specific-date-time-modal.component';
import { RecurringDetailsFormComponent } from './components/modals/edit-specific-date-time-modal/recurring-details-form/recurring-details-form.component';
import { DateTimeWindowFormComponent } from './components/modals/edit-specific-date-time-modal/date-time-window-form/date-time-window-form.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';
import { StickyModalModule } from '@mobilefirstdev/sticky-modal';

@NgModule({
  declarations: [
    AllDisplaysComponent,
    NewDisplayModalComponent,
    LiveViewModalComponent,
    EditDisplayComponent,
    AddMenuOrCollectionModalComponent,
    EditDisplayMainSectionFormComponent,
    MenuAddedToDisplayFormComponent,
    EditDisplayContainerComponent,
    EditDisplayHeaderComponent,
    EditDisplayActionsComponent,
    EditDisplayDetailsComponent,
    EditDisplayMenuDetailsComponent,
    EditDisplayMenuDetailsActionComponent,
    DisplayMenuSectionComponent,
    DisplayMenuSectionHeaderComponent,
    DisplayMenuSectionBodyComponent,
    MenuIncludedInDisplayPipe,
    TemplateImportedAtMyLocationPipe,
    GetMenuOptionsForDisplayPipe,
    ChooseDisplayContentOptionComponent,
    AddMenuOrCollectionModalHeaderComponent,
    SelectMenuOrCollectionComponent,
    CollectionIncludedInDisplayPipe,
    DisplayTemplateCollectionSectionComponent,
    CollectionRequiredInDisplayPipe,
    DisplayMenuSectionInsideTemplateCollectionComponent,
    DisplayMenuSectionInsideTemplateCollectionHeaderComponent,
    DisplayMenuSectionInsideTemplateCollectionBodyComponent,
    DisplayMenuSectionOptionsComponent,
    DisplayMenuSectionBodyContainerComponent,
    DisplayMenuSectionInsideTemplateCollectionOptionsComponent,
    DisplayMenuSectionOptionsContainerComponent,
    MenuWithinCollectionAddedToDisplayFormComponent,
    DisplayMenuSectionHeaderContainerComponent,
    EditSpecificDateTimeModalComponent,
    RecurringDetailsFormComponent,
    DateTimeWindowFormComponent,
    MenuResultsComponent,
    IsPublishedCollectionWithDraftTemplatePipe,
    CollectionResultsComponent,
    DraftTemplateTooltipPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DisplayRoutingModule,
    NgbTooltipModule,
    ReactiveFormModule,
    FlexLayoutModule,
    ReactiveButtonsModule,
    RxPush,
    RxFor,
    RxIf,
    ReactiveFormExtensionsModule,
    StickyAutoPositionDirective,
    StickyModalModule,
  ],
  exports: [
    EditDisplayContainerComponent,
    EditDisplayActionsComponent,
    DisplayMenuSectionComponent,
    EditDisplayMenuDetailsComponent,
    CollectionResultsComponent,
    SelectMenuOrCollectionComponent,
  ],
  providers: []
})
export class DisplayModule {
}
