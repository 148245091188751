<div
  libStickyAutoPosition
  [stickyZ]="13"
  [stickyCollectionKey]="'select-stacks'"
  [stickyOrder]="0"
  class="pt-16px bg-white">
  <app-search-with-look-ahead
    [placeHolder]="'Search card stacks by name'"
    [lookAheadItems]="viewModel.menuStacksToSelectFrom$ | push"
    [searchTheseProperties]="['name']"
    [disablePopper]="true"
    [nLookAheadItems]="10000"
    [outputAllItemsWhenSearchIsEmpty]="true"
    (lookAheadHits)="viewModel.connectToSearchedMenus($event)">
  </app-search-with-look-ahead>
</div>
<div class="modal-search-results-container">
  <app-print-job-menu-form
    [type]="'stack'"
    [job]="viewModel.job$ | push"
    [menuChoices]="viewModel.searchedMenus$ | push"
    [menusAsSelectionItem]="viewModel.menusAsSelectionItem$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [selectedMenuIds]="viewModel.selectedStackIds$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    (add)="add.emit($event)"
    (remove)="remove.emit($event)"
    (bulkAdd)="bulkAdd.emit($event)"
    (bulkRemove)="bulkRemove.emit($event)">
  </app-print-job-menu-form>
</div>
