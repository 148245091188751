import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { EditVariantTerpenesViewModel } from '../edit-variant-terpenes-view-model';
import { debounceTime, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { exists } from '../../../../../../../../functions/exists';
import { TerpeneUnitOfMeasure } from '../../../../../../../../models/utils/dto/terpene-unit-of-measure-type';

export interface TerpenePreviewInfo {
  terpene: string;
  title: string;
  value: string;
  source: string;
}

type TerpeneStringHelper = { terpeneName: string, pascalCased: string, camelCased: string };

@Injectable()
export class TerpenesPreviewViewModel extends BaseViewModel {

  constructor(
    private editVariantTerpenesViewModel: EditVariantTerpenesViewModel
  ) {
    super();
  }

  public variant$ = this.editVariantTerpenesViewModel.container.variant$;
  public companyDA$ = this.editVariantTerpenesViewModel.updatedCompanyDA$;
  public locationDA$ = this.editVariantTerpenesViewModel.updatedLocationDA$;
  public useRange$ = this.editVariantTerpenesViewModel.container.useTerpeneRange$;
  public terpeneUnitOfMeasure$ = this.editVariantTerpenesViewModel.selectedTUOM$;
  public enabledIndividualTerpeneNames$ = this.editVariantTerpenesViewModel.enabledTerpenesNamesData$;
  public enabledTerpeneNames$ = this.editVariantTerpenesViewModel.enabledTerpeneNames$;
  public posSupportedTerpeneNames$ = this.editVariantTerpenesViewModel.posSupportedEnabledTerpeneNames$;
  public otherEnabledTerpeneNames$ = this.editVariantTerpenesViewModel.otherEnabledTerpeneNames$;
  public hasEnabledTerpenes$ = this.editVariantTerpenesViewModel.hasEnabledTerpenes$;
  public hasPosSupportedTerpenes$ = this.editVariantTerpenesViewModel.hasPosSupportedTerpenes$;
  private locationTerpenesFromDisplayAttributes$ =
    this.editVariantTerpenesViewModel.locationPresentTerpenesFromDisplayAttributes$;

  public totalTerpenePreviewInfo$ = this.useRange$.pipe(
    switchMap(useRange => {
      const totalTerpene = { terpeneName: 'Total Terpenes', camelCased: 'totalTerpene', pascalCased: 'TotalTerpene' };
      return this.createTerpenePreviewsFromList$([totalTerpene], useRange, false);
    })
  );

  public topTerpenePreviewInfo$ = this.useRange$.pipe(
    switchMap(useRange => {
      const terpenes = [
        { terpeneName: 'Present Terpenes', camelCased: 'presentTerpenes', pascalCased: 'PresentTerpenes' },
        { terpeneName: 'Top Terpene', camelCased: 'topTerpene', pascalCased: 'TopTerpene' }
      ];
      return this.createTerpenePreviewsFromList$(terpenes, useRange, false);
    })
  );

  public individualTerpenesPreviewInfo$ = combineLatest([
    this.enabledIndividualTerpeneNames$,
    this.useRange$,
  ]).pipe(
    switchMap(([individualTerpeneNames, useRange]) => {
      return this.createTerpenePreviewsFromList$(individualTerpeneNames, useRange);
    }),
    startWith([])
  );

  public posSupportedTerpenesPreviewInfo$ = combineLatest([
    this.posSupportedTerpeneNames$,
    this.useRange$,
  ]).pipe(
    switchMap(([individualTerpeneNames, useRange]) => {
      return this.createTerpenePreviewsFromList$(individualTerpeneNames, useRange);
    }),
    startWith([])
  );

  public otherEnabledTerpenesPreviewInfo$ = combineLatest([
    this.otherEnabledTerpeneNames$,
    this.useRange$,
  ]).pipe(
    switchMap(([individualTerpeneNames, useRange]) => {
      return this.createTerpenePreviewsFromList$(individualTerpeneNames, useRange);
    }),
    startWith([])
  );

  public readonly allTerpenePreviewInfo$ = combineLatest([
    this.totalTerpenePreviewInfo$,
    this.topTerpenePreviewInfo$,
    this.individualTerpenesPreviewInfo$,
    this.posSupportedTerpenesPreviewInfo$,
    this.otherEnabledTerpenesPreviewInfo$
  ]).pipe(
    debounceTime(1), // the above inputs get spammed when an input changes, so debounce to prevent multiple calculations
    map(([total, top, individual, posSupported, other]) => {
      return [
        ...(total ?? []),
        ...(top ?? []),
        ...(individual ?? []),
        ...(posSupported ?? []),
        ...(other ?? [])
      ]?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private createTerpenePreviewsFromList$(
    terpenes: TerpeneStringHelper[],
    useRange: boolean,
    includeTUOM: boolean = true
  ): Observable<TerpenePreviewInfo[]> {
    const previewObservables$ = terpenes?.map(terpene => {
      switch (terpene.camelCased) {
        case 'presentTerpenes':
          return this.createPresentTerpenePreviewInfo$();
        case 'totalTerpene':
          return this.createTotalTerpenePreview$(useRange, terpene);
        case 'topTerpene':
          return this.createTopTerpenePreviewInfo$();
        default:
          return this.createTerpenePreviewInfo$(terpene, useRange, includeTUOM);
      }
    });
    return combineLatest(previewObservables$).pipe(
      map((previewResults) => previewResults?.flatten() ?? [])
    );
  }

  private createTerpenePreviewInfo$(
    terpene: TerpeneStringHelper,
    useRange: boolean,
    includeTUOM: boolean = true
  ): Observable<TerpenePreviewInfo|TerpenePreviewInfo[]> {
    if (useRange) {
      return combineLatest([
        this.createTerpenePreviewInfoHelper$('min' + terpene.pascalCased, 'Min ' + terpene.terpeneName, includeTUOM),
        this.createTerpenePreviewInfoHelper$('max' + terpene.pascalCased, 'Max ' + terpene.terpeneName, includeTUOM),
      ]);
    }
    return this.createTerpenePreviewInfoHelper$(terpene.camelCased, terpene.terpeneName, includeTUOM);
  }

  private createTerpenePreviewInfoHelper$(
    terpene: string,
    title: string,
    includeTUOM: boolean = true
  ): Observable<TerpenePreviewInfo> {
    return combineLatest([
      this.companyDA$,
      this.locationDA$,
      this.terpeneUnitOfMeasure$
    ]).pipe(
      map(([ companyDA, locationDA, tuom]) => {
        const companyDATerpene = companyDA?.[terpene];
        const locationDATerpene = locationDA?.[terpene];
        let val: string;
        let source: string;
        if (exists(locationDATerpene)) {
          val = locationDATerpene;
          source = '(Location)';
        } else if (exists(companyDATerpene)) {
          val = companyDATerpene;
          source = '(Company)';
        }
        const infoUOM = includeTUOM ? tuom : '';
        const info = (exists(val) && tuom !== TerpeneUnitOfMeasure.NA && tuom !== TerpeneUnitOfMeasure.UNKNOWN)
            ? val + infoUOM
            : '--';
        if (info === '--') source = '';
        return {
          terpene: terpene,
          title: title,
          value: info,
          source: source,
        };
      })
    );
  }

  private createPresentTerpenePreviewInfo$(): Observable<TerpenePreviewInfo> {
    return combineLatest([
      this.companyDA$,
      this.locationDA$,
      this.locationTerpenesFromDisplayAttributes$
    ]).pipe(
      map(([companyDA, locationDA, locationTerpenesFromDA]) => {
        const companyPresentTerpenes = companyDA?.presentTerpenes ?? [];
        const locationPresentTerpenes = locationDA?.presentTerpenes ?? [];
        let val: number = 0;
        let source: string;
        if (locationPresentTerpenes?.length > 0) {
          val = locationPresentTerpenes.length;
          source = '(Location)';
        } else if (companyPresentTerpenes?.length > 0) {
          val = companyPresentTerpenes?.length;
          source = '(Company)';
        } else {
          val = (locationTerpenesFromDA || []).unique().length;
          source = val > 0 ? '(Auto-calculated)' : '';
        }
        return {
          terpene: 'presentTerpenes',
          title: 'Present Terpenes',
          value: (val || '--').toString(),
          source: source,
        };
      })
    );
  }

  private createTopTerpenePreviewInfo$() {
    return combineLatest([this.variant$, this.locationDA$, this.companyDA$, this.enabledTerpeneNames$]).pipe(
      map(([variant, locationDA, companyDA, enabledIndividualTerpeneNames]) => {
        let val: string;
        let src: string;
        const locationDAtopTerpene = locationDA?.topTerpene;
        const companyDAtopTerpene = companyDA?.topTerpene;
        const topTerpenePreview = variant.getAutoCalculatedTopTerpene(
          enabledIndividualTerpeneNames,
          [locationDA, companyDA]
        );
        const ifExistsAndEnabledUseThis = (x: any, enabledNames: string[], useThisInstead?: any) => {
          return exists(x) && enabledNames?.includes(x) ? (useThisInstead ?? x) : null;
        };
        val = ifExistsAndEnabledUseThis(locationDAtopTerpene, enabledIndividualTerpeneNames)
           || ifExistsAndEnabledUseThis(companyDAtopTerpene, enabledIndividualTerpeneNames);
        src = ifExistsAndEnabledUseThis(locationDAtopTerpene, enabledIndividualTerpeneNames, '(Location)')
           || ifExistsAndEnabledUseThis(companyDAtopTerpene, enabledIndividualTerpeneNames, '(Company)');
        if (!val && topTerpenePreview) {
          val = topTerpenePreview;
          src = '(Auto-calculated)';
        }
        return {
          terpene: 'topTerpene',
          title: 'Top Terpene',
          value: val || '--',
          source: src || '',
        };
      })
    );
  }

  private createTotalTerpenePreview$(
    useRange: boolean,
    terpene: TerpeneStringHelper
  ): Observable<TerpenePreviewInfo|TerpenePreviewInfo[]> {
    if (useRange) {
      return combineLatest([
        this.createTotalTerpenesPreviewInfoHelpeer$('min' + terpene.pascalCased, 'Min ' + terpene.terpeneName),
        this.createTotalTerpenesPreviewInfoHelpeer$('max' + terpene.pascalCased, 'Max ' + terpene.terpeneName),
      ]);
    }
    return this.createTotalTerpenesPreviewInfoHelpeer$(terpene.camelCased, terpene.terpeneName);
  }

  private createTotalTerpenesPreviewInfoHelpeer$(terpene: string, title: string) {
    return combineLatest([
      this.variant$,
      this.locationDA$,
      this.companyDA$,
      this.enabledTerpeneNames$,
      this.terpeneUnitOfMeasure$
    ]).pipe(
      map(([variant, locationDA, companyDA, enabledTerpeneNames, tuom]) => {
        let val: string;
        let src: string;
        const locationDAtopTerpene = locationDA?.[terpene];
        const companyDAtopTerpene = companyDA?.[terpene];
        const min = terpene?.substring(0, 3) === 'min' ? 'min' : '';
        const max = terpene?.substring(0, 3) === 'max' ? 'max' : '';
        const rangeAccessor = min || max || null;
        const totalTerpenesPreview = variant.getAutoCalculatedTotalTerpenes(
          enabledTerpeneNames,
          rangeAccessor,
          [locationDA, companyDA]
        )?.toString();
        const ifExistsUseThis = (x: any, useThisInstead?: any) => exists(x) ? (useThisInstead ?? x) : null;
        val = ifExistsUseThis(locationDAtopTerpene) || ifExistsUseThis(companyDAtopTerpene);
        src = ifExistsUseThis(locationDAtopTerpene, '(Location)') || ifExistsUseThis(companyDAtopTerpene, '(Company)');
        if (!val && exists(totalTerpenesPreview)) {
          val = totalTerpenesPreview;
          src = '(Auto-calculated)';
        }
        const infoUOM = tuom ? tuom : '';
        const info = (exists(val) && tuom !== TerpeneUnitOfMeasure.NA && tuom !== TerpeneUnitOfMeasure.UNKNOWN)
          ? val + infoUOM
          : '--';
        return {
          terpene: terpene,
          title: title,
          value: info,
          source: src,
        };
      })
    );
  }

}
