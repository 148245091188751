import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'pascalCaseToSentenceCase'
})
export class PascalCaseToSentenceCasePipe implements PipeTransform {

  transform(pascalCased: string): string {
    return StringUtils.splitPascalCaseIntoSentence(pascalCased);
  }

}
