<thead class="header-container">
  <tr [style.visibility]="loading ? 'hidden' : null">
    <ng-container
      *rxFor="
        let header of viewModel.productTableColumnHeaders$ | push : 'userBlocking';
        trackBy: viewModel.trackByName
      ">
      <ng-container
        [ngSwitch]="
          header
            | ProductTableColumnKeyToData
              : (viewModel.enabledCannabinoids$ | push : 'userBlocking')
              : (viewModel.enabledTerpenes$ | push : 'userBlocking')
        ">
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Name" resizable class="fixed-column">
          <div class="header-title" (click)="nameSort.clicked()">Name</div>
          <lib-reactive-table-asc-desc-sort
            #nameSort
            fxFlex
            [ascSort]="productNameAsc"
            [ascSubSort]="variantNameAsc"
            [descSort]="productNameDesc"
            [descSubSort]="variantNameDesc"></lib-reactive-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Brand" resizable>
          <div class="header-title" (click)="brandSort.clicked()">Brand</div>
          <app-table-asc-desc-sort
            #brandSort
            fxFlex
            [ascSort]="productBrandAsc"
            [ascSubSort]="variantBrandAsc"
            [descSort]="productBrandDesc"
            [descSubSort]="variantBrandDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.ProductType" resizable>
          <div class="header-title" (click)="typeSort.clicked()">Type</div>
          <app-table-asc-desc-sort
            #typeSort
            fxFlex
            [ascSort]="productTypeAsc"
            [ascSubSort]="variantTypeAsc"
            [descSort]="productTypeDesc"
            [descSubSort]="variantTypeDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.StrainType" resizable>
          <div class="header-title" (click)="strainSort.clicked()">Strain Type</div>
          <app-table-asc-desc-sort
            #strainSort
            fxFlex
            [ascSort]="productStrainAsc"
            [ascSubSort]="variantStrainAsc"
            [descSort]="productStrainDesc"
            [descSubSort]="variantStrainDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Quantity" resizable>
          <div class="header-title" (click)="qtySort.clicked()">Quantity</div>
          <app-table-asc-desc-sort
            #qtySort
            fxFlex
            [ascSort]="productQtyAsc"
            [ascSubSort]="variantQtyAsc"
            [descSort]="productQtyDesc"
            [descSubSort]="variantQtyDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Price" resizable>
          <div class="header-title" (click)="priceSort.clicked()">Price</div>
          <app-table-asc-desc-sort
            #priceSort
            fxFlex
            [ascSort]="productPriceAsc"
            [ascSubSort]="variantPriceAsc"
            [descSort]="productPriceDesc"
            [descSubSort]="variantPriceDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.SecondaryPrice" resizable>
          <div class="header-title" (click)="secondaryPriceSort.clicked()">Secondary Price</div>
          <app-table-asc-desc-sort
            #secondaryPriceSort
            fxFlex
            [ascSort]="productSecondaryPriceAsc"
            [ascSubSort]="variantSecondaryPriceAsc"
            [descSort]="productSecondaryPriceDesc"
            [descSubSort]="variantSecondaryPriceDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Label" resizable>
          <div class="header-title" (click)="labelSort.clicked()">Label</div>
          <app-table-asc-desc-sort
            #labelSort
            fxFlex
            [ascSort]="productLabelAsc"
            [ascSubSort]="variantLabelAsc"
            [descSort]="productLabelDesc"
            [descSubSort]="variantLabelDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Badge" resizable>
          <div class="header-title" (click)="badgeSort.clicked()">Badge</div>
          <app-table-asc-desc-sort
            #badgeSort
            fxFlex
            [ascSort]="productBadgeAsc"
            [descSort]="productBadgeDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.TopTerpene" resizable>
          <div class="header-title">Top Terpene</div>
          <app-table-asc-desc-sort
            #topTerpeneSort
            fxFlex
            [ascSort]="productTopTerpeneAsc"
            [ascSubSort]="variantTopTerpeneAsc"
            [descSort]="productTopTerpeneDesc"
            [descSubSort]="variantTopTerpeneDesc"></app-table-asc-desc-sort>
        </th>
        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Cannabinoid">
          <th *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'" resizable>
            <ng-container *ngIf="header === PrimaryCannabinoid.TAC; else restOfCannabinoids">
              <div class="header-title" (click)="minCannabinoidSort.clicked()">Min {{ header }}</div>
              <app-table-asc-desc-sort
                #minCannabinoidSort
                fxFlex
                [ascSort]="productMinTacAsc"
                [ascSubSort]="variantMinTacAsc"
                [descSort]="productMinTacDesc"
                [descSubSort]="variantMinTacDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfCannabinoids>
              <div class="header-title" (click)="minCannabinoidSort.clicked()">Min {{ header }}</div>
              <app-table-asc-desc-sort
                #minCannabinoidSort
                fxFlex
                [ascSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty : 'min')?.productSort
                "
                [ascSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty : 'min')?.variantSort
                "
                [descSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty : 'min')?.productSort
                "
                [descSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty : 'min')?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>

          <th *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'" resizable>
            <ng-container *ngIf="header === PrimaryCannabinoid.TAC; else restOfCannabinoids">
              <div class="header-title" (click)="maxCannabinoidSort.clicked()">Max {{ header }}</div>
              <app-table-asc-desc-sort
                #maxCannabinoidSort
                fxFlex
                [ascSort]="this.productMaxTacAsc"
                [ascSubSort]="variantMaxTacAsc"
                [descSort]="this.productMaxTacDesc"
                [descSubSort]="variantMaxTacDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfCannabinoids>
              <div class="header-title" (click)="maxCannabinoidSort.clicked()">Max {{ header }}</div>
              <app-table-asc-desc-sort
                #maxCannabinoidSort
                fxFlex
                [ascSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty : 'max')?.productSort
                "
                [ascSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty : 'max')?.variantSort
                "
                [descSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty : 'max')?.productSort
                "
                [descSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty : 'max')?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>

          <th *rxIf="!(viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking')" resizable>
            <ng-container *ngIf="header === PrimaryCannabinoid.TAC; else restOfCannabinoids">
              <div class="header-title" (click)="cannabinoidSort.clicked()">{{ header }}</div>
              <app-table-asc-desc-sort
                #cannabinoidSort
                fxFlex
                [ascSort]="productTacAsc"
                [ascSubSort]="variantTacAsc"
                [descSort]="productTacDesc"
                [descSubSort]="variantTacDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfCannabinoids>
              <div class="header-title" (click)="cannabinoidSort.clicked()">{{ header }}</div>
              <app-table-asc-desc-sort
                #cannabinoidSort
                fxFlex
                [ascSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty)?.productSort
                "
                [ascSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'asc' : this.variantProperty)?.variantSort
                "
                [descSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty)?.productSort
                "
                [descSubSort]="
                  (header | getTableSortingFunctionsForCannabinoid : 'desc' : this.variantProperty)?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Terpene">
          <th *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'" resizable>
            <ng-container *ngIf="header === 'Total Terpene'; else restOfTerpenes">
              <div class="header-title" (click)="minTerpeneSort.clicked()">Min {{ header }}</div>
              <app-table-asc-desc-sort
                #minTerpeneSort
                fxFlex
                [ascSort]="productMinTotalTerpeneAsc"
                [ascSubSort]="variantMinTotalTerpeneAsc"
                [descSort]="productMinTotalTerpeneDesc"
                [descSubSort]="variantMinTotalTerpeneDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfTerpenes>
              <div class="header-title" (click)="minTerpeneSort.clicked()">Min {{ header }}</div>
              <app-table-asc-desc-sort
                #minTerpeneSort
                fxFlex
                [ascSort]="
                  (header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty : 'min')?.productSort
                "
                [ascSubSort]="
                  (header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty : 'min')?.variantSort
                "
                [descSort]="
                  (header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty : 'min')?.productSort
                "
                [descSubSort]="
                  (header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty : 'min')?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>

          <th *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'" resizable>
            <ng-container *ngIf="header === 'Total Terpene'; else restOfTerpenes">
              <div class="header-title" (click)="maxTerpeneSort.clicked()">Max {{ header }}</div>
              <app-table-asc-desc-sort
                #maxTerpeneSort
                fxFlex
                [ascSort]="productMaxTotalTerpeneAsc"
                [ascSubSort]="variantMaxTotalTerpeneAsc"
                [descSort]="productMaxTotalTerpeneDesc"
                [descSubSort]="variantMaxTotalTerpeneDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfTerpenes>
              <div class="header-title" (click)="maxTerpeneSort.clicked()">Max {{ header }}</div>
              <app-table-asc-desc-sort
                #maxTerpeneSort
                fxFlex
                [ascSort]="
                  (header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty : 'max')?.productSort
                "
                [ascSubSort]="
                  (header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty : 'max')?.variantSort
                "
                [descSort]="
                  (header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty : 'max')?.productSort
                "
                [descSubSort]="
                  (header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty : 'max')?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>

          <th *rxIf="!(viewModel.displayTerpenesInRanges$ | push : 'userBlocking')" resizable>
            <ng-container *ngIf="header === 'Total Terpene'; else restOfTerpenes">
              <div class="header-title" (click)="terpeneSort.clicked()">{{ header }}</div>
              <app-table-asc-desc-sort
                #terpeneSort
                fxFlex
                [ascSort]="productTotalTerpeneAsc"
                [ascSubSort]="variantTotalTerpeneAsc"
                [descSort]="productTotalTerpeneDesc"
                [descSubSort]="variantTotalTerpeneDesc"></app-table-asc-desc-sort>
            </ng-container>
            <ng-template #restOfTerpenes>
              <div class="header-title" (click)="terpeneSort.clicked()">{{ header }}</div>
              <app-table-asc-desc-sort
                #terpeneSort
                fxFlex
                [ascSort]="(header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty)?.productSort"
                [ascSubSort]="(header | getTableSortingFunctionsForTerpene : 'asc' : this.variantProperty)?.variantSort"
                [descSort]="(header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty)?.productSort"
                [descSubSort]="
                  (header | getTableSortingFunctionsForTerpene : 'desc' : this.variantProperty)?.variantSort
                ">
              </app-table-asc-desc-sort>
            </ng-template>
          </th>
        </ng-container>
      </ng-container>
    </ng-container>
  </tr>
</thead>
