import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { exists } from '../../../../../functions/exists';
import type { StrainClassificationType } from '../../../../../models/utils/dto/strain-classification-type';
import type { ProductTypeDefinition } from '../../../../../models/utils/dto/product-type-definition';
import type { ProductType } from '../../../../../models/enum/dto/product-type';

@Injectable()
export class ProductAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor() {
    super();
  }

  public dropdownProductTypes$ = window?.types?.productTypes$ || of<ProductTypeDefinition[]>([]);
  public dropdownClassificationTypes$ = window?.types?.strainTypes$ || of<StrainClassificationType[]>([]);

  private _minPrice = new BehaviorSubject<number>(0.01);
  public minPrice$ = this._minPrice as Observable<number>;
  connectToMinPrice = (minPrice: number) => this._minPrice.next(this.minNumberToSet(minPrice));

  private _maxPrice = new BehaviorSubject<number>(undefined);
  public maxPrice$ = this._maxPrice as Observable<number>;
  connectToMaxPrice = (maxPrice: number) => this._maxPrice.next(maxPrice);

  private _minSecondaryPrice = new BehaviorSubject<number>(0.01);
  public minSecondaryPrice$ = this._minSecondaryPrice as Observable<number>;
  connectToMinSecondaryPrice = (minSecondaryPrice: number) => {
    this._minSecondaryPrice.next(this.minNumberToSet(minSecondaryPrice));
  };

  private _maxSecondaryPrice = new BehaviorSubject<number>(undefined);
  public maxSecondaryPrice$ = this._maxSecondaryPrice as Observable<number>;
  connectToMaxSecondaryPrice = (maxSecondaryPrice: number) => this._maxSecondaryPrice.next(maxSecondaryPrice);

  private _selectedProductType = new BehaviorSubject<ProductType>(null);
  public selectedProductType$ = this._selectedProductType as Observable<ProductType>;
  public connectToSelectedProductType = (pt: ProductType) => this._selectedProductType.next(pt);

  public enableVariantType$ = combineLatest([
    this.isViewOnly$,
    this.selectedProductType$
  ]).pipe(
    map(([isViewOnly, selectedProductType ]) => {
      return !isViewOnly && !!selectedProductType;
    })
  );

  private listenToSmartFilter = this.smartFilter$.subscribeWhileAlive({
    owner: this,
    next: sf => {
      if (exists(sf?.minPrice)) this.connectToMinPrice(sf?.minPrice);
      if (exists(sf?.maxPrice)) this.connectToMaxPrice(sf?.maxPrice);
      if (exists(sf?.minSecondaryPrice)) this.connectToMinSecondaryPrice(sf?.minSecondaryPrice);
      if (exists(sf?.maxSecondaryPrice)) this.connectToMaxSecondaryPrice(sf?.maxSecondaryPrice);
    }
  });

  private minNumberToSet(price: number): number {
    return exists(price) ? price : 0.01;
  }

}
